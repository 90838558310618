import { useEffect, useState } from 'react';

import SocialMediaPost from './SocialMediaPost';
import loadingStore from '../../../store/loading';
import widgetApi from '../../../api/widget';
import { Carousel } from 'react-responsive-carousel';

const { setLoading } = loadingStore;

const MediaPost = () => {
  const [sliderItems, setSliderItems] = useState(null);

  const fetchRecentPosts = () => {
    setLoading(true);
    widgetApi
      .getRecentPosts()
      .then((response) => {
        const posts = [];
        Object.entries(response).forEach((result) => {
          const channelType = result[0];
          result[1].map((item) =>
            posts.push({
              url: item,
              type: channelType
            })
          );
        });
        setSliderItems(posts);
        setLoading(false);
      })
      .catch((_error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRecentPosts();
  }, []);

  return (
    <>
      <div className='h-screen'>
        <Carousel
          className='h-full'
          infiniteLoop
          autoPlay={true}
          stopOnHover={true}
          showStatus={false}
          showThumbs={false}
          dynamicHeight={true}
          axis="vertical"
          centerMode={true}
          centerSlidePercentage={60}
          thumbWidth={100}
          interval={3000}
          transitionTime={1000}
        >
          {sliderItems?.map((post, index) => (
            <div key={index} className="rounded-lg mt-5">
              <SocialMediaPost
                type={post.type}
                content={post.url}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default MediaPost;

import PropTypes from 'prop-types';
import { Button, Container, Modal, Typography, Stack } from '@mui/material';
import ModalHead from './Header/modalHead';
import useSettings from '../../hooks/useSettings';

DeleteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  onDelete: PropTypes.func,
  del: PropTypes.array
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};
export default function DeleteConfirmationModal ({
  open,
  handleCancel,
  handleConfirm,
  del,
  selected
}) {
  const { themeStretch } = useSettings();

  return (
    <Modal open={open} onClose={handleCancel} style={{ zIndex: 1111111 }}>
      <div
        style={{
          ...modalStyle,
          backgroundColor: 'white',
          width: '800px'
        }}
      >
        <ModalHead handleClose={handleCancel} heading={`Delete ${del[0]}`} />
        <div className="flex justify-center">
          <img
            alt="delete"
            src={process.env.PUBLIC_URL + '/static/icons/delete-icon2.png'}
          />
        </div>
        <Stack style={{ textAlign: 'center' }}>
          <Typography
            component="span"
            variant="h6"
            style={{
              marginTop: '40px',
              marginBottom: '15px'
            }}
          >
            {selected
              ? `Are you sure want to delete ${selected.length} ${
                  selected.length > 1 ? del[1] : del[0]
                }?`
              : `Are you sure want to delete this ${del}?`}
          </Typography>
        </Stack>
        <Container
          maxWidth={themeStretch ? false : 'lg'}
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            padding: '20px',
            marginBottom: '40px'
          }}
        >
          <Button
            className="mrvn-del-cln rounded text-lg"
            onClick={handleCancel}
            sx={{ color: 'text.secondary', marginRight: '8px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            className="mrvn-delete rounded text-lg"
          >
            Delete
          </Button>
        </Container>
      </div>
    </Modal>
  );
}

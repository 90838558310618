import { Box, Card, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { publicationStatuses } from '../../../constants/publicationStatues';
import loadingStore from '../../../store/loading';
import content from '../../../api/content';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import convertToUtc from '../../../utils/convertToUtc';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    height: '5rem',
    border: `1px solid ${theme.palette.blue.main}`,
    borderRadius: 20
  }
}));

const { setLoading } = loadingStore;

const PostingSummary = () => {
  const classes = useStyles();
  const [summary, setSummary] = useState('');

  const fetchPostingSummary = () => {
    const params = {
      startDate: convertToUtc(dayjs().startOf('date')),
      endDate: convertToUtc(dayjs().endOf('date'))
    };

    content
      .summary(params)
      .then((res) => {
        setLoading(true);
        setSummary(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPostingSummary();
  }, []);

  return (
    <Card style={{ boxShadow: 'none' }}>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={4}>
          <div className="mb-2 mt-4">
            <div>
              <h4 className="font-semibold">Posting Summary</h4>
            </div>
            <p className='text-xs'>
              Here’s a summary of your posts across all your social accounts.
            </p>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Link
            to={'/contents/search?status=PUBLISHED'}
            style={{ textDecoration: 'none' }}
          >
            <Box className={classes.box}>
              <Stack className="p-2" direction="row" alignItems="center" justifyContent="center">
                <span className="w-14">
                  {publicationStatuses.PUBLISHED.icon}
                </span>
                <Stack
                  className="ml-2 text-black"
                  direction="column"
                  alignItems="start"
                >
                  <Typography className="mb-[-8px]" variant="h5">
                    <b>{summary.published}</b>
                  </Typography>
                  <Typography>Published</Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Link
            to={'/contents/search?status=SCHEDULED'}
            style={{ textDecoration: 'none' }}
          >
            <Box className={classes.box}>
              <Stack className="p-2" direction="row" alignItems="center" justifyContent="center">
                <span className="w-14">
                  {publicationStatuses.SCHEDULED.icon}
                </span>
                <Stack
                  className="ml-2 text-black"
                  direction="column"
                  alignItems="start"
                >
                  <Typography className="mb-[-8px]" variant="h5">
                    <b>{summary.queued}</b>
                  </Typography>
                  <Typography>Queued</Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Link
            to="/contents/search?status=DRAFT"
            style={{ textDecoration: 'none' }}
          >
            <Box className={classes.box}>
              <Stack className="p-2" direction="row" alignItems="center" justifyContent="center">
                <span className="w-14">
                  {publicationStatuses.DRAFT.icon}
                </span>

                <Stack
                  className="ml-2 text-black"
                  direction="column"
                  justifyContent="start"
                  alignItems="start"
                >
                  <Typography className="mb-[-8px]" variant="h5">
                    <b>{summary.draft}</b>
                  </Typography>
                  <Typography>Draft</Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Link
            to="/contents/search?status=FAILED"
            style={{ textDecoration: 'none' }}
          >
            <Box className={classes.box}>
              <Stack className="p-2" direction="row" alignItems="center" justifyContent="center">
                <span className="w-14">
                  {publicationStatuses.FAILED.icon}
                </span>

                <Stack
                  className="ml-2 text-black"
                  direction="column"
                  justifyContent="start"
                  alignItems="start"
                >
                  <Typography className="mb-[-8px]" variant="h5">
                    <b>{summary.error}</b>
                  </Typography>
                  <Typography>Failed</Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PostingSummary;

import { AddCircleOutlineOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Chip,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import channelsStore from '../../store/channel/channel';

const ChannelInstanceMultiSelect = ({ companyId, updateOnChange, channelInstances }) => {
  const { fetchAllChannels } = channelsStore;

  const [selectedChannels, setSelectedChannels] = useState([]);
  const [allChannels, setAllChannels] = useState([]);

  useEffect(() => {
    fetchAllChannels(companyId).then((response) => {
      setAllChannels(response);
    });
  }, [companyId]);

  useEffect(() => {
    if (channelInstances?.length > 0 && allChannels.length > 0) {
      setSelectedChannels(channelInstances.map(instance => allChannels.find(ch => ch.id === instance.id)));
    }
  }, [channelInstances, allChannels]);

  const handleDelete = (channelName) => {
    const filteredChannels = selectedChannels.filter((c) => c.name !== channelName);
    setSelectedChannels(filteredChannels);
    updateOnChange(filteredChannels);
  };

  return (
    <div className="py-5">
      <InputLabel className="font-black pb-2">Channel Instance</InputLabel>
      <Autocomplete
        multiple
        filterSelectedOptions
        disableClearable
        limitTags={0}
        options={allChannels}
        value={selectedChannels}
        getOptionLabel={(option) => option.name}
        className="mb-3"
        onChange={(event, newValue) => {
          updateOnChange(newValue);
          setSelectedChannels(newValue);
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.id}
              className="flex justify-content-between p-2"
            >
              <span className="flex gap-2">
                <Avatar
                  sx={{ width: 26, height: 26 }}
                  className="text-white"
                  src={
                    option.channelImageUrl || process.env.REACT_APP_PLACEHOLDER_IMAGE
                  }
                />
                <Typography>{option.name}</Typography>
              </span>
              <AddCircleOutlineOutlined fontSize="small" color="primary" />
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} placeholder="Choose" />}
      />
      <Stack
        direction={'row'}
        useFlexGap
        flexWrap="wrap"
        style={{ gap: '8px' }}
      >
        {selectedChannels.length > 0 &&
          selectedChannels.map((channel, index) => (
            <Chip
              avatar={
                <Avatar
                  src={
                    channel.channelImageUrl || process.env.REACT_APP_PLACEHOLDER_IMAGE
                  }
                  className="text-white"
                />
              }
              key={index}
              label={channel.name}
              style={{ backgroundColor: 'hsla(192, 68%, 41%, 0.07)' }}
              onDelete={handleDelete.bind(this, channel.name)}
            />
          ))}
      </Stack>
    </div>
  );
};

export default ChannelInstanceMultiSelect;

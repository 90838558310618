import { filter } from 'lodash';
import { useState, useEffect, Fragment } from 'react';
import {
  Card,
  Table,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Button
  , Pagination, Stack
} from '@mui/material';

import ListToolbar from '../../components/Global/ListToolbar';
import ListHead from '../../components/Global/ListHead';

import userStore from '../../store/security/user';
import companyStore from '../../store/organisation/company';

import { observer } from 'mobx-react';
import moment from 'moment';

import { useSnackbar } from 'notistack';

import { RxCross1 } from 'react-icons/rx';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.givenName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.familyName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.emailAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const UserList = ({ close, onSubmit }) => {
  const {
    state: { users },
    getUsers
  } = userStore;

  const { getCompany } = companyStore;

  const { enqueueSnackbar } = useSnackbar();

  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const rowsPerPage = 10;
  const itemsPerPage = 10;
  const page = 0;

  const TABLE_HEAD = [
    { id: 'givenName', label: 'User', isSorted: orderBy === 'givenName' },
    {
      id: 'company',
      label: 'Primary Company'
    },
    {
      id: 'emailAddress',
      label: 'Email',
      isSorted: orderBy === 'emailAddress'
    },
    {
      id: 'isVerified',
      label: 'License Type'
    },
    { id: 'statusFull', label: 'Status' },
    { id: 'lastlogin', label: 'Last Login' }
  ];

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getUsers().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch users', { variant: 'error' })
    );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  const modalStyle = {
    position: 'absolute',
    margin: '10px',
    height: '95vh !important',
    border: '5px',
    overflow: 'auto',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    zIndex: '1111'
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-[#000]/20 flex items-center"
      style={{
        ...modalStyle,
        backgroundColor: 'white',
        width: '800px',
        flexDirection: 'column'
      }}
    >
      <Container className="w-[90%]">
        <div className="bg-blue w-full p-3 flex justify-between">
          <p className="text-lg text-white">Add User</p>
          <button onClick={close} className="text-white text-xl mr-2">
            <RxCross1 />
          </button>
        </div>

        <Card style={{ boxShadow: 'none' }}>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 35px' }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {visibleItems
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    const isItemSelected = selected.indexOf(row.id) !== -1;
                    return (
                      <Row
                        key={key}
                        row={row}
                        handleClick={handleClick}
                        isItemSelected={isItemSelected}
                        getCompany={getCompany}
                      />
                    );
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{ py: 3 }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <Stack spacing={2} className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
              />
            </Stack>

            <hr className="my-5" />

            <div className="flex justify-end">
              <button
                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                onClick={close}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                color="primary"
                className="mb-4 mrvn-sv"
                onClick={() => onSubmit(selected)}
              >
                Save
              </Button>
            </div>
          </TableContainer>
        </Card>
      </Container>
    </div>
  );
};

export default observer(UserList);

const Row = ({
  row,
  row: {
    emailAddress,
    givenName,
    familyName,
    statusFull,
    lastLogin,
    picture,
    companyId
  },
  handleClick,
  isItemSelected,
  getCompany
}) => {
  const [company, setCompany] = useState({});

  const fetchCompany = async () => {
    const res = await getCompany(companyId);
    setCompany(res);
  };

  useEffect(() => {
    fetchCompany();
  }, [companyId]);

  return (
    <Fragment>
      <TableRow
        hover
        key={row.id}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row.id)}
          />
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt="avatar" src={picture} />
            <Typography variant="subtitle2" noWrap>
              {givenName} {familyName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {company.name ? company.name : '-'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{emailAddress}</TableCell>
        <TableCell align="left">-</TableCell>
        <TableCell align="left">-</TableCell>
        <TableCell align="left">
          {moment(lastLogin).format('MMMM Do YYYY, h:mm:ss a')}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

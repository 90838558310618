import { IconButton, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/InfoOutlined';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e2e8f0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

function InfoButton ({ message, color }) {
  return (
    <LightTooltip title={message} placement="bottom-start">
    <IconButton>
      <CloseIcon style={{ color: color || 'gray' }} />
    </IconButton>
  </LightTooltip>
  );
}

export default InfoButton;

import { Player } from '@lottiefiles/react-lottie-player';
import './loader.css';

const Loader = () => {
  return (
    <div className="loader-overlay">
      <Player
        className="player"
        src={process.env.PUBLIC_URL + '/static/gifs/loader.json'}
        autoplay
        loop
        speed={2}
        style={{ height: '150px', width: '150px' }}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      />
    </div>
  );
};

export default Loader;

// eslint-disable-next-line camelcase
import { diff_match_patch } from 'diff-match-patch';
import TagsList from './TagsList';

const TagsDiff = ({ originalTags, modifiedTags }) => {
  if (originalTags === undefined && modifiedTags === undefined) {
    return (
      <>
        <div className="col-span-3 p-3"></div>
        <div className="col-span-3 text-diff-modified p-3 border-l-[1px] border-l-[#eee]"></div>
      </>
    );
  }

  const originalTagsText = (originalTags === undefined) ? '' : originalTags.sort((a, b) => a.localeCompare(b)).join('|');
  const modifiedTagsText = (modifiedTags === undefined) ? '' : modifiedTags.sort((a, b) => a.localeCompare(b)).join('|');

  // eslint-disable-next-line new-cap
  const dmp = new diff_match_patch();

  const diff = dmp.diff_main(originalTagsText, modifiedTagsText);
  dmp.diff_cleanupSemantic(diff);

  const highlightedText = diff.map((part, index) => {
    const color = part[0] === 1 ? 'light-green' : part[0] === -1 ? 'light-red' : undefined;
    const data = part[1].split('|');

    return (
        <TagsList data={data.filter(Boolean)} color={color}/>
    );
  });

  return (
      <>
        <div className="col-span-3 p-3"><TagsList data={originalTags} /></div>
        <div className="col-span-3 p-3 border-l-[1px] border-l-[#eee]">
          {highlightedText}
        </div>
      </>
  );
};

export default TagsDiff;

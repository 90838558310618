import { makeAutoObservable } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';

const ENDPOINT = '/phrases/phrases';

class PhraseStore {
  state = {
    orgPhrases: [],
    userPhrases: [],
    favPhrases: [],
    myFavPhrases: [],
    phrases: []
  };

  constructor () {
    makeAutoObservable(this);
  }

  getOrganisationPhrases = async (params) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/organisation`, {
        params
      });
      this.state.orgPhrases = response.data?.content || [];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createOrganisationPhrase = async (data) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(
        process.env.REACT_APP_BASE_URL + ENDPOINT + '/organisation',
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      this.state.orgPhrases = [response.data, ...this.state.orgPhrases];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateOrganisationPhrase = async (data) => {
    loadingStore.setLoading(true);

    try {
      await client.put(process.env.REACT_APP_BASE_URL + ENDPOINT + '/organisation/' + data.id, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      this.state.orgPhrases = this.state.orgPhrases.map((x) => x.id === data.id ? data : x);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteOrganisationPhrase = async (phrase) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(process.env.REACT_APP_BASE_URL + ENDPOINT + '/organisation/' + phrase.id);
      this.state.orgPhrases = this.state.orgPhrases.filter((x) => x.id !== phrase.id);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getUserPhrases = async (params) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user`, {
        params
      });
      this.state.userPhrases = response.data?.content || [];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createUserPhrase = async (data) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(process.env.REACT_APP_BASE_URL + ENDPOINT + '/user', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      this.state.userPhrases = [response.data, ...this.state.userPhrases];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateUserPhase = async (data) => {
    loadingStore.setLoading(true);
    try {
      await client.put(process.env.REACT_APP_BASE_URL + ENDPOINT + '/user/' + data.id, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      this.state.userPhrases = this.state.userPhrases.map((x) => x.id === data.id ? data : x);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteUserPhrase = async (phrase) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(process.env.REACT_APP_BASE_URL + ENDPOINT + '/user/' + phrase.id);
      this.state.userPhrases = this.state.userPhrases.filter((x) => x.id !== phrase.id);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getFavoritePhrases = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/favourite`);
      this.state.favPhrases = response.data;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getMyFavoritePhrases = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/my-favourite`);
      this.state.myFavPhrases = response.data;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createUserFavouritePhrase = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(process.env.REACT_APP_BASE_URL + ENDPOINT + '/favourite/user/' + id);
      this.state.favPhrases = [response.data, ...this.state.favPhrases];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createOrganisationFavouritePhrase = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(process.env.REACT_APP_BASE_URL + ENDPOINT + '/favourite/organisation/' + id);
      this.state.favPhrases = [response.data, ...this.state.favPhrases];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteFavouritePhrase = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(process.env.REACT_APP_BASE_URL + ENDPOINT + '/favourite/' + id);
      this.state.favPhrases = this.state.favPhrases.filter((x) => x.phraseId !== id);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const phraseStore = new PhraseStore();
export default phraseStore;

import { RxArrowLeft } from 'react-icons/rx';
import { useAuth0 } from '@auth0/auth0-react';
import ToggleButton from '../ToggleButton';
import PublishButton from './Button/PublishButton';
import SendForApprovalButton from './Button/SendForApprovalButton';
import ApproveButton from './Button/ApproveButton';

const Navbar = ({
  id,
  viewOnly,
  distracted,
  handleDistractionMode,
  publish,
  publishEvent,
  goBack,
  status,
  doApproval,
  sendForApprovalEvent,
  doApproveEvent,
  isMyArticle
}) => {
  const { user } = useAuth0();

  return (
    <div className="fixed z-20 bg-white w-full py-3 px-6 flex gap-5 items-center drop-shadow">
      <button className="text-xl" onClick={goBack}>
        <RxArrowLeft />
      </button>
      <img src={user.picture} alt="user" className="h-10 w-10 rounded-full" />
      <div className="flex-1"></div>
      <div className="flex items-center gap-2">
        <ToggleButton toggle={distracted} handleClick={handleDistractionMode} />
        <p className="font-bold">Distraction Free Mode</p>
      </div>
      {!viewOnly && (
        <button
          onClick={publish}
          className={`border-[1px] border-blue text-blue px-4 py-2 rounded ${viewOnly ? 'opacity-50' : ''}`}
          disabled={viewOnly}
        >
          Save Draft
        </button>
      )}
      <SendForApprovalButton
        doApproval={doApproval}
        status={status}
        sendForApprovalEvent={sendForApprovalEvent}
      />
      <ApproveButton
        doApproval={doApproval}
        status={status}
        doApproveEvent={doApproveEvent}
        isMyArticle={isMyArticle}
      />
      <PublishButton
        id={id}
        publishEvent={publishEvent}
        viewOnly={viewOnly}
        doApproval={doApproval}
        status={status}
      />
    </div>
  );
};

export default Navbar;

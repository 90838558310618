/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable prefer-const */
import ReactEcharts from 'echarts-for-react';

const AnalyticsAreaLineChart = ({ pageAnalytics, keyword, availableDates, heading }) => {
  let seriesData = [pageAnalytics.length];
  let uniqueLabelsSet = new Set();

  const filteredData = pageAnalytics.filter(
    (entry) => entry.error === null || entry.error === undefined
  );
  filteredData.map((entry, index) => {
    if (entry.analytics[keyword] && entry.analytics[keyword].periodicValues) {
      seriesData.push({
        name: entry.channelInstance.name,
        type: 'line',
        data: (entry.analytics[keyword].periodicValues.map((item) => item.value)),
        connectNulls: false,
        areaStyle: {},
        emphasis: {
          focus: 'series'
        }
      });
      return uniqueLabelsSet.add(entry.channelInstance.name);
    }
    return null;
  });

  const option = {
    tooltip: {
      trigger: 'axis',
      position: 'inside',
      axisPointer: {
        shadowStyle: {
          color: '#000',
          shadowBlur: 0,
          opacity: 0.07
        },
        type: 'shadow'
      },
      textStyle: {
        fontFamily: 'Quicksand'
      },
      extraCssText: 'padding: 8px 15px; font-size: 13px;'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    legend: {
      data: [...uniqueLabelsSet]
    },
    xAxis: {
      type: 'category',
      data: availableDates
    },
    yAxis: {
      type: 'value'
    },
    series: seriesData
  };

  return (
    <div>
      <h1 className="text-lg font-bold mb-5">{heading}</h1>
      <ReactEcharts option={option}/>
    </div>
  );
};

export default AnalyticsAreaLineChart;

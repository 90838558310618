import { Box, Typography, Button } from '@mui/material';

import { Close } from '@mui/icons-material';

export default function ModalHead ({ action, heading, sx, handleClose }) {
  return (
    <Box
      className={'bg-blue'}
      style={{
        mb: 5,
        ...sx,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        marginBottom: '20px'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, padding: '10px 25px', color: '#FAFAFA' }}>
          <Typography
            variant="h6"
            style={{ fontSize: '16px' }}
          >
            {heading}
          </Typography>
        </Box>
        <Button onClick={handleClose}>
          <Close style={{ color: 'white' }} />
        </Button>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
    </Box>
  );
}

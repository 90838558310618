import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Pagination,
  Stack,
  FormControl,
  experimentalStyled as styled,
  Paper
} from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import analyticsStore from '../../store/analytics/analytics';
import { useEffect, useState } from 'react';
import loadingStore from '../../store/loading';
import ListToolbar from '../../components/Global/ListToolbar';
import { useSearchParams } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function formatDateToString (date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const PostAnalytics = () => {
  const { fetchPostsAnalytics } = analyticsStore;
  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams] = useSearchParams();

  const [params, setParams] = useState({
    pageIndex: 1,
    articleId: searchParams.get('articleId')
  });
  const { isloading, setLoading } = loadingStore;
  const [selectedMonth, setSelectedMonth] = useState('THIS_MONTH');
  const [xSeries, setXSeries] = useState([]);

  useEffect(async () => {
    setLoading(true);
    setXSeries([]);
    const response = await fetchPostsAnalytics(params);
    setContents(response.articlesPageResponse.content ?? []);
    setCurrentPage(response.articlesPageResponse.page);
    setTotalPages(Math.ceil(response.articlesPageResponse.totalElements / response.articlesPageResponse.size));
    setXSeries(response.availableDates.map((item) => new Date(item)));
    setLoading(false);
  }, [
    params.pageIndex,
    params.range,
    params.searchText,
    params.articleId
  ]);

  const handelPageChange = (data) => {
    setCurrentPage(data);
  };

  useEffect(() => {
    setParams({ ...params, pageIndex: currentPage });
  }, [currentPage]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setParams({ ...params, range: event.target.value });
  };

  const handleFilterByName = (event) => {
    setParams({ ...params, searchText: event.target.value });
  };

  return (
    <>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Post Analytics"></HeaderBreadcrumbs>
        <Grid className='mb-6' item xs={12}>
            <Item className='bg-white text-left border border-neutral-400'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                    <Grid item xs={6}>
                        <ListToolbar
                            placeholder={'Search Posts'}
                            numSelected={0}
                            filterName={params.searchText}
                            onFilterName={handleFilterByName}
                            extraClasses={['p-0']}
                        />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl className="formControl w-full mt-6" >
                          <label htmlFor="month-select" className="label">Select Month</label>
                          <Select
                              padding="none"
                              labelId="month-select-label"
                              id="month-select"
                              value={selectedMonth}
                              onChange={handleMonthChange}
                              className="select"
                          >
                              <MenuItem value="THIS_MONTH">This Month</MenuItem>
                              <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                </Grid>
            </Item>
        </Grid>
        <Grid item xs={12} spacing={2}>
          { contents.length > 0 && contents.map((row) => {
            return (
              <>
                <ContentItemCard row={row} key={row.id} xSeries={xSeries} />
              </>
            );
          })}
          {contents.length <= 0 && !isloading && (
            <Stack>
              <p>No records found in current search criteria.</p>
            </Stack>
          )}
          <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => handelPageChange(value)}
              color="primary"
            />
          </Stack>
        </Grid>
      </Container>
    </>
  );
};

const ContentItemCard = ({
  row,
  xSeries
}) => {
  const [selectedValue, setSelectedValue] = useState('clicks');

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const {
    title
  } = row;

  return (
    <Card className="mb-2 post-analytics-chart" key={row.id}>
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <Typography component="div" variant="h5">{ title }</Typography>
            </div>
            <Select
              value={selectedValue}
              onChange={handleSelectChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem key={0} value={'clicks'}>Clicks</MenuItem>
              <MenuItem key={1} value={'engagements'}>Engagements</MenuItem>
              <MenuItem key={2} value={'impressions'}>Impressions</MenuItem>
              <MenuItem key={3} value={'reactions'}>Reactions</MenuItem>
            </Select>
        </div>
        <BasicLineChart xSeries={xSeries} instances={row.channelInstance} analyticsType={selectedValue}></BasicLineChart>
      </CardContent>
    </Card>
  );
};

const normalizeDate = (date) => {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

const BasicLineChart = ({
  xSeries,
  instances,
  analyticsType
}) => {
  const [analytics, setAnalytics] = useState([]);

  useEffect(() => {
    setAnalytics([]);
    instances.map((row) => {
      const analtyicsRec = row.analytics && row.analytics[analyticsType] ? row.analytics[analyticsType] : [];
      if (xSeries.length > 0) {
        // if(xAxisData.length === 0) {
        //   setXAxisData(analtyicsRec.periodicValues.map(item => item.date));
        // }
        // analtyicsRec.periodicValues.map(item => item.value)
        const seriesData = xSeries.map(date => {
          const matchingItem = analtyicsRec.periodicValues && analtyicsRec.periodicValues.find(item => {
            const itemDate = normalizeDate(item.date);
            return formatDateToString(itemDate) === formatDateToString(date);
          });
          return matchingItem ? matchingItem.value : 0;
        });

        setAnalytics((prevAnalytics) => [...prevAnalytics,
          { label: row.name, curve: 'linear', data: seriesData }
        ]);
      }
      return true;
    });
  }, [instances, xSeries, analyticsType]);

  return xSeries.length > 0 && (
    <LineChart
      height={400}
      xAxis={[
        {
          id: 'Years',
          data: xSeries,
          scaleType: 'band',
          valueFormatter: (date) => new Date(date).toLocaleDateString()
        }
      ]}
      series={analytics}
      margin={{ left: 70 }}
      slotProps={{
        legend: {
          hidden: true
        }
      }}
      bottomAxis="Years" // Refer to the correct axis ID
    >
    </LineChart>
  );
};

export default PostAnalytics;

import { publicationStatuses } from '../../../constants/publicationStatues';

const PublicationStatusTopInfo = ({ status }) => {
  return (
    <>
        <div className="bg-white h-10 mt-1 inline-flex items-center px-2.5 py-0.5 text-xs rounded mr-2 border border-gray-500 capitalize">
            <span className={`inline-flex items-center justify-center w-5 h-5 mr-2 text-white rounded-full ${publicationStatuses[status]?.bgColor}`}>
                {publicationStatuses[status]?.icon}
            </span>
            {publicationStatuses[status]?.name}
        </div>
    </>
  );
};

export default PublicationStatusTopInfo;

import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { AiOutlineMore } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import contentApi from '../../api/content';
import SchedulePublish from '../../components/ContentManagement/SchedulePublish';
import canI from '../../utils/canI';
import ConfirmationModal from '../Modal/Confirm';

const SearchContentMoreMenu = ({ handleEdit, row, refresh }) => {
  const [isOpenForCancel, setIsOpenForCancel] = useState(false);
  const [openSchedulePublish, setOpenSchedulePublish] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePublicationStatus = () => {
    navigate('/content/' + row.id + '/publication-status-history');
  };

  const onCancelEvent = () => {
    contentApi.cancelArticle(row.id).then((res) => {
      refresh();
    });
  };

  const onCloneArticle = () => {
    contentApi.cloneArticle(row.id).then((id) => {
      navigate('/content/' + id);
    });
  };

  const handleMenuOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="relative">
        {canI([
          'update:articles',
          'delete:articles',
          'create:publish-content',
          'delete:publish-content'
        ]) && (
          <>
          <Button
            onClick={handleMenuOpenClick}
            aria-controls={open ? 'more-menu-options' : undefined}
            className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
          >
            <AiOutlineMore />
          </Button>
          <Menu
            id="more-menu-options"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            {canI(['update:articles']) && (
                <MenuItem
                  onClick={handleEdit}
                  className="w-full text-start block px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] hover:text-gray-900"
                >
                  {row.editable === true ? 'Edit' : 'View Only'}
                </MenuItem>
            )}
              <MenuItem
                onClick={handlePublicationStatus}
                className="w-full text-start block px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] hover:text-gray-900"
              >
                History
              </MenuItem>

              {row.editable !== true && canI(['create:articles']) && (
                <MenuItem
                  onClick={onCloneArticle}
                  className="w-full text-start block px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] hover:text-gray-900"
                >
                  {'Clone'}
                </MenuItem>
              )}

              {row.editable === true && canI(['delete:publish-content']) && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setIsOpenForCancel(true);
                  }}
                  className="w-full text-start block px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] hover:text-gray-900"
                >
                  {'Cancel'}
                </MenuItem>
              )}
          </Menu>
          </>
        )}
          </div>
        <ConfirmationModal
          selected={() => {}}
          setSelected={() => {}}
          setIsOpen={() => {}}
          open={isOpenForCancel}
          handleCancel={() => setIsOpenForCancel(false)}
          onConfirm={onCancelEvent}
          message={[
            'Are you sure you want to proceed operation?',
            'selected item will be proceed for cancellation'
          ]}
        />
        {openSchedulePublish && (
          <SchedulePublish
            content={row}
            versions={[row]}
            open={openSchedulePublish}
            handleClose={() => setOpenSchedulePublish(false)}
          />
        )}
    </>
  );
};

export default SearchContentMoreMenu;

import Moment from 'react-moment';
import { EndOnType } from '../../../constants/EndOnType';
import { useContext, useEffect, useState } from 'react';
import { icons } from '../../../constants/channels';
import Tooltip from '@mui/material/Tooltip';
import { CompanyChannelListContext } from '../../../pages/ContentManagement';

const ReadyToPublishOption = ({
  recurringState,
  setStep,
  publishDate,
  publishTime,
  contents,
  whenToPublish,
  handlePublish,
  handleClose,
  content
}) => {
  const [channel, setChannel] = useState([]);
  const allChannels = useContext(CompanyChannelListContext);
  const [summary, setSummary] = useState('');

  useEffect(() => {
    const channelIds = contents
      ? contents.reduce((acc, content) => acc.concat(content.channelId || []), [])
      : [];
    const data = channelIds.map(channelId => allChannels.find(
      (x) => parseInt(x.id) === parseInt(channelId)
    ));
    setChannel(data);
  }, [contents, allChannels]);

  useEffect(() => {
    if (whenToPublish === 'recur') {
      const summary = `Every ${recurringState.noOfRepetition} ${
        recurringState.recurringType
      } ${
        recurringState.recurringType === 'Week'
          ? 'on ' + recurringState.weekDaysRepetition.join(', ')
          : ''
      } until
    ${
      recurringState.endingCondition === EndOnType.EndDate
        ? new Date(recurringState.endDate).toLocaleDateString()
        : recurringState.endingCondition === EndOnType.OccurrenceNumber
        ? recurringState.endAfterOccurrence + ' occurrences'
        : EndOnType.Never
    }`;
      setSummary(summary);
    }
  }, []);

  const findIcon = (name) => {
    return icons.find((c) => c.name === name);
  };

  const findTerms = (name) => {
    const icon = icons.find((c) => c.name === name);
    return icon ? icon.htmlContent : '';
  };

  return (
    <>
      <div className="h-[400px]">
        <div className="bg-[#fafafa] p-3 rounded my-3 flex items-center gap-3">
          <p className="flex-1 font-bold">Please check the detail below</p>
          <button
            onClick={() => setStep(2)}
            className="text-xs text-[#888] underline"
          >
            Change
          </button>
        </div>
        <div className="flex items-center gap-3 mt-2 font-semibold">
          <dl className="p-4">
            <div className="flex">
              <dt className="font-semibold mr-2 w-32">Channels</dt>
              <dd className="flex gap-3">
                  {channel.map((c, index) =>
                    (
                      <Tooltip key={index} title={c?.name} arrow>
                        <div>
                          {findIcon(c?.channelType.channelName).colorIcon}
                        </div>
                      </Tooltip>
                    ))
                  }
              </dd>
            </div>
            <div className="flex mt-3">
              <dt className="font-semibold mr-2 w-32">Article Title</dt>
              <dd>{contents ? contents[0]?.title : content.title}</dd>
            </div>
            <div className="flex mt-3">
              <dt className="font-semibold mr-2 w-32">Date</dt>
              <dd>
                <Moment format="MMM DD, YYYY">{publishDate}</Moment>
              </dd>
            </div>
            <div className="flex mt-3">
              <dt className="font-semibold mr-2 w-32">Time</dt>
              <dd>{publishTime}</dd>
            </div>
            {summary !== '' && (
              <div className="flex mt-3">
                <dt className="font-semibold mr-10">Recurrence</dt>
                <dd>{summary}</dd>
              </div>
            )}
             <hr className="my-5" />
            <div className="block mt-2">
              {channel.map((c, index) =>
                (
                  <p
                    key={index}
                    className="text-xs mt-2"
                    dangerouslySetInnerHTML={{ __html: findTerms(c.channelType.channelName) }}
                  />
                ))
              }
            </div>
          </dl>
        </div>
      </div>

      <hr className="my-5" />
      <div className="flex items-center justify-between">
        <button className="text-blue underline" onClick={() => setStep(2)}>
          Back
        </button>
        <div className="flex">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
            onClick={() => handlePublish()}
          >
            Publish
          </button>
        </div>
      </div>
    </>
  );
};

export default ReadyToPublishOption;

const StepBar = ({ step }) => {
  return (
    <div className="arrow-steps clearfix">
      <div className={`step ${step === 1 ? 'current' : ''}`}>
        <span>Restriction Level</span>
      </div>
      <div className={`step ${step === 2 ? 'current' : ''}`}>
        <span>Restriction Applies</span>
      </div>
      <div className={`step ${step === 3 ? 'current' : ''}`}>
        <span>Restriction Details</span>
      </div>
      <div className={`step ${step === 4 ? 'current' : ''}`}>
        <span>Restriction Name</span>
      </div>
    </div>
  );
};

export default StepBar;

import './userprofile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { faUser, faPowerOff, faCog } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import settingsStore from '../../../../store/settings/settings';
import Button from '@mui/material/Button';
import SessionStorage from '../../../../utils/marvinSession';

const UserProfile = ({ onClose, user }) => {
  const { deleteDeviceToken } = settingsStore;
  const { logout } = useAuth0();

  const logoutWithRedirect = () => {
    deleteDeviceToken();
    SessionStorage.clearSessionData();
    localStorage.removeItem('deviceToken');
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  return (
    <div>
      {/* <div className="align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">My Profile</h3>
      </div> */}
      <div className="align-items-center mt-5 mb-5">
        <div className="mr-5 min-w-100px min-h-100px">
          <img src={user.picture} alt="Profile" />
        </div>
        <div className="d-flex flex-column align-items-start">
          <div className="font-weight-bold font-size-h5 text-dark-75">
            {user.given_name} {user.family_name}
          </div>
          <div className="text-muted font-size-sm max-w-200px text-ellipsis">
            {user.email}
          </div>
          <Button className="mt-3" variant="outlined" color="error" onClick={logoutWithRedirect}>
            <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
            Sign Out
          </Button>
        </div>
      </div>

      <ListGroup className="list-group">
        <ListGroupItem
          tag={RouterNavLink}
          to="/profile/settings"
          className="list-group-item"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faUser} className="icon" /> Manage Account
        </ListGroupItem>
        <ListGroupItem
          tag={RouterNavLink}
          to="/profile/preference"
          className="list-group-item"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faCog} className="icon" /> Preferences
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default UserProfile;

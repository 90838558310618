import { useState, useEffect } from 'react';
import AdminNavbar from './Navbars/AdminNavbar';
import Sidebar from './Sidebar/Sidebar';
import './marvinlayout.css';

export default function MarvinLayout ({ children }) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [mainContentStyle, setMainContentStyle] = useState('md:ml-68 main-content-transition');

  useEffect(() => {
    if (sidebarCollapsed) {
      setMainContentStyle('md:ml-20 main-content-transition');
    } else {
      setMainContentStyle('md:ml-64 main-content-transition');
    }
  }, [sidebarCollapsed]);

  return (
    <>
      <AdminNavbar sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <Sidebar sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
        <div className={`relative md:pt-20 h-full bg-gray-light min-h-screen ${mainContentStyle}`}>
          <div className='w-full'>
            {/* <div className='flex flex-wrap'> */}
              <main>{children}</main>
            {/* </div> */}
          </div>
        </div>
    </>
  );
}

export const CIIM = [
  {
    name: 'Facebook Page',
    description: 'Facebook page connected profile'
  },
  {
    name: 'Enterprise',
    description: 'Twitter enterprise connected profile'
  },
  {
    name: 'Instagram Business',
    description: 'Instagram business profile'
  },
  {
    name: 'We Chat',
    description: 'We Chat profile'
  },
  {
    name: 'Telegram',
    description: 'Telegram connected channel'
  },
  {
    name: 'Tik Tok',
    description: 'Tik Tok connected profile'
  },
  {
    name: 'Slack',
    description: 'Slack connected channel'
  },
  {
    name: 'Linkedin',
    description: 'Linkedin connected profile'
  },
  {
    name: 'Webhook',
    description: 'Webhook connected url'
  },
  {
    name: 'Linkedin Business',
    description: 'Linkedin business connected profile'
  },
  {
    name: 'Formerly Twitter',
    description: 'Formerly twitter connected profile'
  },
  {
    name: 'Premium',
    description: 'X Premium connected profile'
  },
  {
    name: 'YouTube Shorts',
    description: 'YouTube Shorts connected channel'
  }
];

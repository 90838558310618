import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

// routes
// hooks
// components

import { AddCompanyButton } from '../../components/AddButton';
import { BulkDeleteButton } from '../../components/DeleteButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import CompanyMoreMenu from '../../components/Organisations/companyList/CompanyMoreMenu';

import companyStore from '../../store/organisation/company';
// import userStore from "../../store/user";
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import fileStore from '../../store/file';
import canI from '../../utils/canI';
import NoContent from '../../components/NoContent';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  if (orderBy === 'name') {
    return order === 'desc'
      ? (a, b) => b.name?.toLowerCase().localeCompare(a.name.toLowerCase())
      : (a, b) => a.name?.toLowerCase().localeCompare(b.name.toLowerCase());
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((b, a) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const Company = () => {
  const {
    state: { companies },
    getCompanies,
    deleteCompany
  } = companyStore;
  const { enqueueSnackbar } = useSnackbar();

  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage] = useState(10);
  const [itemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [companySortDirection, setCompanySortDirection] = useState('desc');

  const filteredUsers = applySortFilter(
    // userList,
    companies,
    getComparator(order, orderBy),
    filterName
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getCompanies().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch companies', { variant: 'error' })
    );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (property === 'company') {
      setCompanySortDirection(companySortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = userList.map((user) => user.id);
      const newSelecteds = companies.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Company', isSorted: orderBy === 'name' },
    { id: 'industry', label: 'Industry', isSorted: orderBy === 'industry' },
    // { id: "owner", label: "Owner" },
    { id: 'access', label: 'Access' },
    { id: 'moreMenu' }
  ];

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (id) => {
    // dispatch(deleteUser(id));
    deleteCompany(id).then((res) =>
      res === 1
        ? enqueueSnackbar('Company deleted successfully', {
          variant: 'success'
        })
        : enqueueSnackbar(res.response.data ?? 'Failed to delete company', { variant: 'error' })
    );
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Companies"
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'User', href: '/security/user' },
            { name: 'List' }
          ]}
          actions={[
            canI(['delete:company']) && <BulkDeleteButton
              onDelete={deleteCompany}
              selected={selected}
              setSelected={setSelected}
              heading={['Company', 'Companies']}
            />,
            canI(['create:company']) && <AddCompanyButton />
          ]}
          selected={selected}
        />

        <Card style={{ boxShadow: 'none' }}>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          {filteredUsers.length > 0 && (
            <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={userList.length}
                  rowCount={companies.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {visibleItems
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <Row
                          row={row}
                          id={id}
                          key={id}
                          name={name}
                          handleDeleteUser={handleDeleteUser}
                          handleClick={handleClick}
                          isItemSelected={isItemSelected}
                        />
                      );
                    })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={6}
                        sx={{ py: 3 }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              <Stack spacing={2} className="pagination">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  variant="outlined"
                  onChange={(event, value) => setCurrentPage(value)}
                  color="primary"
                />
              </Stack>
            </TableContainer>
          )}
        </Card>
      </Container>
      {filteredUsers.length < 1 && NoContent('No Companies Found')}
    </>
  );
};
export default observer(Company);

const Row = ({
  row: { id, name, industry, accessOption },
  row,
  handleClick,
  handleDeleteUser,
  isItemSelected
}) => {
  const { fetchImageByID } = fileStore;

  const [image, setImage] = useState(null);

  useEffect(() => {
    row.imageId
      ? fetchImageByID(row.imageId).then((res) => {
        res !== 0 ? setImage(res) : setImage(null);
      })
      : setImage(null);
  }, [row, row.imageId]);

  return (
    <TableRow
      hover
      key={id}
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, id)}
        />
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          <img
            alt={name}
            className="w-10 h-10 object-contain"
            src={image || process.env.REACT_APP_PLACEHOLDER_IMAGE}
          />

          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">{industry}</TableCell>
      {/* <TableCell component="th" scope="row" padding="none">
        {owner.givenName} {owner.familyName}
      </TableCell> */}

      <TableCell align="left">
        {accessOption === 'ALL_USERS'
          ? 'All Users'
          : accessOption === 'MEMBERS_ONLY'
            ? 'Members Only'
            : accessOption === 'CUSTOM' && 'Custom'}
      </TableCell>
      <TableCell align="right">
        <CompanyMoreMenu
          onDelete={() => handleDeleteUser(id)}
          row={{ ...row, image, imageId: row.imageId }}
        />
      </TableCell>
    </TableRow>
  );
};

import menuStore from '../store/security/menu';
import AuthStore from '../store/auth';

class PermissionChecker {
  static parseJwt (token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  static getMyPermission (token) {
    const decodedToken = this.parseJwt(token);
    const permissions = decodedToken.permissions; // Assuming permissions are space-separated
    return permissions;
  }

  static hasMenuPermission (key, permission) {
    const {
      state: { menus }
    } = menuStore;

    const havePagePermission = menus.find((item) => item.key === key);

    return havePagePermission ?? false;
  }

  static hasSubMenuPermission (key, permission, definedMenus) {
    // give home page permission to all user
    if (key === '/') {
      return true;
    }

    const {
      state: { menus }
    } = menuStore;

    const urlSegments = key.split('/').filter(Boolean);

    let rootMenus = definedMenus ?? menus;
    let segmentTofind = '';

    for (const segment of urlSegments) {
      segmentTofind += '/' + segment;
      const foundedMenu = rootMenus.find((item) => item.url === segmentTofind);
      if (foundedMenu) {
        if (foundedMenu.url === key && foundedMenu[permission] === true) {
          return true;
        } else {
          rootMenus = foundedMenu.children ?? [];
        }
      }
    }

    return false;
  }

  static hasAnyPermissions (permissions) {
    const { state: { userToken } } = AuthStore;
    if (!userToken) {
      return false;
    }
    return this.getMyPermission(userToken).find((item) => permissions.indexOf(item) !== -1);
  }
}

export default PermissionChecker;

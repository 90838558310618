import Dashboard from '../pages/Dashboard';
import Company from '../pages/Organisation/Company';
import Location from '../pages/Organisation/Location';
import UserList from '../pages/Security/Users';
import GroupList from '../pages/Security/Group';
import Role from '../pages/Security/Role';
import Permissions from '../pages/Security/Permissions';
import ManageTags from '../pages/Tags/ManageTags';
import GroupMembership from '../pages/Security/GroupMembership';
import ContentManagement from '../pages/ContentManagement';
import PublicationStatusHistory from '../pages/ContentManagement/PublicationStatusHistory';
import SearchContent from '../pages/Content/SearchContent';
import Restrictions from '../pages/Channels/Restrictions';
import ManageContent from '../pages/Content/ManageContent';
import MyDraft from '../pages/Content/MyDraft';
import MyFavorites from '../pages/Content/MyFavorites';
import AuthoringGuidance from '../pages/Content/AuthoringGuidance';
import PhraseLibrary from '../pages/ToolsandStyling/PhraseLibrary';
import ProfileSettings from '../pages/Profile/ProfileSettings';
import Features from '../pages/Settings/Features';
import VersionComparison from '../pages/ContentManagement/VersionComparison';
import Department from '../pages/Organisation/Department';
import Team from '../pages/Organisation/Team';
import Programmes from '../pages/Organisation/Programmes';
import Project from '../pages/Organisation/Project';
import Folder from '../pages/Organisation/Folder';
import MyPhrases from '../pages/ToolsandStyling/MyPhrases';
import Integrations from '../pages/Settings/Integrations';
import ProfilePreference from '../pages/Profile/ProfilePreference';
import Calendar from '../pages/Calendar';
import ManageChannel from '../pages/Channels/ManageChannel';
import { AddUserChannelButton } from '../components/AddButton';
import ManageContact from '../pages/Contact/ManageContact';
import AddOrEditContact from '../pages/Contact/AddOrEditContact';
import Analytics from '../pages/Analytics/Analytics';
import PostAnalytics from '../pages/Analytics/PostAnalytics';
import AddOrEditChannel from '../pages/Channels/AddOrEditChannel';
import ManageChannelGroup from '../pages/Channels/ChannelGroup/ManageChannelGroup';
import AddOrEditChannelGroup from '../pages/Channels/ChannelGroup/AddOrEditChannelGroup';
import ThirdPartyIntegrations from '../pages/Settings/ThirdPartyIntegrations';
import NewSearchContent from '../pages/Content/NewSearchContent';

const routes = [
  {
    path: '/',
    element: Dashboard,
    permissions: []
  },
  {
    path: '/calendar',
    element: Calendar,
    permissions: []
  },
  {
    path: '/security/companies',
    element: Company,
    permissions: ['read:company']
  },
  {
    path: '/organisation/location',
    element: Location,
    permissions: ['read:locations']
  },
  {
    path: '/organisation/departments',
    element: Department,
    permissions: ['read:departments']
  },
  {
    path: '/organisation/teams',
    element: Team,
    permissions: ['read:teams']
  },
  {
    path: '/organisation/programmes',
    element: Programmes,
    permissions: ['read:programmes']
  },
  {
    path: '/organisation/projects',
    element: Project,
    permissions: ['read:projects']
  },
  {
    path: '/organisation/folders',
    element: Folder,
    permissions: ['read:folders']
  },
  {
    path: '/security/users',
    element: UserList,
    permissions: ['read:users']
  },
  {
    path: '/security/groups',
    element: GroupList,
    permissions: ['read:groups']
  },
  {
    path: '/security/group/membership',
    element: GroupMembership,
    permissions: ['read:groups']
  },
  {
    path: '/security/roles',
    element: Role,
    permissions: ['read:roles']
  },
  {
    path: '/security/permissions',
    element: Permissions,
    permissions: ['read:permissions']
  },
  {
    path: '/content/create',
    element: ContentManagement,
    permissions: ['create:articles']
  },
  {
    path: '/content/:contentId',
    element: ContentManagement,
    permissions: ['update:articles']
  },
  {
    path: '/content/:contentId/publication-status-history',
    element: PublicationStatusHistory,
    permissions: ['read:articles']
  },
  {
    path: '/contents/oldsearch',
    element: SearchContent,
    permissions: ['read:articles']
  },
  {
    path: '/contents/search',
    element: NewSearchContent,
    permissions: ['read:articles']
  },
  {
    path: '/analytics/profile-analytics',
    element: Analytics,
    permissions: ['read:profile-analytics']
  },
  {
    path: '/analytics/post-analytics',
    element: PostAnalytics,
    permissions: ['read:post-analytics']
  },
  {
    path: '/contents/manage-content',
    element: ManageContent,
    permissions: ['read:articles']
  },
  {
    path: '/contents/my-drafts',
    element: MyDraft,
    permissions: ['create:articles']
  },
  {
    path: '/contents/my-favourites',
    element: MyFavorites,
    permissions: ['read:articles']
  },
  {
    path: '/contents/authoring-guidance',
    element: AuthoringGuidance,
    permissions: ['read:articles']
  },
  {
    path: '/channels/manage-channels',
    element: ManageChannel,
    permissions: ['read:channelinstance']
  },
  {
    path: '/channels/manage-channels/new',
    element: AddOrEditChannel,
    permissions: ['create:channelinstance']
  },
  {
    path: '/channels/manage-channels/:channelInstanceId',
    element: AddOrEditChannel,
    permissions: ['update:channelinstance']
  },
  {
    path: '/channels/add-channel',
    element: AddUserChannelButton,
    permissions: ['read:channelinstance']
  },
  {
    path: '/channels/restrictions',
    element: Restrictions,
    permissions: ['read:channelrules']
  },
  {
    path: '/channels/manage-group',
    element: ManageChannelGroup,
    permissions: ['read:channelinstance']
  },
  {
    path: '/channels/manage-group/new',
    element: AddOrEditChannelGroup,
    permissions: ['create:channelinstance']
  },
  {
    path: '/channels/manage-group/:channelGroupId',
    element: AddOrEditChannelGroup,
    permissions: ['create:channelinstance']
  },
  {
    path: '/toolsandstyling/phrase-library',
    element: PhraseLibrary,
    permissions: ['read:phrases']
  },
  {
    path: '/toolsandstyling/my-phrases',
    element: MyPhrases,
    permissions: ['read:myphrases']
  },
  {
    path: '/toolsandstyling/manage-tags',
    element: ManageTags,
    permissions: ['read:tags']
  },
  {
    path: '/toolsandstyling/manage-contact',
    element: ManageContact,
    permissions: ['read:directory']
  },
  {
    path: '/toolsandstyling/manage-contact/new',
    element: AddOrEditContact,
    permissions: ['create:directory']
  },
  {
    path: '/toolsandstyling/manage-contact/:contactId',
    element: AddOrEditContact,
    permissions: ['update:directory']
  },
  {
    path: '/profile/settings',
    element: ProfileSettings,
    permissions: []
  },
  {
    path: '/profile/preference',
    element: ProfilePreference,
    permissions: []
  },
  {
    path: '/setting/features',
    element: Features,
    permissions: ['read:features']
  },
  {
    path: '/setting/shadow',
    element: Integrations,
    permissions: ['read:shadow']
  },
  {
    path: '/setting/integration',
    element: ThirdPartyIntegrations,
    permissions: ['read:integration']
  },
  {
    path: '/content/version-comparison/:versionId1/:versionId2',
    element: VersionComparison,
    permissions: ['read:articles']
  },
  {
    path: '*',
    element: Dashboard,
    permissions: []
  }
];

export default routes;

import { Container } from '@mui/material';
import { useState } from 'react';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Menu from '../../components/Profile/Settings/Menu';
import PasswordForm from '../../components/Profile/Settings/PasswordForm';
import ProfileForm from '../../components/Profile/Settings/ProfileForm';
import TermAndCondition from '../../components/TermAndCondition';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Billing from '../../components/Profile/Settings/Billing';

const ProfileSettings = () => {
  const [selected, setSelected] = useState('Profile');

  return (
    <div>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Manage Account" />

        <div className="bg-white rounded drop-shadow-md p-5">
          <Menu selected={selected} setSelected={setSelected} />

          {selected === 'Profile' && <ProfileForm />}
          {selected === 'Change Password' && <PasswordForm />}
          {selected === 'Terms of Service' && <TermAndCondition />}
          {selected === 'Privacy' && <PrivacyPolicy />}
          {selected === 'Billing' && <Billing />}
        </div>
      </Container>
    </div>
  );
};

export default ProfileSettings;

import client from '../../utils/axios';
import { makeAutoObservable } from 'mobx';
import loadingStore from '../loading';

const NOTI_CONFIG_ENDPOINT = '/user/notification/config';
const NOTI_ENDPOINT = '/user/notification';

class NotificationStore {
  state = {
    notificationConfig: [],
    notificationCount: 0,
    loaded: false
  };

  constructor () {
    makeAutoObservable(this);
  }

  getNotificationConfig = async () => {
    loadingStore.setLoading(true);
    try {
      const notificationResponse = await client.get(`${process.env.REACT_APP_BASE_URL}${NOTI_CONFIG_ENDPOINT}`);
      this.state.notificationConfig = notificationResponse.data;
      this.state.loaded = true;
    } catch (error) {
      console.error('Failed to notification config:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateNotificationConfig = async (updatedSettings, event, notificationType, status) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
                `${process.env.REACT_APP_BASE_URL}${NOTI_CONFIG_ENDPOINT}/${event}`,
                {
                  notificationType,
                  status
                }
      );

      this.state.notificationConfig = this.state.notificationConfig.map((x) =>
        x.name === event
          ? {
              ...updatedSettings,
              post: updatedSettings.post,
              email: updatedSettings.email
            }
          : x
      );
    } catch (error) {
      console.error('Failed to update notification config :', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createNotificationConfig = async (event, notificationType, status) => {
    loadingStore.setLoading(true);
    try {
      const notificationResponse = await client.put(
                `${process.env.REACT_APP_BASE_URL}${NOTI_CONFIG_ENDPOINT}/${event}`,
                {
                  notificationType,
                  status
                }
      );
      this.state.notificationConfig =
                [...this.state.notificationConfig, notificationResponse.data];
    } catch (error) {
      console.error('Failed to update notification config :', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getNotificationUnreadCount = async () => {
    try {
      const notificationResponse = await client.get(`${process.env.REACT_APP_BASE_URL}${NOTI_ENDPOINT}/unread/count`);
      this.state.notificationCount = notificationResponse.data;
      return notificationResponse.data;
    } catch (error) {
      console.error('Failed to get notification count :', error);
    }
  };

  getNotifications = async (params) => {
    try {
      const notificationResponse = await client.get(`${process.env.REACT_APP_BASE_URL}${NOTI_ENDPOINT}`, {
        params
      });
      return notificationResponse.data;
    } catch (error) {
      console.error('Failed to get notifications :', error);
    }
  };

  markNotificationAsRead = async (id) => {
    try {
      await client.patch(`${process.env.REACT_APP_BASE_URL}${NOTI_ENDPOINT}/mark/read-unread/${id}/true`);
      this.getNotificationUnreadCount();
    } catch (error) {
      console.error('Failed to get notifications :', error);
    }
  };

  markAllNotificationAsRead = async () => {
    try {
      await client.patch(`${process.env.REACT_APP_BASE_URL}${NOTI_ENDPOINT}/mark/all-read-unread/true`);
      this.getNotificationUnreadCount();
    } catch (error) {
      console.error('Failed to get notifications :', error);
    }
  };
}

const notificationStore = new NotificationStore();
export default notificationStore;

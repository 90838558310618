import { RxArrowLeft } from 'react-icons/rx';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = ({ goBack, children }) => {
  const { user } = useAuth0();

  return (
    <div className="bg-white w-full py-3 px-6 flex gap-5 items-center drop-shadow">
      <button className="text-xl" onClick={goBack}>
        <RxArrowLeft />
      </button>
      <img src={user.picture} alt="user" className="h-10 w-10 rounded-full" />
      {children ?? (
        <>
          <div className="flex-1" />
          <button
            onClick={goBack}
            className="border-[1px] border-blue text-blue px-4 py-2 rounded"
          >
            CLOSE
          </button>
        </>
      )}
    </div>
  );
};

export default Navbar;

import widgetApi from '../../../api/widget';
import { useEffect, useState } from 'react';
import { icons } from '../../../constants/channels';
import loadingStore from '../../../store/loading';
import AreaLineChartWidget from '../Common/AreaLineChart';
import { Skeleton } from '@mui/material';

const filterRangeOptions = [
  {
    id: 2,
    key: 'LAST_3_MONTH',
    label: 'Last 3 Month'
  },
  {
    id: 3,
    key: 'LAST_6_MONTH',
    label: 'Last 6 Month'
  },
  {
    id: 4,
    key: 'LAST_YEAR',
    label: 'Last Year'
  }
];

const PublishingHistoryChart = () => {
  const [data, setData] = useState({
    labels: [],
    series: [],
    colors: []
  });

  const {
    setLoading
  } = loadingStore;

  const [params, setParams] = useState({
    dateFilterFields: 'LAST_6_MONTH',
    timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  const [useSkelton, setUseSkelton] = useState(false);

  const channelTypes = icons.map((item) => item.name);
  const fetchPublishingHistories = () => {
    setLoading(true);
    setUseSkelton(true);
    widgetApi.getPublishingHistories(params).then((response) => {
      const labels = [];
      const channelSeries = [];

      const sortedData = Object.keys(response)
        .sort((a, b) => new Date(`${b}-01`) - new Date(`${a}-01`))
        .reduce((acc, key) => {
          acc[key] = response[key];
          return acc;
        }, {});

      Object.entries(sortedData).forEach((result, index) => {
        labels.push(result[0]);
        channelTypes.forEach((cType) => {
          const chnanelCount = result[1].find((item) => item.channelTypeName === cType);
          if (!channelSeries[cType]) {
            channelSeries[cType] = [];
          }
          if (chnanelCount) {
            channelSeries[cType].push(chnanelCount.count);
          } else {
            channelSeries[cType].push(0);
          }
        });
      });

      const series = [];
      const colors = [];
      Object.entries(channelSeries).forEach((result, index) => {
        const channelType = icons.find((item) => item.name === result[0]);
        if (channelType) {
          colors.push(channelType.color);
        } else {
          colors.push('#eeeeee');
        }

        series.push({
          data: result[1],
          label: result[0],
          area: true,
          showMark: true
        });
      });

      setData({
        ...data,
        labels,
        series,
        colors
      });

      setLoading(false);
      setUseSkelton(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      setUseSkelton(false);
    });
  };

  useEffect(() => {
    fetchPublishingHistories();
  }, [params.dateFilterFields]);

  return (
        <>
            {!useSkelton && (
                <>
                    <div className="flex-row">
                        <FilterRangeOptions
                            params={params}
                            setParams={setParams}
                        />
                        <AreaLineChartWidget
                            labels={data.labels}
                            series={data.series}
                            colors={data.colors}
                        />
                    </div>
                </>
            )}
            {useSkelton && (
                <>
                    <div className="flex-row">
                      <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                      <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                      <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                      <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                      <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                    </div>
                </>
            )}
        </>
  );
};

const FilterRangeOptions = ({
  params,
  setParams
}) => {
  return (
        <>
            <ul className="items-center mt-2 text-xs p-1 font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:inline-flex">
                {filterRangeOptions.map((item, index) => (
                    <li key={index} className="border-b border-gray-200 sm:border-b-0">
                        <div className="flex items-center pl-3">
                            <input id={'horizontal-list-radio-' + item.key} onChange={(e) => {
                              setParams({
                                ...params,
                                dateFilterFields: e.target.value
                              });
                            }} type="radio" value={item.key} checked={params.dateFilterFields === item.key} name="dateFilterFields" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" />
                            <label htmlFor={'horizontal-list-radio-' + item.key} className="w-full py-1 my-1 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{item.label}</label>
                        </div>
                    </li>
                ))}
            </ul>
        </>
  );
};

export default PublishingHistoryChart;

import { useEffect, useState } from 'react';
import { VscVersions } from 'react-icons/vsc';
import {
  AiOutlineClockCircle
} from 'react-icons/ai';
import CollabEditor from './ColabEditor';
import ValidationTimer from './ValidationTimer';
import { useSnackbar } from 'notistack';
import settingsStore from '../../store/settings/settings';
import userStore from '../../store/security/user';
import Attachments from './Attachments/Attachments';
import { Chip } from '@mui/material';
import Moment from 'react-moment';
import { ReactComponent as MagicWand } from '../../assets/icons/magic-wand.svg';
import externalAiStore from '../../store/content/externalai';
import PreviewAiContent from '../Modal/Content/PreviewAiContent';
import PopupLayout from '../../layout/PopupLayout';

const Editor = ({
  title,
  setTitle,
  setContent,
  content,
  setPlainText,
  newFiles,
  setNewFiles,
  files,
  setFiles,
  displayFiles,
  setDisplayFiles,
  pingValidation,
  setPingValidation,
  fireValidationChannels,
  viewOnly,
  articleVersion,
  selectedChannels,
  setEmojis,
  characters,
  words,
  setCharacters,
  setWords,
  setHtmlContent,
  hashTag,
  customTag,
  setCustomTag,
  setMentions,
  isEnableOpenAI
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [delay, setDelay] = useState(120);
  const [validateShortenerUrl, setValidateShortenerUrl] = useState(0);
  const [contentValidationTimerFq, setContentValidationTimerFq] = useState(null);
  const [author, setAuthor] = useState(null);
  const [aiContentPreview, setAiContentPreview] = useState('');
  const [selectedAiContent, setSelectedAiContent] = useState('');
  const {
    state: { userSettings }
  } = settingsStore;

  const {
    getUser
  } = userStore;

  const {
    generateContent
  } = externalAiStore;

  const handlePaste = (event) => {
    const plainText = event.clipboardData.getData('text/plain');
    const json = JSON.parse(plainText);

    if (json.category === 'TITLE') {
      setTitle(json.text);
    } else {
      enqueueSnackbar('This phrase is not for title', {
        variant: 'warning'
      });
    }
    event.preventDefault();
  };

  const handleValidateNow = () => {
    /* setAllChannels([]);
    fetchAllChannels(); */
    fireValidationChannels();
    setPingValidation(true);
    enqueueSnackbar('Validation request started', {
      variant: 'success'
    });
  };

  const handleValidateShorternUrl = () => {
    setValidateShortenerUrl(validateShortenerUrl + 1);
  };

  useEffect(() => {
    const contentValidationFq = userSettings.find(
      (x) => x.name === 'content-validation-timer-frequency'
    );

    setContentValidationTimerFq(
      contentValidationFq ? contentValidationFq.value : 10
    );
  }, [userSettings]);

  useEffect(() => {
    if (content && content.authorId) {
      getUser(content.authorId).then(res => setAuthor(res));
    }
  }, [content]);

  useEffect(() => {
    if (contentValidationTimerFq !== null) {
      if (parseInt(contentValidationTimerFq) > 0) {
        setDelay(contentValidationTimerFq * 60);
      } else {
        setDelay(0);
      }
    }
  }, [contentValidationTimerFq]);

  const generateAiContent = async () => {
    try {
      if (title !== '') {
        const response = await generateContent({
          prompt: title
        });
        if (response.data.completion) {
          setAiContentPreview(response.data.completion);
        } else {
          enqueueSnackbar('Unable to generate content please check request.', {
            variant: 'warning'
          });
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <div className="my-5">
      {!viewOnly && (
        <div className="bg-white p-3 mb-2 flex justify-between rounded text-xs">
          <div className="flex gap-2 items-center">
            {contentValidationTimerFq > 0 && (
              <>
                <p className="flex-shrink-0">Validation Time Left</p>
                <ValidationTimer
                  delayResend={delay}
                  setPingValidation={setPingValidation}
                  pingValidation={pingValidation}
                  fireValidationChannels={fireValidationChannels}
                />
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <button
                onClick={() => handleValidateNow() }
                className="border-[1px] border-blue text-blue px-4 py-2 rounded"
              >
                Validate Now
            </button>

            <button
                onClick={() => handleValidateShorternUrl() }
                className="border-[1px] border-blue text-blue px-4 py-2 rounded"
              >
                Shorten My Url
            </button>
          </div>
        </div>
      )}

      <div className="bg-white p-3 mb-2 flex justify-between rounded text-xs">
        {author && (
          <div className="flex gap-2 items-center">
            <p>Created by</p>
            <img
              className="h-8 w-8 rounded-full ml-2"
              src={author.picture}
              alt="user"
            />
            <p>{author.givenName} {author.familyName}</p>
          </div>
        )}
        <div className="flex items-center gap-2">
          <VscVersions />
          <p className="mr-2">Version: v{(articleVersion.version) ? articleVersion.version : 0}.{(content && articleVersion.revision !== undefined) ? articleVersion.revision : 0}</p>
          {(
          content && content.updatedAt
            ? content.updatedAt.length >= 2 &&
            <>
              <AiOutlineClockCircle />
              <p>
                {'Last Updated: '}
                <Moment format="MMM, DD hh:mm A" className="text-xs">{content.updatedAt}</Moment>
              </p>
            </>
            : '')}
        </div>
      </div>

      <div className="rounded bg-[#FFF] content-editor border-[1px] border-blue">
        <div className="flex justify-between items-end p-3">
          <div className="flex-1">
                <input
                  disabled={viewOnly}
                  value={title ?? ''}
                  onChange={(e) => setTitle(e.target.value)}
                  onPaste={handlePaste}
                  placeholder="| Title here"
                  className="w-full border-none outline-none text-xl font-bold text-black"
                />
          </div>
          <div className="flex gap-2 text-xs items-center">
            { isEnableOpenAI && title?.length > 0 && (
              <p title="Generate AI content"><MagicWand onClick={generateAiContent} className="w-6 h-6 cursor-pointer text-blue" /></p>
            )}
            <p>{title?.length} characters</p>
            <p>{title?.length > 0 ? title.split(' ').length : 0} words</p>
          </div>
        </div>
        <hr className="border-[#eee]" />
        <CollabEditor
          content={content}
          setText={setContent}
          isEditing={!!(content && content.id)}
          docName={content && content.id ? content.id : 'null'}
          endpoint={process.env.REACT_APP_DOC_COLLAB_SERVER}
          setWords={setWords}
          setCharacters={setCharacters}
          setPlainText={setPlainText}
          characters={characters}
          words={words}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
          files={files}
          setFiles={setFiles}
          viewOnly={viewOnly}
          selectedChannels={selectedChannels}
          setEmojis={setEmojis}
          hashTag={hashTag}
          validateShortenerUrl={validateShortenerUrl}
          setHtmlContent={setHtmlContent}
          setMentions={setMentions}
          selectedAiContent={selectedAiContent}
          setSelectedAiContent={setSelectedAiContent}
        />
        <CustomTags
         customTag={customTag}
         setCustomTag={setCustomTag}
         viewOnly={viewOnly}
        />
        <Attachments
          files={files}
          setFiles={setFiles}
          displayFiles={displayFiles}
          setDisplayFiles={setDisplayFiles}
          content={content}
          viewOnly={viewOnly}
        />
      </div>
      <PopupLayout
          className="wizard"
          open={aiContentPreview !== ''}
          handleClose={() => {
            setAiContentPreview('');
          }}
          heading="Preview AI Generated Content"

          width={730}
        >
          <PreviewAiContent
              aiContentPreview={aiContentPreview}
              handleClose={() => setAiContentPreview('')}
              doRegenerateAiContent={() => generateAiContent()}
              useAiContent={() => {
                setSelectedAiContent(aiContentPreview);
                setAiContentPreview('');
              }}
            />
    </PopupLayout>
    </div>
  );
};

export default Editor;

const CustomTags = ({ customTag, setCustomTag, viewOnly }) => {
  const handleDelete = (index) => {
    const oldTags = [...customTag];
    oldTags.splice(index, 1);
    setCustomTag(oldTags);
  };

  return (
    <>
      {customTag && customTag.length > 0 && (
        <div className='border-t-[1px] border-t-blue rounded-t-md min-h-[50px] p-1'>
          {customTag.map((tag, index) => (
            <Chip
              disabled={viewOnly}
              key={index}
              color='primary'
              className='m-1'
              label={tag.name}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </div>
      )}
    </>
  );
};

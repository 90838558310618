import client from '../utils/axios';

const CHANNEL_TYPE_ENDPOINT = process.env.REACT_APP_BASE_URL + '/channeltypes';
const POST_ENDPOINT = process.env.REACT_APP_BASE_URL + '/post';

export default {
  getChannelBreakDown: async () => {
    const { data } = await client.get(CHANNEL_TYPE_ENDPOINT + '/instance/channel-breakdown');
    return data;
  },
  getPublishingHistories: async (params) => {
    const { data } = await client.get(POST_ENDPOINT + '/article/publish-history', {
      params
    });
    return data;
  },
  getRecentPosts: async () => {
    const { data } = await client.get(POST_ENDPOINT + '/article/recent-post');
    return data;
  }
};

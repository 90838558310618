import { makeAutoObservable } from 'mobx';
import client from '../../utils/axios';

const ENDPOINT = '/shadow/settings/integration/secrets';

class IntegrationsSecretsStore {
  state = {
    integrationsSecrets: {}
  };

  constructor () {
    makeAutoObservable(this);
  }

  getConnectionTypeSecrets = async (id) => {
    //  loadingStore.setLoading(true);
    try {
      const result = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.integrationsSecrets = result ? result.data : null;
      return this.state.integrationsSecrets;
    } catch (error) {
      console.error('Failed to fetch connection secrets :', error);
    } finally {
      //  loadingStore.setLoading(false);
    }
  };

  regenerateConnectionSecrets = async (id, postData) => {
    //  loadingStore.setLoading(true);
    try {
      const result = await client.put(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`, postData);
      this.state.integrationsSecrets = result ? result.data : null;
      return this.state.integrationsSecrets;
    } catch (error) {
      console.error('Failed to fetch connection type :', error);
    } finally {
      //  loadingStore.setLoading(false);
    }
  };
}

const integrationsSecretsStore = new IntegrationsSecretsStore();
export default integrationsSecretsStore;

import { makeAutoObservable, runInAction, action } from 'mobx';
import client from '../../utils/axios';

const ANALYTICS_ENDPOINT = process.env.REACT_APP_BASE_URL + '/social-analytics';

class AnalyticsStore {
  state = {
    analytics: []
  };

  constructor () {
    makeAutoObservable(this, {
      setAnalytics: action,
      fetchChannelAnalytics: false // Exclude this method from being autoActioned
    });
  }

  setAnalytics = (data) => {
    this.state.analytics = data;
  };

  fetchChannelAnalytics = async (ids, includeDetails, selectedMonth) => {
    try {
      const idsString = ids.join(',');
      const url = `${ANALYTICS_ENDPOINT}/page/${idsString}?range=${selectedMonth}&includeDetails=${includeDetails}&period=DAY`;
      const response = await client.get(url);
      runInAction(() => {
        this.state.analytics = response.data;
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  fetchPostsAnalytics = async (params) => {
    try {
      const url = `${ANALYTICS_ENDPOINT}/post`;
      const response = await client.get(url, { params });
      return response.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };
}

const analyticsStore = new AnalyticsStore();
export default analyticsStore;

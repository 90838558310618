import { observer } from 'mobx-react';
import PhrasesTable from '../../components/Profile/Settings/PhrasesTable';

const MyPhrases = () => {
  return (
        <>
            <PhrasesTable />
        </>
  );
};

export default observer(MyPhrases);

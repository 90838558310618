import React, { useEffect } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import PermissionChecker from '../utils/permissionChecker';
import Loader from '../components/Layout/Loader';
import routes from '../routes';
import MarvinLayout from '../components/Layout/MarvinLayout';
import userflow from 'userflow.js';
import CryptoJS from 'crypto-js';
import AuthStore from '../store/auth';

const Layout = ({ children }) => {
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [permissionError, setPermissionError] = React.useState(null);
  const [permissionChecked, setPermissionChecked] = React.useState(false);
  const { setUserToken } = AuthStore;

  const callHomePage = () => {
    navigate('/');
  };

  const findRoute = () => {
    for (const route of routes) {
      const match = matchPath({ path: route.path }, location.pathname);
      if (match) {
        return route;
      }
    }
  };

  const checkPageHasPermission = (token) => {
    const currentRoute = findRoute();
    if (currentRoute && currentRoute.permissions.length > 0) {
      const hasPermission = PermissionChecker.getMyPermission(token).filter(
        (permission) => currentRoute.permissions.indexOf(permission) !== -1
      );

      if (hasPermission.length === 0) {
        setPermissionChecked(true);
        setPermissionError({
          message:
            'You are not authorized for this page , please contact administrator'
        });
      } else {
        setPermissionChecked(true);
        setPermissionError(null);
      }
    } else {
      setPermissionError(null);
      setPermissionChecked(true);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          setUserToken(token);
          checkPageHasPermission(token);
        } catch (e) {
          if (
            e.error === 'login_required' ||
            e.error === 'consent_required' ||
            e.error === 'invalid_grant' ||
            e.error === 'missing_refresh_token' ||
            e.error === 'interaction_required' ||
            e.error === 'invalid_token'
          ) {
            loginWithRedirect();
          }
          throw e;
        }
      })();
    } else {
      setUserToken(null);
    }
  }, [isAuthenticated, getAccessTokenSilently, location.key]);

  useEffect(() => {
    if (!permissionError && permissionChecked) {
      const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_USERFLOW_SECRET_KEY);
      const id = CryptoJS.enc.Utf8.parse(user.sub);
      const signature = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(id, key));

      userflow.init(process.env.REACT_APP_USERFLOW_ENV_KEY);
      userflow.identify(user.sub, {
        name: user.name,
        email: user.email
      },
      {
        signature
      });
    }
  }, [permissionChecked, permissionError]);

  return (
    <>
      {!permissionError && permissionChecked
        ? (
        <>
          {location.pathname.includes('/content/')
            ? (
            <>{children} </>
              )
            : (
            <MarvinLayout>{children}</MarvinLayout>
              )}
        </>
          )
        : (
        <>
          {permissionChecked
            ? (
            <div id="root" className="h-full">
              <div className="h-screen w-full flex">
                <div className="w-full relative">
                  <img
                    className="w-full h-full object-cover absolute"
                    alt="bg"
                    src="/static/images/signup-bg.png"
                  />
                  <div className="flex items-center justify-center h-full">
                    <p className="absolute z-10 text-lg text-white font-bold left-5 top-5">
                      <a onClick={callHomePage}>
                        <img
                          className="h-14 object-contain"
                          src="/static/icons/Marvinlogobig.svg"
                          alt="logo"
                        />
                      </a>
                    </p>
                    <div className="relative">
                      <p className="text-white font-bold text-4xl">
                        {permissionError.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              )
            : (
            <Loader />
              )}
        </>
          )}
    </>
  );
};

export default Layout;

import client from '../../utils/axios';
import { makeAutoObservable } from 'mobx';
import loadingStore from '../loading';
const ENDPOINT = '/tagging/tags';

class TagStore {
  state = {
    tags: [],
    suggestedTags: []
  };

  constructor () {
    makeAutoObservable(this);
  }

  createTag = async (tag) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, tag);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateTag = async (id, tag) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.put(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`, tag);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getSuggestedTag = async (searchTags) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.get(
        process.env.REACT_APP_BASE_URL + '/tagging/tags/suggest', {
          params: searchTags,
          paramsSerializer: {
            indexes: null
          }
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getTags = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.tags = response.data;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteTag = async (id) => {
    try {
      loadingStore.setLoading(true);
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return 1;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getTagsByPage = async (params) => {
    try {
      const { data } = await client.get(process.env.REACT_APP_BASE_URL + ENDPOINT, { params });
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  uploadCsvFile = async (base64Str) => {
    try {
      const { data } = await client.post(process.env.REACT_APP_BASE_URL + ENDPOINT + '/import/csv', {
        base: base64Str
      });
      return data;
    } catch (error) {
      console.error(error);
    }
  };
}

const tagStore = new TagStore();
export default tagStore;

import canI from '../../../utils/canI';
const PublishButton = ({
  id,
  publishEvent,
  viewOnly,
  doApproval,
  status
}) => {
  return (
        <>
            { canI('create:publish-content') && ((!doApproval && !viewOnly) || (doApproval && status === 'AUTHORISATION PASSED')) && (
                <button
                    onClick={publishEvent}
                    className={'bg-blue rounded px-4 py-2 uppercase text-white'}
                >
                    Publish
                </button>
            )}
        </>
  );
};

export default PublishButton;

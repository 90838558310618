/* eslint-disable new-cap */
// eslint-disable-next-line camelcase
import { diff_match_patch } from 'diff-match-patch';

const TextDiff = ({ originalText, modifiedText, bold }) => {
  const dmp = new diff_match_patch();

  const diff = dmp.diff_main(originalText, modifiedText);
  dmp.diff_cleanupSemantic(diff);

  const highlightedText = diff.map((part, index) => {
    const key = `${index}-${part[0]}`;
    const className =
      part[0] === 1 ? 'added' : part[0] === -1 ? 'removed' : 'unchanged';
    return (
      <span key={key} className={className}>
        {part[1]}
      </span>
    );
  });

  return (
    <>
      <div className="text-diff-original col-span-3 p-3">{ bold ? (<p className="text-xl font-bold mb-3">{originalText}</p>) : originalText}</div>
      <div className="col-span-3 text-diff-modified p-3 border-l-[1px] border-l-[#eee]">
        { bold ? (<p className="text-xl font-bold mb-3">{highlightedText}</p>) : highlightedText}
      </div>
    </>
  );
};

export default TextDiff;

import PopupLayout from '../../../layout/PopupLayout';

const RestrictionDetail = ({
  open,
  handleClose,
  violation: {
    name,
    channelRuleType: { entityType, parameterType, description },
    paramInt0,
    paramInt1,
    paramDouble0,
    paramDouble1,
    paramString
  },
  channel
}) => {
  return (
    <PopupLayout
      open={open}
      heading={name}
      handleClose={handleClose}
      className="details"
    >
      <div className="grid grid-cols-2 grid-row-2 gap-10 pb-5">
        <div>
          <p>Restriction Name</p>
          <p className="font-bold mt-2">{name}</p>
        </div>
        <div>
          <p>Level</p>
          <p className="font-bold mt-2">{channel.name}</p>
        </div>
        <div>
          <p>Rule Type</p>
          <p className="font-bold mt-2">{entityType}</p>
        </div>
        <div>
          <p>Rule</p>
          <p className="font-bold mt-2">
            {description}:{' '}
            {parameterType === 'INT'
              ? paramInt0
              : parameterType === 'BETWEEN'
                ? paramInt0 + ', ' + paramInt1
                : parameterType === 'BETWEEN_DOUBLE'
                  ? paramDouble0 + ', ' + paramDouble1
                  : (parameterType === 'DROPDOWN' || parameterType === 'STRING') && paramString}
          </p>
        </div>
      </div>
    </PopupLayout>
  );
};

export default RestrictionDetail;

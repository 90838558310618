import { Container } from '@mui/material';
import { useState } from 'react';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import AuthoringEditingForm from '../../components/Profile/Settings/AuthoringEditingForm';
import NotificationConfig from '../../components/Profile/Settings/NotificationConfig';
import Preferences from '../../components/Profile/Settings/Preferences';

const ProfilePreference = () => {
  const [selected, setSelected] = useState('Authoring & Editing');

  return (
    <div>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Preferences" />

        <div className="bg-white rounded drop-shadow-md p-5">
          <Preferences selected={selected} setSelected={setSelected} />
          {selected === 'Authoring & Editing' && <AuthoringEditingForm />}
          {selected === 'Notification' && <NotificationConfig />}
        </div>
      </Container>
    </div>
  );
};

export default ProfilePreference;

import { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAuth0 } from '@auth0/auth0-react';
import channelsStore from '../../../store/channel/channel';
import { icons } from '../../../constants/channels';
import FacebookPreview from './FacebookPreview';
import InstagramPreview from './InstagramPreview';
import publicationStore from '../../../store/channel/publication';
import YouTubeShortsPreview from './YouTubeShortsPreview';
import TwitterPreview from './TwitterPreview';
import LinkedInPreview from './LinkedInPreview';
import TikTokPreview from './TikTokPreview';
import { observer } from 'mobx-react';
import { Card, CardContent, CardHeader } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const allowedChannelTypes = ['YouTube Shorts', 'Facebook Page', 'Instagram Business', 'Linkedin Business', 'Linkedin', 'Formerly Twitter', 'Tik Tok', 'Enterprise'];

const PostPreview = ({
  files,
  plainText,
  selectedChannels,
  mentions
}) => {
  const { retrieveChannelPreviewByChannelInstance } = publicationStore;

  const [channels, setChannels] = useState(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { state: { allChannels } } = channelsStore;
  const { user } = useAuth0();
  const [activeChannel, setActiveChannel] = useState(null); // Set the initial active channel
  const [channelPreviewList, setChannelPreviewList] = useState([]); // Set the initial active channel

  useEffect(() => {
    async function loadChannelPreviewInfo () {
      if (selectedChannels) {
        const channelFilter = allChannels?.filter(
          (x) => (selectedChannels?.includes(parseInt(x.id))) &&
          allowedChannelTypes.includes(x.channelType.channelName)
        );
        if (channelFilter?.length > 0) {
          try {
            const channelPreviewData = await retrieveChannelPreviewByChannelInstance(channelFilter.map(channel => parseInt(channel.id)));
            setChannelPreviewList(channelPreviewData);
          } catch (error) {
            console.log(error);
          }
          setChannels(channelFilter);
          setActiveChannel(channelFilter[0]);
        } else {
          setChannels(null);
          setActiveChannel(null);
        }
      }
    }
    loadChannelPreviewInfo();
  }, [selectedChannels, allChannels]);

  const getChannelIcon = (type) => {
    const icon = icons.filter((icon) => icon.name === type.channelName);
    return icon[0];
  };

  const channelPreview = (channelType) => {
    const previewData = channelPreviewList.find(channelPreview => channelPreview.channelTypeName === channelType);

    switch (channelType) {
      case 'Facebook Page':
        return (
          <FacebookPreview
            plainText={plainText}
            files={files}
            user={user}
            activeChannel={activeChannel}
            settings={settings}
            previewData={previewData}
          />
        );

      case 'Instagram Business':
        return (
          <InstagramPreview
            plainText={plainText}
            files={files}
            user={user}
            activeChannel={activeChannel}
            settings={settings}
            previewData={previewData}
            renderDefaultPreview={renderDefaultPreview}
            mentions={mentions}
          />
        );

      case 'YouTube Shorts':
        return (
          <YouTubeShortsPreview
            plainText={plainText}
            files={files}
            user={user}
            activeChannel={activeChannel}
            settings={settings}
            previewData={previewData}
            renderDefaultPreview={renderDefaultPreview}
          />
        );

      case 'Tik Tok':
        return (
          <TikTokPreview
            plainText={plainText}
            files={files}
            user={user}
            previewData={previewData}
            renderDefaultPreview={renderDefaultPreview}
          />
        );

      case 'Formerly Twitter':
      case 'Premium':
        return (
          <TwitterPreview
            plainText={plainText}
            files={files}
            user={user}
            previewData={previewData}
            mentions={mentions}
          />
        );

      case 'Linkedin':
      case 'Linkedin Business':
        return (
          <LinkedInPreview
            plainText={plainText}
            files={files}
            user={user}
            previewData={previewData}
          />
        );

      default:
        return (
          <p className='flex font-bold justify-center items-center h-[600px]'>COMING SOON</p>
        );
    }
  };

  const renderDefaultPreview = () => {
    return (
      <Card className={`${!channels ? 'mt-12' : ''}`}>
        <CardHeader
          avatar={
            <div className='rounded-full bg-gray-light w-12 h-12' />
          }
          action={
            <MoreVertIcon className='text-gray-light'/>
          }
          title={<div className='rounded-md bg-gray-light w-1/2 h-2' />}
          subheader={<div className='rounded-md mt-2 bg-gray-light w-1/2 h-2' />}
        />
        <CardContent className='rounded-md bg-gray-light h-44'>
          <div className='text-xs flex flex-col h-full justify-center items-center'>
            <p>Start typing or attach media to preview the content</p>
            <p>Note: Network preview only available for social channel</p>
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <div className='bg-white rounded-md content-perview'>
        <div>
          <h4 className="text-lg text-center font-bold mb-4">Network Preview</h4>
          <p className='text-xs text-center mb-4'>Preview approximates how your content will display when published. Actual appearance might be a bit different based on social networks configurations.</p>
        </div>
        {/* Tab Navigation */}
          <div className='flex justify-start overflow-x-auto network-preview'>
            {channels && channels.map((channel) => (
              <button
                key={channel.id}
                onClick={() => { setActiveChannel(channel); }}
                className={`px-4 py-4 rounded-t-md transition-color duration-500 ease-in-out ${
                  activeChannel?.id === channel.id ? 'bg-gray-light' : 'bg-white'
                }
                `}
              >
                {getChannelIcon(channel.channelType).colorIcon}
              </button>
            ))}
          </div>

          {/* Content */}
          {!activeChannel
            ? renderDefaultPreview()
            : ((files && files.length > 0) || plainText)
                ? channelPreview(activeChannel?.channelType.channelName)
                : renderDefaultPreview()
          }

      </div>
    </>
  );
};

export default observer(PostPreview);

import { LineChart } from '@mui/x-charts/LineChart';

const AreaLineChartWidget = ({
  labels,
  series,
  colors
}) => {
  return (
        <div className="w-full">
            <LineChart
                sx={{
                  '& .MuiChartsLegend-root': {
                    display: 'none'
                  }
                }}
                xAxis={[{
                  id: 'barCategories',
                  data: labels,
                  scaleType: 'band'
                }]}
                series={series}
                colors={colors ?? ['#ffb946']}
                height={300}
                className="w-full"
            />
        </div>
  );
};

export default AreaLineChartWidget;

import { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import organisation from '../../../api/organisation';
import loadingStore from '../../../store/loading';
import { useSnackbar } from 'notistack';

const PasswordForm = () => {
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [params, setParams] = useState({
    oldPassword: '',
    newPassword: '',
    reNewPassword: ''
  });

  const [error, setError] = useState({
    oldPassword: null,
    newPassword: null,
    reNewPassword: null
  });

  const { enqueueSnackbar } = useSnackbar();

  const { setLoading } = loadingStore;
  const PASS_REGEX = /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  const testError = () => {
    if (!PASS_REGEX.test(params.oldPassword)) {
      setError({
        ...error,
        oldPassword:
          'Password should contain atleast 1 uppercase letter, 1 digit and 1 special character'
      });
      return false;
    }

    if (!PASS_REGEX.test(params.newPassword)) {
      setError({
        ...error,
        newPassword:
          'New Password should contain atleast 1 uppercase letter, 1 digit and 1 special character'
      });
      return false;
    }

    if (params.reNewPassword !== params.newPassword) {
      setError({
        ...error,
        reNewPassword:
          'Confirm password should same as new password'
      });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (testError()) {
      setLoading(true);
      organisation.updatePassword(params).then((response) => {
        setLoading(false);
        enqueueSnackbar('Change password setting has been updated.', { variant: 'success' });
      }).catch((_error) => {
        setLoading(false);
        enqueueSnackbar('Oops , something went wrong while updating password.', { variant: 'error' });
      });
    }
  };

  return (
    <form
      onFocus={() =>
        setError({
          oldPassword: null,
          newPassword: null,
          reNewPassword: null
        })
      }
    >
      <div className="grid grid-cols-3">
        <div>
          <div className="flex flex-col mt-5">
            <label>Current Password</label>
            <div className="relative w-full">
              <input
                className="bg-[#eee] px-4 py-2 rounded w-full"
                type={showCurrent ? 'text' : 'password'}
                onChange={(e) => setParams({ ...params, oldPassword: e.target.value })}
              />
              <button
                type="button"
                onClick={() => setShowCurrent(!showCurrent)}
                className="absolute right-2 top-3"
              >
                {!showCurrent ? <BsEyeSlash /> : <BsEye />}
              </button>
            </div>
            <p className="text-red">{error.oldPassword}</p>
          </div>
          <div className="flex flex-col mt-5">
            <label>New Password</label>
            <div className="relative w-full">
              <input
                className="bg-[#eee] px-4 py-2 rounded w-full"
                type={showNew ? 'text' : 'password'}
                onChange={(e) => setParams({ ...params, newPassword: e.target.value })}
              />
              <button
                type="button"
                onClick={() => setShowNew(!showNew)}
                className="absolute right-2 top-3"
              >
                {!showNew ? <BsEyeSlash /> : <BsEye />}
              </button>
            </div>
            <p className="text-red">{error.newPassword}</p>
          </div>
          <div className="flex flex-col mt-5">
            <label>Confirm New Password</label>
            <div className="relative w-full">
              <input
                className="bg-[#eee] px-4 py-2 rounded w-full"
                type={showConfirm ? 'text' : 'password'}
                onChange={(e) => setParams({ ...params, reNewPassword: e.target.value })}
              />
              <button
                type="button"
                onClick={() => setShowConfirm(!showConfirm)}
                className="absolute right-2 top-3"
              >
                {!showConfirm ? <BsEyeSlash /> : <BsEye />}
              </button>
            </div>
            <p className="text-red">{error.reNewPassword}</p>
          </div>
        </div>
      </div>

      <hr className="mt-5" />

      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className="mt-5 mrvn-sv rounded"
        >
          Change Password
        </button>
      </div>
    </form>
  );
};

export default PasswordForm;

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Pagination,
  Stack,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelInstanceMultiSelect from '../../../components/Channel/ChannelInstanceMultiSelect';
import ListToolbar from '../../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import NoContent from '../../../components/NoContent';
import channelGroupAPI from '../../../store/channel/channelGroup';
import canI from '../../../utils/canI';
import './channel-group.css';

const ManageChannelGroup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const defaultValue = {
    groupName: '',
    channelInstanceIds: '',
    sortDirection: 'desc',
    pageIndex: 1,
    pageSize: 8
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [channelGroups, setChannelGroups] = useState([]);
  const [channelGroupSearchParams, setChannelGroupSearchParams] = useState(defaultValue);

  const fetchChannelGroup = async () => {
    try {
      setPageLoading(true);
      const { content, totalElements } = await channelGroupAPI.searchChannelGroup(
        channelGroupSearchParams
      );
      setChannelGroups(content || []);
      setTotalPages(
        Math.ceil(parseInt(totalElements) / channelGroupSearchParams.pageSize)
      );
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      enqueueSnackbar('Error fetching contact directory', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchChannelGroup(null);
  }, [channelGroupSearchParams.pageIndex]);

  const handlePageChange = (data) => {
    setCurrentPage(data);
    setChannelGroupSearchParams({ ...channelGroupSearchParams, pageIndex: data });
  };

  const clearAllFilter = () => {
    setChannelGroupSearchParams(defaultValue);
    fetchChannelGroup(null);
  };

  const updateChannelInstanceIds = (value) => {
    setChannelGroupSearchParams((prev) => ({
      ...prev,
      channelInstanceIds: value.map((v) => v.id).toString()
    }));
  };

  const editChannelGroup = async (groupId) => {
    if (canI(['update:channelinstance'])) {
      navigate('/channels/manage-group/' + groupId);
    } else {
      enqueueSnackbar('User do not have permission to edit channel group', {
        variant: 'warning'
      });
    }
  };

  const deleteChannelGroup = async (groupId) => {
    try {
      if (canI(['delete:channelinstance'])) {
        await channelGroupAPI.deleteChannelGroup(groupId);
        enqueueSnackbar('Channel Group deleted successfully', {
          variant: 'success'
        });
        fetchChannelGroup(null);
      } else {
        enqueueSnackbar('User do not have permission to delete channel group', {
          variant: 'warning'
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to delete channel group', { variant: 'error' });
    }
  };

  return (
    <Box
      className="bg-slate-100 overflow-hidden py-2.5 px-5"
      sx={{ height: '88vh' }}
    >
      <HeaderBreadcrumbs
        heading="Manage Channel Group"
        actions={[
          canI(['create:channelinstance']) && <AddChannelGroupButton />
        ]}
      />
      <Grid container spacing={1} sx={{ height: '77vh' }}>
        <Grid item md={4} style={{ height: 'inherit' }}>
          <Card
            className="p-0 h-full border-solid border-1 rounded-md"
            style={{ borderColor: '#A7A6A7' }}
          >
            <CardContent className="p-0 h-full">
              <Stack justifyContent={'space-between'} className="h-full">
                <div>
                  <ListToolbar
                    placeholder={'Search Channel Group'}
                    filterName={channelGroupSearchParams.groupName}
                    onFilterName={(e) =>
                      setChannelGroupSearchParams({
                        ...channelGroupSearchParams,
                        groupName: e.target.value,
                        pageIndex: 1
                      })
                    }
                  />
                  <div className="px-6">
                    <Typography
                      style={{ color: '#848084' }}
                      className="text-base"
                    >
                      Filters
                    </Typography>
                    <ChannelInstanceMultiSelect
                      updateOnChange={(newValue) => updateChannelInstanceIds(newValue)}
                      companyId={null}
                    />
                  </div>
                </div>
                <div className="px-6">
                  <Stack direction="row" spacing={2} justifyContent={'center'}>
                    <Button
                      className="uppercase w-1/2 p-2 bg-white border-blue border-2 border-solid text-blue rounded-md"
                      onClick={clearAllFilter}
                    >
                      Clear all
                    </Button>
                    <Button
                      className="uppercase w-1/2 p-2 text-white bg-blue rounded-md"
                      onClick={() => fetchChannelGroup()}
                    >
                      Apply Filter
                    </Button>
                  </Stack>
                  <Stack spacing={2} className="pagination py-2 ">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      variant="outlined"
                      onChange={(event, value) => handlePageChange(value)}
                      color="primary"
                    />
                  </Stack>
                </div>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={8} style={{ height: 'inherit' }}>
          {pageLoading && <LinearProgress color="secondary" />}
          {!pageLoading && channelGroups.length < 1 && NoContent('No Channel Group Found')}
          {!pageLoading && (
            <Stack
              sx={{ height: '-webkit-fill-available' }}
              className="overflow-auto"
            >
              {channelGroups.map((group, index) => (
                <Card
                  key={index}
                  className="my-0.5 rounded-md"
                  style={{
                    minHeight: '100px',
                    borderColor: '#A7A6A7',
                    borderWidth: '1px'
                  }}
                >
                  <CardContent className="p-0 h-full">
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      className="h-full"
                    >
                      <div className="flex flex-column align-items-center py-5 pl-5">
                        <span>
                          <Typography className="text-black font-black">
                            {group.groupName}
                          </Typography>
                        </span>
                        <AvatarGroup className="mt-4">
                          {group.channelInstances?.map((instance, index) => {
                            return (
                              <Avatar
                                key={index}
                                sx={{ width: 30, height: 30 }}
                                className="text-white"
                                src={ instance.channelImageUrl || process.env.REACT_APP_PLACEHOLDER_IMAGE}
                              />
                            );
                          })}
                        </AvatarGroup>
                      </div>
                      <div
                        className="flex flex-column border-l justify-content-evenly"
                        style={{ borderColor: '#A7A6A7' }}
                      >
                        <Button
                          className = 'border-b p-4 rounded-none border-solid'
                          style={{ borderColor: '#A7A6A7' }}
                          onClick={() => editChannelGroup(group.id)}
                        >
                          <EditOutlinedIcon fontSize="small" color="primary" />
                        </Button>
                        <Button
                          className = 'p-4 rounded-none'
                          onClick={() => deleteChannelGroup(group.id)}
                        >
                          <DeleteOutlineOutlinedIcon
                            fontSize="small"
                            color="primary"
                          />
                        </Button>
                      </div>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageChannelGroup;

const AddChannelGroupButton = () => {
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate('/channels/manage-group/new');
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleAdd}
      >
        Create Channel Group
      </button>
    </>
  );
};

import { useState } from 'react';

import { MdCancel } from 'react-icons/md';

const Progress = ({
  selectedImage,
  handleRemove,
  handleSubmit,
  handleClose
}) => {
  const [progress] = useState(100);

  return (
    <>
      <div className="my-1">
        <div className="flex gap-2 mb-2 items-center">
          <img
            className="h-10 w-10 rounded-full"
            src={URL.createObjectURL(selectedImage)}
            alt="preview"
          />

          <div className="flex-1">
            <p className="font-bold">{selectedImage.filename}</p>
            <p className="text-xs">
              {selectedImage.size / 1024 / 1024 < 1
                ? `${(selectedImage.size / 1024).toFixed(2)} kb`
                : `${(selectedImage.size / 1024 / 1024).toFixed(2)} mb`}
            </p>
          </div>
          <button onClick={handleRemove} className="text-red text-xl">
            <MdCancel />
          </button>
        </div>
        <div className="flex items-center">
          <hr
            className="border-2 border-blue"
            style={{ width: `${progress}%` }}
          />
          <hr className="border-2 border-gray flex-1" />
          <p className="ml-2">{progress}%</p>
        </div>
      </div>
      <hr className="my-5" />
      <div className="flex justify-end">
        <button
          onClick={handleClose}
          type="button"
          className="uppercase text-blue border-[1px] border-blue rounded px-4 py-2 mr-2"
        >
          Cancel
        </button>
        <button
          type="button"
          className={'uppercase text-white rounded px-4 py-2 mr-2 bg-blue'}
          onClick={handleSubmit}
        >
          Upload
        </button>
      </div>
    </>
  );
};

export default Progress;

import axios from 'axios';
import AuthStore from '../store/auth';

const { setUserToken } = AuthStore;
const client = axios.create();

export const configureAxiosClient = ({ responseInterceptorSuccess, responseInterceptorError }, getAccessTokenSilently) => {
  client.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError);
  client.interceptors.request.use(async function (config) {
    if (config.url.includes(process.env.REACT_APP_BASE_URL)) {
      const token = await getAccessTokenSilently();
      setUserToken(token);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

export default client;

import { Box, Modal } from '@mui/material';
import { useState } from 'react';
import DeleteConfirmationModal from './Modal/Delete';

const BulkDeleteButton = ({ selected, setSelected, onDelete, heading, refresh }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    await selected.forEach(async (x, i) => {
      await onDelete(x);
      if (selected.length - 1 === i) {
        if (refresh instanceof Function) {
          refresh(true);
        }
      }
    });
    setIsOpen(false);
    setSelected([]);
  };

  return (
    <>
      {selected.length > 0 && (
        <>
          <button
            variant="outlined"
            className="border-[1px] border-blue rounded text-blue px-4 py-2 mt-1 uppercase"
            onClick={handleOpen}
          >
            DELETE {selected.length}{' '}
            {selected.length > 1 ? heading[1] : heading[0]}
          </button>
          <Modal open={isOpen} onClose={handleClose}>
            <Box>
              <DeleteConfirmationModal
                open={isOpen}
                onDelete={handleDelete}
                del={heading}
                handleConfirm={handleDelete}
                handleCancel={() => setIsOpen(false)}
                selected={selected}
              />
            </Box>
          </Modal>{' '}
        </>
      )}
    </>
  );
};

export { BulkDeleteButton };

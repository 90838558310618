import React, { useEffect, useState } from 'react';

import {
  Card,
  Checkbox,
  Collapse,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// hooks
// components

import { observer } from 'mobx-react';
import { FaChevronRight, FaChevronUp } from 'react-icons/fa';
import PermissionHead from '../../components/Global/Permission/permissionHead';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import menuStore from '../../store/security/menu';
import roleStore from '../../store/security/role';

import { useSnackbar } from 'notistack';

const TABLE_HEAD = [
  { id: 'page', label: 'Pages', width: '45%' },
  { id: 'view', label: 'View', width: '11%' },
  { id: 'add', label: 'Add', width: '11%' },
  { id: 'edit', label: 'Edit', width: '11%' },
  { id: 'delete', label: 'Delete', width: '11%' },
  { id: 'authorised', label: 'Authorised', width: '11%' }
];

const Permissions = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [order] = useState('desc');
  const [selected] = useState([]);
  const [orderBy] = useState('name');
  const [menuItems, setMenuItems] = useState([]);

  const {
    state: { role }
  } = roleStore;

  const {
    getMenusByRole,
    updateMenuForRole,
    deleteMenuForRole
  } = menuStore;

  useEffect(() => {
    if (!role) {
      enqueueSnackbar('Please select a role', { variant: 'warning' });
      navigate('/security/roles');
    }

    if (role) {
      getMenusByRole(role.id).then((res) => {
        setMenuItems(res);
      });
    }
  }, [role]);

  const handleCheck = async (e, index, item, action) => {
    const positions = index.split(':');
    if (e.target.checked) {
      const response = await updateMenuForRole(role.id, {
        role: action,
        key: item.key
      });
      if (response === true) {
        const updatedItems = [...menuItems];
        updatedItems[positions[0]].children[positions[1]][action.toLowerCase()] = true;
        setMenuItems(updatedItems);
      }
    } else {
      const response = await deleteMenuForRole(role.id, {
        role: action,
        key: item.key
      });
      if (response === true) {
        const updatedItems = [...menuItems];
        updatedItems[positions[0]].children[positions[1]][action.toLowerCase()] = false;
        setMenuItems(updatedItems);
      }
    }
  };

  const isUserNotFound = menuItems.length === 0;
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };
  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Permissions"
        // actions={[<AddRoleButton />]}
      />

      <Card style={{ boxShadow: 'none' }}>
        {/* <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        /> */}

        <TableContainer sx={{ minWidth: 800 }} style={{ padding: '25px 25px' }}>
          <p className="text-sm text-[gray] pb-1 border-b border-b-gray w-56">
            Update permissions for this role
          </p>
          <h1 className="text-lg font-bold mb-5">Role: {role && role.name}</h1>
          <Table>
            <PermissionHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={menuItems.length}
              numSelected={selected.length}
              onRequestSort={() => {}}
              onSelectAllClick={() => {}}
              firstWidth={'45%'}
            />

            <TableBody>
              {menuItems.map((row, index) => {
                const { key, name, children, disabled } = row;
                return (
                    <React.Fragment key={key}>
                      <TableRow hover tabIndex={-1} key={key}>
                        <TableCell align="left" colSpan={6}>
                          <>
                            {row.children && row.children.length > 0 && (
                                <IconButton
                                  size="small"
                                  onClick={() => toggleRowExpansion(row.key)}
                                >
                                {expandedRows.includes(row.key)
                                  ? (
                                  <FaChevronUp />
                                    )
                                  : (
                                  <FaChevronRight />
                                    )}
                              </IconButton>
                            )}
                            {name}
                          </>
                        </TableCell>
                      </TableRow>
                      {expandedRows.includes(row.key) &&
                        children &&
                        children.length > 0 && (
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0
                              }}
                              colSpan={7}
                            >
                              <Collapse
                                in={expandedRows.includes(row.key)}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Table>
                                  <TableBody>
                                    {children.map((x, childKey) => (
                                      <TableRow key={x.key}>
                                        <TableCell align="left" width={'45%'}>
                                          <div
                                            style={{
                                              paddingLeft: 26,
                                              width: 170,
                                              color: disabled && 'gray'
                                            }}
                                          >
                                            {x.name}
                                          </div>
                                        </TableCell>
                                        <TableCell width={'11%'}>
                                          {typeof x.read !== 'undefined'
                                            ? (
                                            <Checkbox
                                              checked={x.read && x.read === true}
                                              disabled={typeof x.read === 'undefined'}
                                              onChange={(e) =>
                                                handleCheck(e, index + ':' + childKey, x, 'READ')
                                              }
                                            />
                                              )
                                            : (<span className="ml-2">-</span>)}
                                        </TableCell>
                                        <TableCell width={'11%'}>
                                          {typeof x.create !== 'undefined'
                                            ? (
                                            <Checkbox
                                              checked={x.create && x.create === true}
                                              disabled={typeof x.create === 'undefined'}
                                              onChange={(e) =>
                                                handleCheck(e, index + ':' + childKey, x, 'CREATE')
                                              }
                                            />
                                              )
                                            : (<span className="ml-2">-</span>)}
                                        </TableCell>
                                        <TableCell width={'11%'}>
                                          {typeof x.update !== 'undefined'
                                            ? (
                                              <Checkbox
                                                checked={x.update && x.update === true}
                                                disabled={typeof x.update === 'undefined'}
                                                onChange={(e) =>
                                                  handleCheck(e, index + ':' + childKey, x, 'UPDATE')
                                                }
                                              />
                                              )
                                            : (<span className="ml-2">-</span>)}
                                        </TableCell>
                                        <TableCell width={'11%'}>
                                          {typeof x.delete !== 'undefined'
                                            ? (
                                            <Checkbox
                                              checked={x.delete && x.delete === true}
                                              disabled={typeof x.delete === 'undefined'}
                                              onChange={(e) =>
                                                handleCheck(e, index + ':' + childKey, x, 'DELETE')
                                              }
                                            />
                                              )
                                            : (<span className="ml-2">-</span>)}
                                        </TableCell>
                                        <TableCell width={'11%'}>
                                          {typeof x.authorised !== 'undefined'
                                            ? (
                                            <Checkbox
                                              checked={x.authorised && x.authorised === true}
                                              disabled={typeof x.authorised === 'undefined'}
                                              onChange={(e) =>
                                                handleCheck(e, index + ':' + childKey, x, 'AUTHORISED')
                                              }
                                            />
                                              )
                                            : (<span className="ml-2">-</span>)}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                      )}
                    </React.Fragment>
                );
              })}
            </TableBody>

            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    sx={{ py: 3 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default observer(Permissions);

/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import menuStore from '../../../store/security/menu';
import { icons } from '../../../constants/marvinMenuIcon';
import { Avatar, SwipeableDrawer } from '@mui/material';
import Quickaccess from '../quickaccess';
import './Sidebar.css';
import getThemeStyleByEnv from '../../../utils/getThemeStyleByEnv';

export default function Sidebar ({ sidebarCollapsed, setSidebarCollapsed }) {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    state: { menus },
    getMenus
  } = menuStore;
  const { user } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      getMenus().then(() => {
        setHoveredIndex(true);
        setHoveredIndex(false); // Need to fix menu rendering issue
      });
    }
  }, [isAuthenticated]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSidebarMenu, setOpenSidebarMenu] = useState('');
  const toggleDrawer = () => {
    setOpenSidebar(!openSidebar);
  };
  const [expandedIndex, setExpandedIndex] = useState(-1); // Index of the currently expanded item, -1 means none expanded
  const [parentIndex, setParentIndex] = useState(0);
  const handleItemClick = (index, hasChildren) => {
    if (hasChildren) {
      setParentIndex(index);
      setExpandedIndex(index === expandedIndex ? -1 : index);
    } else {
      setParentIndex(0);
      setExpandedIndex(-1);
    }
  };
  const [hoveredIndex, setHoveredIndex] = useState(false);

  const findUrlInArray = (menus, targetUrl) => {
    for (let parentIndex = 0; parentIndex < menus.length; parentIndex++) {
      const item = menus[parentIndex];

      if (item.children) {
        for (let childIndex = 0; childIndex < item.children.length; childIndex++) {
          const row = item.children[childIndex];

          if (row.url === targetUrl) {
            return { parentIndex, childIndex };
          }
        }
      }
    }
    return null; // Return null if no match is found
  };

  useEffect(() => {
    const result = findUrlInArray(menus, currentPath);
    if (result && result.parentIndex) {
      setParentIndex(result.parentIndex);
      setExpandedIndex(result.parentIndex);
    }
  }, [currentPath, menus]);

  return (
    <>
      <div>
        <nav className={`md:left-0 md:block md:fixed md:top-12 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-blue flex flex-wrap items-center justify-between relative z-10 py-0 px-0 sidebar-transition
        ${sidebarCollapsed && !hoveredIndex ? 'md:w-20' : 'md:w-64'} `}
          style={ getThemeStyleByEnv('left-sidebar') }
          onMouseEnter={() => setHoveredIndex(true)}
          onMouseLeave={() => setHoveredIndex(false)}
        >
          <div className='md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
            {/* Brand */}
            <div className='d-block d-md-none'>
              <Link
                className='md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase  p-4 px-0'
                to='/'
              >
                <img
                  className='h-14 object-contain'
                  src='/static/icons/Marvinlogobig.svg'
                  alt='logo'
                />
              </Link>
            </div>
            {/* Toggler */}
            <button
              className='cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
              type='button'
              onClick={() => setCollapseShow('bg-blue m-2 py-3 px-6')}
            >
              <i className='fas fa-bars'></i>
            </button>
            {/* Collapse */}
            <div
              className={
                'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
                collapseShow
              }
            >
              {/* Collapse header */}
              <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200'>
                <div className='flex flex-wrap'>
                  <div className='w-6/12'>
                    <Avatar
                      onClick={() => {
                        if (openSidebar && openSidebarMenu === 'user-profile') {
                          setOpenSidebar(false);
                          setCollapseShow('hidden');
                          setOpenSidebarMenu('');
                        } else {
                          setOpenSidebar(true);
                          setCollapseShow('hidden');
                          setOpenSidebarMenu('user-profile');
                        }
                      }}
                      alt='Profile'
                      src={user.picture}
                    />
                  </div>
                  <div className='w-6/12 flex justify-end'>
                    <button
                      type='button'
                      className='cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                      onClick={() => setCollapseShow('hidden')}
                    >
                      <i className='fas fa-times'></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Navigation */}
              <div className={`${!sidebarCollapsed || hoveredIndex ? '' : 'hidden'} `}>
                <ul className='md:flex-col md:min-w-full flex flex-col list-none pt-10'>
                  {menus?.map((item, index) => {
                    if (item.hasOwnProperty('url')) {
                      if (item.children) {
                        return (
                          <>
                            <li className={`items-center hover:text-white px-3 ${parentIndex === index ? 'bg-light-blue text-white' : 'text-white'} `}>
                              <span
                                className={'text-xs uppercase py-3 flex items-center cursor-pointer'}
                                onClick={() => handleItemClick(index, item.children)}>
                                <i className={'pr-2 text-xs'}>{icons.find((c) => c.name === item.icon)?.icon}</i>
                                <span>{item.name}</span>
                                {item.children && <span className="ml-auto">{expandedIndex === index ? <i className='fas fa-chevron-down'></i> : <i className='fas fa-chevron-right'></i>}</span>}
                              </span>
                              <ul className={`md:flex-col md:min-w-full flex flex-col list-none ml-5 w-full bg-light-blue child-list ${expandedIndex === index ? 'expanded' : ''}`}>
                                {expandedIndex === index && item.children.map((child, index) => {
                                  if (child.hasOwnProperty('url')) {
                                    return (
                                      <li className={`items-center hover:text-white w-full ${currentPath === child.url ? 'text-white underline' : 'text-white'}`}>
                                        <Link
                                          className={'text-xs uppercase py-3 block '}
                                          to={child.url}
                                          onClick={() => setCollapseShow('hidden')}
                                        >
                                          <span className="flex items-center">
                                            <i className={'pr-2 text-sm'}>{icons.find((c) => c.name === child.icon)?.icon}</i>
                                            <span>{child.name}</span>
                                          </span>
                                        </Link>
                                      </li>
                                    );
                                  }
                                })
                                }
                              </ul>
                            </li>
                          </>
                        );
                      } else {
                        return (
                          <li className={`items-center px-3 hover:text-white ${parentIndex === index ? 'bg-light-blue' : ''} ${currentPath === item.url ? 'text-white underline' : 'text-white'}`}>
                            <Link
                              className={'text-xs uppercase py-3  block'}
                              to='/'
                              onClick={() => {
                                handleItemClick('-1');
                                setCollapseShow('hidden');
                              }}
                            >
                              <span className="flex items-center">
                                <i className={'pr-2 text-sm'}>{icons.find((c) => c.name === item.icon)?.icon}</i>
                                <span>{item.name}</span>
                              </span>
                              <ul className={`md:flex-col md:min-w-full flex flex-col list-none ml-5 w-full bg-light-blue child-list ${expandedIndex === index ? 'expanded' : ''}`}>
                                {expandedIndex === index && item.children.map((child, index) => {
                                  if (child.hasOwnProperty('url')) {
                                    return (
                                      <li className={`items-center hover:text-white w-full ${currentPath === child.url ? 'text-white underline' : 'text-white'}`}>
                                        <Link
                                          className={'text-xs uppercase py-3 block '}
                                          to={child.url}
                                          onClick={() => setCollapseShow('hidden')}
                                        >
                                          <span className="flex items-center">
                                            <i className={'pr-2 text-sm'}>{icons.find((c) => c.name === child.icon)?.icon}</i>
                                            <span>{child.name}</span>
                                          </span>
                                        </Link>
                                      </li>
                                    );
                                  }
                                })
                                }
                              </ul>
                            </Link>
                          </li>
                        );
                      }
                    }
                  })}

                </ul>
              </div>
              <div className={`${sidebarCollapsed && !hoveredIndex ? '' : 'hidden'} `}>
                <ul className='md:flex-col md:min-w-full flex flex-col list-none pt-10 items-center'
                >
                  {menus?.map((item, index) => {
                    if (item.hasOwnProperty('url')) {
                      if (item.children) {
                        return (
                          <>
                            <li className={`items-center hover:text-white px-3 ${parentIndex === index ? 'bg-light-blue text-white' : 'text-white'} `}>
                              <span
                                className={'py-3 flex items-center cursor-pointer'}
                                onClick={() => handleItemClick(index, item.children)}>
                                {icons.find((c) => c.name === item.icon)?.icon}
                              </span>
                            </li>
                          </>
                        );
                      } else {
                        return (
                          <li className={`items-center px-3 hover:text-white ${parentIndex === index ? 'bg-light-blue' : ''} ${currentPath === item.url ? 'text-white underline' : 'text-white'}`}>
                            <Link
                              className={'uppercase py-3  block'}
                              to='/'
                              onClick={() => {
                                handleItemClick('-1');
                                setCollapseShow('hidden');
                              }}
                            >
                              <span className="flex items-center">
                                {icons.find((c) => c.name === item.icon)?.icon}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                    }
                  })}

                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div>
        {openSidebar && (
          <SwipeableDrawer
            anchor='right'
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            open={openSidebar}
            style={{ width: '300px' }}
          >
            <Quickaccess
              onClose={toggleDrawer}
              openSidebar={openSidebar}
              openSidebarMenu={openSidebarMenu}
            />
          </SwipeableDrawer>
        )}
      </div>
    </>
  );
}

import client from '../../utils/axios';
import { action, makeObservable, observable } from 'mobx';

import loadingStore from '../loading';

const ENDPOINT = '/groups/locations';

class LocationStore {
  state = {
    locations: [],
    memberships: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getLocations: action,
      getLocation: action,
      addLocation: action,
      updateLocation: action,
      deleteLocation: action
    });
  }

  setLocations = () => {

  };

  getLocations = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.locations = response.data;
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getLocation = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addLocation = async (location) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, location);
      this.state.locations = [...this.state.locations, response.data];
      return 1;
    } catch (error) {
      // console.error(error);
      if (error?.response?.data?.includes('already added')) {
        return 2;
      }
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateLocation = async (location) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${location.id}`,
        {
          location: {
            name: location.name
          }
        }
      );
      const index = this.state.locations.findIndex((c) => c.id === location.id);
      this.state.locations[index] = location;
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteLocation = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.locations = this.state.locations.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getLocationMemberships = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addLocationMembership = async (data, locId) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${locId}/member`,
        data
      );
      // this.state.memberships = [...this.state.memberships, response];
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response ? error.response.code : 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateLocationMembership = async (data) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}/member`,
        { userId: data.user_id, readOnly: data.readOnly }
      );

      // this.state.memberships = this.state.memberships.map((x) =>
      //   x.id === data.id ? data : x
      // );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteLocationMembership = async (id, data) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        { data }
      );

      this.state.memberships = this.state.memberships.filter(
        (x) => x.id !== id
      );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const locationStore = new LocationStore();
export default locationStore;

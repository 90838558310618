const Stepbar = ({ step }) => {
  return (
    <div className="arrow-steps clearfix mb-5">
      <div className={`step ${step === 1 ? 'current' : ''}`}>
        <span>Now/Later</span>
      </div>
      <div className={`step ${step === 2 ? 'current' : ''}`}>
        <span>When to Publish</span>
      </div>
      <div className={`step ${step === 3 ? 'current' : ''}`}>
        <span>When to Recur</span>
      </div>
      <div className={`step ${step === 4 ? 'current' : ''}`}>
        <span>Ready to Publish</span>
      </div>
    </div>
  );
};

export default Stepbar;

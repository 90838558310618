import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { observer } from 'mobx-react';
import InfoButton from '../InfoButton';
import { icons } from '../../constants/channels';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e2e8f0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

const tableHeader = [
  {
    id: 'ChannelName',
    label: 'Channel Name'
  },
  {
    id: 'EngagementsRate',
    label: 'Engagements Rate',
    info: 'Percentage of impression that converts to engagements.'
  },
  {
    id: 'PageImpression',
    label: 'Page Impression',
    info: 'The number of times any content from your Page or about your Page entered a person\'s screen. This includes posts, stories, ads, as well other content or information on your Page. (Total Count)'
  },
  {
    id: 'PageEngagements',
    label: 'Page Engagements',
    info: 'The number of times people have engaged with your posts through like, comments and shares and more.'
  },
  {
    id: 'PageSharePosts',
    label: 'Page Share Post',
    info: 'The number of shares of your posts, stories, reels, videos and live videos.'
  },
  {
    id: 'PageLikePosts',
    label: 'Page Like Posts',
    info: 'The number of likes on your content minus the number of unlikes.'
  },
  {
    id: 'PageVideoViews',
    label: 'Page Video Views',
    info: 'Total number of times videos have been viewed for more than 3 seconds. (Total Count)'
  }
];

const AnalyticsTable = ({ data }) => {
  if (!Array.isArray(data)) {
    return <p>No data available</p>;
  }

  const getChannelIcon = (type) => {
    const icon = icons.filter((icon) => icon.name === type);
    return icon[0];
  };
  const Row = observer(({ item }) => {
    return (
      <TableRow hover tabIndex={-1}>
        {/* Row with channel information */}
        <TableCell align="left">
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
            {getChannelIcon(item.channelInstance.channelName).colorIcon}
            {item.channelInstance.name}
            {item.error && <InfoButton message={item.error} color='red'></InfoButton>}
          </div>
        </TableCell>
        {/* Cell with engagement rate */}
        <TableCell align="center">
          {item.analytics?.impressions?.value && item.analytics?.engagements?.value
            ? (<div className="trending-container">
              <TrendingUpIcon style={{ color: '#54D62C' }}/>
              {(item.analytics.engagements.value / item.analytics.impressions.value * 100).toFixed(2)}
            </div>
              )
            : (
                '-'
              )}
      </TableCell>

        {/* Cells for various page metrics */}
        {['impressions', 'engagements', 'shares', 'likes', 'videoViews'].map((metric, index) => (
          <TableCell key={index} align="center">
            {item.analytics?.[metric]?.value !== undefined && item.analytics?.[metric]?.value !== null ? item.analytics[metric].value : '-'}
          </TableCell>
        ))}
      </TableRow>
    );
  });
  return (
    <div>
     <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeader.map((header) => (
                  <TableCell key={header.id} align="center">
                      <LightTooltip title={header.info} arrow>
                      <div> {header.label} </div>
                      </LightTooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { data.map((item, index) => {
                return (
                    <Row
                      item={item}
                      key={index}
                    />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
    </div>

  );
};

export default AnalyticsTable;

import { Container } from '@mui/material';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import IntegrationsSetting from '../../components/Settings/Integration/IntegrationsSetting';

const Integrations = () => {
  return (
    <div>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Integrations" />
        <IntegrationsSetting></IntegrationsSetting>
      </Container>
    </div>
  );
};

export default Integrations;

import {
  FaXTwitter,
  FaYoutube,
  FaFacebook,
  FaTiktok,
  FaTelegram,
  FaSlack,
  FaLinkedin,
  FaInstagram,
  FaGoogle
} from 'react-icons/fa6';
import { AiOutlineWechat } from 'react-icons/ai';
import { TbWebhook } from 'react-icons/tb';

export const icons = [
  {
    name: 'Facebook Page',
    icon: <FaFacebook size="28"/>,
    colorIcon: <FaFacebook color="#316FF6" size="28" />,
    contrastIcon: <FaFacebook color="#FFFFFF" size="28" />,
    treeIcon: <FaFacebook color="#FFFFFF" size="45" />,
    color: '#316FF6'
  },
  {
    name: 'Instagram Business',
    icon: <FaInstagram size="28"/>,
    colorIcon: <FaInstagram color="#d62976" size="28"/>,
    contrastIcon: <FaInstagram color="#FFFFFF" size="28" />,
    treeIcon: <FaInstagram color="#FFFFFF" size="45" />,
    color: '#d62976'
  },
  {
    name: 'Formerly Twitter',
    icon: <FaXTwitter size="28"/>,
    colorIcon: <FaXTwitter color="#0F1419" size="28" />,
    contrastIcon: <FaXTwitter color="#FFFFFF" size="28" />,
    treeIcon: <FaXTwitter color="#FFFFFF" size="45" />,
    color: '#0F1419'
  },
  {
    name: 'Premium',
    icon: <FaXTwitter size="28"/>,
    colorIcon: <FaXTwitter color="#0F1419" size="28" />,
    contrastIcon: <FaXTwitter color="#FFFFFF" size="28" />,
    treeIcon: <FaXTwitter color="#FFFFFF" size="45" />,
    color: '#0F1419'
  },
  {
    name: 'Enterprise',
    icon: <FaXTwitter size="28"/>,
    colorIcon: <FaXTwitter color="#0F1419" size="28" />,
    contrastIcon: <FaXTwitter color="#FFFFFF" size="28" />,
    treeIcon: <FaXTwitter color="#FFFFFF" size="45" />,
    color: '#0F1419'
  },
  {
    name: 'We Chat',
    icon: <AiOutlineWechat size="28"/>,
    colorIcon: <AiOutlineWechat color="#00cf63" size="28" />,
    contrastIcon: <AiOutlineWechat color="#FFFFFF" size="28" />,
    treeIcon: <AiOutlineWechat color="#FFFFFF" size="45" />,
    color: '#00cf63'
  },
  {
    name: 'Telegram',
    icon: <FaTelegram size="28"/>,
    colorIcon: <FaTelegram color="#039ce5" size="28" />,
    contrastIcon: <FaTelegram color="#FFFFFF" size="28" />,
    treeIcon: <FaTelegram color="#FFFFFF" size="45" />,
    color: '#039ce5'
  },
  {
    name: 'Tik Tok',
    icon: <FaTiktok size="28"/>,
    colorIcon: <FaTiktok color="#000000" size="28" />,
    contrastIcon: <FaTiktok color="#FFFFFF" size="28" />,
    treeIcon: <FaTiktok color="#FFFFFF" size="45" />,
    color: '#000000',
    htmlContent: "<div> <span role=\"img\" aria-label=\"warning\" style={{ marginRight: '4px' }}>⚠️</span> By posting, you agree to TikTok's <a style=\"color: #2193B0;\" href=\"https://www.tiktok.com/legal/page/global/music-usage-confirmation/en\" target=\"_blank\">Music Usage Confirmation</a>.</div>"
  },
  {
    name: 'Slack',
    icon: <FaSlack size="28"/>,
    colorIcon: <FaSlack color="#00b67c" size="28" />,
    contrastIcon: <FaSlack color="#FFFFFF" size="28" />,
    treeIcon: <FaSlack color="#FFFFFF" size="45" />,
    color: '#00b67c'
  },
  {
    name: 'Linkedin',
    icon: <FaLinkedin size="28"/>,
    colorIcon: <FaLinkedin color="#0077b5" size="28" />,
    contrastIcon: <FaLinkedin color="#FFFFFF" size="28" />,
    treeIcon: <FaLinkedin color="#FFFFFF" size="45" />,
    color: '#0077b5'
  },
  {
    name: 'Webhook',
    icon: <TbWebhook size="28"/>,
    colorIcon: <TbWebhook color="#37474F" size="28" />,
    contrastIcon: <TbWebhook color="#FFFFFF" size="28" />,
    treeIcon: <TbWebhook color="#FFFFFF" size="45" />,
    color: '#37474F'
  },
  {
    name: 'Linkedin Business',
    icon: <FaLinkedin size="28"/>,
    colorIcon: <FaLinkedin color="#0077b5" size="28" />,
    contrastIcon: <FaLinkedin color="#FFFFFF" size="28" />,
    treeIcon: <FaLinkedin color="#FFFFFF" size="45" />,
    color: '#0077b5'
  },
  {
    name: 'YouTube Shorts',
    icon: <FaYoutube size="32" />,
    colorIcon: <FaYoutube color="#FF0000" size="32" />,
    contrastIcon: <FaYoutube color="#FFFFFF" size="32" />,
    treeIcon: <FaYoutube color="#FFFFFF" size="45" />,
    color: '#FF0000',
    htmlContent: "<div> <span role=\"img\" aria-label=\"warning\" style={{ marginRight: '4px' }}>⚠️</span> By publishing this post, you certify that the content you are uploading complies with the YouTube Terms of Service (including the YouTube Community Guidelines) at <a style=\"color: #2193B0;\" target=\"_blank\" href=\"https://www.youtube.com/t/terms\">https://www.youtube.com/t/terms</a>. Please be sure not to violate others ' copyright or privacy rights.</div>"
  },
  {
    name: 'Google Business',
    icon: <FaGoogle size="28"/>,
    colorIcon: <FaGoogle color="#000000" size="28" />,
    contrastIcon: <FaGoogle color="#FFFFFF" size="28" />,
    treeIcon: <FaGoogle color="#FFFFFF" size="45" />,
    color: '#000000'
  }
];

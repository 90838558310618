/* eslint-disable camelcase */
import {
  Box,
  Card,
  Checkbox,
  Container,
  Modal,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

// hooks
// components

import { observer } from 'mobx-react';
import { AddGroupButton } from '../../components/AddButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import GroupMoreMenu from '../../components/Security/groupList/GroupMoreMenu';
import groupStore from '../../store/security/group';
import userStore from '../../store/security/user';

import { useSnackbar } from 'notistack';
import { BulkDeleteButton } from '../../components/DeleteButton';
import fileStore from '../../store/file';
import canI from '../../utils/canI';
import GroupUserList from './GroupMemberList';
import NoContent from '../../components/NoContent';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.group_desc.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.group_type.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const GroupList = () => {
  const {
    state: { groups },
    getGroups,
    deleteGroup
  } = groupStore;
  const { enqueueSnackbar } = useSnackbar();

  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage] = useState(10);
  const [itemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const filteredUsers = applySortFilter(
    groups,
    getComparator(order, orderBy),
    filterName
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Group Name',
      isSorted: orderBy === 'name'
    },
    {
      id: 'group_desc',
      label: 'Description',
      isSorted: orderBy === 'group_desc'
    },
    {
      id: 'group_type',
      label: 'Group Type',
      isSorted: orderBy === 'group_type'
    },
    { id: 'owner', label: 'Owner', isSorted: orderBy === 'owner' },
    { id: 'moreMenu' }
  ];

  useEffect(() => {
    getGroups().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch groups', { variant: 'error' })
    );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = groups.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = (groupId) => {
    deleteGroup(groupId).then((res) =>
      res === 1
        ? enqueueSnackbar('Group deleted successfully', { variant: 'success' })
        : enqueueSnackbar('Failed to delete group', { variant: 'error' })
    );
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Groups"
          actions={[
            canI(['delete:groups']) && <BulkDeleteButton
              onDelete={deleteGroup}
              selected={selected}
              setSelected={setSelected}
              heading={['Group', 'Groups']}
            />,
            canI(['create:groups']) && <AddGroupButton />
          ]}
        />

        <Card style={{ boxShadow: 'none' }}>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          {filteredUsers.length > 0 && (
          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={groups.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {visibleItems
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    const isItemSelected = selected.indexOf(row.id) !== -1;
                    return (
                      <Row
                        key={key}
                        row={row}
                        isItemSelected={isItemSelected}
                        handleDelete={handleDelete}
                        handleClick={handleClick}
                        setSelectedGroup={setSelectedGroup}
                      />
                    );
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{ py: 3 }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <Stack spacing={2} className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
              />
            </Stack>
          </TableContainer>
          )}
        </Card>
      </Container>
      {filteredUsers.length < 1 && NoContent('No Groups Found')}
      {selectedGroup && (
        <Modal open={!!selectedGroup} close={!selectedGroup ? 'true' : undefined}>
          <Box>
          <GroupUserList
            group={selectedGroup}
            close={() => setSelectedGroup(null)}
          />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default observer(GroupList);

const Row = ({
  row,
  row: { id, name, group_desc, group_type, image_id },
  handleDelete,
  isItemSelected,
  handleClick,
  setSelectedGroup
}) => {
  const { fetchImageByID } = fileStore;
  const { getUser } = userStore;
  const [owner, setOwner] = useState({});
  const [image, setImage] = useState(null);

  useEffect(() => {
    getUser(row.owner_user_id).then((res) => setOwner(res));
  }, [row]);

  useEffect(() => {
    image_id
      ? fetchImageByID(image_id).then((res) => {
        res !== 0 ? setImage(res) : setImage(null);
      })
      : setImage(null);
  }, [row.id, image_id]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, id)}
        />
      </TableCell>
      <TableCell align="left">
        <Stack direction="row" alignItems="center" spacing={2}>
          <img
            alt={name}
            className="w-10 h-10 object-contain"
            src={image || process.env.REACT_APP_PLACEHOLDER_IMAGE}
          />
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">{group_desc}</TableCell>
      <TableCell align="left">{group_type}</TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
            {owner.givenName} {owner.familyName}
          </Stack>
      </TableCell>
      <TableCell align="right">
        <GroupMoreMenu
          onDelete={() => handleDelete(id)}
          row={{ ...row, image, image_id: row.image_id }}
          selectGroup={() =>
            setSelectedGroup({
              ...row,
              owner_name: owner.givenName + ' ' + owner.familyName
            })
          }
        />
      </TableCell>
    </TableRow>
  );
};

import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import { AddUserButton } from '../../components/AddButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import UserMoreMenu from '../../components/Security/userList/UserMoreMenu';

import { BulkDeleteButton } from '../../components/DeleteButton';
import companyStore from '../../store/organisation/company';
import userStore from '../../store/security/user';

import { observer } from 'mobx-react';
import moment from 'moment';

import { useSnackbar } from 'notistack';
import fileStore from '../../store/file';
import canI from '../../utils/canI';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        (_user.givenName &&
          _user.givenName.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_user.familyName &&
          _user.familyName.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_user.emailAddress &&
          _user.emailAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const UserList = () => {
  const {
    state: { users },
    getUsers,
    deleteUser
  } = userStore;

  const { getCompanyByIds } = companyStore;

  const { enqueueSnackbar } = useSnackbar();

  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('givenName');
  const [filterName, setFilterName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const rowsPerPage = 10;
  const itemsPerPage = 10;
  const page = 0;

  const TABLE_HEAD = [
    { id: 'givenName', label: 'User', isSorted: orderBy === 'givenName' },
    {
      id: 'company',
      label: 'Primary Company',
      isSorted: orderBy === 'givenName'
    },
    {
      id: 'emailAddress',
      label: 'Email',
      isSorted: orderBy === 'givenName'
    },
    { id: 'lastlogin', label: 'Last Login', isSorted: orderBy === 'givenName' },
    { id: 'moreMenu' }
  ];

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    visibleItems.length === 0 &&
      currentPage > 1 &&
      setCurrentPage(currentPage - 1);
  }, [visibleItems]);

  useEffect(() => {
    getUsers().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch users', { variant: 'error' })
    );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    deleteUser(userId).then((res) => {
      res === 1
        ? enqueueSnackbar('User deleted successfully', { variant: 'success' })
        : enqueueSnackbar('Failed to delete user', { variant: 'error' });

      res === 1 && setSelected(selected => selected.filter(item => item !== userId));
    }
    );
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Users"
        actions={[
          canI(['delete:users']) && <BulkDeleteButton
            onDelete={deleteUser}
            selected={selected}
            setSelected={setSelected}
            heading={['User', 'Users']}
          />,
          canI(['create:users']) && <AddUserButton name="Add User" />
        ]}
      />

      <Card style={{ boxShadow: 'none' }}>
        <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={users.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {visibleItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = selected.indexOf(row.id) !== -1;
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      handleClick={handleClick}
                      handleDeleteUser={handleDeleteUser}
                      isItemSelected={isItemSelected}
                      getCompanyByIds={getCompanyByIds}
                    />
                  );
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    sx={{ py: 3 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default observer(UserList);

const Row = ({
  row,
  row: {
    id,
    emailAddress,
    givenName,
    familyName,
    statusFull,
    lastLogin,
    companyId
  },
  handleClick,
  handleDeleteUser,
  isItemSelected,
  getCompanyByIds
}) => {
  const { fetchImageByID } = fileStore;
  const [company, setCompany] = useState({});
  const [image, setImage] = useState(null);

  useEffect(() => {
    row.imageId
      ? fetchImageByID(row.imageId).then((res) => {
        res !== 0 ? setImage(res) : setImage(null);
      })
      : setImage(null);
  }, [row, row.imageId]);

  const fetchCompany = async () => {
    const res = await getCompanyByIds(companyId);
    setCompany(res);
  };

  useEffect(() => {
    fetchCompany();
  }, [companyId]);

  return (
    <Fragment>
      <TableRow
        hover
        key={row.id}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row.id)}
          />
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <img
              alt={givenName}
              className="w-10 h-10 object-contain"
              src={image || process.env.REACT_APP_PLACEHOLDER_IMAGE}
            />
            <Typography variant="subtitle2" noWrap>
              {givenName} {familyName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {company?.length > 0 ? company.map(c => c.name).join(' , ') : '-'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{emailAddress}</TableCell>
        <TableCell align="left">
          {lastLogin && lastLogin !== 'null'
            ? moment(lastLogin).format('MMMM Do YYYY, h:mm:ss a')
            : '-'}
        </TableCell>

        <TableCell align="right">
          <UserMoreMenu
            onDelete={() => handleDeleteUser(id)}
            row={{ ...row, image, imageId: row.imageId }}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

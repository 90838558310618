import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import { MdWavingHand } from 'react-icons/md';
import MediaPost from './Widgets/MediaPost';
import { styled } from '@mui/material/styles';
import PostingSummary from './Widgets/PostingSummary';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import PublishingHistoryChart from './Widgets/PublishingHistoryChart';
import ChannelBreakdownChart from './Widgets/ChannelBreakdownChart';
import loadingStore from '../../store/loading';
import widgetApi from '../../api/widget';
import { icons } from '../../constants/channels';
import { Player } from '@lottiefiles/react-lottie-player';
import idelDashboardJson from '../../assets/lottie/idel_dashboard.json';
import boxaddChannel from '../../assets/images/boxaddChannel.png';
import boxcreatePost from '../../assets/images/boxcreatePost.png';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FAFAFA',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const DashboardBox = () => {
  const {
    setLoading
  } = loadingStore;
  const { user } = useAuth0();
  const [data, setData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const fetchChannelBreakdown = () => {
    setLoading(true);
    widgetApi.getChannelBreakDown().then((response) => {
      const newData = response.map((result, index) => {
        const channelType = icons.find((item) => item.name === result.channelType.channelName);
        const color = channelType ? channelType.color : '#eeeeee';
        return {
          id: index,
          label: result.channelType.channelName,
          value: result.count,
          color
        };
      });

      setData(newData);
      setLoading(false);
      setIsDataLoading(false);
    }).catch((_error) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchChannelBreakdown();
  }, []);

  return (
    <>
      {!isDataLoading && data.length > 0 && (
        <div>
          <div className="flex justify-start mb-5" style={{ fontSize: '22px' }}>
            Hey, {user.given_name} <MdWavingHand style={{ color: '#ffb946' }} />
          </div>
          <div className="flex justify-start mb-5" style={{ fontSize: '16px' }}>
            Explore and keep a track of your posting summary
          </div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={9}>
                <Item>
                  <PostingSummary></PostingSummary>
                </Item>
                <Item className='mt-10 pl-5 pt-2'>
                  <div className="flex justify-start font-semibold text-lg">
                    Publishing History
                  </div>
                  <p className="flex justify-start mb-5 text-xs">See how you have been posting to different networks during the reporting period.</p>
                  <hr className="my-5" />
                  <PublishingHistoryChart></PublishingHistoryChart>
                </Item>
                <Item className='mt-10'>
                  <div className="flex justify-start font-semibold text-lg">
                    Channel Breakdown
                  </div>
                  <p className="flex justify-start mb-5 text-xs">See how many channel of different type you have configured.</p>
                  <hr className="my-5" />
                  <ChannelBreakdownChart data={data}></ChannelBreakdownChart>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item>
                  <div className="flex justify-center mb-5 text-gray font-semibold" style={{ fontSize: '14px' }}>
                    Recently Published Post
                  </div>
                  <MediaPost></MediaPost>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      {!isDataLoading && data.length <= 0 && (
        <div>
          <div className="flex justify-start mb-5" style={{ fontSize: '22px' }}>
            Welcome to Share With Marvin, {user.given_name} <MdWavingHand style={{ color: '#ffb946' }} />
          </div>
          <div className="flex justify-start" style={{ fontSize: '16px' }}>
            To start using please connect any social media channel.
          </div>
          <div className="flex justify-start mt-10 ml-10" style={{ fontSize: '20px', alignItems: 'center' }}>
            <div className="flex ml-10">
              <Item sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <Link
                  to="/channels/manage-channels/new"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src={boxaddChannel}
                    alt="icon"
                    style={{ height: '300px', width: '300px' }}
                  />
                </Link>
              </Item>
              <Item sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <Link
                  to="/contents/search"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src={boxcreatePost}
                    alt="icon"
                    style={{ height: '300px', width: '300px' }}
                  />
                </Link>
              </Item>
            </div>
            <Player
              src={idelDashboardJson}
              className="player"
              autoplay
              loop
              style={{ height: '400px', width: '400px' }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default observer(DashboardBox);

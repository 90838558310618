import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function FigmaConnectionDialog ({
  hasFigmaConnection,
  setFigmaConnection
}) {
  const navigate = useNavigate();

  return (
      <React.Fragment>
        <Dialog
          open={hasFigmaConnection}
          onClose={() => setFigmaConnection(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'No Figma Connection Detected?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            It looks like your Figma account is not linked. Visit Settings to integrate and link your Figma account for full functionality.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='bg-white text-blue border-blue border' onClick={() => setFigmaConnection(false)}>Cancel</Button>
            <Button className='bg-blue text-white' onClick={() => navigate('/setting/integration') } autoFocus>
              Go To integration
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

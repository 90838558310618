class SessionStorage {
  static saveData = (key, value) => {
    sessionStorage.setItem(key, value);
  };

  static getSessionData = (key) => {
    if (sessionStorage.getItem(key)) {
      return sessionStorage.getItem(key);
    } else {
      return null;
    }
  };

  static clearSessionData = () => {
    sessionStorage.clear();
  };
}
export default SessionStorage;

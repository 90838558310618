import Slider from 'react-slick';
import { ReactComponent as InstagramLike } from '../../../assets/icons/Instagram/like.svg';
import { ReactComponent as InstagramShare } from '../../../assets/icons/Instagram/share.svg';
import { ReactComponent as InstagramComment } from '../../../assets/icons/Instagram/comment.svg';
import { ReactComponent as InstagramBookmark } from '../../../assets/icons/Instagram/bookmark.svg';
import { useEffect, useRef } from 'react';
import mentionReplaceUtil from './MentionReplaceUtil';

function InstagramPreview ({
  plainText,
  files,
  settings,
  previewData,
  user,
  renderDefaultPreview,
  mentions
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    if (mentions) {
      const content = mentionReplaceUtil(mentions, plainText, 'instagram', 'https://www.instagram.com/');
      if (contentRef?.current) {
        contentRef.current.innerHTML = content;
      }
    }
  }, [mentions, plainText]);

  if (!files || (files.length === 0)) {
    return renderDefaultPreview();
  }

  const handleClick = () => {
    if (previewData.socialChannelLink) {
      window.open(previewData.socialChannelLink, '_blank');
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = user?.picture;
  };

  return (
    <div className='bg-gray-light p-3 rounded-md'>
      <div className='flex items-center mt-3 mb-2'>
        <img onError={addDefaultSrc}
          src={previewData?.profilePicUrl ? previewData?.profilePicUrl : user?.picture}
          alt='User'
          className='w-8 h-8 rounded-full mr-2'
        />
        <p className='text-sm font-semibold cursor-pointer' onClick={handleClick}>{previewData?.displayName ? previewData?.displayName : user.nickname}</p>
      </div>
      <Slider {...settings}>
        {files.map((file, index) => (
          <div key={file.fileKey} className='items-center'>
            {file.contentType.startsWith('image/')
              ? (
                  <img
                    src={file.url}
                    alt={file.filename}
                    className='rounded-md object-cover w-full h-[400px]'
                  />
                )
              : (
              <video controls className='w-full h-[400px] object-cover'>
                <source src={file.url} type={file.contentType} />
                Your browser does not support the video tag.
              </video>
                )}
          </div>
        ))}
      </Slider>
      <div className='flex items-center gap-2 justify-between mt-7'>
        <div className='flex justify-between gap-2'>
          <InstagramLike />
          <InstagramComment />
          <InstagramShare />
        </div>
        <InstagramBookmark />
      </div>
      <p className='text-[10px] mt-2 break-words'><span className='font-semibold mr-1'>{previewData?.displayName}</span><span ref={contentRef}></span></p>
      <hr className="my-5 border-[#eee]" />
    </div>
  );
}

export default InstagramPreview;

import { useState, useEffect } from 'react';

import { icons } from '../../../constants/channels';

import contentStore from '../../../store/content/content';
import channelsStore from '../../../store/channel/channel';
import { observer } from 'mobx-react';

const ContentSelection = ({
  setStep,
  handleClose,
  version,
  setVersion,
  content,
  setContent
}) => {
  const {
    state: { versions }
  } = contentStore;

  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (version) {
      setStep(3);
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <div className="h-[400px]">
        {versions.map((x, i) => (
          <Item
            key={i}
            x={x}
            content={content}
            setContent={setContent}
            version={version}
            setVersion={setVersion}
          />
        ))}
      </div>
      {error && <p className="text-red">Select atleast a version!</p>}

      <hr className="my-5" />
      <div className="flex justify-between">
        <button className="text-blue underline" onClick={() => setStep(1)}>
          Back
        </button>

        <div className="flex">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ContentSelection);

const Item = ({ x, version, setVersion, content, setContent }) => {
  const handleSelect = (e) => {
    setVersion(e.target.value);
  };

  const handleCheckBody = (e) => {
    setContent((content) => (content = { ...content, body: e.target.checked }));
  };

  const handleCheckTags = (e) => {
    setContent((content) => (content = { ...content, tags: e.target.checked }));
  };

  const handleCheckAttachment = (e) => {
    setContent(
      (content) => (content = { ...content, attachments: e.target.checked })
    );
  };

  return (
    <div className="bg-[#fafafa] p-5 rounded flex items-start gap-3 mb-3">
      <input
        type="radio"
        value={x.id}
        checked={version === x.id}
        onChange={handleSelect}
      />
      <div className="mt-[-7px]">
        <p className="flex items-center gap-3">
          <span className="font-bold text-lg">{x.title}</span>{' '}
          {x.channelId.map((x, i) => (
            <Channel key={i} id={x} />
          ))}
        </p>

        {version === x.id && (
          <div className="flex gap-5 mt-3">

            <div className="flex items-center gap-2">
              <input
                className="mt-[-7px]"
                id={'body'}
                type="checkbox"
                checked={content.body}
                onChange={handleCheckBody}
              />
              <label htmlFor={'body'}>Body</label>
            </div>

            <div className="flex items-center gap-2">
              <input
                className="mt-[-7px]"
                id={'tags'}
                type="checkbox"
                checked={content.tags}
                onChange={handleCheckTags}
              />
              <label htmlFor={'tags'}>Tags</label>
            </div>

            <div className="flex items-center gap-2">
              <input
                className="mt-[-7px]"
                type="checkbox"
                checked={content.attachment}
                onChange={handleCheckAttachment}
                id={'attachments'}
              />
              <label htmlFor={'attachments'}>Attachments</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Channel = observer(({ id }) => {
  const {
    state: { allChannels }
  } = channelsStore;

  const [channel, setChannel] = useState({});
  const [icon, setIcon] = useState({});

  useEffect(() => {
    const data = allChannels.find((x) => parseInt(x.id) === parseInt(id));
    setChannel(data || {});
  }, [id]);

  useEffect(() => {
    const data = icons.find((c) => c.name === channel.name);
    setIcon(data || {});
  }, [channel]);

  return (
    <span
      className="mr-2"
    >
      {icon.colorIcon}
    </span>
  );
});

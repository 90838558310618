import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';

import {
  AddTagButton,
  UploadCsvTagButton
} from '../../components/AddButton';
import { BulkDeleteButton } from '../../components/DeleteButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import { useSnackbar } from 'notistack';

import { observer } from 'mobx-react';
import Moment from 'react-moment';
import TagMoreMenu from '../../components/Security/tagList/TagMoreMenu';
import TableSkelton from '../../components/Table/Skeleton';
import userStore from '../../store/security/user';
import tagStore from '../../store/tags/tag';
import canI from '../../utils/canI';
import NoContent from '../../components/NoContent';

const ManageTags = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    deleteTag,
    getTagsByPage
  } = tagStore;

  const [selected, setSelected] = useState([]);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [params, setParams] = useState({
    name: '',
    category: '',
    sortColumn: 'lastModifiedOn',
    sortDirection: 'desc',
    pageIndex: 1,
    pageSize: 8
  });

  const handelPageChange = (data) => {
    setSelected([]);
    setCurrentPage(data);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.map((item) => item.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Tags'
    },
    {
      id: 'category',
      label: 'Tags Category'
    },
    {
      id: 'lastModifiedBy',
      label: 'Last Updated By'
    },
    {
      id: 'lastModifiedOn',
      label: 'Last Updated',
      isSorted: params.sortColumn === 'lastModifiedOn'
    },
    { id: 'moreMenu' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = params.sortColumn === property && params.sortDirection === 'asc';
    setParams({
      ...params,
      sortColumn: property,
      sortDirection: isAsc ? 'desc' : 'asc'
    });
  };

  const handleFilterByName = (event) => {
    setParams({ ...params, name: event.target.value });
  };

  const handleFilterByCategory = (event) => {
    setParams({ ...params, category: event.target.value });
  };

  const handleDelete = async (id) => {
    try {
      await deleteTag(id);
      enqueueSnackbar('Tag deleted successfully', { variant: 'success' });
      refresh();
    } catch (error) {
      enqueueSnackbar('Failed to delete tag', { variant: 'error' });
    }
  };

  const {
    state: { users },
    getUsers
  } = userStore;

  useEffect(() => {
    getUsers();
  }, []);

  const fetchTags = async () => {
    try {
      setPageLoading(true);
      const { content, totalElements } = await getTagsByPage(params);
      setItems(content ?? []);
      setTotalPages(Math.ceil(parseInt(totalElements) / params.pageSize));
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      enqueueSnackbar('Error fetching tags', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchTags();
  }, [
    params.pageSize,
    params.pageIndex,
    params.sortColumn,
    params.sortDirection,
    params.name,
    params.category
  ]);

  useEffect(() => {
    setParams({ ...params, pageIndex: currentPage });
  }, [currentPage]);

  const refresh = (reset) => {
    if (reset) {
      setParams({ ...params, pageIndex: 1 });
    } else {
      fetchTags();
    }
  };

  return (
    <>
      <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Manage Tags"
        actions={[
          canI(['delete:tags']) && (
            <BulkDeleteButton
              onDelete={handleDelete}
              selected={selected}
              setSelected={setSelected}
              heading={['Tag', 'Tags']}
              refresh={refresh}
            />
          ),
          canI(['create:tags']) && <AddTagButton refresh={refresh} />
        ]}
      />

      <Card style={{ boxShadow: 'none' }}>
        <div
          className="justify-start"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className="block" style={{ width: '45%' }}>
            <ListToolbar
              placeholder={'Search by Tag Name'}
              numSelected={selected.length}
              filterName={params.name}
              onFilterName={handleFilterByName}
            />
          </div>
          <div className="block" style={{ width: '45%' }}>
            <ListToolbar
              placeholder={'Search by Tag Category'}
              numSelected={selected.length}
              filterName={params.category}
              onFilterName={handleFilterByCategory}
            />
          </div>
          <div className="block" style={{ width: '10%' }}>
            <UploadCsvTagButton />
          </div>
        </div>

        {items.length > 0 && (
          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
            <Table>
              <ListHead
                order={params.sortDirection}
                orderBy={params.sortColumn}
                headLabel={TABLE_HEAD}
                rowCount={items.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {pageLoading && (
                  <TableSkelton rows={params.pageSize} columns={6}></TableSkelton>
                )}
                {!pageLoading && (
                  items.map((row, index) => {
                    const isItemSelected = selected.indexOf(row.id) !== -1;
                    return (
                      <Row
                        item={row}
                        key={index}
                        refresh={refresh}
                        users={users}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                        isItemSelected={isItemSelected}
                      />
                    );
                  }))
                }
              </TableBody>
            </Table>
            <Stack spacing={2} className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                onChange={(event, value) => handelPageChange(value)}
                color="primary"
              />
            </Stack>
          </TableContainer>
        )}
      </Card>
      </Container>
      {!pageLoading && items.length < 1 && NoContent('No Tags Found')}
    </>
  );
};
export default observer(ManageTags);

const Row = observer(({
  item: { id, lastModifiedOn, lastModifiedBy, createdOn, name, category },
  item,
  isItemSelected,
  handleClick,
  handleDelete,
  users,
  refresh,
  selected
}) => {
  const [modifiedPerson, setModifiedPerson] = useState('');

  useEffect(() => {
    const user = users.find((x) => parseInt(x.id) === parseInt(lastModifiedBy));
    if (user) {
      setModifiedPerson(user.givenName + ' ' + user.familyName);
    }
  }, [users, item]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      selected={selected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, id)}
        />
      </TableCell>
      <TableCell align="left">
        {name}
      </TableCell>
      <TableCell align="left">{category}</TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          {modifiedPerson}
        </Stack>
      </TableCell>
      <TableCell align="left"><Moment format="MMM, DD YYYY hh:mm A">{lastModifiedOn}</Moment></TableCell>
      <TableCell align="right">
        <TagMoreMenu
          onDelete={() => handleDelete(id)}
          row={{ ...item }}
          refresh={refresh}
        />
      </TableCell>
    </TableRow>
  );
});

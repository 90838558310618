import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';

import loadingStore from '../loading';

const ENDPOINT = '/groups/team';

class TeamStore {
  state = {
    teams: [],
    memberships: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getTeams: action,
      getTeam: action,
      addTeam: action,
      updateTeam: action,
      deleteTeam: action
    });
  }

  getTeams = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.teams = response.data;
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getTeam = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addTeam = async (team) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, team);
      this.state.teams = [...this.state.teams, response.data];
      return 1;
    } catch (error) {
      if (error?.response?.data?.includes('already added')) {
        return 2;
      }

      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateTeam = async (team) => {
    loadingStore.setLoading(true);
    try {
      await client.put(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${team.id}`, {
        name: team.name
      });
      const index = this.state.teams.findIndex((c) => c.id === team.id);
      this.state.teams[index] = team;
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteTeam = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.teams = this.state.teams.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getTeamMemberships = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`);
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addTeamMembership = async (data, teamId) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${teamId}`,
        data
      );
      // this.state.memberships = [...this.state.memberships, response];
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response ? error.response.code : 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateTeamMembership = async (data) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/member/${data.id}`,
        { userId: data.user_id, readOnly: data.readOnly }
      );

      // this.state.memberships = this.state.memberships.map((x) =>
      //   x.id === data.id ? data : x
      // );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteTeamMembership = async (id, data) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        { data }
      );

      // this.state.memberships = this.state.memberships.filter(
      //   (x) => x.id !== id
      // );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const teamStore = new TeamStore();
export default teamStore;

import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Modal
  , Pagination, Stack
} from '@mui/material';

import ListToolbar from '../../../components/Global/ListToolbar';
import ListHead from '../../../components/Global/ListHead';

// import companyStore from "../../../../store/organisation/company";
import fileStore from '../../../store/file';
// import { useSnackbar } from "notistack";
import { observer } from 'mobx-react';
import ModalHead from '../../Modal/Header/modalHead';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  if (orderBy === 'name') {
    return order === 'desc'
      ? (a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase())
      : (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((b, a) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const AddEntity = ({
  open,
  handleClose,
  title,
  data,
  selected,
  setSelected,
  handleSave,
  viewOnly
}) => {
  // const {
  // state: { data },
  //   getCompanies,
  // } = companyStore;
  // const { enqueueSnackbar } = useSnackbar();

  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage] = useState(10);
  const [itemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [companySortDirection, setCompanySortDirection] = useState('desc');

  let filteredUsers = applySortFilter(
    // userList,
    data,
    getComparator(order, orderBy),
    filterName
  );

  if (viewOnly) {
    filteredUsers = filteredUsers.filter((item) => selected.includes(item.id));
  }

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // useEffect(() => {
  //   getCompanies().then(
  //     (res) =>
  //       res === 0 &&
  //       enqueueSnackbar("Failed to fetch users", { variant: "error" })
  //   );
  // }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (property === 'company') {
      setCompanySortDirection(companySortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  const handleSelectAllClick = (event) => {
    if (viewOnly) {
      return;
    }
    if (event.target.checked) {
      // const newSelecteds = userList.map((user) => user.id);
      const newSelecteds = data.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', isSorted: orderBy === 'name' }
  ];

  const handleClick = (event, name) => {
    if (viewOnly) {
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Modal open={open}>
      <div className="flex items-center h-full bg-black/10">
        <form className="bg-white w-[600px] mx-auto rounded-lg drop-shadow-lg">
          <ModalHead handleClose={handleClose} heading={title} />
          <Card style={{ boxShadow: 'none' }} className="mt-[-20px]">
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <TableContainer
              sx={{ minWidth: 800 }}
              style={{ padding: '0 25px' }}
            >
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={userList.length}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {visibleItems
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { id, name } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <Row
                          row={row}
                          id={id}
                          key={index}
                          name={name}
                          handleClick={handleClick}
                          isItemSelected={isItemSelected}
                          title={title}
                        />
                      );
                    })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={6}
                        sx={{ py: 3 }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              <Stack spacing={2} className="pagination w-[550px]">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  variant="outlined"
                  onChange={(event, value) => setCurrentPage(value)}
                  color="primary"
                />
              </Stack>
            </TableContainer>
          </Card>

          <hr className="m-3" />
          <div className="flex justify-end pt-0 p-5">
            <button
              className="text-blue border-[1px] border-blue rounded px-4 mr-2"
              onClick={handleClose}
            >
              Cancel
            </button>
            {!viewOnly && (
              <button type="button" className="mrvn-sv rounded" onClick={handleSave}>
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default observer(AddEntity);

const Row = observer(({
  row: { id, name },
  row,
  handleClick,
  isItemSelected,
  title
}) => {
  const { fetchImageByID } = fileStore;

  const [image, setImage] = useState(null);

  useEffect(() => {
    row.imageId
      ? fetchImageByID(row.imageId).then((res) => {
        res !== 0 ? setImage(res) : setImage(null);
      })
      : setImage(null);
  }, [row, row.imageId]);

  return (
    <TableRow
      hover
      key={id}
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, id)}
        />
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          {title === 'Companies' && (
            <img
              alt={name}
              className="w-10 h-10 object-contain"
              src={image || process.env.REACT_APP_PLACEHOLDER_IMAGE}
            />
          )}

          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
});

import { useState, useEffect, useContext } from 'react';
import MyTable from './ChannelList';
import channelsStore from '../../../store/channel/channel';
import { CompanyChannelListContext } from '../../../pages/ContentManagement';

const ChannelSelection = ({
  handleClose,
  setStep,
  setSelectedChannels,
  selectedRows,
  setSelectedRows
}) => {
  const {
    state: { allChannelTypes }
  } = channelsStore;
  const companyChannelList = useContext(CompanyChannelListContext);

  const [error, setError] = useState(false);
  const [channelTypes, setChannelTypes] = useState([]);

  const handleSubmit = () => {
    if (selectedRows.length > 0) {
      setStep(2);
      const selectedChannels = [];
      const channelToBeIgnored = [];
      try {
        // @todo
        selectedRows.forEach((selected) => {
          if (typeof selected === 'string' && selected.split('-').length > 1) {
            const row = selected.split('-');
            const selectedarray = channelTypes.filter((x, i) => i === Number(row[0])).map((x) => x.instances.filter((r, t) => t === Number(row[1]))).map((x) => x[0]);
            selectedChannels.push(...selectedarray);
            channelToBeIgnored.push(parseInt(row[0]));
          }
        });

        selectedRows.forEach((selected) => {
          if (typeof selected !== 'string' && !channelToBeIgnored.includes(selected)) {
            const selectedarray = channelTypes.filter((x, i) => i === selected).map((x) => x.instances.map((r) => r));
            selectedChannels.push(...selectedarray[0]);
          }
        });
      } catch (error) {
        console.log(error);
      }

      setSelectedChannels(selectedChannels);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (companyChannelList.length > 0) {
      const result = companyChannelList?.reduce(function (r, a) {
        r[a.type_id] = r[a.type_id] || [];
        r[a.type_id].push(a);
        return r;
      }, Object.create(null));

      const types = [];
      if (result) {
        const dataKeys = Object.keys(result);
        dataKeys.forEach(key => {
          const channel = allChannelTypes.find((c) => c.id === +key);
          if (channel) {
            types.push({
              id: channel.id,
              name: channel.channelName,
              instances: result[key]
            });
          }
        });
      }
      setChannelTypes(types);
    }
  }, [companyChannelList]);

  return (
    <div>
      <div className="h-[400px]">
        <div
          onClick={() => setError(false)}
          className="h-[300px] overflow-auto"
        >
          <MyTable
            all_channels={companyChannelList}
            channelTypes={channelTypes}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
      {error && <p className="text-red">Select atleast one channel!</p>}
      <hr className="my-5" />
      <div className="flex justify-between">
        <button className="text-blue underline">Clear All</button>

        <div className="flex">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChannelSelection;

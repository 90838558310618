const Level = ({ handleClose, setStep, level, setLevel }) => {
  const handleLevelChange = () => {
    setLevel(level === 1 ? 2 : 1);
  };
  return (
    <>
      <div className="h-[300px]">
        <p className="my-5 font-bold text-lg">
          Select the level that you wish to apply restriction at
        </p>

        <div className="flex items-center">
          <input
            type="radio"
            id="type"
            name="filterType"
            value="type"
            checked={level === 1}
            onChange={handleLevelChange}
            className="mt-[-8px]"
          />
          <label htmlFor="type" className="mr-3 ml-1">
            Channel Type
          </label>

          <input
            type="radio"
            id="instance"
            name="filterType"
            value="instance"
            checked={level === 2}
            onChange={handleLevelChange}
            className="mt-[-8px]"
          />
          <label htmlFor="instance" className="ml-1">
            Channel Instance
          </label>
        </div>
      </div>
      <hr className="my-5" />

      <div className="flex justify-end">
        <button
          className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          onClick={() => setStep(2)}
          variant="contained"
          color="primary"
          className="mb-4 mrvn-sv rounded"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Level;

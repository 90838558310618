import { ReactComponent as LinkedinLike } from '../../../assets/icons/Linkedin/like.svg';
import { ReactComponent as LinkedinComment } from '../../../assets/icons/Linkedin/comment.svg';
import { ReactComponent as LinkedinShare } from '../../../assets/icons/Linkedin/share.svg';
import { ReactComponent as LinkedinRepost } from '..//../../assets/icons/Linkedin/repost.svg';
import GridlayoutPreview from './GridlayoutPreview';

function LinkedInPreview ({
  plainText,
  files,
  previewData,
  user
}) {
  const handleClick = () => {
    if (previewData.socialChannelLink) {
      window.open(previewData.socialChannelLink, '_blank');
    }
  };

  return (
    <div className='bg-gray-light rounded-md p-3'>
      <div className='flex items-center mt-3 mb-2'>
        <img
          src={previewData?.profilePicUrl ? previewData?.profilePicUrl : user?.picture}
          alt='User'
          className='w-8 h-8 rounded-full mr-2'
        />
        <div>
          <p className='text-sm font-semibold cursor-pointer' onClick={handleClick}>{previewData?.displayName ? previewData?.displayName : user.nickname}</p>
          <p className='text-xs mt-[-4px] font-thin'>followers</p>
        </div>
      </div>

      {plainText && <p className='mb-4'>{plainText}</p>}

      {files && files.length > 0 && (
        <>
          {files.find(file => file.contentType.startsWith('image/'))
            ? (
                <GridlayoutPreview countFrom={5} images={files.filter(file => file.contentType.startsWith('image/')).map(file => file.url)}/>
              )
            : (
                files.find(file => file.contentType.startsWith('video/'))
                  ? (
                      <video controls width='100%' className='h-[400px] object-cover'>
                        <source src={files.find(file => file.contentType.startsWith('video/')).url} type={files.find(file => file.contentType.startsWith('video/')).contentType} />
                        Your browser does not support the video tag.
                      </video>)
                  : <></>
              )}
        </>
      )}
      <hr className="my-5 border-[#eee]" />
      <div className='flex items-center gap-2 justify-between'>
        <div className='flex items-center gap-2 justify-between'>
          <LinkedinLike />
        </div>
        <div className='flex items-center gap-2 justify-between'>
          <LinkedinComment />
        </div>
        <div className='flex items-center gap-2 justify-between'>
          <LinkedinRepost />
        </div>
        <div className='flex items-center gap-2 justify-between'>
          <LinkedinShare />
        </div>
      </div>
    </div>
  );
}

export default LinkedInPreview;

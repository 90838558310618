import { useState } from 'react';

import ModalHead from '../Modal/Header/modalHead';

import Progress from './Progress';
import Resize from './Resize';
import Upload from './Upload';

const ImageUpload = ({ onClose, handleSubmit, handleRemoveImage }) => {
  const [step, setStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = (data) => {
    setSelectedImage(data[0]);
    setStep(2);
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setStep(1);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setStep(1);
    onClose();
  };

  const handleRemoveAllImage = () => {
    handleRemoveImage();
    handleClose();
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-full">
      <div className="flex items-start drop-shadow-lg mt-28 h-full w-full">
        <div className="w-[500px] mx-auto bg-white rounded">
          <ModalHead heading="Upload Image" handleClose={handleClose} />
          <div className="p-5 pt-0">
            {step === 1 && (
              <Upload handleChange={handleChange} handleClose={handleClose} />
            )}

            {step === 2 && (
              <Progress
                selectedImage={selectedImage}
                handleRemove={handleRemove}
                handleSubmit={() => setStep(3)}
                handleClose={handleClose}
              />
            )}

            {step === 3 && (
              <Resize
                src={selectedImage}
                submitImage={handleSubmit}
                handleClose={handleClose}
                setSelectedImage={setSelectedImage}
                handleRemove={handleRemoveAllImage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;

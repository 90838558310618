import client from '../../utils/axios';
import { makeObservable, observable, action } from 'mobx';
import loadingStore from '../loading';

const ROLES_ENDPOINT = '/organisations/roles';

class RoleStore {
  state = {
    roles: [],
    role: null
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      addRole: action,
      deleteRole: action,
      updateRole: action,
      getRoles: action
    });
  }

  setRole = (data) => {
    this.state.role = data;
  };

  // Get all roles
  getRoles = async () => {
    loadingStore.setLoading(true);
    try {
      // client.get('https://api.dev.sharewithmarvin.io/connections/available');
      // client.get('https://api.dino-foto.de/events/query/');

      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ROLES_ENDPOINT}`);
      this.state.roles = response.data;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  // Add a new role
  addRole = async (role) => {
    // this.state.roles = [role, ...this.state.roles];
    loadingStore.setLoading(true);

    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ROLES_ENDPOINT}`, role, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      this.state.roles = [response.data, ...this.state.roles];
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  // Update a role
  updateRole = async (updatedRole) => {
    // this.state.roles = this.state.roles.map(x => x.id === updatedRole.id ? updatedRole : x)
    loadingStore.setLoading(true);

    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ROLES_ENDPOINT}/${updatedRole.id}`,
        updatedRole,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const roleIndex = this.state.roles.findIndex(
        (role) => role.id === updatedRole.id
      );
      this.state.roles[roleIndex] = updatedRole;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  // Delete a role
  deleteRole = async (roleId) => {
    // this.state.roles = this.state.roles.filter((role) => role.id !== roleId);
    loadingStore.setLoading(true);

    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ROLES_ENDPOINT}/${roleId}`);
      this.state.roles = this.state.roles.filter((role) => role.id !== roleId);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

// Create a new instance of the RoleStore class
const roleStore = new RoleStore();

export default roleStore;

import SearchIcon from '@mui/icons-material/Search';
import VerifiedIcon from '@mui/icons-material/Verified';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import client from '../../utils/axios';
import { useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import mentionStyles from './mentionStyles';
import mentionsInputStyles from './mentionsInputStyles';
import loadingStore from '../../store/loading';
const CHANNEL_USER_SEARCH_ENDPOINT = '/directory/channel/handler';

const ChannelHandlerField = (props) => {
  const {
    parentIndex,
    setValue,
    channelInstanceId,
    selectedChannelType,
    trigger,
    error,
    defaultValues,
    isDeleted
  } = props;
  const [mentionValue, setMentionValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isValidUser, setIsValidUser] = useState(false);
  const { setLoading } = loadingStore;

  const isSlack = () => {
    return selectedChannelType?.channelName.toLowerCase() === 'slack';
  };

  const isTwitter = () => {
    return selectedChannelType?.channelName.toLowerCase() === 'formerly twitter' ||
    selectedChannelType?.channelName.toLowerCase() === 'premium';
  };

  const isFbOrLinkedin = () => {
    return selectedChannelType?.channelName.toLowerCase() === 'facebook page' ||
    selectedChannelType?.channelName.toLowerCase() === 'linkedin' ||
    selectedChannelType?.channelName.toLowerCase() === 'linkedin business';
  };

  useEffect(() => {
    if (defaultValues?.channelHandlerList[parentIndex]?.handlerId?.trim() && !isDeleted) {
      setLoading(true);
      if (isSlack() || isTwitter()) {
        setSearchText(defaultValues.channelHandlerList[parentIndex].handlerName);
      } else {
        setMentionValue(defaultValues.channelHandlerList[parentIndex].handlerName);
      }
      setValue(`channelHandlerList.${parentIndex}.handlerSearchText`, defaultValues.channelHandlerList[parentIndex].handlerName);
      updateFormControlValue([{
        id: defaultValues.channelHandlerList[parentIndex].handlerId,
        display: defaultValues.channelHandlerList[parentIndex].handlerName
      }]);
    }
  }, [defaultValues]);

  function updateFormControlValue (userInfo) {
    if (!userInfo.length) {
      userInfo[0] = {
        id: '',
        display: ''
      };
      setIsValidUser(false);
      setValue(`channelHandlerList.${parentIndex}.isUserValid`, false);
    } else {
      setIsValidUser(true);
      setValue(`channelHandlerList.${parentIndex}.isUserValid`, true);
      setSearchText(userInfo[0].display);
    }
    setValue(`channelHandlerList.${parentIndex}.handlerName`, userInfo[0].display);
    setValue(`channelHandlerList.${parentIndex}.handlerId`, userInfo[0].id);
    trigger([`channelHandlerList.${parentIndex}.handlerSearchText`, `channelHandlerList.${parentIndex}.isUserValid`]);
    setLoading(false);
  };

  const validateUser = async (callback) => {
    setLoading(true);
    if (isSlack()) {
      const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;
      if (emailRegex.test(searchText)) {
        await client
          .get(`${process.env.REACT_APP_BASE_URL}${CHANNEL_USER_SEARCH_ENDPOINT}` + '/search', {
            params: {
              channelInstanceId,
              channelTypeName: selectedChannelType.channelName,
              channelTypeId: selectedChannelType.id,
              searchText
            }
          }).then(res => callback(res.data)).catch((e) => {
            updateFormControlValue([]);
          });
      } else {
        updateFormControlValue([]);
      }
    }
    if (isTwitter()) {
      const validRegex = /\s/;
      if (validRegex.test(searchText)) {
        updateFormControlValue([]);
      } else {
        await client
          .get(`${process.env.REACT_APP_BASE_URL}${CHANNEL_USER_SEARCH_ENDPOINT}` + '/search', {
            params: {
              channelTypeName: selectedChannelType.channelName,
              channelTypeId: selectedChannelType.id,
              searchText
            }
          }).then(res => callback(res.data)).catch((e) => updateFormControlValue([]));
      }
    }
  };

  const onChangeMention = (event, newValue, newPlainTextValue, mentions) => {
    setMentionValue(event.target.value);
    setValue(`channelHandlerList.${parentIndex}.handlerSearchText`, event.target.value);
    if (mentions.length) {
      updateFormControlValue([{
        id: mentions.map(m => m.id).toString(),
        display: event.target.value
      }]);
    } else {
      updateFormControlValue([{
        id: event.target.value,
        display: event.target.value
      }]);
    }
  };

  const onInputChange = (event) => {
    setSearchText(event.target.value);
    setValue(`channelHandlerList.${parentIndex}.handlerSearchText`, event.target.value);
    if (isValidUser) {
      setValue(`channelHandlerList.${parentIndex}.isUserValid`, false);
      setIsValidUser(false);
    }
    if (!event.target.value?.trim()) {
      updateFormControlValue([]);
    }
  };

  return (
    <>
      <div style={{ height: '56px' }} className="w-3/4">
        { isSlack() || isTwitter()
          ? <TextField
              value={searchText}
              placeholder={isSlack() ? 'email' : 'username'}
              InputProps={{
                endAdornment: (
                <InputAdornment position='end'>
                  {!isValidUser && <IconButton
                    onClick={() => validateUser(updateFormControlValue)}
                  >
                    <SearchIcon/>
                  </IconButton>}
                  {isValidUser && <VerifiedIcon style={{ color: 'green' }}/>}
                </InputAdornment>)
              }}
              className='w-full'
              onChange={(e) => onInputChange(e)}
              error={(error.channelHandlerList ? !!error.channelHandlerList[parentIndex]?.handlerSearchText || !!error.channelHandlerList[parentIndex]?.isUserValid : false)}
              onBlur={() => trigger([`channelHandlerList.${parentIndex}.handlerSearchText`, `channelHandlerList.${parentIndex}.isUserValid`])}
            >
            </TextField>
          : <MentionsInput
            singleLine
            style={mentionsInputStyles}
            value={mentionValue}
            placeholder={ isFbOrLinkedin() ? 'Link' : '@username'}
            onChange={onChangeMention}
            className="w-full"
            allowSpaceInQuery = {true}
            allowSuggestionsAboveCursor={true}
            customSuggestionsContainer={(children) => <div style={{ maxHeight: '250px', overflowY: 'auto' }}>{children}</div>}
          >
            <Mention
              data={[]}
              style={mentionStyles}
              appendSpaceOnAdd
            ></Mention>
          </MentionsInput>
        }
      </div>
    </>
  );
};

export default ChannelHandlerField;

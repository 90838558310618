export default function HeaderBreadcrumbs ({
  links,
  actions,
  heading,
  moreLink = '' || [],
  sx,
  selected,
  ...other
}) {
  return (
    <div className="mb-5">
      <div className="flex items-center">
        <div className="flex-grow">
          <h4 className="text-lg font-bold mb-4">{heading}</h4>
        </div>

        {actions?.length > 0 &&
          actions.map((x, i) => x && (
            <div key={i} className="flex-shrink-0 ml-4">
              {x}
            </div>
          ))}
      </div>

      <div className="mt-2">
        {typeof moreLink === 'string'
          ? (
          <a
            href={moreLink}
            // eslint-disable-next-line react/no-unknown-property
            without
            rel="noreferrer"
            target="_blank"
            className="text-sm"
          >
            {moreLink}
          </a>
            )
          : (
              moreLink.map((href) => (
            <a
              key={href}
              href={href}
              target="_blank"
              // eslint-disable-next-line react/no-unknown-property
              without
              rel="noreferrer"
              className="text-sm inline-block"
            >
              {href}
            </a>
              ))
            )}
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import { AiOutlineMore } from 'react-icons/ai';
import DeleteConfirmationModal from '../../Modal/Delete';
import GroupMembershipCreate from './GroupMembershipCreate';

export default function GroupMembershipMoreMenu ({
  onDelete,
  row,
  selectGroup
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleConfirmDelete = () => {
    onDelete();
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleShowMembers = () => {
    selectGroup();
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
        >
          <AiOutlineMore />
        </button>

        {isOpen && (
          <div
            ref={ref}
            className="z-10 origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <button
              onClick={handleShowMembers}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
            >
              View Group
            </button>
            <button
              onClick={handleShowMembers}
              className="flex text-start items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
            >
              Manage Group Members
            </button>
            {/* <button
              onClick={handleDeleteClick}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
            >
              <AiFillDelete className="text-red mr-2" />
              Delete
            </button>
            <button
              onClick={() => handleEditClick(currentGroup)}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
            >
              <AiFillEdit className="text-green mr-2" />
              Edit
            </button> */}
          </div>
        )}
      </div>
      {isEditOpen && (
        <GroupMembershipCreate
          open={true}
          handleClose={() => setIsEditOpen(false)}
          isEdit={true}
          currentGroup={row}
        />
      )}
      <DeleteConfirmationModal
        open={showDeleteModal}
        onDelete={onDelete}
        del={['Group']}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import {
  FaFacebookF,
  FaInstagram,
  FaSlack,
  FaTelegramPlane,
  FaTiktok,
  FaYoutube
} from 'react-icons/fa';
import { AiOutlineWechat, AiOutlineFile } from 'react-icons/ai';
import { TfiWorld } from 'react-icons/tfi';
import { CiSearch } from 'react-icons/ci';
import { BiEnvelope } from 'react-icons/bi';
import {
  FaXTwitter
} from 'react-icons/fa6';

const AppliesTo = ({
  handleClose,
  setStep,
  fetchChannels,
  level,
  userChannelsAppliesTo,
  types,
  channel,
  setChannel
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const icons = [
    {
      name: 'Facebook Page',
      icon: <FaFacebookF className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Facebook Page')
        ? types.find((x) => x.channelName === 'Facebook Page').id
        : 0
    },
    {
      name: 'Instagram Business',
      icon: <FaInstagram className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Instagram Business')
        ? types.find((x) => x.channelName === 'Instagram Business').id
        : 0
    },
    {
      name: 'Formerly Twitter',
      icon: <FaXTwitter className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Formerly Twitter')
        ? types.find((x) => x.channelName === 'Formerly Twitter').id
        : 0
    },
    {
      name: 'Premium',
      icon: <FaXTwitter className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Premium')
        ? types.find((x) => x.channelName === 'Premium').id
        : 0
    },
    {
      name: 'Enterprise',
      icon: <FaXTwitter className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Enterprise')
        ? types.find((x) => x.channelName === 'Enterprise').id
        : 0
    },
    {
      name: 'We Chat',
      icon: <AiOutlineWechat className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'We Chat')
        ? types.find((x) => x.channelName === 'We Chat').id
        : 0
    },
    {
      name: 'Telegram',
      icon: <FaTelegramPlane className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Telegram')
        ? types.find((x) => x.channelName === 'Telegram').id
        : 0
    },
    {
      name: 'Tik Tok',
      icon: <FaTiktok className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Tik Tok')
        ? types.find((x) => x.channelName === 'Tik Tok').id
        : 0
    },
    {
      name: 'File',
      icon: <AiOutlineFile className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'File')
        ? types.find((x) => x.channelName === 'File').id
        : 0
    },
    {
      name: 'Web',
      icon: <TfiWorld className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Web')
        ? types.find((x) => x.channelName === 'Web').id
        : 0
    },
    {
      name: 'Email',
      icon: <BiEnvelope className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Email')
        ? types.find((x) => x.channelName === 'Email').id
        : 0
    },
    {
      name: 'Slack',
      icon: <FaSlack className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Email')
        ? types.find((x) => x.channelName === 'Email').id
        : 0
    },
    {
      name: 'YouTube Shorts',
      icon: <FaYoutube className="text-blue mr-1" />,
      id: types.find((x) => x.channelName === 'Email')
        ? types.find((x) => x.channelName === 'Email').id
        : 0
    }
  ];

  const onSubmit = () => {
    channel
      ? setStep(3)
      : enqueueSnackbar('Select a channel', { variant: 'warning' });
  };

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    fetchChannels();
  }, []);

  return (
    <>
      <div className="h-[300px]">
        <div className="flex my-5">
          <CiSearch className="border-[1px] border-[#ccc] border-r-0 rounded-l p-3 text-5xl" />
          <input
            className="border-[1px] py-[11px] border-[#ccc] border-l-0 rounded-r w-full outline-none"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-4 items-center">
          {level === 1 &&
            types.map((x, i) => (
              <React.Fragment key={x.id}>
                {(searchText === '' ||
                  x.channelName?.toLowerCase().includes(searchText)) && (
                  <div key={x.id} className="flex items-center">
                    <input
                      type="radio"
                      id={x.channelName}
                      name="filterType"
                      value="type"
                      checked={channel === x.id}
                      onChange={() => setChannel(x.id)}
                      className="mt-[-8px]"
                    />
                    <label
                      htmlFor={x.channelName}
                      className="mx-3 flex items-center"
                    >
                      {icons.find((c) => x.channelName === c.name)
                        ? (
                            icons.find((c) => x.channelName === c.name).icon
                          )
                        : (
                        <TfiWorld className="text-blue mr-1" />
                          )}{' '}
                      {x.channelName}
                    </label>
                  </div>
                )}
              </React.Fragment>
            ))}
          {level === 2 &&
            userChannelsAppliesTo
              .filter((x) => x.active)
              .map((x, i) => (
                <React.Fragment key={x.id}>
                  {(searchText === '' ||
                    x.name?.toLowerCase().includes(searchText)) && (
                    <div key={i} className="flex items-center">
                      <input
                        type="radio"
                        id={x.name}
                        name="filterType"
                        value="type"
                        checked={channel === x.id}
                        onChange={() => setChannel(x.id)}
                        className="mt-[-8px]"
                      />
                      <label
                        htmlFor={x.name}
                        className="mx-3 flex items-center"
                      >
                        {icons.find((c) => x.type_id === c.id)
                          ? (
                              icons.find((c) => x.type_id === c.id).icon
                            )
                          : (
                          <TfiWorld className="text-blue mr-1" />
                            )}{' '}
                        {x.name}
                      </label>
                    </div>
                  )}
                </React.Fragment>
              ))}
        </div>
      </div>
      <hr className="my-5" />
      <div className="flex items-center justify-between">
        <button onClick={() => setStep(1)} className="text-blue underline">
          Back
        </button>{' '}
        <div className="flex justify-end">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AppliesTo;

import { Box, Button, Checkbox, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { BsCamera } from 'react-icons/bs';
import companyStore from '../../../store/organisation/company';
import roleStore from '../../../store/security/role';
import userStore from '../../../store/security/user';
import ImageUpload from '../../ImageUpload';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
const UserNewForm = ({ isEdit, currentUser, handleClose }) => {
  const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const PASS_REGEX = /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  const {
    state: { companies },
    getCompanies
  } = companyStore;

  const {
    state: { roles },
    getRoles
  } = roleStore;

  const { addUser, updateUser } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [formData, setFormData] = useState({
    givenName: '',
    familyName: '',
    email: '',
    password: '',
    confirmPassword: '',
    connection: 'Username-Password-Authentication',
    companyId: [],
    imageId: null,
    roleId: null
  });

  const [error, setError] = useState({
    givenName: null,
    familyName: null,
    email: null,
    password: null,
    confirmPassword: null,
    companyId: null,
    roleId: null
  });

  const { givenName, familyName, email, companyId, password, confirmPassword, imageId, roleId } =
    formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const adding = () => {
    formData.companyId = companies.map(m => formData.companyId.indexOf(m.name) > -1 ? m.id : null).filter(f => f);
    addUser(formData, selectedImage).then((res) => {
      if (res === 1) {
        enqueueSnackbar('User added successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a user', { variant: 'error' });
      }
    });
  };

  const editing = () => {
    formData.companyId = companies.map(m => formData.companyId.indexOf(m.name) > -1 ? m.id : null).filter(f => f);
    updateUser(
      {
        ...formData,
        tenant: currentUser.tenantId,
        imageId: imageId || null
      },
      selectedImage
    ).then((res) => {
      if (res === 1) {
        enqueueSnackbar('User edited successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to edit a user', { variant: 'error' });
      }
    });
  };

  const testError = () => {
    if (givenName.length === 0) {
      setError({ ...error, givenName: 'First name can not be empty' });
      return false;
    }
    if (familyName.length === 0) {
      setError({ ...error, familyName: 'Last name can not be empty' });
      return false;
    }
    if (!EMAIL_REGEX.test(email)) {
      setError({ ...error, email: 'Email not valid' });
      return false;
    }

    if (!PASS_REGEX.test(password) && !isEdit) {
      setError({
        ...error,
        password:
          'Password should contain atleast 1 uppercase letter, 1 digit and 1 special character'
      });
      return false;
    }

    if (password !== confirmPassword && !isEdit) {
      setError({ ...error, confirmPassword: 'Passwords do not match' });
      return false;
    }

    if (companyId.length === 0) {
      setError({
        ...error,
        companyId: 'Select a company'
      });
      return false;
    }

    if (roleId === null) {
      setError({
        ...error,
        roleId: 'Select a Role'
      });
      return false;
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (testError()) {
      isEdit ? editing() : adding();
    }
  };

  const handleRemoveImage = () => {
    setFormData((formData) => (formData = { ...formData, imageId: null }));
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handleImageUpload = (file) => {
    setSelectedImage(file);
    setShowUploadImage(false);
  };

  useEffect(() => {
    getRoles();
    getCompanies().then(response => {
      if (isEdit) {
        setFormData({
          ...formData,
          givenName: currentUser.givenName,
          familyName: currentUser.familyName,
          email: currentUser.emailAddress,
          password: currentUser.password,
          id: currentUser.id,
          companyId: response.map(m => currentUser.companyId.indexOf(m.id) > -1 ? m.name : null).filter(f => f),
          imageId: currentUser.imageId,
          roleId: currentUser.roleId
        });
        currentUser.image && setImagePreview(currentUser.image);
      }
    });
  }, []);

  useEffect(() => {
    selectedImage && setImagePreview(URL.createObjectURL(selectedImage));
  }, [selectedImage]);

  return (
    <>
      <form
        onSubmit={onSubmit}
        onFocus={() =>
          setError({
            givenName: null,
            familyName: null,
            email: null,
            password: null,
            confirmPassword: null,
            companyId: null
          })
        }
      >
        <Grid container spacing={2} className='p-5'>
          <Grid container item xs={8} md={8} spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor='lastname-signup'>First Name*</InputLabel>
              <OutlinedInput
                fullWidth
                placeholder='First Name'
                name='givenName'
                value={givenName}
                onChange={onChange}
              />
              {error.givenName && (
                <FormHelperText error id="helper-text">
                  {error.givenName}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor='lastname-signup'>Last Name*</InputLabel>
              <OutlinedInput
                fullWidth
                placeholder='Last Name'
                name='familyName'
                value={familyName}
                onChange={onChange}
              />
              {error.familyName && (
                <FormHelperText error id="helper-text">
                  {error.familyName}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box sx={{ mb: 2, mt: 2 }}>
              <FormControl>
                <div
                  className={`border-2 border-blue relative ${imagePreview ? 'bg-white' : 'bg-light-blue'
                    } text-blue text-lg font-bold w-32 h-32 rounded-full flex items-center justify-center`}
                >
                  {!imagePreview && 'Add Logo'}
                  {imagePreview && (
                    <img
                      className='absolute rounded-full object-cover h-full w-full'
                      src={imagePreview}
                      alt='channel'
                    />
                  )}
                  <button
                    type='button'
                    onClick={() => setShowUploadImage(true)}
                    className='cursor-pointer text-white bg-blue p-2 rounded-full absolute bottom-[-5px] right-[-5px]'
                  >
                    <BsCamera />
                  </button>
                </div>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor='email'>Email*</InputLabel>
              <OutlinedInput
                fullWidth
                placeholder='User Email'
                name='email'
                value={email}
                onChange={onChange}
              />
              {error.email && (
                <FormHelperText error id="helper-text">
                  {error.email}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor='company'>Primary Company*</InputLabel>
            <Select
              fullWidth
              multiple
              value={companyId ?? []}
              input={<OutlinedInput label="Company" />}
              renderValue={(selected) => selected.join(', ')}
              onChange={(e) =>
                setFormData({ ...formData, companyId: e.target.value })
              }
            >
              <MenuItem value='null' disabled>
                Select Company
              </MenuItem>
              {companies.map((x, i) => (
                <MenuItem key={i} value={x.name}>
                  <Checkbox checked={companyId.indexOf(x.name) > -1} />
                  <ListItemText primary={x.name} />
                </MenuItem >
              ))}
            </Select>
            {error.companyId && (
              <FormHelperText error id="helper-text">
                {error.companyId}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor='role'>Role*</InputLabel>
            <Select
              fullWidth
              onChange={(e) =>
                setFormData({ ...formData, roleId: e.target.value })
              }
              name='roleId' value={roleId ?? 'null'}>
              <MenuItem value='null' disabled hidden>
                Select Role
              </MenuItem>
              {roles.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {error.roleId && (
              <FormHelperText error id="helper-text">
                {error.roleId}
              </FormHelperText>
            )}
          </Grid>
          {!isEdit && (
            <>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor='email'>Password*</InputLabel>
                <OutlinedInput
                  fullWidth
                  placeholder='Password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {error.password && (
                  <FormHelperText error id="helper-text">
                    {error.password}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor='email'>Confirm Password*</InputLabel>
                <OutlinedInput
                  fullWidth
                  placeholder='Confirm Password'
                  name='confirmPassword'
                  value={confirmPassword}
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {error.confirmPassword && (
                  <FormHelperText error id="helper-text">
                    {error.confirmPassword}
                  </FormHelperText>
                )}
              </Grid>
            </>
          )}
          {!isEdit && (<Grid item xs={12} md={12}>
            <div className="rounded-md h-15 p-2 justify-start text-xs shadow-lg" style={{ backgroundColor: '#FF9141', color: '#fff' }}>
              <InfoIcon sx={{ color: '#fff' }} />
              <span> {'Please Note'}</span>
              <br></br>
              <span> {'Adding a new user will increase the number of seats required by your organisation.'} </span>
              <br></br>
              <span> {'Charges in the first month after adding will be pro-rated.'} </span>
            </div>
          </Grid>
          )}
          <Grid item xs={12} md={12}>
            <hr />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
              {/* <Button color='error' className='mrvn-rst rounded px-4'>
                Reset Password
              </Button> */}
              <div>
                <Button className='mrvn-cln rounded' onClick={handleClose}>
                  Cancel
                </Button>
                <Button type='submit' className='mrvn-sv rounded'>
                  {!isEdit ? 'Add User' : 'Save Changes'}
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </form>

      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}
    </>
  );
};

export default observer(UserNewForm);

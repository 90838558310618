/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Avatar,
  Card,
  Checkbox,
  Container,
  InputBase,
  MenuItem,
  Pagination, Select, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled
} from '@mui/material';

import {
  AddContentButton,
  PublishContentButton
} from '../../components/AddButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import Moment from 'react-moment';
import ArticleApi from '../../api/content';
import SearchContentMoreMenu from '../../components/Content/SearchContentMoreMenu';
import TableSkelton from '../../components/Table/Skeleton';
import ToggleButton from '../../components/ToggleButton';
import { publicationStatuses } from '../../constants/publicationStatues';
import contentStore from '../../store/content/content';
import userStore from '../../store/security/user';
import canI from '../../utils/canI';
import convertToUtc from '../../utils/convertToUtc';
import useDebounce from '../../utils/debounce';
import NoContent from '../../components/NoContent';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const SearchContent = () => {
  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');
  const navigate = useNavigate();
  const defaultParams = {
    myArticles: false,
    pageIndex: 1,
    pageSize: 10,
    status: queryStatus != null ? queryStatus : '',
    startDate:
      queryStatus != null
        ? convertToUtc(dayjs().format('YYYY-MM-DD 00:00:00'))
        : '',
    startDateStr:
      queryStatus != null ? dayjs().format('YYYY-MM-DD 00:00:00') : '',
    endDate:
      queryStatus != null
        ? convertToUtc(dayjs().format('YYYY-MM-DD 23:59:59'))
        : '',
    endDateStr:
      queryStatus != null ? dayjs().format('YYYY-MM-DD 23:59:59') : '',
    sortColumn: '',
    sortDirection: '',
    searchText: '',
    showCancelled: false,
    authorId: ''
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [selected, setSelected] = useState([]);
  const [isUserNotFound, setIsUserNotFound] = useState(true);
  const [items, setItems] = useState([]);
  const [params, setParams] = useState(defaultParams);
  const [loading, setLoading] = useState(false);
  const { handlePublishEvent } = contentStore;
  const debouncedSearchTerm = useDebounce(params.searchText, 500);
  const location = useLocation();
  const { state: { users }, getUsers } = userStore;

  const refresh = () => {
    setLoading(true);
    ArticleApi.search(params).then(({ content, page, totalElements }) => {
      setItems(content ?? []);
      setCurrentPage(page);
      setTotalPages(Math.ceil(totalElements / defaultParams.pageSize));
      setLoading(false);
    });
  };

  useEffect(() => {
    refresh();
  }, [
    params.myArticles,
    params.pageIndex,
    params.searchText,
    params.status,
    params.startDate,
    params.endDate,
    params.sortColumn,
    params.sortDirection,
    location.key,
    params.showCancelled,
    params.authorId
  ]);

  useEffect(() => {
    setParams({ ...params, pageIndex: currentPage });
  }, [currentPage]);

  const TABLE_HEAD = [
    { id: 'CONTENT_NAME', label: 'Title' },
    { id: 'CHANNEL', label: 'Authored By' },
    { id: 'createdOn', label: 'Authored At' },
    { id: 'updatedAt', label: 'Modified At' },
    { id: 'status', label: 'Status' },
    { id: 'MOREMENU' }
  ];

  const handleAllItemCheck = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleItemCheck = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setParams({ ...params, searchText: event.target.value });
  };

  const handleEditItem = (data) => {
    navigate(`/content/${data.id}`);
  };

  useEffect(() => {
    setParams({ ...params, searchText: debouncedSearchTerm });
  }, [debouncedSearchTerm]);

  const handleSort = (event, property) => {
    if (['createdOn', 'updatedAt', 'status'].includes(property)) {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setParams({
        ...params,
        sortColumn: property,
        sortDirection: isAsc ? 'desc' : 'asc'
      });
    }
  };

  const onPublishContents = async (id) => {
    if (selected.length > 0) {
      const requests = selected.map((id, index) => {
        return handlePublishEvent(id).then((res) => {
          return res;
        });
      });

      Promise.all(requests).then(() => {
        navigate('/contents/search');
      });
    }
  };

  const handelPageChange = (data) => {
    setSelected([]);
    setCurrentPage(data);
  };

  useEffect(() => {
    setIsUserNotFound(items?.length === 0);
  }, [items]);

  useEffect(() => {
    getUsers();
  }, []);

  const handleStatus = (value) => {
    setParams({ ...params, status: value });
  };

  const onAuthorChange = (value) => {
    setParams({ ...params, authorId: value });
  };

  const onChangeStartDate = (value) => {
    setParams({
      ...params,
      startDate: convertToUtc(value.format('YYYY-MM-DD 00:00:00')),
      startDateStr: value.format('YYYY-MM-DD 00:00:00')
    });
  };

  const onChangeEndDate = (value) => {
    setParams({
      ...params,
      endDate: convertToUtc(value.format('YYYY-MM-DD 23:59:59')),
      endDateStr: value.format('YYYY-MM-DD 23:59:59')
    });
  };

  const clearFilter = () => {
    setParams({
      ...defaultParams,
      status: '',
      startDate: '',
      startDateStr: '',
      endDate: '',
      endDateStr: ''
    });
  };

  const STATUS_LIST = [
    'DRAFT',
    'READY TO PUBLISH',
    'PUBLISH IN PROGRESS',
    'PARTIALLY PUBLISH',
    'SCHEDULED',
    'FAILED',
    'PUBLISHED',
    'RECURRING',
    'AUTHORISATION PENDING',
    'AUTHORISATION REJECTED'
  ];

  return (
    <>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Content"
          actions={[
            canI(['create:articles']) && <AddContentButton />,
            selected.length > 0 && canI(['create:publish-content']) && (
              <PublishContentButton
                selected={selected}
                setSelected={setSelected}
                onConfirm={onPublishContents}
              />
            )
          ]}
        />

        <Card style={{ boxShadow: 'none' }}>
          <div className="flex items-center">
            <div style={{ width: '60%' }}>
              <ListToolbar
                placeholder={'Search by Article Title or Body'}
                numSelected={selected.length}
                filterName={params.searchText}
                onFilterName={handleFilterByName}
              />
            </div>
            <div className="ml-5 flex">
              <span className="mr-1 text-sm">Authored By Me:</span>
              <span>
                <ToggleButton
                  toggle={params.myArticles}
                  handleClick={(value) =>
                    setParams({
                      ...params,
                      myArticles: !params.myArticles,
                      pageIndex: 1
                    })
                  }
                />
              </span>
            </div>
            <div className="ml-5 flex">
              <span className="mr-1 text-sm">Show Cancelled:</span>
              <span>
                <ToggleButton
                  toggle={params.showCancelled}
                  handleClick={(value) =>
                    setParams({
                      ...params,
                      showCancelled: !params.showCancelled,
                      pageIndex: 1
                    })
                  }
                />
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="ml-5 flex">
              <Select
                className="rounded"
                sx = {{ width: 250 }}
                SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 12 } }}
                displayEmpty={true}
                value={params.status}
                onChange={(e) => handleStatus(e.target.value)}
                renderValue={(value) => {
                  if (value) {
                    const selectedUser = STATUS_LIST.find(u => u === value);
                    return (selectedUser);
                  } else {
                    return ('Filter By Status');
                  }
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {STATUS_LIST.map((x, i) => (
                  <MenuItem key={i} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="ml-5 flex gap-2">
              <Select
                className="rounded"
                sx = {{ width: 250 }}
                SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 12 } }}
                displayEmpty={true}
                value={params.authorId}
                renderValue={(value) => {
                  if (value) {
                    const selectedUser = users.find(u => u.id === value);
                    return (selectedUser.givenName + ' ' + selectedUser.familyName);
                  } else {
                    return ('Filter By Author');
                  }
                }}
                onChange={(e) => onAuthorChange(e.target.value)}
                input={<BootstrapInput />}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {users.map((x, i) => (
                  <MenuItem key={i} value={x.id}>
                    <div className='flex align-items-center'>
                      <Avatar src={x.picture} className="mr-2" sx={{ width: 30, height: 30 }}/>
                      {x.givenName + ' ' + x.familyName}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="ml-3 flex gap-2 px-3 calendar-range-picker rounded outline-none border-[#ccc] py-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  label="Start Date"
                  onChange={onChangeStartDate}
                  value={
                    params.startDateStr !== '' ? dayjs(params.startDateStr) : ''
                  }
                />
                <DatePicker
                  slotProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                  label="End Date"
                  onChange={onChangeEndDate}
                  value={
                    params.endDateStr !== '' ? dayjs(params.endDateStr) : ''
                  }
                />
              </LocalizationProvider>
            </div>
            <div onClick={clearFilter} className="mr-2 flex cursor-pointer text-blue">
              {'Clear'}
            </div>
          </div>
          <div>
            {items.length > 0 && (
              <TableContainer
                style={{ padding: '0 25px' }}
              >
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={items.length}
                    numSelected={selected.length}
                    onRequestSort={handleSort}
                    onSelectAllClick={handleAllItemCheck}
                  />
                  <TableBody>
                    {loading && (
                      <TableSkelton
                        rows={params.pageSize}
                        columns={6}
                      ></TableSkelton>
                    )}
                    {!loading &&
                      items.map((row) => {
                        const isItemSelected = selected.indexOf(row.id) !== -1;
                        const {
                          title,
                          authorUserName,
                          status,
                          createdOn,
                          updatedAt
                        } = row;
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onClick={(event) =>
                                  handleItemCheck(event, row.id)
                                }
                              />
                            </TableCell>
                            <TableCell align="left">{title}</TableCell>
                            <TableCell align="left">{authorUserName}</TableCell>
                            <TableCell>
                              <Moment format="MMM, DD YYYY hh:mm A">
                                {createdOn}
                              </Moment>
                            </TableCell>
                            <TableCell>
                              <Moment format="MMM, DD YYYY hh:mm A">
                                {updatedAt}
                              </Moment>
                            </TableCell>
                            <TableCell>
                              <div className="h-10 inline-flex items-center px-2.5 py-0.5 text-xs rounded capitalize">
                                <span
                                  className={`inline-flex items-center justify-center w-7 h-7 mr-2 text-white rounded-full ${publicationStatuses[status]?.bgColor}`}
                                >
                                  {publicationStatuses[status]?.icon}
                                </span>
                                {status}
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <SearchContentMoreMenu
                                handleEdit={() => handleEditItem(row)}
                                row={row}
                                refresh={refresh}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={6}
                          sx={{ py: 3 }}
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                <Stack spacing={2} className="pagination">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    onChange={(event, value) => handelPageChange(value)}
                    color="primary"
                  />
                </Stack>
              </TableContainer>
            )}
          </div>
        </Card>
      </Container>
      {!loading && items.length < 1 && NoContent('No Content found')}
    </>
  );
};

export default observer(SearchContent);

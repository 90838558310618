import React, { useEffect, useState } from 'react';
import usersStore from '../../../store/settings/user';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import currency from '../../../utils/currency';

const Billing = () => {
  const { fetchSubscription, fetchCustomerPortal } = usersStore;
  const [subscription, setSubscription] = useState([]);
  const [customerPortal, setCustomerPortal] = useState(null);
  const [thirdPartyManage, setThirdPartyManage] = useState('');

  useEffect(() => {
    fetchSubscription().then((response) => {
      if (response.length > 0) {
        setSubscription(response);
      } else {
        setThirdPartyManage('It looks like your subscription or payment is not managed by Marvin but by a third party provider. Please reach out to the platform where you purchased the subscription.');
      }
    });
  }, []);

  useEffect(() => {
    fetchCustomerPortal().then((response) => {
      setCustomerPortal(response);
    });
  }, []);

  const formatDate = (timestamp) => {
    return moment.unix(timestamp).format('L');
  };

  return (
    <>
      <div className='mt-10'>
        { thirdPartyManage !== '' && (<p>{thirdPartyManage}</p>) }
        {subscription.map((item, index) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction='column' spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle1' component='div' className='uppercase'>
                    {item.status}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2' component='div' color='text.secondary'>
                    <strong>Trialing until</strong>: {formatDate(item.trial_end)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>
                    <strong>Current period</strong>: {formatDate(item.current_period_start)} - {formatDate(item.current_period_end)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'>
                    <strong>Plan Details</strong>:
                  </Typography>
                </Grid>
                <Grid item spacing={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Number of Seats</strong></TableCell>
                          <TableCell><strong>Interval</strong></TableCell>
                          <TableCell><strong>Amount</strong> (*Excluding Taxes)</TableCell>
                          <TableCell><strong>Status</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.plan.map((plan, index) => (
                          <TableRow key={index}>
                            <React.Fragment key={index}>
                              <TableCell>{plan.quantity}</TableCell>
                              <TableCell className='uppercase'>{plan.interval}</TableCell>
                              <TableCell>{currency(plan.amount / 100, item.currency)}</TableCell>
                              <TableCell>{plan.active ? 'Active' : 'Inactive'}</TableCell>
                            </React.Fragment>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {customerPortal && (<Grid item>
                <Link variant='subtitle2'>
                  <a style={{ color: '#2193B0', textDecoration: 'underline' }}
                    // eslint-disable-next-line react/no-unknown-property
                    without
                    rel='noreferrer'
                    href={customerPortal} target='_blank'> Update Payment Details</a>
                </Link>
              </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};

export default Billing;

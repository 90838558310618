import PropTypes from 'prop-types';
// material
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// ----------------------------------------------------------------------

PermissionHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function PermissionHead ({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  firstWidth
}) {
  return (
    <TableHead>
      <TableRow style={{ background: 'rgba(25, 35, 58, 0.1)' }}>
        {headLabel.map((headCell, i) => (
          <TableCell
            style={{ width: headCell.width ? headCell.width : firstWidth && (i === 0 ? firstWidth : 'auto') }}
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel hideSortIcon>
              {headCell.label}
              {orderBy === headCell.id
                ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

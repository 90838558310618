import { useEffect, useState } from 'react';
import { TfiWorld } from 'react-icons/tfi';
import { icons } from '../../../constants/channels';
import channelsStore from '../../../store/channel/channel';
import fileStore from '../../../store/file';
import { observer } from 'mobx-react';

const ChannelsList = ({ channels, isCompare, colour }) => {
  const {
    state: { allChannels, allChannelTypes },
    fetchAllChannels
  } = channelsStore;

  useEffect(() => {
    fetchAllChannels(null);
  }, []);

  let css = 'relative bg-[#e9f4f7] hover:bg-[#e9f4f7] text-blue rounded-full py-2 px-2';
  if (colour !== undefined) {
    css = 'relative bg-' + colour + ' hover:bg-' + colour + ' text-blue rounded-full py-2 px-2 background';
  }

  return (
    <div className={isCompare ? 'inline-flex items-center gap-1 ' + css : 'inline-flex items-center gap-1'}>
      {channels.map((x, i) => (
        <Item
          key={i}
          id={x}
          channels={allChannelTypes}
          itemAllChannels={allChannels}
        />
      ))}
    </div>
  );
};

export default observer(ChannelsList);

const Item = ({ id, channels, itemAllChannels }) => {
  const [channel, setChannel] = useState(null);
  const [icon, setIcon] = useState(<TfiWorld />);
  const [image, setImage] = useState(null);
  const [color, setColor] = useState('#006ace');
  const [isHovered, setIsHovered] = useState(false);
  const { fetchImageByID } = fileStore;

  const {
    getChannelInstance
  } = channelsStore;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const channelInstance = itemAllChannels.find((c) => c.id === id);
    if (channelInstance) {
      setChannel(channelInstance);
    } else {
      getChannelInstance(id).then(response => {
        setChannel(response);
      });
    }
  }, [id]);

  const findIcon = (name) => {
    return icons.find((c) => c.name === name);
  };

  useEffect(() => {
    if (channel) {
      if (channel.imageId) {
        fetchImageByID(channel.imageId).then((res) => setImage(res));
      } else {
        const channelType = channels.find((c) => c.id === channel.type_id);
        if (channelType) {
          const icon = findIcon(channelType.channelName);
          if (icon) {
            setIcon(icon.icon);
            setColor(icon.color);
          } else {
            setIcon(<TfiWorld />);
            setColor('#006ace');
          }
        }
      }
    }
  }, [channel, channels]);

  return (
    <>
      <p
        style={{ backgroundColor: image ? '' : color }}
        className={`text-white rounded-full ${image ? 'p-0' : 'p-2'} `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        { image ? (<img src={image} alt="icon" className={'w-8 h-8 object-cover rounded-full'}/>) : icon }
      </p>
      <div className="relative">
        {isHovered && channel && (
          <p className="absolute top-0 left-[-5px] whitespace-nowrap bg-black text-white rounded-full px-4 z-30 drop-shadow-lg">
            {channel.name ? channel.name : channel.channelName}
          </p>
        )}
      </div>
    </>
  );
};

import { useState, useEffect, useRef } from 'react';
import { BsInstagram } from 'react-icons/bs';

import { FaFacebookF } from 'react-icons/fa';
import AddEntity from './AddEntity';
import { observer } from 'mobx-react';
import companyStore from '../../../store/organisation/company';
import locationStore from '../../../store/organisation/location';
import departmentStore from '../../../store/organisation/department';
import teamStore from '../../../store/organisation/team';
import programStore from '../../../store/organisation/program';
import projectStore from '../../../store/organisation/project';
import folderStore from '../../../store/organisation/folder';

const EntitiesItem = ({
  title,
  slug,
  onClick,
  isSelected,
  activeMenu,
  contentParams,
  setContentParams,
  viewOnly
}) => {
  const ref = useRef(null);
  const [showList, setShowList] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);

  const {
    getCompanies
  } = companyStore;
  const {
    getLocations
  } = locationStore;
  const {
    getDepartments
  } = departmentStore;
  const {
    getTeams
  } = teamStore;
  const {
    getProgrammes
  } = programStore;
  const {
    getProjects
  } = projectStore;
  const {
    getFolders
  } = folderStore;

  const handleClick = () => {
    onClick();
    setShowAdd(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (activeMenu === 'locations' && isSelected) {
      getLocations().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu] ?? []);
    } else if (activeMenu === 'companies' && isSelected) {
      getCompanies().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu]);
    } else if (activeMenu === 'departments' && isSelected) {
      getDepartments().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu] ?? []);
    } else if (activeMenu === 'teams' && isSelected) {
      getTeams().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu] ?? []);
    } else if (activeMenu === 'programmes' && isSelected) {
      getProgrammes().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu] ?? []);
    } else if (activeMenu === 'projects' && isSelected) {
      getProjects().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu] ?? []);
    } else if (activeMenu === 'folders' && isSelected) {
      getFolders().then((result) => {
        setItems(result);
      });
      setSelected(contentParams[activeMenu] ?? []);
    }
  }, [activeMenu]);

  const handleSave = () => {
    if (slug === 'locations') {
      setContentParams({ ...contentParams, locations: selected });
    } else if (slug === 'companies') {
      setContentParams({ ...contentParams, companies: selected });
    } else if (slug === 'departments') {
      setContentParams({ ...contentParams, departments: selected });
    } else if (slug === 'teams') {
      setContentParams({ ...contentParams, teams: selected });
    } else if (slug === 'programmes') {
      setContentParams({ ...contentParams, programmes: selected });
    } else if (slug === 'projects') {
      setContentParams({ ...contentParams, projects: selected });
    } else if (slug === 'folders') {
      setContentParams({ ...contentParams, folders: selected });
    }

    setShowAdd(false);
  };

  return (
    <div className="relative">
      <button
        onClick={handleClick}
        className={`${
          isSelected
            ? 'bg-[#e9f4f7] hover:bg-[#e9f4f7] drop-shadow text-blue'
            : ''
        } rounded w-full p-3 pl-6 flex items-center justify-between my-2 z-10`}
      >
        {title}{' '}
        <span
          // onClick={() => setShowList(true)}
          className={`${
            isSelected ? 'bg-[#cbe5ec]' : 'bg-[#f2f2f2]'
          } h-5 w-5 text-sm rounded-full`}
        >
          {contentParams[slug] ? contentParams[slug].length : 0}
        </span>
      </button>
      {showList && (
        <div
          ref={ref}
          className="absolute bg-white p-3 drop-shadow-lg right-5 w-52 rounded top-5 z-20 text-sm"
        >
          <p className="text-start text-gray font-bold">{title}</p>
          <div className="flex items-center gap-3 mt-3">
            <input type="checkbox" id="1" className="mt-[-9px]" />
            <label htmlFor="1" className="flex items-center gap-3">
              <FaFacebookF className="text-blue" /> Facebook
            </label>
          </div>

          <div className="flex items-center gap-3">
            <input type="checkbox" id="2" className="mt-[-9px]" />
            <label htmlFor="2" className="flex items-center gap-3">
              <BsInstagram className="text-[#fa2db0]" /> Instagram
            </label>
          </div>

          <hr className="my-3" />
          <div className="flex justify-center">
            <button
              className="flex-1 text-blue border-[1px] border-blue rounded px-4 mr-2"
              // onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="flex-1 mrvn-sv rounded"
              // onClick={() => setStep(copyContent ? 3 : 4)}
            >
              Save
            </button>
          </div>
        </div>
      )}
      <AddEntity
        open={showAdd}
        handleClose={() => setShowAdd(false)}
        data={items}
        title={title}
        slug={slug}
        selected={selected}
        setSelected={setSelected}
        handleSave={handleSave}
        viewOnly={viewOnly}
      />
    </div>
  );
};

export default observer(EntitiesItem);

/* eslint-disable quotes */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable spaced-comment */
import { makeObservable, observable } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';

const EXTERNALAI_ENDPOINT = process.env.REACT_APP_BASE_URL + '/external-ai';

class ExternaAiStore {
  state = {};

  constructor () {
    makeObservable(this, {
      state: observable
    });
  }

  generateContent = async (params) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(
        EXTERNALAI_ENDPOINT + '/ai/external/text/generations',
        params
      );
      return response;
    } catch (error) {
      return error;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const externalAiStore = new ExternaAiStore();
export default externalAiStore;

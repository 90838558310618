import { FormControl } from '@mui/material';

const Name = ({ handleClose, name, setName, onSubmit, setStep, isEdit }) => {
  return (
    <>
      <div className="h-[300px]">
        <FormControl className="my-5" fullWidth>
          <label htmlFor="company">Restriction Name</label>
          <input
            className="border-[1px] border-gray outline-none p-4 rounded"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
      </div>
      <hr className="my-5" />

      <div className="flex items-center justify-between">
        <button onClick={() => setStep(3)} className="text-blue underline">
          Back
        </button>
        <div className="flex justify-end">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
            onClick={onSubmit}
          >
            {!isEdit ? 'Add Restriction' : 'Save Changes'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Name;

import Version from './Version';
import { FaPlus } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import contentStore from '../../../store/content/content';
import { observer } from 'mobx-react';

const VersionList = ({
  setShowAlternate,
  contentId,
  viewOnly
}) => {
  const {
    state: { versions, version }
  } = contentStore;

  return (
    <div className="flex mb-5 gap-3">
      <Carousel
        className="h-15"
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
      >
        <div className="">
          <div className="flex items-center justify-start gap-3">
            {versions.length > 0 && versions.map((x, i) => (
              <Version
                key={i}
                data={x}
                selected={x.version === version.version}
                contentId={contentId}
              />
            ))}
            {!viewOnly && (
              <button
                onClick={() => setShowAlternate(true)}
                className="bg-blue p-4 text-white rounded"
              >
                <FaPlus />
              </button>
            )}
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default observer(VersionList);

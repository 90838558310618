import {
  Button
} from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import thirdPartyIntegrationStore from '../../../store/settings/thirdPartyIntegration';
import canI from '../../../utils/canI';
import { ReactComponent as OpenAi } from '../../../assets/icons/openai.svg';
import PopupLayout from '../../../layout/PopupLayout';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';

const OpenAiIntegration = () => {
  const {
    state: { connections },
    getConnections,
    createConnection
  } = thirdPartyIntegrationStore;

  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openAiToken, setOpenAiToken] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getConnections();
  }, []);

  const doConnect = async (disconnect) => {
    if (openAiToken.trim() === '' && disconnect !== true) {
      enqueueSnackbar('Please enter valid token', {
        variant: 'error'
      });
    } else {
      const response = await createConnection({
        name: 'OPENAI',
        userRef: openAiToken
      });
      if (response) {
        getConnections();
        setOpenModal(false);
      }
    }
  };

  const doDisconnect = async () => {
    if (canI(['create:integration'])) {
      await doConnect(true);
    }
  };

  const openConnectionModal = () => {
    setOpenModal(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
        <Card variant="outlined" sx={{ maxWidth: 360 }}>
            <Box sx={{ p: 2 }}>
                <OpenAi className='w-12 h-14' />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography gutterBottom variant="h5" component="div">
                    OpenAI
                </Typography>
                </Stack>
                <Typography color="text.secondary" variant="body2">
                 Known for creating advanced models like GPT, OpenAI&#39;s work spans various applications, including natural language processing.
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                { connections && connections.find((item) => item.name === 'OPENAI' && item.active)
                  ? (<Button onClick={doDisconnect} className='bg-green rounded px-4 py-2 uppercase text-white w-full' variant="contained">On</Button>)
                  : (canI(['create:integration']) && (<Button onClick={openConnectionModal} className='bg-blue rounded px-4 py-2 uppercase text-white w-full' variant="contained">Link Account</Button>))
                }
            </Box>
        </Card>
        { openModal && (
            <PopupLayout
                className="wizard"
                open={openModal}
                handleClose={() => {
                  setOpenModal(false);
                }}
                heading="Connect to OpenAI"

                width={730}
            >
               <div className="flex items-center h-full drop-shadow-lg">
                    <div className="w-full">
                    <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Enter token</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setOpenAiToken(e.target.value)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        </FormControl>
                        <hr className="my-5" />
                        <div className="flex justify-end gap-2">
                            <button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="mb-4 mrvn-sv px-2 text-white rounded"
                                onClick={doConnect}
                            >
                            {'Connect'}
                            </button>
                        </div>
                    </div>
                </div>
            </PopupLayout>
        )}
    </>);
};

export default OpenAiIntegration;

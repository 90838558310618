import PropTypes from 'prop-types';
import {
  Skeleton,
  TableRow,
  TableCell
} from '@mui/material';

TableSkelton.propTypes = {
  rows: PropTypes.number,
  columns: PropTypes.number
};

export default function TableSkelton ({
  rows,
  columns
}) {
  return (
    <>
        {Array.from(Array(rows), (e, i) => {
          return (
                <TableRow className="disable-odd-even" key={i}>
                    {Array.from(Array(columns), (t, v) => {
                      return (
                            <TableCell key={v}>
                                <Skeleton></Skeleton>
                            </TableCell>
                      );
                    })}
                </TableRow>
          );
        })}
    </>
  );
}

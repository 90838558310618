import PropTypes from 'prop-types';
import { Button, Container, Modal, Typography, Stack } from '@mui/material';
import ModalHead from './Header/modalHead';
import useSettings from '../../hooks/useSettings';

import { BsCheckCircle } from 'react-icons/bs';

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.array
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};
export default function ConfirmationModal ({
  open,
  handleCancel,
  onConfirm,
  message,
  selected,
  setSelected,
  setIsOpen
}) {
  const { themeStretch } = useSettings();

  const handleConfirm = () => {
    onConfirm(selected);
    setIsOpen(false);
    setSelected([]);
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <div
        style={{
          ...modalStyle,
          backgroundColor: 'white',
          width: '800px'
        }}
      >
        <ModalHead handleClose={handleCancel} heading={`${message[0]}`} />
        <div className="flex justify-center">
            <BsCheckCircle size="40px" className="text-blue" />
        </div>
        <Stack style={{ textAlign: 'center' }}>
          <Typography
            component="span"
            variant="h6"
            style={{
              marginTop: '40px',
              marginBottom: '15px'
            }}
          >
            {`${message[1]}`}
          </Typography>
        </Stack>
        <Container
          maxWidth={themeStretch ? false : 'lg'}
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            padding: '20px',
            marginBottom: '40px'
          }}
        >
          <Button
            className="mrvn-del-cln rounded text-lg"
            onClick={handleCancel}
            sx={{ color: 'text.secondary', marginRight: '8px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            className="mrvn-delete rounded text-lg"
          >
            Ok
          </Button>
        </Container>
      </div>
    </Modal>
  );
}

import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  if (process.env.REACT_APP_AMPLITUDE) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE, {
      defaultTracking: true
    });
  }
};

export const initAmplitudeWithUser = (userEmail) => {
  if (process.env.REACT_APP_AMPLITUDE) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE,
      {
        defaultTracking: true,
        userId: userEmail
      }
    );
  }
};

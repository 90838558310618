import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { AddPhraseButton } from '../../components/AddButton';
import { BulkDeleteButton } from '../../components/DeleteButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import PhraseMoreMenu from '../../components/ToolsandStyling/PhrasesLibrary/PhraseMoreMenu';
import phraseStore from '../../store/toolsandstyling/phrase';
import canI from '../../utils/canI';
import ToggleButton from './../../components/ToggleButton';
import NoContent from '../../components/NoContent';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.text?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.category?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const PhraseLibrary = () => {
  const {
    state: { orgPhrases, favPhrases },
    getOrganisationPhrases,
    updateOrganisationPhrase,
    deleteOrganisationPhrase,
    getFavoritePhrases,
    createOrganisationFavouritePhrase,
    deleteFavouritePhrase
  } = phraseStore;
  const { enqueueSnackbar } = useSnackbar();

  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage] = useState(10);
  const [itemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredUsers = applySortFilter(
    orgPhrases,
    getComparator(order, orderBy),
    filterName
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Phrase',
      isSorted: orderBy === 'name'
    },
    {
      id: 'text',
      label: 'Content',
      isSorted: orderBy === 'text'
    },
    {
      id: 'category',
      label: 'Phrase Category',
      isSorted: orderBy === 'category'
    },
    {
      id: 'favourites',
      label: 'Favourites'
    },
    { id: 'moreMenu' }
  ];

  useEffect(() => {
    getOrganisationPhrases();
    getFavoritePhrases();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(orgPhrases);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, phrase) => {
    if (selected.find((x) => x.id === phrase.id)) {
      setSelected((selected) => selected.filter((x) => x.id !== phrase.id));
    } else {
      setSelected((selected) => [...selected, phrase]);
    }
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = (phrase) => {
    deleteOrganisationPhrase(phrase).then((res) =>
      res === 1
        ? enqueueSnackbar('Phrase deleted successfully', { variant: 'success' })
        : enqueueSnackbar('Failed to delete phrase', { variant: 'error' })
    );
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Phrase Library"
        actions={[
          canI(['delete:phrases']) && <BulkDeleteButton
            onDelete={deleteOrganisationPhrase}
            selected={selected}
            setSelected={setSelected}
            heading={['Phrase', 'Phrases']}
          />,
          canI(['create:phrases']) && <AddPhraseButton type="org" />
        ]}
      />

      <Card style={{ boxShadow: 'none' }}>
        <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        {filteredUsers.length > 0 && (
          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={orgPhrases.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {visibleItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = !!selected.find((x) => x.id === row.id);
                  return (
                    <Row
                      key={index}
                      row={row}
                      isItemSelected={isItemSelected}
                      handleDelete={handleDelete}
                      handleClick={handleClick}
                      updatePhrase={updateOrganisationPhrase}
                      isFav={favPhrases.find((x) => x.phraseId === row.id)}
                      createFavPhrase={createOrganisationFavouritePhrase}
                      deleteFavPhrase={deleteFavouritePhrase}
                    />
                  );
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    sx={{ py: 3 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
        </TableContainer>
        )}
      </Card>
      </Container>
      {filteredUsers.length < 1 && NoContent('No Phrases Found')}
    </>
  );
};

export default observer(PhraseLibrary);

const Row = ({
  row,
  row: { id, name, text, category, scope },
  handleDelete,
  isItemSelected,
  handleClick,
  isFav,
  createFavPhrase,
  deleteFavPhrase
}) => {
  const handleUpdateFav = () => {
    isFav ? deleteFavPhrase(id) : createFavPhrase(id);
  };

  return (
    <TableRow
      hover
      key={id}
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, row)}
        />
      </TableCell>
      <TableCell align="left">
        {name}
      </TableCell>
      <TableCell align="left">{text}</TableCell>
      <TableCell align="left">
        {category === 'TITLE'
          ? 'Title'
          : category === 'BODYINTRO'
            ? 'Body - Intro'
            : category === 'BODYCLOSING'
              ? 'Body - Closing'
              : category === 'BODYCOMPLIANCE'
                ? 'Body - Compliance'
                : ''}
      </TableCell>
      <TableCell>
        <ToggleButton toggle={isFav} handleClick={handleUpdateFav} />
      </TableCell>
      <TableCell align="right">
        <PhraseMoreMenu onDelete={() => handleDelete(row)} row={row} type="org" />
      </TableCell>
    </TableRow>
  );
};

import { ReactComponent as TikTokLike } from '../../../assets/icons/TikTok/like.svg';
import { ReactComponent as TikTokComment } from '../../../assets/icons/TikTok/comment.svg';
import { ReactComponent as TikTokShare } from '../../../assets/icons/TikTok/share.svg';
import { ReactComponent as TikTokDisc } from '../../../assets/icons/TikTok/disc.svg';

function TikTokPreview ({
  plainText,
  files,
  previewData,
  user,
  renderDefaultPreview
}) {
  const videoFile = files && files.find(file => file.contentType.startsWith('video/'));

  if (!videoFile) {
    return renderDefaultPreview();
  }

  const handleClick = () => {
    if (previewData.socialChannelLink) {
      window.open(previewData.socialChannelLink, '_blank');
    }
  };

  return (
    <div className='bg-gray-light p-3 rounded-md'>
      {files && files.length > 0 && (
        <div className='flex items-end'>
          <div key={videoFile.fileKey} className='relative items-center'>
            <video className='rounded-md w-full h-[600px] object-cover'>
              <source src={videoFile.url} type={videoFile.contentType} />
              Your browser does not support the video tag.
            </video>
            <div id="custom-controls" className='absolute' style={{
              bottom: '2%',
              left: '2%',
              color: 'white'
            }}>
              <div className='flex items-center mt-3 mb-2 gap-1'>
                <p className='text-xs font-semibold cursor-pointer' onClick={handleClick}>{previewData?.displayName ?? user.nickname}</p>
              </div>
              {plainText && <p className='text-[10px] mt-2 break-words'>{plainText.length > 40 ? plainText.substring(0, 40) + '...more' : plainText}</p>}
            </div>
            <div className='absolute' style={{
              bottom: '1%',
              right: '2%'
            }}>
                <div className=''>
                  <div className='flex flex-col items-center mb-2'>
                    <img
                      src={previewData?.profilePicUrl ? previewData?.profilePicUrl : user.picture}
                      alt='User'
                      className='w-10 h-10 rounded-full'
                    />
                  </div>
                  <div className='flex flex-col items-center mb-2'>
                      <TikTokLike className='p-3 w-14 h-14'/>
                  </div>
                  <div className='flex flex-col items-center mb-2'>
                      <TikTokComment className='p-3 w-14 h-14'/>
                  </div>
                  <div className='flex flex-col items-center mb-2'>
                      <TikTokShare className='p-3 w-14 h-14'/>
                  </div>
                  <div className='flex flex-col items-center mb-2'>
                      <TikTokDisc className='p-3 w-14 h-14'/>
                  </div>
                </div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
}

export default TikTokPreview;

import { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';

const Details = ({
  handleClose,
  setStep,
  value1,
  setValue1,
  value2,
  setValue2,
  value3,
  setValue3,
  param,
  setParam,
  ruleType,
  setRuleType,
  detailsRuleType,
  doubleValue1,
  setDoubleValue1,
  doubleValue2,
  setDoubleValue2
}) => {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setSelected(
      detailsRuleType.find((x) => x.id === parseInt(param))
        ? detailsRuleType.find((x) => x.id === parseInt(param))
        : {}
    );
  }, [param]);

  return (
    <>
      <div className="h-[300px]">
        <div className="flex gap-3 my-5">
          <FormControl fullWidth>
            <label htmlFor="company">Rule Type</label>
            <select
              className="border-[1px] border-gray outline-none p-4 rounded"
              value={ruleType}
              onChange={(e) => {
                setRuleType(e.target.value);
                setParam('');
              }}
            >
              <option disabled value={''}>Select Rule type</option>
              <option value="attachments">Attachment</option>
              <option value="body">Body</option>
              <option value="tags">Tags</option>
              <option value="title">Title</option>

              {/* {rule_types.map((x, i) => (
                <MenuItem value={x.id}>{x.name}</MenuItem>
              ))} */}
            </select>
          </FormControl>
          <FormControl fullWidth>
            <label htmlFor="company">Rule</label>
            <select
              className="border-[1px] border-gray outline-none p-4 rounded"
              value={param}
              onChange={(e) => setParam(e.target.value)}
            >
              <option value="" disabled>Select Rule</option>
              {detailsRuleType
                .filter((x) => x.entityType.toLowerCase() === ruleType)
                .map((x, i) => (
                  <option key={i} value={x.id}>{x.description}</option>
                ))}
            </select>
          </FormControl>
        </div>

        <div>
          {selected && selected.parameterType !== 'BOOLEAN' && (
            <label>
              Rule Parameter ({selected.parameterType?.toLowerCase()})
            </label>
          )}

          <div className="flex gap-3">
            {selected.parameterType === 'INT' && (
              <input
                className="border-[1px] border-gray outline-none p-4 rounded w-full"
                placeholder="Enter Number"
                type="number"
                value={value1}
                onChange={(e) => setValue1(e.target.value)}
              />
            )}
            {selected.parameterType === 'BETWEEN' && (
              <>
                <input
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  placeholder="Maximum Number"
                  type="number"
                  value={value1}
                  onChange={(e) => setValue1(e.target.value)}
                />
                <input
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  placeholder="Minimum Number"
                  type="number"
                  value={value2}
                  onChange={(e) => setValue2(e.target.value)}
                />
              </>
            )}
            {selected.parameterType === 'BETWEEN_DOUBLE' && (
              <>
                <input
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  placeholder="Maximum Number"
                  type="number"
                  value={doubleValue1}
                  onChange={(e) => setDoubleValue1(e.target.value)}
                />
                <input
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  placeholder="Minimum Number"
                  type="number"
                  value={doubleValue2}
                  onChange={(e) => setDoubleValue2(e.target.value)}
                />
              </>
            )}

            {selected.parameterType === 'ATTACHMENTS' && (
              <>
                <select
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  variant="outlined"
                  // eslint-disable-next-line react/no-unknown-property
                  displayEmpty
                  // eslint-disable-next-line react/no-unknown-property
                  fullWidth
                >
                  <option value="gif">GIF</option>
                  <option value="image">Image</option>
                  <option value="pdf">PDF</option>
                  <option value="video">Video</option>
                </select>
                <input
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  placeholder="Maximum Number"
                />
                <input
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  placeholder="Minimum Number"
                />
              </>
            )}
            {selected.parameterType === 'STRING' && (
              <input
                className="border-[1px] border-gray outline-none p-4 rounded w-full"
                placeholder="Enter Keyword"
                value={value3}
                onChange={(e) => setValue3(e.target.value)}
              />
            )}
            {selected.parameterType === 'DROPDOWN' && (
              <>
                <select
                  className="border-[1px] border-gray outline-none p-4 rounded w-full"
                  variant="outlined"
                  onChange={(e) => setValue3(e.target.value)}
                  value={value3}
                >
                  <option disabled value={''}>Select attachment type</option>
                  <option value="image">Image</option>
                  <option value="gif">GIF</option>
                  <option value="pdf">PDF</option>
                  <option value="video">Video</option>
                </select>
              </>
            )}
          </div>
        </div>
      </div>
      <hr className="my-5" />
      <div className="flex items-center justify-between">
        <button onClick={() => setStep(2)} className="text-blue underline">
          Back
        </button>
        <div className="flex justify-end">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={() => setStep(4)}
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Details;

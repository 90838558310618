const productDetail = (annual, data) => {
  let result = {};
  if (data?.find((f) => f.interval === 'one_time')) {
    result = data?.find((f) => f.interval === 'one_time');
  } else if (!annual) {
    result = data?.find((f) => f.interval === 'month');
  } else {
    result = data?.find((f) => f.interval === 'year');
  }
  return {
    unit_amount_decimal: result?.unit_amount_decimal,
    currency: result?.currency,
    interval: result?.interval,
    interval_count: result?.interval_count
  };
};

export default productDetail;

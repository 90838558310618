import { useEffect, useState } from 'react';

import ToggleButton from '../../ToggleButton';

import settingsStore from '../../../store/settings/settings';
import { FormControl } from '@mui/material';
import { observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';

const AuthoringEditingForm = () => {
  const {
    state: { userSettings },
    createUserSettings,
    updateUserSettings
  } = settingsStore;

  const [distracted, setDistracted] = useState(false);
  const [contentValidationTimerFq, setContentValidationTimerFq] = useState('');
  const [autoTaggingFq, setAutoTaggingFq] = useState('');
  const [toggleSelectAllChannel, setToggleSelectAllChannel] = useState(false);

  const handleDistractionMode = async () => {
    const distractionSetting = userSettings.find(
      (x) => x.name === 'distraction-free-mode'
    );

    if (distractionSetting) {
      const res = await updateUserSettings({ ...distractionSetting, value: !distracted });
      handleResponse(res, 'update');
    } else {
      const res = await createUserSettings({
        name: 'distraction-free-mode',
        value: true,
        type: 'BOOLEAN'
      });
      handleResponse(res, 'create');
    }
  };

  const changeAutoTaggingFq = async (value) => {
    const setting = userSettings.find(
      (x) => x.name === 'auto-tagging-frequency'
    );

    if (setting) {
      const res = await updateUserSettings({ ...setting, value });
      handleResponse(res, 'update');
    } else {
      const res = await createUserSettings({
        name: 'auto-tagging-frequency',
        value,
        type: 'INT'
      });
      handleResponse(res, 'create');
    }
  };

  const changeContentValidationTimerFq = async (value) => {
    const setting = userSettings.find(
      (x) => x.name === 'content-validation-timer-frequency'
    );

    if (setting) {
      const res = await updateUserSettings({ ...setting, value });
      handleResponse(res, 'update');
    } else {
      const res = await createUserSettings({
        name: 'content-validation-timer-frequency',
        value,
        type: 'INT'
      });
      handleResponse(res, 'create');
    }
  };

  const handleResponse = (res, type) => {
    if (res === 1) {
      enqueueSnackbar(`User setting ${type}d successfully`, {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(`Failed to ${type} user setting`, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    const distractionSetting = userSettings.find(
      (x) => x.name === 'distraction-free-mode'
    );

    const contentValidationTimer = userSettings.find(
      (x) => x.name === 'content-validation-timer-frequency'
    );

    const autoTaggingFrequency = userSettings.find(
      (x) => x.name === 'auto-tagging-frequency'
    );

    const selectAllChannel = userSettings.find(
      (x) => x.name === 'select-all-channel'
    );

    setDistracted(
      distractionSetting
        ? distractionSetting.value === 'true'
        : false
    );

    setContentValidationTimerFq(
      contentValidationTimer
        ? contentValidationTimer.value
        : null
    );

    setAutoTaggingFq(
      autoTaggingFrequency
        ? autoTaggingFrequency.value
        : null
    );

    setToggleSelectAllChannel(
      selectAllChannel
        ? selectAllChannel.value === 'true'
        : false
    );
  }, [userSettings]);

  const handleToggleSelectAllChannel = async () => {
    const selectAllChannel = userSettings.find(
      (x) => x.name === 'select-all-channel'
    );

    if (selectAllChannel) {
      const res = await updateUserSettings({ ...selectAllChannel, value: !toggleSelectAllChannel });
      handleResponse(res, 'update');
    } else {
      const res = await createUserSettings({
        name: 'select-all-channel',
        value: true,
        type: 'BOOLEAN'
      });
      handleResponse(res, 'create');
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 gap-8">
        <div>
          <div className="flex w-full justify-between mt-5">
            <label>Distraction Free Mode</label>
            <ToggleButton
              toggle={distracted}
              handleClick={handleDistractionMode}
            />
          </div>
          <div className="flex w-full justify-between mt-5">
            <label>De-select all channels</label>
            <ToggleButton
              toggle={toggleSelectAllChannel}
              handleClick={handleToggleSelectAllChannel}
            />
          </div>
          <div className="flex justify-center">
            <FormControl className="mt-3" fullWidth>
              <label htmlFor="content-validation-timer-frequency">Content Validation Timer Frequency</label>
              <select
                className={'border-[1px] border-gray bg-white outline-none p-4 rounded'}
                value={contentValidationTimerFq}
                onChange={(e) => changeContentValidationTimerFq(e.target.value)}
              >
                <option value="" selected disabled>
                  Select
                </option>
                <option value="1">1 Min</option>
                <option value="2">2 Min</option>
                <option value="5">5 Min</option>
                <option value="10">10 Min</option>
                <option value="-1">On Save</option>
              </select>
            </FormControl>
          </div>
          <div className="flex justify-center">
            <FormControl className="mt-3" fullWidth>
              <label htmlFor="auto-tagging-frequency">Auto Tagging Frequency</label>
              <select
                className={'border-[1px] border-gray bg-white outline-none p-4 rounded'}
                value={autoTaggingFq}
                onChange={(e) => changeAutoTaggingFq(e.target.value)}
              >
                <option value="" selected disabled>
                  Select
                </option>

                <option value="0">Continuous</option>
                <option value="1">1 Min</option>
                <option value="2">2 Min</option>
                <option value="5">5 Min</option>
                <option value="10">10 Min</option>
                <option value="-1">Off</option>
              </select>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AuthoringEditingForm);

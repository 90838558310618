import {
  Breadcrumbs,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { RxArrowLeft } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import channelGroupAPI from '../../../store/channel/channelGroup';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import companyStore from '../../../store/organisation/company';
import ChannelInstanceMultiSelect from '../../../components/Channel/ChannelInstanceMultiSelect';
import { useSnackbar } from 'notistack';

const AddOrEditChannelGroup = () => {
  const { channelGroupId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { fetchUserMetaCompanies } = companyStore;

  const defaultValues = {
    groupName: '',
    companyId: '',
    channelInstances: []
  };

  const loadExistingChannelGroup = async () => {
    const response = await channelGroupAPI.fetchChannelGroupByIdsAndCompanyId(
      channelGroupId.toString(), null
    );
    setValues(response[0]);
    setExistingChannelGroup(response[0]);
    setSelectedChannelInstances(response[0].channelInstances);
  };

  const [values, setValues] = useState(defaultValues);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch
  } = useForm({ defaultValues, values, mode: 'onBlur' });
  const [existingChannelGroup, setExistingChannelGroup] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedChannelInstances, setSelectedChannelInstances] = useState([]);
  const companyIdWatch = watch('companyId');

  useEffect(() => {
    if (channelGroupId) {
      loadExistingChannelGroup();
    }
  }, [channelGroupId]);

  useEffect(async () => {
    const response = await fetchUserMetaCompanies();
    setCompanies(response);
    if (!channelGroupId) {
      setValue('companyId', response[0].id);
    }
  }, []);

  const updateChannelInstances = (value) => {
    setValue('channelInstances', value);
  };

  const formOnSubmit = (values) => {
    if (existingChannelGroup) {
      updateChannelGroup(values);
    } else {
      createChannelGroup(values);
    }
  };

  const updateChannelGroup = (values) => {
    channelGroupAPI.updateChannelGroup(values).then((res) => {
      if (res) {
        setValues(res);
        enqueueSnackbar('Channel group updated successfully', {
          variant: 'success'
        });
        return;
      }
      enqueueSnackbar('Failed to update a channel group', {
        variant: 'error'
      });
    });
  };

  const createChannelGroup = (values) => {
    channelGroupAPI.createChannelGroup(values).then((res) => {
      if (res) {
        enqueueSnackbar('Channel group created successfully', {
          variant: 'success'
        });
        navigate('/channels/manage-group/' + res.id);
      } else {
        enqueueSnackbar('Failed to create channel group', {
          variant: 'error'
        });
      }
    });
  };

  return (
    <Container className="bg-slate-100">
      <form onSubmit={handleSubmit(formOnSubmit)}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <button
            className="text-xl"
            onClick={() => navigate('/channels/manage-group')}
          >
            <RxArrowLeft />
          </button>
          <Breadcrumbs>
            <Link
              component="button"
              underline="hover"
              color="inherit"
              onClick={() => navigate('/channels/manage-group')}
            >
              Manage Channel
            </Link>
            <Typography color="primary">
              {existingChannelGroup
                ? 'Update Channel Group'
                : 'Create Channel Group'}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Card className="w-100 my-5">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <InputLabel className="font-black">
                  ChannelGroupName*
                </InputLabel>
                <FormHelperText className="mb-2">
                  This name would be the reference for this channel throughout
                  the Marvin application
                </FormHelperText>
                <Controller
                  control={control}
                  name="groupName"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-full"
                      placeholder="Enter your channel group name"
                      error={!!errors.groupName}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="groupName"
                  render={() => (
                    <Typography className="text-red">Required</Typography>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className="font-black">Select Company</InputLabel>
                <FormHelperText className="mb-2">
                  This name would be the reference for this channel throughout
                  the Marvin application
                </FormHelperText>
                <Controller
                  control={control}
                  name="companyId"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      className="w-full"
                      error={!!errors.companyId}
                      renderValue={(value) => {
                        if (value) {
                          return (<span className='capitalize'>{companies.find((c) => c.id === value)?.name}</span>);
                        } else {
                          return (
                            <span style={{ color: '#ada6a6' }}>
                              Choose the company to link this Channel Group
                            </span>
                          );
                        }
                      }}
                    >
                      {companies.length > 0 &&
                        companies.map((company, index) => (
                          <MenuItem value={company.id} key={index} className='capitalize'>
                            {company.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="companyId"
                  render={() => (
                    <Typography className="text-red">Required</Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ChannelInstanceMultiSelect
                  updateOnChange={(newValue) =>
                    updateChannelInstances(newValue)
                  }
                  channelInstances={selectedChannelInstances}
                  companyId={companyIdWatch}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'flex-end'}
          className="align-self-end"
        >
          <button
            variant="contained"
            className="p-2 bg-white border-blue border-2 text-blue rounded-md w-40"
            onClick={() => navigate('/channels/manage-group')}
          >
            Cancel
          </button>
          <button
            type="submit"
            variant="contained"
            className="p-2 text-white bg-blue rounded-md w-40"
          >
            {existingChannelGroup ? 'Update' : 'Create'}
          </button>
        </Stack>
      </form>
    </Container>
  );
};

export default AddOrEditChannelGroup;

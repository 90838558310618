import { icons } from '../../../constants/channels';
import { useEffect, useState } from 'react';
import {
  Tooltip
} from '@mui/material';

import {
  BsFileEarmarkRichtext,
  BsBook
} from 'react-icons/bs';

const PublicationStatus = ({ item }) => {
  const [previewIcon, setPreviewIcon] = useState(null);
  const [previewColor, setPreviewColor] = useState(null);

  useEffect(() => {
    if (item.entityType === 'CHANNEL') {
      setPreviewIcon(icons.find((c) => c.name === item.channelTypeName)?.treeIcon);
      setPreviewColor(icons.find((c) => c.name === item.channelTypeName)?.color);
    } else if (item.entityType === 'ROOT') {
      setPreviewIcon(<BsBook color="#FFFFFF" size="32"/>);
      setPreviewColor('#28282B');
    } else {
      setPreviewIcon(<BsFileEarmarkRichtext color="#FFFFFF" size="32"/>);
      setPreviewColor('#28282B');
    }
  }, [item]);

  return (
    <>
        <Tooltip title={item.error ? item.error : ''} >
            <p
            style={{
              background: previewColor
            }}
            className={'inline-flex items-center justify-center w-14 h-14 mr-2 rounded-full'}>
                {previewIcon}
            </p>
        </Tooltip>
    </>
  );
};

export default PublicationStatus;

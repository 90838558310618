import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';

import loadingStore from '../loading';

const ENDPOINT = '/groups/folders';

class FolderStore {
  state = {
    folders: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getFolders: action,
      getFolder: action,
      addFolder: action,
      updateFolder: action,
      deleteFolder: action
    });
  }

  getFolders = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.folders = response.data;
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getFolder = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addFolder = async (data) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, data);
      this.state.folders = [...this.state.folders, response.data];
      return 1;
    } catch (error) {
      console.error(error);

      if (error?.response?.data?.includes('already added')) {
        return 2;
      }
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateFolder = async (data) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.put(
          `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}`,
          {
            folder: data
          }
      );
      const index = this.state.folders.findIndex((c) => c.id === data.id);
      this.state.folders[index] = response.data;
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteFolder = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.folders = this.state.folders.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getMemberships = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addMembership = async (data, locId) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${locId}/member`,
        data
      );
      // this.state.memberships = [...this.state.memberships, response];
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response ? error.response.code : 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateMembership = async (data) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}/member`,
        { userId: data.user_id, readOnly: data.readOnly }
      );

      // this.state.memberships = this.state.memberships.map((x) =>
      //   x.id === data.id ? data : x
      // );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteMembership = async (id, data) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        { data }
      );

      this.state.memberships = this.state.memberships.filter(
        (x) => x.id !== id
      );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const folderStore = new FolderStore();
export default folderStore;

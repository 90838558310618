import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';

const SearchableSelect = ({ heading, data, value, onSelect, disabled }) => {
  const [searchText, setSearchText] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  // const useStyles = makeStyles((theme) => ({
  //   formControl: {
  //     margin: theme.spacing(0),
  //     minWidth: 0,
  //   },
  //   selectEmpty: {
  //     marginTop: theme.spacing(0),
  //   },
  //   menuPaper: {
  //     maxHeight: 0,
  //     minHeight: 0,
  //   },
  // }));

  // const classes = useStyles();

  return (
    <FormControl className={' control mt-3'} fullWidth>
      <label htmlFor="company">{heading}</label>
      <div className="relative">
        <Select
          fullWidth
          variant="outlined"
          displayEmpty
          className="px-4 py-[10px] rounded"
          value={value.name ? value.name : ''}
          onClick={() => !disabled && setShowDropdown(!showDropdown)}
          open={false}
        >
          <MenuItem value={value.name ? value.name : ''} selected disabled>
            {value.name ? value.name : ''}
          </MenuItem>
        </Select>

        {showDropdown && (
          <div className="bg-white drop-shadow-lg absolute z-20 p-3 w-full">
            <div className="relative">
              {/* <AiOutlineSearch className="absolute text-lg mx-3 my-5 text-[#ccc]" /> */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="max-h-[300px] auto">
              {data
                .filter((x) => x.name.includes(searchText))
                .map((x, i) => (
                  <button
                    className="text-lg py-3 w-full hover:bg-[#eee]"
                    key={i}
                    onClick={() => {
                      onSelect(x);
                      setShowDropdown(false);
                    }}
                  >
                    {x.name}
                  </button>
                ))}
            </div>
          </div>
        )}
      </div>
    </FormControl>
  );
};

export default SearchableSelect;

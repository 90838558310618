import { useState, useEffect } from 'react';

const addLeadingZero = (value) => {
  return value < 10 ? ('0' + value).slice(-2) : value;
};

const ValidationTimer = ({
  delayResend = '180',
  pingValidation,
  setPingValidation,
  fireValidationChannels
}) => {
  const [delay, setDelay] = useState(+delayResend);

  const minutes = Math.floor(delay / 60);
  const minuteStr = addLeadingZero(minutes);

  const seconds = Math.floor(delay % 60);
  const secondStr = addLeadingZero(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      setDelay(delayResend);
      fireValidationChannels();
      setPingValidation(true);
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  useEffect(() => {
    if (pingValidation) {
      setDelay(delayResend);
    }
  }, [pingValidation]);

  useEffect(() => {
    setDelay(delayResend);
  }, [delayResend]);

  return (
    <>
      <span className="bg-[#e9f4f7] hover:bg-[#e9f4f7] drop-shadow text-blue rounded w-full p-3 flex items-center justify-between z-10">
        {minuteStr} : {secondStr}
      </span>
    </>
  );
};

export default ValidationTimer;

import {
  Avatar,
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import useSettings from '../../hooks/useSettings';

import ListHead from '../../components/Global/ListHead';
import UserMoreMenu from '../../components/Security/userList/UserMoreMenu';

import { RxCross1 } from 'react-icons/rx';

import companyStore from '../../store/organisation/company';
import groupStore from '../../store/security/group';
import userStore from '../../store/security/user';

import { observer } from 'mobx-react';
import moment from 'moment';

import { useSnackbar } from 'notistack';
import { BulkDeleteButton } from '../../components/DeleteButton';
import GroupUsersList from './GroupUsersList';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.givenName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.familyName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.emailAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const GroupUserList = ({ group, close }) => {
  const {
    state: { users },
    getUsers
  } = userStore;

  const { getCompany } = companyStore;

  const {
    state: { members },
    getGroupMembers,
    addGroupMembers,
    deleteGroupMembers
  } = groupStore;

  const { enqueueSnackbar } = useSnackbar();

  const [addMembers, setAddMembers] = useState(false);

  const { themeStretch } = useSettings();
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const filteredUsers = applySortFilter(
    members,
    getComparator(order, orderBy),
    filterName
  );

  const rowsPerPage = 5;
  const itemsPerPage = 10;
  const page = 0;

  const TABLE_HEAD = [
    { id: 'givenName', label: 'User', isSorted: orderBy === 'givenName' },
    {
      id: 'company',
      label: 'Primary Company'
    },
    {
      id: 'emailAddress',
      label: 'Email',
      isSorted: orderBy === 'emailAddress'
    },
    {
      id: 'isVerified',
      label: 'License Type'
    },
    { id: 'statusFull', label: 'Status' },
    { id: 'lastlogin', label: 'Last Login' },
    { id: 'moreMenu' }
  ];

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getUsers().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch users', { variant: 'error' })
    );
  }, []);

  useEffect(() => {
    getGroupMembers(group.id);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = members.map((user) => user.user_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleDelete = () => {
    const data = { users: selected.map((x) => (x = { user_id: x })) };
    deleteGroupMembers(data, group.id).then((res) =>
      res === 1
        ? enqueueSnackbar('Members removed successfully', {
          variant: 'success'
        })
        : enqueueSnackbar('Failed to remove members', { variant: 'error' })
    );
    setSelected([]);
  };

  const handleDeleteUser = (id) => {
    deleteGroupMembers({
      users: [{
        user_id: id
      }]
    }, group.id).then((res) =>
      res === 1
        ? enqueueSnackbar('Members removed successfully', {
          variant: 'success'
        })
        : enqueueSnackbar('Failed to remove members', { variant: 'error' })
    );
  };

  const handleAddMembers = (ids) => {
    const data = { users: ids.map((x) => (x = { user_id: x })) };
    addGroupMembers(data, group.id);
    setAddMembers(false);
  };

  const isUserNotFound = filteredUsers.length === 0;

  const modalStyle = {
    position: 'absolute',
    margin: '10px',
    height: '95vh !important',
    border: '5px',
    overflow: 'auto',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    zIndex: '1111'
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-[#000]/20 flex items-center"
      style={{
        ...modalStyle,
        backgroundColor: 'white',
        width: '800px',
        flexDirection: 'column'
      }}
    >
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div className="bg-blue w-full p-3 flex justify-between">
          <p className="text-lg text-white">Group Membership - {group.name}</p>
          <button onClick={close} className="text-white text-xl mr-2">
            <RxCross1 />
          </button>
        </div>

        <Card style={{ boxShadow: 'none' }}>
          <div className="p-3 bg-[#eee] m-6 rounded flex justify-between">
            <div>
              <p>Group Name</p>
              <p className="mt-5">{group.name}</p>
            </div>
            <div>
              <p>Owner</p>
              <div className="flex items-center gap-2 mt-2">
                <p>{group.owner_name}</p>
              </div>
            </div>
            <div>
              <p>Last Modified</p>
              <p className="mt-5">{group.last_modified_date}</p>
            </div>
            <div>
              <p>Members</p>
              <p className="mt-5">{visibleItems.length}</p>
            </div>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', paddingRight: 25 }}
          >
            <div className="flex-1">
              <div style={{ width: '40%' }}>
                {/* <ListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                /> */}
              </div>
            </div>

            <BulkDeleteButton
              selected={selected}
              setSelected={setSelected}
              onDelete={handleDelete}
              heading={['Membership', 'Memberships']}
            />

            {/* <button className="border-[1px] border-blue text-blue ml-2 mt-1 rounded px-4 py-2">
              Export CSV
            </button> */}
            <button
              onClick={() => setAddMembers(true)}
              className="border-[1px] border-blue bg-blue hover:bg-blue text-white ml-2 mt-1 mb-2 rounded px-4 py-2"
            >
              Add Member
            </button>
          </div>
          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={members.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {visibleItems
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    const isItemSelected = selected.indexOf(row.user_id) !== -1;
                    const user = users.find((u) => u.id === row.user_id);
                    if (user) {
                      return (
                        <Row
                          row={user}
                          key={key}
                          handleClick={handleClick}
                          handleDeleteUser={handleDeleteUser}
                          isItemSelected={isItemSelected}
                          getCompany={getCompany}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{ py: 3 }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <Stack spacing={2} className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
              />
            </Stack>
          </TableContainer>
        </Card>
      </Container>

      {addMembers && (
        <GroupUsersList
          onSubmit={handleAddMembers}
          close={() => setAddMembers(false)}
        />
      )}
    </div>
  );
};

export default observer(GroupUserList);

const Row = ({
  row,
  row: {
    emailAddress,
    givenName,
    familyName,
    lastLogin,
    picture,
    companyId
  },
  handleClick,
  handleDeleteUser,
  isItemSelected,
  getCompany
}) => {
  const [company, setCompany] = useState({});

  const fetchCompany = async () => {
    const res = await getCompany(companyId);
    setCompany(res);
  };

  useEffect(() => {
    if (companyId) {
      fetchCompany();
    }
  }, [companyId]);

  return (
    <Fragment>
      <TableRow
        hover
        key={row.id}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row.id)}
          />
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt="avatar" src={picture} />
            <Typography variant="subtitle2" noWrap>
              {givenName} {familyName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {company.name ? company.name : '-'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{emailAddress}</TableCell>
        <TableCell align="left">-</TableCell>
        <TableCell align="left">-</TableCell>
        <TableCell align="left">
          {moment(lastLogin).format('MMMM Do YYYY, h:mm:ss a')}
        </TableCell>

        <TableCell align="right">
          <UserMoreMenu ignoreEdit={true} onDelete={() => handleDeleteUser(row.id)} row={row} />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const getThemeStyleByEnv = (colorFor) => {
  const enviromemnt = process.env.REACT_APP_ENVIRONMENT;
  if (colorFor === 'navbar') {
    if (enviromemnt === 'development') {
      return {
        backgroundImage: 'linear-gradient(to right, #D39D38,#D39D38,#D39D38,#D39D38)' // Adjust colors as needed
      };
    } else if (enviromemnt === 'staging') {
      return {
        backgroundImage: 'linear-gradient(to right, #46b48d,#46b48d,#46b48d,#46b48d)' // Adjust colors as needed
      };
    } else {
      return {
        backgroundImage: 'linear-gradient(to right, #37a4c2,#37a4c2,#008CB0,#00607B)' // Adjust colors as needed
      };
    }
  } else if (colorFor === 'left-sidebar') {
    if (enviromemnt === 'development') {
      return {
        backgroundImage: 'linear-gradient(to top, #D39D38, #D39D38, #D39D38)' // Adjust colors as needed
      };
    } else if (enviromemnt === 'staging') {
      return {
        backgroundImage: 'linear-gradient(to top, #46b48d, #46b48d, #46b48d)' // Adjust colors as needed
      };
    } else {
      return {
        backgroundImage: 'linear-gradient(to top, #00607B, #008CB0,#37a4c2)' // Adjust colors as needed
      };
    }
  }
};

export default getThemeStyleByEnv;

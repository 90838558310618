const ToggleButton = ({
  handleClick,
  toggle,
  disabled
}) => {
  return (
    <button
      disabled={disabled ?? false}
      onClick={handleClick}
      className={`${
        toggle
          ? 'bg-[#1db13f] hover:bg-[#1db13f]'
          : 'bg-[#ccc] hover:bg-[#ccc]'
      } transition-all duration-150 ease-out hover:ease-in h-6 w-10 rounded-full p-1 flex ${
        !toggle ? 'justify-start' : 'justify-end'
      }`}
    >
      <div className="h-4 w-4 bg-white rounded-full" />
    </button>
  );
};

export default ToggleButton;

import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert
} from '@mui/material';
import channelsStore from '../../store/channel/channel';

const privacyLevelLabels = {
  FOLLOWER_OF_CREATOR: 'Followers of Creator',
  MUTUAL_FOLLOW_FRIENDS: 'Mutual Follow Friends',
  SELF_ONLY: 'Only Me',
  PUBLIC_TO_EVERYONE: 'Public'
};

const TiktokCreatorInfo = ({
  selectedChannels,
  companyChannelList,
  creatorInfoParams,
  setCreatorInfoParams
}) => {
  const [tiktokChannels, setTiktokChannels] = useState([]);
  const [loadFirstTime, setLoadFirstTime] = useState(false);
  const { getTiktokCreatorInfo } = channelsStore;
  useEffect(() => {
    // setCreatorInfoParams([]);
    const findedChannles = companyChannelList.filter((item) => selectedChannels.includes(item.id) && item.channelType.channelName === 'Tik Tok');
    findedChannles.map(async (item) => {
      const response = await getTiktokCreatorInfo(item.id);
      // const response = {
      //   data: {
      //     creator_username: 'anubhavsingh2412',
      //     creator_nickname: 'anubhavsingh2412',
      //     creator_avatar_url: 'https://p16-sign-sg.tiktokcdn.com/tos-alisg-avt-0068/e57b6596d44b65b6f9c7e972058326e1~c5_168x168.webp?lk3s=a5d48078&nonce=27342&refresh_token=ba999bda2e5af0816a9fccf01e5b4b75&x-expires=1718967600&x-signature=qfkl1sOmFDhKt3xSZZPbzn%2BCm1A%3D&shp=a5d48078&shcp=bbadf38d',
      //     comment_disabled: false,
      //     duet_disabled: true,
      //     stitch_disabled: true,
      //     privacy_level_options: [
      //       'FOLLOWER_OF_CREATOR',
      //       'MUTUAL_FOLLOW_FRIENDS',
      //       'SELF_ONLY'
      //     ]
      //   }
      // };
      if (response && response.data) {
        setTiktokChannels((prevChannels) => {
          const exists = prevChannels.some(channel => channel.channelId === item.id);
          if (exists) {
            return prevChannels;
          } else {
            return [
              ...prevChannels,
              {
                channelId: item.id,
                ...response.data
              }
            ];
          }
        });

        setCreatorInfoParams((prevState) => {
          if (!prevState) {
            return [
              {
                channelId: item.id,
                privacyStatus: '',
                allowComment: false,
                allowDuet: false,
                allowStitch: false,
                discloseContent: false,
                isYourBrand: false,
                isBrandedContent: false
              }
            ];
          }

          const exists = prevState.some(info => info.channelId === item.id);

          if (exists) {
            return prevState.map(info =>
              info
            );
          }

          return [
            ...prevState,
            {
              channelId: item.id,
              privacyStatus: '',
              allowComment: false,
              allowDuet: false,
              allowStitch: false,
              discloseContent: false,
              isYourBrand: false,
              isBrandedContent: false
            }
          ];
        });
      }
      return true;
    });
    // setTiktokChannels(findedChannles);
  }, [selectedChannels, loadFirstTime, companyChannelList]);

  useEffect(() => {
    setLoadFirstTime(true);
  }, []);
  return (
        <>
          {tiktokChannels.map((item, index) => {
            return selectedChannels.includes(item.channelId) && (
              <Card key={index} variant="outlined" className='mb-2'>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe" alt={item.creator_username} src={item.creator_avatar_url} />
                  }
                  title={item.creator_username}
                  subheader={'Upload to tiktok'}
                />
                <CardContent>
                    <TiktokCreatorForm
                      item={item}
                      creatorInfoParams={creatorInfoParams}
                      setCreatorInfoParams={setCreatorInfoParams}
                    />
                </CardContent>
              </Card>
            );
          })}
        </>
  );
};

const TiktokCreatorForm = ({
  item,
  creatorInfoParams,
  setCreatorInfoParams
}) => {
  const selectedParams = creatorInfoParams.find((row) => row.channelId === item.channelId);
  const [privacy, setPrivacy] = useState(selectedParams?.privacyStatus);
  const [allowComment, setAllowComment] = useState(!!selectedParams?.allowComment);
  const [allowDuet, setAllowDuet] = useState(!!selectedParams?.allowDuet);
  const [allowStitch, setAllowStitch] = useState(!!selectedParams?.allowStitch);
  const [discloseContent, setDiscloseContent] = useState(!!selectedParams?.discloseContent);
  const [promoteYourBrand, setPromoteYourBrand] = useState(!!selectedParams?.isYourBrand);
  const [promoteBrandContent, setPromoteBrandContent] = useState(!!selectedParams?.isBrandedContent);

  const handleChange = (e) => {
    setPrivacy(e.target.value);
    if (e.target.value === 'SELF_ONLY') {
      setPromoteBrandContent(false);
    }
  };

  const handleDiscloseContent = (event) => {
    setDiscloseContent(event.target.checked);
    if (!event.target.checked) {
      setPromoteBrandContent(false);
      setPromoteYourBrand(false);
    }
  };

  useEffect(() => {
    setCreatorInfoParams((prevState) => {
      if (!prevState) {
        return [];
      }
      return prevState.map(info =>
        info.channelId === item.channelId
          ? {
              ...info,
              privacyStatus: privacy,
              allowComment,
              allowDuet,
              allowStitch,
              discloseContent,
              isYourBrand: promoteYourBrand,
              isBrandedContent: promoteBrandContent
            }
          : info
      );
    });
  }, [
    privacy,
    allowComment,
    allowDuet,
    allowStitch,
    discloseContent,
    promoteYourBrand,
    promoteBrandContent
  ]);

  return (
      <>
        <Grid item xs={12}>
          <InputLabel className="font-black mb-2">
            Who can view this video ?
          </InputLabel>
          <FormControl className='w-96'>
            <Select
              value={privacy}
              onChange={handleChange}
            >
              {item.privacy_level_options.map((option, index) => {
                return (<MenuItem key={index} value={option}>{privacyLevelLabels[option]}</MenuItem>);
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid className='mt-2' item xs={12}>
          <InputLabel className="font-black mb-2">
            Allow users to
          </InputLabel>
          <FormControlLabel
            control={
              <Checkbox disabled={item.comment_disabled} checked={allowComment} onChange={(e) => setAllowComment(e.target.checked)} name="allow_comment" />
            }
            label="Comment"
          />
          <FormControlLabel
            control={
              <Checkbox disabled={item.duet_disabled} checked={allowDuet} onChange={(e) => setAllowDuet(e.target.checked)} name="allow_duet" />
            }
            label="Duet"
          />
          <FormControlLabel
            control={
              <Checkbox disabled={item.stitch_disabled} checked={allowStitch} onChange={(e) => setAllowStitch(e.target.checked)} name="allow_stitch" />
            }
            label="Stitch"
          />
        </Grid>
        <Grid className='mt-2' item xs={12}>
          <div className='flex inline-flex gap-2 items-center'>
              <InputLabel className="font-black">
                Disclose video content
              </InputLabel>
              <Switch checked={discloseContent} onChange={handleDiscloseContent} />
          </div>
        </Grid>
        {discloseContent && (
          <Grid className='mt-2' item xs={12}>
            { promoteYourBrand && !promoteBrandContent && (
              <Alert className='mb-2' severity="info">Your photo/video will be labeled as &quot;Promotional content&quot;</Alert>
            )}
            { promoteBrandContent && (
              <Alert className='mb-2' severity="info">Your photo/video will be labeled as &quot;Paid partnership&quot;</Alert>
            )}
            <p className='mb-1'>Turn on to disclose that this video promotes good or services in exchange for something of values. Your video could promote your self, a third parties or both.</p>
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
                <ListItem sx={{ paddingLeft: 0 }}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      checked={promoteYourBrand}
                      onChange={(e) => setPromoteYourBrand(e.target.checked)}
                    />
                  }
                >
                  <ListItemText
                      primary="Your Brand?"
                      secondary={
                        <>
                          {'You are promoting yourself or your own business. This content will be classified as Brand Organic.'}
                        </>
                      }
                    />
                </ListItem>
                <Divider variant="inset" sx={{ margin: 0 }} component="li" />
                <ListItem sx={{ paddingLeft: 0 }}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      checked={promoteBrandContent}
                      onChange={(e) => setPromoteBrandContent(e.target.checked)}
                      disabled={privacy === 'SELF_ONLY'}
                    />
                  }
                >
                  <ListItemText
                      primary="Branded Content?"
                      secondary={
                        <>
                          {'You are promoting another brand or a third party. This content will be classified as Branded Content.'}
                          {privacy === 'SELF_ONLY' && (<p className="text-red">Visibility for branded content can&lsquo;t be private.</p>)}
                        </>
                      }
                    />
                </ListItem>
            </List>
            { promoteYourBrand && !promoteBrandContent && (
              <p>By posting, you agree to TikTok&lsquo;s <a className='text-red' target='__blank' href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a>.</p>
            )}
            { promoteBrandContent && (
              <p>By posting, you agree to TikTok&lsquo;s <a className='text-red' target='__blank' href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a className='text-red' target='__blank' href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a>.</p>
            )}
          </Grid>
        )}

      </>
  );
};

export default TiktokCreatorInfo;

import { Modal } from '@mui/material';
import AddTagType from '../../Modal/Tag/AddTagType';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};

export default function TagCreate ({
  open,
  handleClose,
  isEdit,
  row,
  refresh
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          ...modalStyle,
          flexDirection: 'column'
        }}
      >
        <AddTagType
          isEdit={isEdit}
          row={row}
          handleClose={handleClose}
          refresh={refresh}
        />
      </div>
    </Modal>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/ContentManagement/VersionComparison/Navbar';
import ChannelsDiff from '../../components/ContentManagement/VersionComparison/ChannelsDiff';
import TagsDiff from '../../components/ContentManagement/VersionComparison/TagsDiff';
import TextDiff from '../../components/ContentManagement/VersionComparison/TextDiff';
import channelsStore from '../../store/channel/channel';
import contentStore from '../../store/content/content';

const VersionComparison = () => {
  const { restorePreviousVersion } = contentStore;

  const { versionId1, versionId2 } = useParams();
  const { getContentById, getContentHistoryById } = contentStore;
  const navigate = useNavigate();

  const [content1, setContent1] = useState({});
  const [content2, setContent2] = useState({});

  const { fetchAllChannelTypes } = channelsStore;

  useEffect(() => {
    fetchAllChannelTypes();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getContentById(versionId1).then((res) => {
      setContent1(res.data);
    });
    getContentHistoryById(versionId2).then((res) => {
      setContent2(res.data);
    });
  }, []);

  function extractPlainTextFromJSON (data) {
    const parsedData = JSON.parse(data);
    let plainText = '';

    for (const item of parsedData.ops) {
      if (item.insert) {
        plainText += item.insert;
      }
    }

    plainText = plainText.replace(/\n/g, '').trim();

    return plainText;
  }

  const handleRestorePreviousVersion = async () => {
    restorePreviousVersion(versionId1, versionId2).then((res) => {
      navigate(`/content/${versionId1}`);
    });
  };

  return (
    <div className="bg-[#f1f4f7] h-screen">
      <Navbar goBack={goBack} />
      <div className="p-10 h-full w-full">
        <div className="bg-white rounded">
          <div className="grid grid-cols-7">
            <div className="border-r-[1px] border-r-[#eee]"></div>
            <div className="col-span-3 p-3 flex">
              <div className="border-2 border-blue rounded text-blue font-bold px-4 py-2">
                Previous Version
              </div>
            </div>
            <div className="col-span-3 p-3 flex">
              <div className="border-2 border-blue rounded text-blue font-bold px-4 py-2">
                Current Version
              </div>
            </div>
          </div>

          <div className="grid grid-cols-7">
            <div className="border-r-[1px] border-r-[#eee] p-3">
              <p className="bg-light-blue text-blue font-bold rounded py-2 px-4">
                Text
              </p>
            </div>

            <TextDiff
              originalText={content2.title ? content2.title : ''}
              modifiedText={content1.title ? content1.title : ''}
            />
          </div>

          <div className="grid grid-cols-7 border-b-[1px] border-b-[#eee]">
            <div className="border-r-[1px] border-r-[#eee]"></div>
            <TextDiff
              originalText={
                content2.body ? extractPlainTextFromJSON(content2.body) : ''
              }
              modifiedText={
                content1.body ? extractPlainTextFromJSON(content1.body) : ''
              }
            />
          </div>

          <div className="grid grid-cols-7">
            <div className="border-r-[1px] border-r-[#eee] border-b-[1px] border-b-[#eee] p-3">
              <p className="bg-light-blue text-blue font-bold rounded py-2 px-4">
                Channels
              </p>
            </div>
            <ChannelsDiff
              originalChannels={content2.channelId ? content2.channelId : []}
              modifiedChannels={content1.channelId ? content1.channelId : []}
            />
          </div>

          <div className="grid grid-cols-7">
            <div className="border-r-[1px] border-r-[#eee] border-b-[1px] border-b-[#eee] p-3">
              <p className="bg-light-blue text-blue font-bold rounded py-2 px-4">
                Tags
              </p>
            </div>
            <TagsDiff
              originalTags={content2.usersTags ? content2.usersTags.map(tag => tag.name) : []}
              modifiedTags={content1.usersTags ? content1.usersTags.map(tag => tag.name) : []}
            />
          </div>

          {content1.editable &&
            (<div className="grid grid-cols-7">
            <div className="col-span-7 p-5 flex justify-end">
              <button
                onClick={handleRestorePreviousVersion}
                className="px-4 py-2 border-[1px] border-blue text-blue rounded"
              >
                Restore Previous Version
              </button>
            </div>
          </div>)
          }
        </div>
      </div>
    </div>
  );
};

export default VersionComparison;

import { ReactComponent as DRAFT } from '../assets/icons/statuses/DRAFT.svg';
import { ReactComponent as FAILED } from '../assets/icons/statuses/FAILED.svg';
import { ReactComponent as READY_TO_PUBLISH } from '../assets/icons/statuses/READY_TO_PUBLISH.svg';
import { ReactComponent as PARTIALLY_PUBLISHED } from '../assets/icons/statuses/PARTIALLY_PUBLISHED.svg';
import { ReactComponent as PUBLISHED } from '../assets/icons/statuses/PUBLISHED.svg';
import { ReactComponent as PUBLISH_IN_PROGRESS } from '../assets/icons/statuses/PUBLISH_IN_PROGRESS.svg';
import { ReactComponent as AUTHORISATION_PENDING } from '../assets/icons/statuses/AUTHORISATION_PENDING.svg';
import { ReactComponent as AUTHORISATION_PASSED } from '../assets/icons/statuses/AUTHORISATION_PASSED.svg';
import { ReactComponent as AUTHORISATION_REJECTED } from '../assets/icons/statuses/AUTHORISATION_REJECTED.svg';
import { ReactComponent as RECURRING } from '../assets/icons/statuses/RECURRING.svg';
import { ReactComponent as SCHEDULED } from '../assets/icons/statuses/SCHEDULED.svg';
import { ReactComponent as CANCELLED } from '../assets/icons/statuses/CANCELLED.svg';

export const publicationStatuses = {
  DRAFT: {
    name: 'Draft',
    icon: <DRAFT />,
    bgColor: 'bg-[#404040]',
    topBg: 'bg-[#404040]'
  },
  FAILED: {
    name: 'Failed',
    icon: <FAILED />,
    bgColor: 'bg-[#F34922]',
    topBg: 'bg-[#F34922]'
  },
  'READY TO PUBLISH': {
    name: 'Ready To Publish',
    icon: <READY_TO_PUBLISH />,
    bgColor: 'bg-[#2193B0]',
    topBg: 'bg-[#2193B0]'
  },
  'PARTIALLY PUBLISH': {
    name: 'Partially Publish',
    icon: <PARTIALLY_PUBLISHED />,
    bgColor: 'bg-[#065F46]',
    topBg: 'bg-[#065F46]'
  },
  PUBLISHED: {
    name: 'Published',
    icon: <PUBLISHED />,
    bgColor: 'bg-[#1DB13F]',
    topBg: 'bg-[#1DB13F]'
  },
  'PUBLISH IN PROGRESS': {
    name: 'Publish In Progress',
    icon: <PUBLISH_IN_PROGRESS className="animate-spin" />,
    bgColor: 'bg-[#384EC2]',
    topBg: 'bg-[#384EC2]'
  },
  SCHEDULED: {
    name: 'Scheduled',
    icon: <SCHEDULED />,
    bgColor: 'bg-[#5CACDE]',
    topBg: 'bg-[#5CACDE]'
  },
  CANCELLED: {
    name: 'Cancelled',
    icon: <CANCELLED />,
    bgColor: 'bg-[#F34922]',
    topBg: 'bg-[#F34922]'
  },
  'AUTHORISATION PENDING': {
    name: 'Authorisation Pending',
    icon: <AUTHORISATION_PENDING />,
    bgColor: 'bg-[#EAB308]',
    topBg: 'bg-[#EAB308]'
  },
  'AUTHORISATION PASSED': {
    name: 'Authorisation Passed',
    icon: <AUTHORISATION_PASSED />,
    bgColor: 'bg-[#1DB13F]',
    topBg: 'bg-[#1DB13F]'
  },
  'AUTHORISATION REJECTED': {
    name: 'Authorisation Rejected',
    icon: <AUTHORISATION_REJECTED />,
    bgColor: 'bg-[#F34922]',
    topBg: 'bg-[#F34922]'
  },
  RECURRING: {
    name: 'Recurring',
    icon: <RECURRING />,
    bgColor: 'bg-[#7951F9]',
    topBg: 'bg-[#7951F9]'
  }
};

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Dialog from '@mui/material/Dialog';
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from 'react-icons/bs';
import timeSlotsArray from '../../../hooks/useTimeSlot';
import dayjs from 'dayjs';
import { useState } from 'react';

const DateTimeCalendar = ({
  publishDate,
  openCalendar,
  publishTime,
  handleCloseCalendar,
  dayOfWeekFormatter,
  onSelectedDate,
  setPublishTime
}) => {
  const [slotPage, setSlotPage] = useState(1);
  const perSlot = 4;
  const totalPages = Math.ceil(timeSlotsArray.length / perSlot);

  const nextTimeSlot = () => {
    if (slotPage < totalPages) {
      setSlotPage(slotPage + 1);
    }
  };

  const prevTimeSlot = () => {
    if (slotPage <= totalPages && slotPage > 1) {
      setSlotPage(slotPage - 1);
    }
  };

  return (
    <Dialog onClose={handleCloseCalendar} open={openCalendar}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          minDate={dayjs()}
          className="schedule-calendar w-full"
          dayOfWeekFormatter={dayOfWeekFormatter}
          value={dayjs(publishDate)}
          onChange={(date) => onSelectedDate(date)}
        />
        <hr />
        <div className="m-5">
          <div className="flex gap-2 w-[420px] justify-between">
            <span className="text-sm font-semibold">Time Slot :</span>
            <BsFillArrowLeftCircleFill
              onClick={prevTimeSlot}
              className="items-center text-blue mt-1 cursor-pointer"
            />
            <div className="inline-flex gap-2 cursor-pointer">
              {timeSlotsArray
                .slice((slotPage - 1) * perSlot, slotPage * perSlot)
                .map((item, index) => {
                  return (
                    <span
                      onClick={() => setPublishTime(item)}
                      key={index}
                      className={` ${
                        publishTime === item
                          ? 'bg-blue text-white'
                          : 'bg-slate-200 text-gray-600'
                      }  inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium hover:bg-blue hover:text-white`}
                    >
                      {item}
                    </span>
                  );
                })}
            </div>
            <BsFillArrowRightCircleFill
              onClick={nextTimeSlot}
              className="items-center text-blue mt-1 cursor-pointer"
            />
          </div>
        </div>
      </LocalizationProvider>
      <div className="flex items-center justify-between p-2">
        <div className="flex justify-end w-full">
          <button
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
            onClick={() => handleCloseCalendar()}
          >
            Schedule
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DateTimeCalendar;

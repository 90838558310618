import PropTypes from 'prop-types';
import { useTheme, styled, Toolbar, Typography, InputBase } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 0, 3)
}));

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    paddingLeft: 40,
    border: '1px solid #ced4da',
    borderRadius: 4,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onSort: PropTypes.func,
  sortOptions: PropTypes.array,
  selectedSortOption: PropTypes.string
};

export default function ListToolbar ({ numSelected, onFilterName, filterName, placeholder, extraClasses }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const classes = useStyles();
  const placeholderValue = placeholder || 'Search';
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'white' : 'primary.dark'
        })
      }}
      className={ extraClasses ? extraClasses.join(' ') : '' }
    >
      {numSelected > 0
        ? (
        <Typography
          className="bg-white text-black"
          component="div"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
          )
        : (
        <div className={classes.search}>
          <div className={`${classes.searchIcon} absolute`}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={placeholderValue}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={filterName}
            onChange={onFilterName}
            fullWidth
          />
        </div>
          )}
    </RootStyle>
  );
}

import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';
import fileStore from '../file';
const ENDPOINT = '/organisations/users';

class UserStore {
  state = {
    users: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getUsers: action,
      getUser: action,
      addUser: action,
      updateUser: action,
      deleteUser: action
    });
  }

  getUsers = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(process.env.REACT_APP_BASE_URL + ENDPOINT);
      this.state.users = response.data;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getUser = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(process.env.REACT_APP_BASE_URL + ENDPOINT + '/' + id);
      return response.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addUser = async (newUser, file) => {
    loadingStore.setLoading(true);
    try {
      const image = file ? await fileStore.addImage(file, 'user') : null;
      const response = await client.post(
        process.env.REACT_APP_BASE_URL + ENDPOINT,
        { ...newUser, imageId: image },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      this.state.users.push(response.data);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateUser = async (updatedUser, file) => {
    loadingStore.setLoading(true);
    try {
      const image = file
        ? await fileStore.addImage(file, 'user')
        : updatedUser.imageId;
      const response = await client.put(
        process.env.REACT_APP_BASE_URL + ENDPOINT + '/' + updatedUser.id,
        {
          ...updatedUser,
          imageId: image
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const index = this.state.users.findIndex(
        (user) => user.id === response.data.id
      );
      this.state.users[index] = response.data;
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteUser = async (id) => {
    // this.state.users = this.state.users.filter((x) => x.id !== id);
    loadingStore.setLoading(true);
    try {
      await client.delete(process.env.REACT_APP_BASE_URL + ENDPOINT + '/' + id);
      const index = this.state.users.findIndex((user) => user.id === id);
      this.state.users.splice(index, 1);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getUsersByTenant = async (companyName) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(process.env.REACT_APP_BASE_URL + ENDPOINT);
      this.state.users = response.data.filter(d => d.companyName === companyName);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const userStore = new UserStore();

export default userStore;

import {
  BiTime
} from 'react-icons/bi';
import {
  MdVerifiedUser,
  MdOutlineWarning,
  MdError,
  MdWarning
} from 'react-icons/md';
import {
  GrConnect
} from 'react-icons/gr';
import {
  FaThumbsDown
} from 'react-icons/fa';

export const events = [
  {
    name: 'DELETE_POST',
    color: '#F34922',
    icon: <MdError color="#F34922" style={{ fontSize: '45px' }} />
  },
  {
    name: 'PUBLISH_POST',
    color: '#1DB13F',
    icon: <MdVerifiedUser color="#1DB13F" style={{ fontSize: '45px' }} />
  },
  {
    name: 'AUTHORIZATION_UPDATE',
    color: '#EAB308',
    icon: <MdOutlineWarning color="#EAB308" style={{ fontSize: '45px' }} />
  },
  {
    name: 'INSTANCE_CONNECTED',
    color: '#065F46',
    icon: <GrConnect color="#065F46" style={{ fontSize: '45px' }} />
  },
  {
    name: 'SCHEDULE_ARTICLE',
    color: '#5CACDE',
    icon: <BiTime color="#5CACDE" style={{ fontSize: '45px' }} />
  },
  {
    name: 'INSTANCE_TOKEN_EXPIRING',
    color: '#EAB308',
    icon: <MdWarning color="#EAB308" style={{ fontSize: '45px' }} />
  },
  {
    name: 'INSTANCE_TOKEN_EXPIRED',
    color: '#F34922',
    icon: <FaThumbsDown color="#F34922" style={{ fontSize: '45px' }} />
  }
];

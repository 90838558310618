const SendForApprovalButton = ({
  doApproval,
  status,
  sendForApprovalEvent
}) => {
  return (
        <>
            { doApproval && status === 'DRAFT' && (
                <button
                    onClick={sendForApprovalEvent}
                    className={'bg-blue rounded px-4 py-2 uppercase text-white'}
                >
                    Send For Approval
                </button>
            )}
        </>
  );
};

export default SendForApprovalButton;

import { useContext, useEffect, useRef, useState } from 'react';

import { TfiNa, TfiWorld } from 'react-icons/tfi';
import { icons } from '../../../constants/channels';

import { observer } from 'mobx-react';
import channelsStore from '../../../store/channel/channel';
import { CompanyChannelListContext } from '../../../pages/ContentManagement';

const Channel = ({
  channelId,
  handleSelect,
  handleDeselect,
  multiSelected,
  handleChannelClick,
  handleShowInstance
}) => {
  const ref = useRef(null);
  const companyChannelList = useContext(CompanyChannelListContext);
  const {
    state: { allChannelTypes },
    fetchAllChannelTypes
  } = channelsStore;

  const [channel, setChannel] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [icon, setIcon] = useState(<TfiWorld />);
  const [color, setColor] = useState('#006ace');
  const [image, setImage] = useState(null);
  const [smallIcon, setSmallIcon] = useState(null);
  const [violated, setViolated] = useState([]);

  useEffect(async () => {
    if (!allChannelTypes.length) {
      await fetchAllChannelTypes();
    }
  }, [allChannelTypes]);

  useEffect(() => {
    if (channel.validationStatus === false) {
      handleDeselect(channel.id);
      setViolated(channel.failed_rules);
    } else {
      setViolated([]);
    }
  }, [channel]);

  const handleClick = (e) => {
    e.preventDefault();
    if (violated.length === 0) handleSelect(channel.id);
    else {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        handleChannelClick(
          { x: rect.left + 20, y: rect.top + 20 },
          channel,
          violated,
          {
            icon,
            color
          }
        );
      }
    }
  };

  const displayInstanceFor = (e) => {
    e.preventDefault();
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      handleShowInstance({ x: rect.left + 20, y: rect.top + 20 }, channel, {
        icon,
        color
      });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const data = companyChannelList?.find(
      (x) => parseInt(x.id) === parseInt(channelId)
    );

    setChannel(data || {});
  }, [channelId, companyChannelList]);

  // const _isSelected = () => {
  //   return multiSelected.find((x) => x === channel.id);
  // };

  const findIcon = (name) => {
    return icons.find((c) => c.name === name);
  };

  useEffect(() => {
    const icon = findIcon(channel.name);
    if (icon) {
      setIcon(icon.icon);
    } else {
      setIcon(<TfiNa />);
    }
  }, [channel]);

  useEffect(() => {
    setImage(null);
    channel.channelImageUrl && setImage(channel.channelImageUrl);
  }, [channel]);

  useEffect(() => {
    setSmallIcon(null);
    if (channel && channel.type_id && allChannelTypes) {
      const channelType = allChannelTypes.find((c) => c.id === channel.type_id);
      if (channelType) {
        const icon = findIcon(channelType.channelName);
        if (icon) {
          setSmallIcon(icon.icon);
          setColor(icon.color);
        } else {
          setSmallIcon(<TfiWorld />);
          setColor('#006ace');
        }
      }
    }
  }, [channel, allChannelTypes]);

  return (
    <>
      <div
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          border:
            multiSelected.find((x) => x === channel.id) &&
            `2px dashed ${color}`
        }}
        className={`${image && multiSelected.find((x) => x === channel.id) ? 'pt-1 px-1' : 'p-1'} rounded-full relative`}
      >
        {violated.length > 0 && (
          <p className="absolute top-0 left-0 bg-red text-white w-5 h-5 font-bold rounded-full text-center text-[10px] pt-[3px]">
            {violated.length}
          </p>
        )}
        <button
          onClick={handleClick}
          style={{
            background: multiSelected.find((x) => x === channel.id)
              ? color
              : '#ccc'
          }}
          className={`${image && multiSelected.find((x) => x === channel.id) ? 'p-0' : 'p-2'} text-xl rounded-full text-white`}
        >
          { violated.length <= 0 && image && multiSelected.find((x) => x === channel.id) && (
            <img
              src={image}
              alt="icon"
              className={`${multiSelected.find((x) => x === channel.id) ? '' : 'opacity-50'} w-8 h-8 object-cover rounded-full`}
            />
          )}

          { violated.length <= 0 && (!image || !multiSelected?.find((x) => x === channel.id)) && (
            channel.type === 'channelInstance' ? smallIcon : icon
          )}

          { violated.length > 0 && channel.type === 'channelInstance' && (
            <TfiNa/>
          )}

          { violated.length > 0 && channel.type !== 'channelInstance' && (
            icon
          )}
        </button>
        {channel.instances && channel.instances.length > 0 && (
          <p
            onClick={displayInstanceFor}
            className="absolute bottom-0 right-0 bg-blue text-white w-5 h-5 font-bold rounded-full text-center cursor-pointer text-[10px] pt-[3px]"
          >
            {channel.instances.length}+
          </p>
        )}
        {channel.type === 'channelInstance' && (violated.length > 0 || !multiSelected.find((x) => x === channel.id)) && (
          <p
            className="absolute bottom-2 right-0 w-5 h-5 p-0.6 pt-0.6 font-bold rounded-full text-center cursor-pointer"
          >
            {image
              ? (
              <img
                src={image}
                alt="icon"
                className={'w-5 h-5 object-cover rounded-full'}
              />
                )
              : (
                  smallIcon
                )}
          </p>
        )}
      </div>
      <div className="relative">
        {isHovered && (
          <p className="absolute top-0 left-[-5px] whitespace-nowrap bg-black text-white rounded-full px-4 z-30 drop-shadow-lg">
            {channel.name}
          </p>
        )}
      </div>
    </>
  );
};

export default observer(Channel);

import { Visibility } from '@mui/icons-material';
import { Button, Menu, MenuItem, Modal } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { AiFillDelete, AiFillEdit, AiOutlineMore } from 'react-icons/ai';
import { BsLightning, BsXCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ModalBox from '../../components/Modal/Box';
import channelsStore from '../../store/channel/channel';
import publicationStore from '../../store/channel/publication';
import canI from '../../utils/canI';
import AddUserChannel from '../Modal/Channel/AddUserChannel';
import DeleteConfirmationModal from '../Modal/Delete';
import ShowChannelSecrets from './ShowChannelSecrets';

const UserDefinedMoreMenu = ({
  row,
  image,
  loadInstances,
  setStatusText,
  setStatus,
  setIsTestStatusOpen
}) => {
  const navigate = useNavigate();
  const { testChannel, connectChannel } = publicationStore;

  const { deleteChannel } = channelsStore;
  const { enqueueSnackbar } = useSnackbar();

  const [openDelete, setOpenDelete] = useState(false);
  const [opensecret, setOpensecret] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [stepNo] = useState(2);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleCancelDelete = () => {
    setOpenDelete(false);
  };

  const onDelete = () => {
    deleteChannel(row.id).then(() => loadInstances(true));
    setOpenDelete(false);
  };

  const onOpenSecret = () => {
    setOpensecret(true);
  };

  const onCloseSecret = () => {
    setOpensecret(false);
    handleClose();
  };

  const handleCancelEdit = () => {
    loadInstances();
    setOpenEdit(false);
  };

  // const handleConnectChannel = () => {
  //   handleClose();
  //   setOpenEdit(true);
  //   setSetNo(3);
  // };

  const handleEditClick = async () => {
    handleClose();
    navigate('/channels/manage-channels/' + row.id);
  };

  const handleMenuOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnectChannel = () => {
    handleClose();
    connectChannel(row.id)
      .then((response) => {
        if (response.redirectUrl) {
          window.location.replace(response.redirectUrl);
        } else {
          if (response.status === true) {
            enqueueSnackbar('Channel connection has been established', {
              variant: 'success'
            });
          } else {
            enqueueSnackbar('Channel connection has been disconnected', {
              variant: 'success'
            });
          }
        }
        loadInstances();
      })
      .catch((_error) => {
        enqueueSnackbar('Failed to connect channel', { variant: 'error' });
      });
  };

  const testConnect = () => {
    handleClose();
    testChannel(row.id)
      .then((response) => {
        if (response) {
          setIsTestStatusOpen(true);
          setStatus(response.status);
          setStatusText(
            response.error
              ? response.error
              : response.remarks
                ? response.remarks
                : 'Test failed!'
          );
        } else {
          enqueueSnackbar('Test connection has been failed', {
            variant: 'success'
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      });
  };

  return (
    <>
      <div className="relative">
        {canI([
          'create:channelinstance',
          'delete:channelinstance',
          'update:channelinstance'
        ]) && (
          <>
            <Button
              onClick={handleMenuOpenClick}
              aria-controls={open ? 'more-menu-options' : undefined}
              className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
            >
              <IconContext.Provider value={{ className: 'text-xl' }}>
                <AiOutlineMore />
              </IconContext.Provider>
            </Button>
            <Menu
              id="more-menu-options"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {row.channelInstanceConfig?.status &&
                canI(['create:publication-setting']) && (
                  <MenuItem
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                    onClick={handleDisconnectChannel}
                  >
                    <BsXCircle className="w-5 h-5 text-red mr-2" />
                    Disconnect
                  </MenuItem>
              )}
              {/* {!row.channelInstanceConfig?.status &&
                canI(['create:publication-setting']) && (
                  <MenuItem
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                    onClick={handleConnectChannel}
                  >
                    <BsCheck2Circle className="w-5 h-5 text-green mr-2" />
                    Connect
                  </MenuItem>
              )} */}

              {canI(['delete:channelinstance']) && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenDelete(true);
                  }}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillDelete className="text-red mr-2" />
                  Delete
                </MenuItem>
              )}

              {canI([
                'update:channelinstance',
                'update:publication-setting'
              ]) && (
                <MenuItem
                  onClick={handleEditClick}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillEdit className="text-green mr-2" />
                  Edit
                </MenuItem>
              )}

               {canI(['create:channelinstance']) && (
                <MenuItem
                  onClick={testConnect}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <BsLightning className="text-red mr-2" />
                  Test
                </MenuItem>)}

              {row.channelInstanceConfig?.connectionTypes?.connectionType === 'WEBHOOK' &&
                  canI(['create:channelinstance']) && (
                <MenuItem
                  onClick={onOpenSecret}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <Visibility className="text-red mr-2" />
                  Show Secrets
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>

      <DeleteConfirmationModal
        open={openDelete}
        onDelete={onDelete}
        del={['Channel']}
        handleConfirm={onDelete}
        handleCancel={handleCancelDelete}
      />

      <ShowChannelSecrets
        open={opensecret}
        handleClose={onCloseSecret}
        id={row.id}/>

      {openEdit && (
        <Modal open={openEdit} onClose={handleCancelEdit}>
          <ModalBox>
            <AddUserChannel
              handleClose={handleCancelEdit}
              isEdit
              currentChannel={{
                ...row,
                image,
                imageId: row.imageId
              }}
              stepNo={stepNo}
            />
          </ModalBox>
        </Modal>
      )}
    </>
  );
};

export default UserDefinedMoreMenu;

import { AiFillFolderOpen, AiOutlineTeam } from 'react-icons/ai';
import {
  FaBook,
  FaBriefcase,
  FaBuilding,
  FaLocationArrow,
  FaLock,
  FaPencilRuler,
  FaScroll,
  FaTags,
  FaTools,
  FaUser,
  FaUserLock,
  FaUserShield,
  FaUserTag,
  FaLink
} from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { HiBuildingLibrary, HiBuildingOffice, HiUsers } from 'react-icons/hi2';
import { IoIosFlower, IoMdAnalytics } from 'react-icons/io';
import { MdFolderCopy, MdManageAccounts, MdOutlineSocialDistance, MdRvHookup, MdPermContactCalendar } from 'react-icons/md';
import { RiDashboardFill, RiOrganizationChart } from 'react-icons/ri';
import {
  TbHierarchy3,
  TbPresentationAnalytics,
  TbDeviceAnalytics,
  TbReportSearch,
  TbSocial
} from 'react-icons/tb';

export const icons = [
  { name: 'home', icon: <RiDashboardFill style={{ fontSize: '20px' }} /> },
  { name: 'channels', icon: <TbSocial style={{ fontSize: '20px' }} /> },
  { name: 'content', icon: <FaBook style={{ fontSize: '20px' }} /> },
  { name: 'tags', icon: <FaTags style={{ fontSize: '20px' }} /> },
  { name: 'tools-styling', icon: <FaTools style={{ fontSize: '20px' }} /> },
  { name: 'organisation', icon: <FaBuilding style={{ fontSize: '20px' }} /> },
  { name: 'security', icon: <FaLock style={{ fontSize: '20px' }} /> },
  { name: 'setting', icon: <MdManageAccounts style={{ fontSize: '20px' }} /> },
  { name: 'analytics', icon: <IoMdAnalytics style={{ fontSize: '20px' }} /> },
  {
    name: 'Analytics/ProfileAnalytics',
    icon: <TbPresentationAnalytics style={{ fontSize: '20px' }} />
  },
  {
    name: 'Analytics/PostAnalytics',
    icon: <TbDeviceAnalytics style={{ fontSize: '20px' }} />
  },
  {
    name: 'Channel/restriction',
    icon: <FaPencilRuler style={{ fontSize: '20px' }} />
  },
  {
    name: 'Channel/internal',
    icon: <IoIosFlower style={{ fontSize: '20px' }} />
  },
  {
    name: 'Channel/external',
    icon: <MdOutlineSocialDistance style={{ fontSize: '20px' }} />
  },

  {
    name: 'Content/SearchContent',
    icon: <TbReportSearch style={{ fontSize: '20px' }} />
  },

  {
    name: 'Tags/ManageTags',
    icon: <FaUserTag style={{ fontSize: '20px' }} />
  },
  {
    name: 'Tags/TagingRules',
    icon: <FaScroll style={{ fontSize: '20px' }} />
  },

  {
    name: 'Tools-and-Styling/Phrase',
    icon: <HiBuildingLibrary style={{ fontSize: '20px' }} />
  },

  {
    name: 'Tools-and-Styling/myphrases',
    icon: <HiBuildingOffice style={{ fontSize: '20px' }} />
  },

  {
    name: 'Tags/ManageContact',
    icon: <MdPermContactCalendar style={{ fontSize: '20px' }}/>
  },

  {
    name: 'Security/Companies',
    icon: <RiOrganizationChart style={{ fontSize: '20px' }} />
  },
  {
    name: 'organisation/Location',
    icon: <FaLocationArrow style={{ fontSize: '20px' }} />
  },
  {
    name: 'organisation/Department',
    icon: <TbHierarchy3 style={{ fontSize: '20px' }} />
  },
  {
    name: 'organisation/Teams',
    icon: <AiOutlineTeam style={{ fontSize: '20px' }} />
  },
  {
    name: 'organisation/Programmes',
    icon: <MdFolderCopy style={{ fontSize: '20px' }} />
  },
  {
    name: 'organisation/Projects',
    icon: <FaBriefcase style={{ fontSize: '20px' }} />
  },
  {
    name: 'organisation/Folder',
    icon: <AiFillFolderOpen style={{ fontSize: '20px' }} />
  },

  { name: 'Security/Users', icon: <FaUser style={{ fontSize: '20px' }} /> },
  { name: 'Security/Group', icon: <HiUsers style={{ fontSize: '20px' }} /> },
  {
    name: 'Security/Roles',
    icon: <FaUserShield style={{ fontSize: '20px' }} />
  },
  {
    name: 'Security/Permission',
    icon: <FaUserLock style={{ fontSize: '20px' }} />
  },
  {
    name: 'Settings/Features',
    icon: <GiReceiveMoney style={{ fontSize: '20px' }} />
  },
  {
    name: 'Settings/Shadow',
    icon: <MdRvHookup style={{ fontSize: '20px' }} />
  },
  {
    name: 'Settings/Integration',
    icon: <FaLink/>
  }
];

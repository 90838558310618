import { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { useNavigate, useParams } from 'react-router-dom';
import contentApi from '../../api/content';
import PublicationStatus from '../../components/ContentManagement/PublicationHistory/PublicationStatus';
import PublicationStatusNode from '../../components/ContentManagement/PublicationHistory/PublicationStatusNode';
import PublicationStatusTopInfo from '../../components/ContentManagement/PublicationHistory/PublicationStatusTopInfo';
import Navbar from '../../components/ContentManagement/VersionComparison/Navbar';
import { publicationStatuses } from '../../constants/publicationStatues';
import { useCenteredTreeView } from '../../hooks/useCenteredTreeView';
import loadingStore from '../../store/loading';

import {
  BsArrowClockwise,
  BsBootstrapReboot,
  BsFillEyeFill,
  BsInfoCircle,
  BsTrash3,
  BsZoomIn,
  BsZoomOut
} from 'react-icons/bs';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import Moment from 'react-moment';
import ConfirmationModal from '../../components/Modal/Confirm';
import ToggleButton from '../../components/ToggleButton';
import canI from '../../utils/canI';

const containerStyles = {
  width: '98vw',
  height: '85vh'
};

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  repost,
  viewPost,
  deletePost
}) => (
  <g>
    <foreignObject {...foreignObjectProps}>
      <div className={`flex items-center rounded-md space-x-4 h-full p-5 relative ${publicationStatuses[nodeDatum.data.status]?.topBg} bg-white`} onClick={toggleNode}>
        <PublicationStatus item={nodeDatum.data} />
        <div className="font-medium">
          {nodeDatum.data.entityType !== 'ROOT' && nodeDatum.data.title && (
            <Tooltip title={nodeDatum.data.title}>
              <div>{nodeDatum.data.title.length > 35 ? nodeDatum.data.title.substring(0, 35) + '...' : nodeDatum.data.title}</div>
            </Tooltip>
          )}
          {nodeDatum.data.createdOn && !nodeDatum.data.updatedAt && (<div>Created on : <Moment format="MMM, DD hh:mm A">{nodeDatum.data.createdOn}</Moment></div>)}
          {nodeDatum.data.updatedAt && (<div>Updated at : <Moment format="MMM, DD hh:mm A">{nodeDatum.data.updatedAt}</Moment></div>)}
          {nodeDatum.data.version && (<div>Version : {nodeDatum.data.version}</div>)}
          {nodeDatum.data.channelTypeName && (<div>Channel Type : {nodeDatum.data.channelTypeName}</div>)}
          {nodeDatum.data.entityType === 'CHANNEL' && !nodeDatum.data.deleted && (
            <div className="flex gap-2 items-center">
              <button onClick={() => { repost(nodeDatum.data); }} className="mt-1 flex text-blue items-center">
                <BsBootstrapReboot />&nbsp;Repost
              </button>
              {nodeDatum.data.status !== 'FAILED' && (
                <>
                  {(nodeDatum.data.channelTypeName !== 'Tik Tok') && (
                    <button onClick={(e) => {
                      e.preventDefault();
                      viewPost(nodeDatum.data);
                    }} className="mt-1 flex text-blue items-center">
                      <BsFillEyeFill />&nbsp;View
                    </button>
                  )}
                  {(nodeDatum.data.channelTypeName !== 'Instagram Business' && nodeDatum.data.channelTypeName !== 'Tik Tok') && (
                    <button onClick={() => { deletePost(nodeDatum.data); }} className="mt-1 flex text-red items-center">
                      <BsTrash3 />&nbsp;Delete
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <PublicationStatusNode item={nodeDatum.data} />
      </div>
    </foreignObject>
  </g>
);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '15px',
    maxWidth: 800
  }
}));

const PublicationStatusHistory = () => {
  const navigate = useNavigate();
  const [dimensions, translate, containerRef] = useCenteredTreeView();
  const [chart, setChart] = useState(null);
  const [includeHistory, setIncludeHistory] = useState(false);
  const [isHorizontalMode, setIsHorizontalMode] = useState(true);
  const { setLoading } = loadingStore;
  const { contentId } = useParams();
  const nodeSize = { x: 400, y: 112 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -220, y: isHorizontalMode ? -50 : -90 };
  const [startLoadTime, setStartLoadTime] = useState(0);
  const RELOAD_STATUS = [
    'PUBLISH IN PROGRESS',
    'READY TO PUBLISH'
  ];
  const [zoomLevel, setZoomLevel] = useState(0.75);
  const [seletectedItem, setSelectedItem] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const goBack = () => {
    navigate('/contents/search');
  };

  const goToPostAnalytics = () => {
    navigate('/analytics/post-analytics?articleId=' + contentId);
  };

  const fetchPublicationStatusHistory = () => {
    return contentApi.fetchTree(contentId, includeHistory).then((res) => {
      setChart(res);
      return res;
    });
  };

  const refresh = () => {
    setLoading(true);
    fetchPublicationStatusHistory().then((res) => {
      if (RELOAD_STATUS.indexOf(res.data.status) !== -1) {
        setTimeout(() => {
          setStartLoadTime(startLoadTime + 1);
        }, 3000);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    refresh();
  }, [contentId, includeHistory]);

  useEffect(() => {
    startLoadTime > 0 && fetchPublicationStatusHistory().then((res) => {
      if (RELOAD_STATUS.indexOf(res.data.status) !== -1) {
        setTimeout(() => {
          setStartLoadTime(startLoadTime + 1);
        }, 3000);
      }
    });
  }, [startLoadTime]);

  const increaseZoom = () => {
    if (zoomLevel >= 1) {
      setZoomLevel(1);
    } else {
      setZoomLevel(zoomLevel + 0.1);
    }
  };

  const decreaseZoom = () => {
    if (zoomLevel <= 0.1) {
      setZoomLevel(0.1);
    } else {
      setZoomLevel(zoomLevel - 0.1);
    }
  };

  const repost = (item) => {
    setLoading(true);
    contentApi.postPublish({
      rootArticleId: chart.data.id,
      articleId: item.articleId,
      channelInstanceIds: [item.channelInstanceId]
    }).then(() => {
      setLoading(false);
      refresh();
    }).catch((_error) => {
      enqueueSnackbar('we are unable to repost.', { variant: 'error' });
    });
  };

  const viewPost = (item) => {
    setLoading(true);
    contentApi.getLinkViewPost(item.referenceId, item.channelInstanceId).then((response) => {
      setLoading(false);
      if (response && response.length > 0) {
        response.forEach((item) => {
          window.open(item, '_blank');
        });
      }
    }).catch((_error) => {
      setLoading(false);
      enqueueSnackbar('we are unable to display view post.', { variant: 'error' });
    });
  };

  const deletePost = (item) => {
    setConfirmDelete(true);
    setSelectedItem(item);
  };

  const onConfirmDelete = () => {
    setLoading(true);
    contentApi.deletePost(
      seletectedItem.referenceId,
      seletectedItem.channelInstanceId
    ).then((_response) => {
      setLoading(false);
      setConfirmDelete(false);
      setSelectedItem(null);
      refresh();
    }).catch((_error) => {
      setLoading(false);
      enqueueSnackbar('we are unable to delete post.', { variant: 'error' });
      setConfirmDelete(false);
      setSelectedItem(null);
    });
  };

  const onHandleCancel = () => {
    setConfirmDelete(false);
    setSelectedItem(null);
  };

  return (
    <div className="bg-[#f1f4f7] h-screen">
      <Navbar goBack={goBack}>
        <div className="flex-1">
          <b>Article Tree</b>
          {chart && chart.data.title && (<><br /><span className="text-sm">{chart.data.title}</span></>)}
        </div>
        <div className="flex items-center gap-2 font-bold text-sm">
          <ToggleButton toggle={isHorizontalMode} handleClick={() => setIsHorizontalMode(!isHorizontalMode)} />
          <p>Horizontal Mode</p>
          <ToggleButton toggle={includeHistory} handleClick={() => setIncludeHistory(!includeHistory)} />
          <p>Include History</p>
        </div>

        {canI(['read:profile-analytics']) && (
          <button onClick={() => goToPostAnalytics()} className={'border-[1px] border-blue bg-blue text-white px-4 py-2 rounded'}>
            Analytics
          </button>
        )}
        <div className="flex items-center gap-2">
          <div className={'flex justify-end'}>
            <HtmlTooltip arrow title={
              <>
                {Object.keys(publicationStatuses).map((status, index) => (
                  <PublicationStatusTopInfo key={index} status={status} />
                ))}
              </>
            }>
              <div className="bg-white cursor-pointer h-10 inline-flex gap-2 items-center px-2.5 py-0.5 text-xs rounded mr-2 border border-gray-500">
                <BsInfoCircle /> Help
              </div>
            </HtmlTooltip>
          </div>
        </div>
      </Navbar>
      {chart && (
        <div style={containerStyles} ref={containerRef}>
          <div className={'flex relative top-5 justify-end gap-2 '}>
            <div onClick={increaseZoom} className="bg-white cursor-pointer h-10 inline-flex items-center px-2.5 py-0.5 text-xs rounded mr-2 border border-gray-500">
              <BsZoomIn />
            </div>
            <div onClick={decreaseZoom} className="bg-white cursor-pointer h-10 inline-flex items-center px-2.5 py-0.5 text-xs rounded mr-2 border border-gray-500">
              <BsZoomOut />
            </div>
            <div onClick={refresh} className="bg-white cursor-pointer h-10 inline-flex items-center px-2.5 py-0.5 text-xs rounded mr-2 border border-gray-500">
              <BsArrowClockwise />
            </div>
          </div>
          <Tree
            nodeSize={{ x: 500, y: 200 }}
            data={chart}
            dimensions={dimensions}
            translate={translate}
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({
                ...rd3tProps,
                foreignObjectProps,
                repost,
                viewPost,
                deletePost
              })
            }
            orientation={isHorizontalMode ? 'horizontal' : 'vertical'}
            pathFunc={'step'}
            scaleExtent={
              {
                max: 1,
                min: 0.1
              }
            }
            zoom={zoomLevel}
            zoomable={true}
          />
        </div>
      )}
      <ConfirmationModal
        open={confirmDelete}
        onConfirm={onConfirmDelete}
        handleCancel={onHandleCancel}
        setIsOpen={() => { }}
        setSelected={() => { }}
        message={['Delete post', 'Are you sure you want to delete post ?']}
      />
    </div>
  );
};

export default PublicationStatusHistory;

import { ErrorMessage } from '@hookform/error-message';
import { ImageOutlined } from '@mui/icons-material';
import {
  Box,
  Chip,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Stack
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { BsCamera, BsInfoCircle, BsTrash } from 'react-icons/bs';
import ImageUpload from '../../components/ImageUpload';
import channelGroupAPI from '../../store/channel/channelGroup';
import ChannelInstanceInfo from './ChannelInstanceInfo';
import ConnectionParams from './ConnectionParams';
import WatchVideoActionButton from '../../components/WatchVideoActionButton';
import ChannelGroupModal from '../../components/Channel/ChannelGroupModal';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ChannelGeneral = ({
  selectedChannelTypeName,
  connectionTypes,
  imageId,
  setImageId,
  setChannelImageFile,
  control,
  errors,
  setValue,
  existingChannel,
  selectedChannelGroups,
  setSelectedChannelGroups,
  companyIdWatch,
  trigger
}) => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [channelGroups, setChannelGroups] = useState([]);
  const [showCreateChannelGroup, setShowCreateChannelGroup] = useState(false);

  useEffect(() => {
    if (companyIdWatch) {
      setSelectedChannelGroups(null);
      reloadChannelGroups();
    }
  }, [companyIdWatch]);

  useEffect(() => {
    if (!selectedChannelGroups) {
      if (channelGroups.length) {
        const defaultGroup = channelGroups.find(g => g.groupName.toLowerCase().includes('default'));
        if (defaultGroup) {
          setSelectedChannelGroups([defaultGroup.id]);
          setValue('channelGroups', [{ id: defaultGroup.id }]);
        } else {
          setSelectedChannelGroups([channelGroups[0].id]);
          setValue('channelGroups', [{ id: channelGroups[0].id }]);
        }
      } else {
        setValue('channelGroups', []);
      }
    } else {
      setValue('channelGroups', selectedChannelGroups.map(groupId => ({ id: groupId })));
    }
  }, [selectedChannelGroups, channelGroups]);

  useEffect(() => {
    imageId ? setImagePreview(imageId) : setImagePreview(null);
  }, [imageId]);

  const handleImageUpload = (file) => {
    setChannelImageFile(file);
    setImagePreview(URL.createObjectURL(file));
    setShowUploadImage(false);
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setImageId(null);
  };

  const reloadChannelGroups = () => {
    setChannelGroups([]);
    channelGroupAPI.fetchChannelGroup(companyIdWatch).then(response => {
      setChannelGroups(response);
    });
  };

  return (
    <Grid container className="p-8">
      <Grid container item xs={9}>
        <Grid item xs={12} style={existingChannel?.channelInstanceConfig?.status ? { pointerEvents: 'none' } : {}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box>
              <InputLabel className="font-black">Channel Instance Name*</InputLabel>
              <FormHelperText className="mb-2">
                This name would be the reference for this channel throughout the
                Marvin application
              </FormHelperText>
            </Box>
            <WatchVideoActionButton link={'https://www.youtube.com/embed/7tR5c7ETFiw?si=hzJLjYkDBBHOdZa'}/>
          </Stack>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter your channel name"
                className="w-full"
                error={!!errors.name}
              ></TextField>
            )}
          ></Controller>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <Typography className="text-red">{message}</Typography>
            )}
          />
        </Grid>
        <Grid item xs={12} className="mt-8">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box>
              <InputLabel className="font-black">
                Channel Group*
              </InputLabel>
              <FormHelperText className="mb-2">
                This name would be the reference for this channel throughout the
                Marvin application
              </FormHelperText>
            </Box>
            <WatchVideoActionButton link={'https://www.youtube.com/embed/CYiyhL4Bn40?si=hzJLjYkDBBHOdZa'}/>
          </Stack>
          <FormControl className='w-full' error={!!errors.channelGroups}>
            <Select
              multiple
              value={selectedChannelGroups === null ? [] : selectedChannelGroups}
              className='w-full'
              onChange={(event) => {
                if (event.target.value.includes('create_new')) {
                  setShowCreateChannelGroup(true);
                } else {
                  setSelectedChannelGroups(event.target.value);
                  setValue('channelGroups', event.target.value.map(v => ({ id: v })));
                }
              }}
              onClose={() => trigger(['channelGroups'])}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const v = channelGroups.find(g => g.id === value);
                    if (v) {
                      return (<Chip key={v?.id} label={v?.groupName} />);
                    } else {
                      return '';
                    }
                  })}
                </Box>
              )}
            >
              {channelGroups.map((group, index) => (
                <MenuItem value={group.id} key={index}>
                  {group.groupName}
                </MenuItem>
              ))}
              <MenuItem value='create_new' key={'create_new'}>
                  {'Create New'}
                </MenuItem>
            </Select>
            {!!errors.channelGroups && <Typography className="text-red">Select at least one Channel Group</Typography>}
          </FormControl>
        </Grid>
        {connectionTypes?.length > 0 && (!existingChannel || (existingChannel && !existingChannel?.channelInstanceConfig?.status)) &&
          <Grid item xs={12} className="mt-8">
            <InputLabel className="font-black mb-2">
              Connect Instance with Your {selectedChannelTypeName}
            </InputLabel>
            <Controller
              name="channelInstanceConfig.connectionTypes.id"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  {connectionTypes.map((connection, index) => (
                    <div key={index}>
                      <FormControlLabel
                        value={connection.id}
                        control={<Radio size="small" />}
                        label={connection.connectionType}
                      />
                      <FormHelperText className="text-xs">
                        {connection.connectionTypeDesc}
                      </FormHelperText>
                    </div>
                  ))}
                </RadioGroup>
              )}
            ></Controller>
          </Grid>
        }
        <Grid item xs={12}>
          <ConnectionParams control={control} errors={errors} existingChannel={existingChannel} />
        </Grid>
        {connectionTypes?.length > 0 && (existingChannel && existingChannel?.channelInstanceConfig?.status) && <Grid item xs={12} className="mt-8">
          <ChannelInstanceInfo existingChannel={existingChannel} />
        </Grid>}
        <Grid item xs={12}>
          {selectedChannelTypeName === 'Slack' && (
            <div className="rounded-md w-full h-17 mt-5 text-black bg-orange-200 p-3 text-center content-center text-x">
              <b><BsInfoCircle /></b>
              <span><b>{'Thank you for your support !'}</b></span>
              <br></br>
              <br></br>
              <span>{'We\'re making progress toward getting the Slack App signed off.'}</span>
              <br></br>
              <br></br>
              <span>
                {
                  'Currently, it may appear as unapproved, but there\'s no need to worry.' +
                  'We\'ll notify you as soon as the approved version becomes available.'
                }
              </span>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={3} className="flex justify-content-end"
        style={existingChannel?.channelInstanceConfig?.status ? { pointerEvents: 'none' } : {}}>
        <div className="flex flex-col align-items-center">
          <div
            className="relative text-white text-sm font-medium w-28 h-28 rounded-full flex items-center justify-center mb-3"
            style={{
              backgroundColor: imagePreview
                ? 'white'
                : 'hsla(192, 68%, 41%, 0.08)'
            }}
          >
            {!imagePreview && (
              <ImageOutlined style={{ color: 'hsla(192, 48%, 53%, 0.34)' }} />
            )}
            {imagePreview && (
              <img
                className="absolute rounded-full object-cover h-full w-full"
                src={imagePreview}
                alt="channel"
              />
            )}
            {imagePreview && (
              <Fab
                sx={{
                  width: '35px',
                  height: '35px'
                }}
                className="text-white bg-red absolute bottom-[-8px] right-[-8px]"
                onClick={handleRemoveImage}
              >
                <BsTrash />
              </Fab>
            )}
            <Fab
              sx={{
                width: '35px',
                height: '35px'
              }}
              className="text-white bg-blue absolute bottom-20 right-[-8px]"
              onClick={() => setShowUploadImage(true)}
            >
              <BsCamera />
            </Fab>
          </div>
          <Typography color="primary" className="text-xs">
            Upload Picture(Optional)
          </Typography>
        </div>
      </Grid>
      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}
      <Dialog
        open={showCreateChannelGroup}
        onClose={() => setShowCreateChannelGroup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title">{'Create Channel Group'}</DialogTitle>
        <DialogContent>
          <ChannelGroupModal
             onSave={() => {
               reloadChannelGroups();
               setShowCreateChannelGroup(false);
             }}
             onCancel={() => {
               setShowCreateChannelGroup(false);
             }}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ChannelGeneral;

import { useState } from 'react';
import ModalHead from '../Header/modalHead';
import { Autocomplete, Box, TextField } from '@mui/material';
import publicationStore from '../../../store/channel/publication';

const SelectConnectionPage = ({
  handleClose,
  redirectPages,
  loadInstances
}) => {
  const { connectChannelPage } = publicationStore;
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  async function onSubmit () {
    if (!value) {
      setError(true);
      return;
    }

    try {
      await connectChannelPage(value.id, redirectPages);
      window.location = window.location.href + '?success=The connection has been successfully established.';
    } catch (error) {
      window.location = window.location.href + '?failed=The connection has been failed.';
    }
    handleClose();
    if (loadInstances) {
      loadInstances();
    }
  };

  return (
        <>
            <div className="flex items-center h-full drop-shadow-lg" >
                <div className="bg-white mx-auto rounded-lg">
                    <ModalHead
                        handleClose={handleClose}
                        heading={'Select Profile'}
                    />
                    <div className="px-5 my-5">
                        <div className="w-[674px] h-[200px]">
                          <h3 className="mb-5 text-lg font-bold">{`Please select ${redirectPages.channelType.toLowerCase()} profile for connection:`}</h3>
                          <Autocomplete
                            id="select-profile-id"
                            autoHighlight
                            options={redirectPages.profileList}
                            getOptionLabel={(option) => option.profileName}
                            className='w-full'
                            renderOption={(props, option) => (
                              <Box {...props}>
                                {option.profileName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Profile"
                                inputProps={{
                                  ...params.inputProps
                                }}
                                error={error}
                                helperText={error ? 'Please select valid Profile' : ''}
                              />
                            )}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                              setError(false);
                            }}
                          />
                          </div>
                          <hr className="my-5" />
                          <div className="flex items-center justify-end">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                                onClick={handleClose}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={onSubmit}
                                variant="contained"
                                color="primary"
                                className="mb-4 mrvn-sv rounded"
                              >
                                Connect
                              </button>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export default SelectConnectionPage;

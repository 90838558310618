import RecommendedItem from './RecommendedItem';

const RecommendedList = ({ data }) => {
  return (
    <div className="flex flex-col gap-3 max-h-[500px] overflow-unset scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded ">
      {data.map((x, i) => (
        <RecommendedItem phrase={x} key={i} />
      ))}
    </div>
  );
};

export default RecommendedList;

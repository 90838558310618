import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { ReactComponent as Calendar } from '../../../assets/icons/Calendar.svg';
import { EndOnType } from '../../../constants/EndOnType';
import DateTimeCalendar from './DateTimeCalendar';
import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';

dayjs.extend(isSameOrAfter);

const RecurringType = ['Day', 'Week', 'Month', 'Year'];

const WEEK_DAYS = [
  { key: 0, title: 'SUNDAY', symbol: 'S' },
  { key: 1, title: 'MONDAY', symbol: 'M' },
  { key: 2, title: 'TUESDAY', symbol: 'T' },
  { key: 3, title: 'WEDNESDAY', symbol: 'W' },
  { key: 4, title: 'THURSDAY', symbol: 'T' },
  { key: 5, title: 'FRIDAY', symbol: 'F' },
  { key: 6, title: 'SATURDAY', symbol: 'S' }
];

const Recurringoption = ({
  recurringState,
  setRecurringState,
  openCalendar,
  setStep,
  publishDateTime,
  publishDate,
  publishTime,
  setPublishTime,
  handleClose,
  onSelectedDate,
  handleSubmitSchedule,
  dayOfWeekFormatter,
  handleCloseCalendar,
  setOpenCalendar
}) => {
  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    const day = dayjs(publishDate).day();
    const filteredWeek = WEEK_DAYS.filter((week) => week.key === day);
    if (recurringState.weekDaysRepetition.length === 0) {
      const includedWeeks = recurringState.weekDaysRepetition;
      setRecurringState({
        ...recurringState,
        weekDaysRepetition: [...includedWeeks, filteredWeek[0].title]
      });
    }
  }, [recurringState.weekDaysRepetition, publishDate]);

  const handleWeekDayRepetition = (e) => {
    const selectedWeek = e.target.value;

    if (recurringState.weekDaysRepetition.includes(selectedWeek)) {
      setRecurringState({
        ...recurringState,
        weekDaysRepetition: recurringState.weekDaysRepetition.filter(
          (week) => week !== selectedWeek
        )
      });
    } else {
      const includedWeeks = recurringState.weekDaysRepetition;
      setRecurringState({
        ...recurringState,
        weekDaysRepetition: [...includedWeeks, selectedWeek]
      });
    }
  };

  useEffect(() => {
    if (
      recurringState.endingCondition === EndOnType.EndDate &&
      dayjs(publishDateTime).isSameOrAfter(dayjs(recurringState.endDate))
    ) {
      setEndDateError(true);
    } else setEndDateError(false);
  }, [recurringState.endingCondition, publishDateTime]);

  return (
    <>
      <Grid container spacing={2} className="h-[300px]">
        <Grid item xs={12}>
          <Box>
            <Typography>Customize the event recurrence</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Typography className="text-black" id="schedule-to-publish">
            Start Date and Time
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <b>{dayjs(publishDateTime).format('MMM, DD hh:mm A')}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Calendar
            className="cursor-pointer"
            onClick={() => setOpenCalendar(true)}
          />
          {openCalendar && (
            <DateTimeCalendar
              publishDate={publishDate}
              openCalendar={openCalendar}
              publishTime={publishTime}
              handleCloseCalendar={handleCloseCalendar}
              dayOfWeekFormatter={dayOfWeekFormatter}
              onSelectedDate={onSelectedDate}
              setPublishTime={setPublishTime}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          <Box>
            <Typography>Repeat every</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" spacing={2}>
            <Box
              sx={{
                width: '50%'
              }}
            >
              <TextField
                className="bg-[#eee] rounded-md w-22 text-center"
                id="outlined-number"
                type="number"
                size="small"
                variant="standard"
                inputProps={{ min: 1, style: { textAlign: 'center' } }}
                InputProps={{
                  disableUnderline: true
                }}
                value={recurringState.noOfRepetition}
                onChange={(e) =>
                  setRecurringState({
                    ...recurringState,
                    noOfRepetition: e.target.value
                  })
                }
              />
            </Box>
            <Box
              sx={{
                width: '50%'
              }}
            >
              <TextField
                className="bg-[#eee] rounded-md"
                id="outlined-number"
                select
                size="small"
                variant="standard"
                inputProps={{ style: { textAlign: 'center' } }}
                InputProps={{
                  disableUnderline: true
                }}
                SelectProps={{
                  native: true
                }}
                defaultValue={recurringState.recurringType}
                onChange={(e) =>
                  setRecurringState({
                    ...recurringState,
                    recurringType: e.target.value
                  })
                }
              >
                {RecurringType.map((type, i) => (
                  <option value={type}>{type}</option>
                ))}
              </TextField>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {recurringState.recurringType === 'Week' && (
            <Box className="flex justify-evenly">
              <Typography>Repeat on</Typography>
              {WEEK_DAYS.map((obj, i) => (
                <Box
                  key={obj.title}
                  className={
                    'rounded-full ml-1 h-6 w-6 ' +
                    (recurringState.weekDaysRepetition.includes(obj.title)
                      ? 'bg-blue'
                      : 'bg-white')
                  }
                >
                  <button
                    onClick={handleWeekDayRepetition}
                    value={obj.title}
                    className={
                      'w-full ' +
                      (recurringState.weekDaysRepetition.includes(obj.title)
                        ? 'text-white'
                        : 'text-blue')
                    }
                  >
                    {obj.symbol}
                  </button>
                </Box>
              ))}
            </Box>
          )}
        </Grid>

        <Grid item xs={1}>
          <Box>
            <Typography>Ends</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box>
            <Stack>
              <RadioGroup
                aria-labelledby="ends"
                value={recurringState.endingCondition}
                name="radio-buttons-group"
                onChange={(e) =>
                  setRecurringState({
                    ...recurringState,
                    endingCondition: e.target.value
                  })
                }
              >
                <Box>
                  <FormControlLabel
                    value={EndOnType.Never}
                    control={<Radio size="small" className="mr-1 text-blue" />}
                    label="Never"
                  />
                </Box>
                <Box
                  className="flex justify-between items-center mt-2"
                  spacing={2}
                >
                  <FormControlLabel
                    value={EndOnType.EndDate}
                    control={<Radio size="small" className="mr-1 text-blue" />}
                    label="On"
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="datepicker ml-8 bg-[#eee] rounded-md"
                      required={true}
                      id="endDate"
                      minDate={dayjs().add(1, 'day')}
                      dayOfWeekFormatter={dayOfWeekFormatter}
                      sx={{
                        width: '65%'
                      }}
                      value={dayjs(recurringState.endDate)}
                      slotProps={{
                        textField: {
                          size: 'small'
                        }
                      }}
                      onChange={(date) => {
                        const selectedDate =
                          dayjs(date).format('YYYY-MM-DD hh:mm A');
                        if (
                          dayjs(selectedDate).isAfter(publishDateTime)
                        ) {
                          setRecurringState({
                            ...recurringState,
                            endDate: selectedDate
                          });
                          setEndDateError(false);
                        } else {
                          setEndDateError(true);
                        }
                      }}
                      disabled={
                        recurringState.endingCondition !== EndOnType.EndDate
                      }
                      onError={(err) => {
                        if (err !== null) return setEndDateError(true);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  spacing={2}
                  className="flex justify-between items-center mt-2 "
                >
                  <FormControlLabel
                    value={EndOnType.OccurrenceNumber}
                    control={<Radio size="small" className="mr-1 text-blue" />}
                    label="After"
                  />
                  <TextField
                    id="outlined-number"
                    disabled={
                      recurringState.endingCondition !==
                      EndOnType.OccurrenceNumber
                    }
                    type="number"
                    inputProps={{
                      min: 1,
                      style: { textAlign: 'center', width: '44px' }
                    }}
                    className="bg-[#eee] ml-4 w-18 p-1 rounded-md outline-none"
                    value={recurringState.endAfterOccurrence}
                    onChange={(e) =>
                      setRecurringState({
                        ...recurringState,
                        endAfterOccurrence: e.target.value
                      })
                    }
                    size="small"
                    variant="standard"
                    sx={{
                      width: '65%'
                    }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          occurrences
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </RadioGroup>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      {endDateError && (
        <p className="text-red">End date should be greater than start date</p>
      )}

      <hr className="my-5" />
      <div className="flex items-center justify-between">
        <button className="text-blue underline" onClick={() => setStep(1)}>
          Back
        </button>
        <div className="flex">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
            onClick={handleSubmitSchedule}
            disabled={endDateError}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Recurringoption;

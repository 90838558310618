import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';

import loadingStore from '../loading';

const ENDPOINT = '/groups/department';

class DepartmentStore {
  state = {
    departments: [],
    memberships: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getDepartments: action,
      getDepartment: action,
      addDepartment: action,
      updateDepartment: action,
      deleteDepartment: action
    });
  }

  getDepartments = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.departments = response.data;
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getDepartment = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addDepartment = async (department) => {
    loadingStore.setLoading(true);

    try {
      //   const image = file ? await fileStore.addImage(file, "department") : null;
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, {
        ...department
        // imageId: image,
      });
      //   this.state.departments = [...this.state.departments, response.data];
      this.state.departments = [...this.state.departments, response.data];
      return 1;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.includes('already added')) {
        return 2;
      }
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateDepartment = async (data) => {
    loadingStore.setLoading(true);
    try {
      //   const image = file
      //     ? await fileStore.addImage(file, "location")
      //     : location.imageId;

      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}`,
        data
      );
      const index = this.state.departments.findIndex((c) => c.id === data.id);
      this.state.departments[index] = data;
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteDepartment = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.departments = this.state.departments.filter(
        (c) => c.id !== id
      );
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getDepartmentMemberships = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`);
      // this.state.memberships = response.data;
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addDepartmentMembership = async (data, deptId) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${deptId}`,
        data
      );
      // this.state.memberships = [...this.state.memberships, response];
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response ? error.response.code : 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateDepartmentMembership = async (data, id) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/member/${data.id}`,
        { userId: data.user_id, readOnly: data.readOnly }
      );

      // this.state.memberships = this.state.memberships.map((x) =>
      //   x.id === data.id ? data : x
      // );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteDepartmentMembership = async (id, data) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        { data }
      );

      this.state.memberships = this.state.memberships.filter(
        (x) => x.id !== id
      );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const departmentStore = new DepartmentStore();
export default departmentStore;

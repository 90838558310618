import { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { observer } from 'mobx-react';
import settingsStore from '../../store/settings/settings';
import tagStore from '../../store/tags/tag';
// import { ReactComponent as MagicIcon } from '../../assets/icons/magic.svg';
import StyleIcon from '@mui/icons-material/Style';
import TagIcon from '@mui/icons-material/Tag';

import {
  Autocomplete,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import useDebounce from '../../utils/debounce';

const addLeadingZero = (value) => {
  return value < 10 ? ('0' + value).slice(-2) : value;
};

const Tags = ({
  plainText,
  viewOnly,
  setHashTag,
  setCustomTag
}) => {
  const { getSuggestedTag } = tagStore;
  const { state: { userSettings } } = settingsStore;

  const [suggestedTags, setSuggestedTags] = useState([]);
  const [searchTags, setSearchTags] = useState('');
  const debouncedSearchTags = useDebounce(searchTags, 500);
  const [autoTagFrequency, setAutoTagFrequency] = useState({ freqUnit: null });
  const [tab, setTab] = useState(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delay, setDelay] = useState(null);

  const minutes = delay && Math.floor(delay / 60);
  const minuteStr = delay && addLeadingZero(minutes);

  const seconds = delay && Math.floor(delay % 60);
  const secondStr = delay && addLeadingZero(seconds);

  useEffect(() => {
    if (delay !== null && delay >= 0) {
      const timer = setInterval(() => {
        setDelay(delay - 1);
      }, 1000);

      if (delay === 0) {
        (async () => {
          const param = { searchToken: plainText };
          const data = await getSuggestedTag(param);
          setSuggestedTags(data);
        })();

        clearInterval(timer);
        setDelay(autoTagFrequency ? autoTagFrequency.freqUnit * 60 : null);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [delay]);

  useEffect(() => {
    if (autoTagFrequency.freqUnit == null || parseInt(autoTagFrequency.freqUnit) === 0) {
      (async () => {
        const param = { searchToken: plainText };
        const data = await getSuggestedTag(param);
        setSuggestedTags(data);
      })();
    }
  }, [autoTagFrequency, plainText]);

  useEffect(() => {
    const autoTagFq = userSettings.find(
      (x) => x.name === 'auto-tagging-frequency'
    );

    if (autoTagFq && parseInt(autoTagFq.value) > 0) {
      setAutoTagFrequency({ freqUnit: autoTagFq.value });
      setDelay(autoTagFq.value * 60);
    }
  }, [userSettings]);

  useEffect(() => {
    if (debouncedSearchTags !== null && debouncedSearchTags !== undefined) {
      let active = true;
      setLoading(true);

      (async () => {
        const param = { searchToken: debouncedSearchTags };
        const data = await getSuggestedTag(param);

        if (active) {
          setOptions(data);
        }
        setLoading(false);
      })();

      return () => {
        active = false;
      };
    }
  }, [debouncedSearchTags]);

  const handleAddTag = (tag) => {
    if (tag.category === 'HashTag') {
      setHashTag({ tag: '#' + tag.name });
    } else {
      setCustomTag(prevTags => [...prevTags, tag]);
    }
  };

  return (
    <div className="rounded w-full bg-white">
      <div className="flex justify-between items-center gap-2">
        <p className="font-bold text-lg">Tags</p>
        {/* <MagicIcon /> */}
        {autoTagFrequency.freqUnit && parseInt(autoTagFrequency.freqUnit) > 0 &&
          <span className="bg-[#e9f4f7] drop-shadow text-blue rounded p-2 flex items-center justify-between z-10">
            {minuteStr} : {secondStr}
          </span>
        }
      </div>
      <hr className="my-5" />
      {!viewOnly && (
        <>
          <Autocomplete
            sx={{ width: '100%' }}
            freeSolo
            filterOptions={(x) => x}
            loading={loading}
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              // click on drop down
              if (value && value.name) {
                setSearchTags(value.name);
                handleAddTag(value);
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > div': { mr: 2, flexShrink: 0 } }} {...props}>
                <div
                  className="w-6 h-6 shadow-lg rounded-full flex justify-center items-center text-blue font-light"
                  loading="lazy"
                  width="20"
                >
                  {option.category === 'HashTag' ? <TagIcon/> : <StyleIcon />}
                </div>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Autocomplete search"
                onChange={(e) => {
                  const inputText = e.target.value;
                  setSearchTags(inputText ?? '');
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />

          <Box className='w-full'>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
              aria-label="tags">
              <Tab label="Suggested" />
            </Tabs>
          </Box>

          {tab === 0 && (
            <div className='flex flex-col gap-2 mt-3'>
              {suggestedTags.map((tag, index) => <TagItem key={index} tag={tag} handleAddTag={handleAddTag}/>)}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default observer(Tags);

const TagItem = ({ tag, handleAddTag }) => {
  return (
    <div className='flex justify-between items-center gap-2 border-[1px] border-[#ccc] rounded-md p-3 text-sm'>
      <div className='flex items-center gap-2'>
        <div className='w-6 h-6 shadow-lg rounded-full flex justify-center items-center text-blue font-light'>
          {tag.category === 'HashTag' ? <TagIcon/> : <StyleIcon />}
        </div>
        <div>{tag.name}</div>
      </div>
      <button onClick={() => handleAddTag(tag)} className='w-6 h-6  rounded-full flex justify-center items-center text-blue font-light border-[1px] text-[22px] border-blue '> <BsPlus/>  </button>
    </div>
  );
};

import { Box, Divider, FormControl, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { BsCamera } from 'react-icons/bs';
import groupStore from '../../../store/security/group';
import userStore from '../../../store/security/user';

import ImageUpload from '../../ImageUpload';

const GroupNewForm = ({ isEdit, currentGroup, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { addGroup, updateGroup } = groupStore;
  const {
    state: { users },
    getUsers
  } = userStore;

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    groupDesc: '',
    groupType: 'System',
    imageId: null
  });

  const { name, groupDesc, groupType, imageId } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const adding = async () => {
    const data = {
      group: {
        name,
        groupDesc,
        groupType
      }
    };

    addGroup(data, selectedImage).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Group added successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a group', { variant: 'error' });
      }
    });
  };

  const editing = () => {
    const data = {
      group: {
        id: currentGroup.id,
        name,
        groupDesc,
        groupType,
        imageId: imageId || null
      }
    };
    updateGroup(data, selectedImage).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Group updated successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to update a group', { variant: 'error' });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    isEdit ? editing() : adding();
  };

  const handleRemoveImage = () => {
    setFormData((formData) => (formData = { ...formData, imageId: null }));
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handleImageUpload = (file) => {
    setSelectedImage(file);
    setShowUploadImage(false);
  };

  useEffect(() => {
    getUsers();
    if (isEdit) {
      setFormData({
        ...formData,
        name: currentGroup.name,
        groupDesc: currentGroup.group_desc,
        groupType: currentGroup.group_type
          ? currentGroup.group_type
          : groupType,
        imageId: currentGroup.imageId
      });
      currentGroup.image && setImagePreview(currentGroup.image);
    }
  }, []);

  useEffect(() => {
    selectedImage && setImagePreview(URL.createObjectURL(selectedImage));
  }, [selectedImage]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={4}>
              <Box>
                <FormControl>
                  <div
                    className={`border-2 border-blue relative ${
                      imagePreview ? 'bg-white' : 'bg-light-blue'
                    } text-blue text-lg font-bold w-32 h-32 rounded-full flex items-center justify-center`}
                  >
                    {!imagePreview && 'Add Logo'}
                    {imagePreview && (
                      <img
                        className="absolute rounded-full object-cover h-full w-full"
                        src={imagePreview}
                        alt="channel"
                      />
                    )}
                    {/* {imagePreview && (
                    <button
                      onClick={handleRemoveImage}
                      className="cursor-pointer text-white bg-red p-2 rounded-full absolute bottom-10 right-[-20px]"
                    >
                      <BsTrash />
                    </button>
                  )}
                  <label className="cursor-pointer text-white bg-blue p-2 rounded-full absolute bottom-[-5px] right-[-5px]">
                    <input
                      type="file"
                      onChange={(e) => {
                        setSelectedImage(e.target.files[0]);
                      }}
                      className="hidden"
                      accept=".jpeg, .jpg, .png, .gif"
                    />
                    <BsCamera />
                  </label> */}
                    <button
                      type="button"
                      onClick={() => setShowUploadImage(true)}
                      className="cursor-pointer text-white bg-blue p-2 rounded-full absolute bottom-[-5px] right-[-5px]"
                    >
                      <BsCamera />
                    </button>
                  </div>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControl fullWidth>
                  <label htmlFor="groupName">Group Name</label>
                  <input
                    placeholder="Type Here"
                    className="border-[1px] border-gray outline-none p-4 rounded"
                    name="name"
                    value={name}
                    onChange={onChange}
                  />
                </FormControl>
              </Stack>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControl fullWidth>
                  <label htmlFor="role">Group Type</label>
                  <select
                    className="border-[1px] border-gray outline-none p-4 rounded"
                    value={groupType}
                    onChange={(e) =>
                      setFormData(
                        (formData) =>
                          (formData = {
                            ...formData,
                            groupType: e.target.value
                          })
                      )
                    }
                  >
                    <option value="" disabled hidden>
                      Select Type
                    </option>
                    <option value="system">System</option>
                    <option value="user">User</option>
                  </select>
                </FormControl>
                <FormControl fullWidth>
                  <label htmlFor="company">Owner</label>
                  <select className="border-[1px] border-gray outline-none p-4 rounded">
                    <option value="" disabled>
                      Select User
                    </option>
                    {users.map((x, i) => (
                      <option key={i} value={x.id} className="flex">
                        {/* <img
                          src={x.picture}
                          alt='avatar'
                          className="h-10 w-10 rounded-full mr-2"
                        /> */}
                        {x.givenName} {x.familyName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <FormControl fullWidth>
                  <label htmlFor="groupDesc">Group Description</label>
                  <textarea
                    className="resize-none border-[1px] border-gray outline-none p-4 rounded"
                    placeholder="Type Here"
                    name="group_desc"
                    value={groupType}
                    onChange={onChange}
                  />
                </FormControl>
              </Stack>

              <Divider />

              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  pb: 3
                }}
              >
                <button className="mrvn-cln rounded" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="mrvn-sv rounded"
                  style={{
                    backgroundColor: '#2193B0',
                    color: 'white',
                    padding: '6px 15px'
                  }}
                >
                  {!isEdit ? 'Add Group' : 'Save Changes'}
                </button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </form>

      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}
    </>
  );
};

export default GroupNewForm;

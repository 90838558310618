const mentionRegex = /\B@(?<userId>\S+)/g;
const mentionReplaceUtil = (mentions, content, channelTypeName, url) => {
  let match;
  do {
    match = mentionRegex.exec(content);
    if (match) {
      Object.entries(mentions).forEach((entry) => {
        if (match?.groups?.userId === entry[0]) {
          const filteredChannelHandler = entry[1].channelHandlerList.filter(
            (ch) => ch.channelTypeName.toLowerCase() === channelTypeName.toLowerCase()
          );
          if (filteredChannelHandler.length) {
            const handlerName = filteredChannelHandler.map(
              (ch) =>
                '<a href="' + url +
                ch.handlerId.replace('@', '') +
                '">' +
                ch.handlerId +
                '</a>'
            );
            content =
              content.substring(0, match.index) +
              handlerName.toString() +
              content.substring(match.index + match[0].length + 1);
          } else {
            const replaceString = entry[1].firstName
              ? entry[1].firstName + ' ' + (entry[1].familyName || '')
              : entry[1].companyName;
            content = content.replace(match[0], replaceString);
          }
        }
      });
    }
  } while (match);
  return content;
};

export default mentionReplaceUtil;

import { useState, useEffect } from 'react';
import ToggleButton from '../../ToggleButton';
import settingsStore from '../../../store/settings/settings';
import { observer } from 'mobx-react';
import canI from '../../../utils/canI';

const Publishing = () => {
  const {
    state: { pubSettings },
    createSettings,
    updateSettings
  } = settingsStore;

  const [settings, setSettings] = useState([
    {
      name: 'Publication Authorisation',
      value: false
    }
  ]);

  const [isDisabled, setIsDisabled] = useState(false);

  const handleSettingUpdate = async (name, value) => {
    // check that if user can do that then only
    if (!canI('update:features')) {
      return false;
    }

    const setting = pubSettings.find((x) => x.name === name);

    if (setting) {
      updateSettings(
        {
          name: setting.name,
          type: setting.type,
          value,
          settingType: 'publishing'
        },
        setting.id
      );
    } else {
      createSettings({
        name,
        value: true,
        type: 'BOOLEAN',
        settingType: 'publishing'
      });
    }
  };

  useEffect(() => {
    const settingsMap = { authorised: 'Publication Authorisation' };
    const settings = Object.entries(settingsMap).map(([name, displayName]) => ({
      name: displayName,
      value:
      pubSettings.find((x) => x.name === displayName)?.value === 'true' ||
        false
    }));

    setSettings(settings);
  }, [pubSettings]);

  useEffect(() => {
    setIsDisabled(!canI('update:features'));
  }, []);

  return (
    <div className="px-5 bg-white rounded mt-10">
      {settings.map((x, i) => (
        <Item
          key={i}
          index={i}
          item={x}
          setSettings={setSettings}
          handleSettingUpdate={handleSettingUpdate}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  );
};

export default observer(Publishing);

const Item = ({
  item: { name, value },
  index,
  handleSettingUpdate,
  isDisabled
}) => {
  const handleToggle = () => {
    handleSettingUpdate(name, !value);
  };

  return (
    <div
      className={`flex justify-between items-center py-5 ${index > 0 && 'border-t-[1px] border-t-[#eee]'
        }`}
    >
      <p>{name}</p>
      <ToggleButton disabled={isDisabled} toggle={value} handleClick={handleToggle} />
    </div>
  );
};

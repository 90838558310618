import { ReactComponent as FacebookLike } from '../../../assets/icons/Facebook/like.svg';
import { ReactComponent as FacebookComment } from '../../../assets/icons/Facebook/comment.svg';
import { ReactComponent as FacebookShare } from '../../../assets/icons/Facebook/share.svg';
import { ReactComponent as FacebookBluetick } from '..//../../assets/icons/Facebook/bluetick.svg';
import { ReactComponent as FacebookEarth } from '../../../assets/icons/Facebook/earth.svg';
import GridlayoutPreview from './GridlayoutPreview';

function FacebookPreview ({
  plainText,
  files,
  previewData,
  user
}) {
  const handleClick = () => {
    if (previewData.socialChannelLink) {
      window.open(previewData.socialChannelLink, '_blank');
    }
  };

  return (
    <div className='bg-gray-light p-3 rounded-md'>
      <div className='flex items-center mt-3 mb-2'>
        <img
          src={previewData?.profilePicUrl ? previewData?.profilePicUrl : user?.picture}
          alt='User'
          className='w-8 h-8 rounded-full mr-2'
        />
        <div>
          <div className='flex items-center gap-1'>
            <p className='text-sm font-semibold cursor-pointer' onClick={handleClick}>{previewData?.displayName ? previewData?.displayName : user.nickname}</p>
            <FacebookBluetick className='w-4 h-4' />
          </div>
          <div className='flex text-xs gap-1'>
            <p>Posted just now</p>
            <FacebookEarth className='w-4 h-4'/>
          </div>
        </div>
      </div>

      {plainText && <p className='mt-2 mb-4 break-words'>{plainText}</p>}

      {files && files.length > 0 && (
        <>
          {files.find(file => file.contentType.startsWith('image/'))
            ? (
                <GridlayoutPreview countFrom={5} images={files.filter(file => file.contentType.startsWith('image/')).map(file => file.url)}/>
              )
            : (
                files.find(file => file.contentType.startsWith('video/'))
                  ? (
                      <video controls width='100%' className='h-[400px] object-cover'>
                        <source src={files.find(file => file.contentType.startsWith('video/')).url} type={files.find(file => file.contentType.startsWith('video/')).contentType} />
                        Your browser does not support the video tag.
                      </video>)
                  : <></>
              )}
        </>
      )}
      <hr className="my-5 border-[#eee]" />
      <div className='flex items-center gap-2 justify-between'>
        <div className='flex items-center gap-2 justify-between'>
          <FacebookLike />
          <p>Like</p>
        </div>
        <div className='flex items-center gap-2 justify-between'>
          <FacebookComment />
          <p>Comment</p>
        </div>
        <div className='flex items-center gap-2 justify-between'>
          <FacebookShare />
          <p>Share</p>
        </div>
      </div>
    </div>
  );
}

export default FacebookPreview;

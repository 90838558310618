import { useEffect, useState } from 'react';
import ToggleButton from '../../ToggleButton';
import { TfiClipboard, TfiComment, TfiEmail } from 'react-icons/tfi';
import notificationStore from '../../../store/settings/notification';
import { observer } from 'mobx-react';

function NotificationConfig () {
  const {
    state: { notificationConfig },
    getNotificationConfig,
    createNotificationConfig,
    updateNotificationConfig
  } = notificationStore;

  const [settings, setSettings] = useState([
    {
      name: 'PUBLISH_POST',
      description: 'Publish Post',
      post: false,
      email: false
    },
    {
      name: 'DELETE_POST',
      description: 'Delete Post',
      post: false,
      email: false
    },
    {
      name: 'AUTHORIZATION_UPDATE',
      description: 'Authorization Update',
      post: false,
      email: false
    },
    {
      name: 'SCHEDULE_ARTICLE',
      description: 'Schedule Article',
      post: false,
      email: false
    },
    {
      name: 'INSTANCE_TOKEN_EXPIRING',
      description: 'Token Expiring',
      post: false,
      email: false
    },
    {
      name: 'INSTANCE_TOKEN_EXPIRED',
      description: 'Token Expired',
      post: false,
      email: false
    },
    {
      name: 'INSTANCE_CONNECTED',
      description: 'Instance Connected',
      post: false,
      email: false
    }
  ]);

  const handleSettingUpdate = async (name, description, type, status) => {
    const setting = notificationConfig.find((x) => x.name === name);
    if (setting) {
      updateNotificationConfig(
        {
          name,
          description,
          post: type === 'POST' ? status : setting.post,
          email: type === 'EMAIL' ? status : setting.email
        },
        name, type, status);
    } else {
      createNotificationConfig(name, type, status);
    }
  };

  useEffect(() => {
    getNotificationConfig();
  }, []);

  useEffect(() => {
    const settingsMap = {
      PUBLISH_POST: 'Publish Post',
      DELETE_POST: 'Delete Post',
      AUTHORIZATION_UPDATE: 'Authorization Update',
      SCHEDULE_ARTICLE: 'Schedule Article',
      INSTANCE_TOKEN_EXPIRING: 'Token Expiring',
      INSTANCE_TOKEN_EXPIRED: 'Token Expired',
      INSTANCE_CONNECTED: 'Instance Connected'
    };

    const tempSettings = Object.entries(settingsMap).map(([name, description]) => {
      return {
        name,
        description,
        post: notificationConfig.find((x) => x.name === name)?.post,
        email: notificationConfig.find((x) => x.name === name)?.email
      };
    });
    setSettings(tempSettings);
  }, [notificationConfig]);

  return (
        <div className="px-5 bg-white rounded">
            {settings.map((x, i) => (
                <Item
                    key={i}
                    index={i}
                    item={x}
                    setSettings={setSettings}
                    handleSettingUpdate={handleSettingUpdate}
                />
            ))}
        </div>
  );
}

export default observer(NotificationConfig);

const Item = ({
  item: { name, description, post, email },
  index,
  handleSettingUpdate
}) => {
  const handleToggle = (type, status) => {
    handleSettingUpdate(name, description, type, !status);
  };

  return (
        <div>
            <div className={`${index > 0 && 'border-t-[1px] border-t-[#eee]'}`}>
                <div className="flex flex-row mt-5">
                    <TfiComment className="mr-3 w-5 h-8" />
                    <div className="flex flex-column mb-5">
                        <p className='text-lg'> {description}</p>
                        <p className='text-sm text-muted'>Push, Email</p>
                        <div className={'flex items-center py-3'}>
                            <div className="flex flex-row">
                                <TfiClipboard className="mr-2 w-4 h-6" />
                                <div className="flex flex-row">
                                    <p className="mr-10"> Push</p>
                                    <ToggleButton toggle={post} handleClick={() => handleToggle('POST', post)} />
                                </div>
                            </div>
                        </div>
                        <div className={'flex items-center py-3'}>
                            <div className="flex flex-row">
                                <TfiEmail className="mr-2 w-4 h-6" />
                                <div className="flex flex-row">
                                    <p className="mr-10"> Email</p>
                                    <ToggleButton toggle={email} handleClick={() => handleToggle('EMAIL', email)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import UserProfile from './UserAvatar/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';

const QuickAccess = ({ onClose, openSidebar, openSidebarMenu }) => {
  const { user } = useAuth0();

  return (
    <div className='h-screen overflow-scroll scrollbar-hide pl-0 flex flex-col gap-1'>
      {openSidebarMenu === 'user-profile' && (
        <motion.div className="w-full rounded-lg p-5">
          <UserProfile isOpen={openSidebar} onClose={onClose} user={user}></UserProfile>
        </motion.div>
      )}
    </div>
  );
};

export default observer(QuickAccess);

import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './components/Layout/Loader';
import { observer } from 'mobx-react';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import settingsStore from './store/settings/settings';
import initFontAwesome from './utils/initFontAwesome';
import Layout from './layout/layout';
import ThemeConfig from './theme';
import routes from './routes';
import { AddUserChannelButton } from './components/AddButton';
import { messaging } from './firebase';
import { getToken } from 'firebase/messaging';
import { Grow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { configureAxiosClient } from './utils/axios';
import { useEffect, useState } from 'react';
import Auth from './pages/Auth';
import loadingStore from './store/loading';
import ReactGA from 'react-ga4';
import { initAmplitude, initAmplitudeWithUser } from './amplitude';
import usersStore from './store/settings/user';
import LicenseCheck from './components/Profile/LicenseCheck';

const WEB_PUSH_CERTIFICATE_KEY = process.env.REACT_APP_WEB_PUSH_CERTIFICATE_KEY;
const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

initFontAwesome();

if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
}

const App = () => {
  const location = useLocation();
  const { fetchLicense } = usersStore;
  const backgroundLocation = location.state?.backgroundLocation;
  const [license, setLicense] = useState(null);
  const { fetchSettings, storeDeviceToken } = settingsStore;
  const { isloading } = loadingStore;
  const { isLoading, error, isAuthenticated, getAccessTokenSilently, logout, user } = useAuth0();
  const [isLicenseStatusLoading, setIsLicenseStatusLoading] = useState(true);

  async function requestPermissionForNotification () {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: WEB_PUSH_CERTIFICATE_KEY });
      localStorage.setItem('deviceToken', token);
      storeDeviceToken(token);
    }
  }

  useEffect(() => {
    ReactGA.send({
      hintType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Share with marvin'
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (user.email) {
        initAmplitudeWithUser(user.email);
      } else {
        initAmplitude();
      }
      configureAxiosClient({
        responseInterceptorSuccess: (response) => response,
        responseInterceptorError: async (error) => {
          if (error.response.status === 401) {
            logout();
          }
          return Promise.reject(error);
        }
      }, getAccessTokenSilently);
      fetchLicense().then((data) => {
        setLicense(data);
        setIsLicenseStatusLoading(false);
      });
      fetchSettings();
      requestPermissionForNotification();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <>
      <SnackbarProvider
        maxSnack={4}
        action={(snackbarId) => (
          <CloseIcon
            variant="outlined"
            style={{ color: 'white' }}
            onClick={() => closeSnackbar(snackbarId)}
          />
        )}
        autoHideDuration={3000}
        TransitionComponent={Grow}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {(isloading || isLoading) && <Loader />}
        {!isLoading && isAuthenticated && (
          <ThemeConfig>
            <Layout>
              <Routes location={backgroundLocation || location}>
                {routes.map((r, i) => (
                  <Route key={i} path={r.path}
                  element={!isLicenseStatusLoading && (license?.status ? <r.element /> : <LicenseCheck/>)}
                  />
                ))}
              </Routes>
              {backgroundLocation && (
                <Routes>
                  <Route
                    path={'channels/add-channel'}
                    element={!isLicenseStatusLoading && (license?.status ? <AddUserChannelButton /> : <LicenseCheck/>)}
                  />
                </Routes>
              )}
            </Layout>
          </ThemeConfig>
        )}
        {(!isLoading && !isAuthenticated) &&
          <ThemeConfig>
            <Auth />
          </ThemeConfig>
        }
      </SnackbarProvider>
    </>
  );
};

export default observer(App);

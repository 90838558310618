/* eslint-disable react/jsx-no-target-blank */
import { observer } from 'mobx-react';

const PrivacyPolicy = () => {
  return (
    <div className='m-10 text-justify'>
      <span className='text-base'>
          This privacy notice explains how ShareWithMarvin collects, uses, processes, discloses, retains, and protects personal information.
      </span>
      <br></br>
      <span className='mt-5 underline text-lg text-blue'>
        <a href='https://sharewithmarvin.io/privacy-policy/' target='_blank'>Read Share With Marvin Privacy Policy</a>
      </span>
    </div>
  );
};
export default observer(PrivacyPolicy);

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox
} from '@mui/material';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { icons } from '../../../constants/channels';
import { TfiWorld } from 'react-icons/tfi';
import { observer } from 'mobx-react';

const MyTable = ({ selectedRows, setSelectedRows, channelTypes }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleRowClick = (index) => {
    const updatedExpandedRows = [...expandedRows];
    if (updatedExpandedRows.includes(index)) {
      updatedExpandedRows.splice(updatedExpandedRows.indexOf(index), 1);
    } else {
      updatedExpandedRows.push(index);
    }
    setExpandedRows(updatedExpandedRows);
  };

  const handleCheckboxClick = (index) => {
    const selectedIndex = selectedRows.indexOf(index);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, index);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
  };

  const isSelected = (index) => selectedRows.includes(index);

  const handleSubRowCheckboxClick = (index, subRowIndex) => {
    const updatedSelectedRows = [...selectedRows];
    const subRowId = `${index}-${subRowIndex}`;

    if (updatedSelectedRows.includes(subRowId)) {
      updatedSelectedRows.splice(updatedSelectedRows.indexOf(subRowId), 1);
    } else {
      updatedSelectedRows.push(subRowId);
    }

    setSelectedRows(updatedSelectedRows);
  };

  const isSubRowSelected = (index, subRowIndex) => {
    const subRowId = `${index}-${subRowIndex}`;
    return selectedRows.includes(subRowId);
  };

  const handleColumnHeaderClick = (column) => {
    if (column === sortColumn) {
      // If the same column header is clicked, toggle the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If a new column header is clicked, set it as the sort column with ascending sort direction
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const getSortedData = () => {
    if (sortColumn) {
      const sortedData = [...channelTypes].sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
      return sortedData;
    }
    return channelTypes;
  };

  const sortedData = getSortedData();

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="bg-[#eee]">
              <TableCell>
                <Checkbox
                  checked={selectedRows.length === channelTypes.length}
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < channelTypes.length
                  }
                  onChange={() => {
                    if (selectedRows.length === channelTypes.length) {
                      setSelectedRows([]);
                    } else {
                      setSelectedRows([...Array(channelTypes.length).keys()]);
                    }
                  }}
                />
              </TableCell>
              <TableCell onClick={() => handleColumnHeaderClick('name')}>
                Channels
                {sortColumn === 'name' && (
                  <span>{sortDirection === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <TableRow
                  onClick={() => handleRowClick(rowIndex)}
                  selected={isSelected(rowIndex)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(rowIndex)}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCheckboxClick(rowIndex);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <p className="flex items-center">
                      <span
                        style={{
                          color: icons.find((c) => c.name === row.name)
                            ? icons.find((c) => c.name === row.name).color
                            : '#eee'
                        }}
                        className="mr-2"
                      >
                        {icons.find((c) => c.name === row.name)
                          ? (
                              icons.find((c) => c.name === row.name).icon
                            )
                          : (
                          <TfiWorld />
                            )}
                      </span>{' '}
                      {row.name}
                      {expandedRows.includes(rowIndex)
                        ? (
                        <FaChevronUp className="text-[#ccc] ml-2" />
                          )
                        : (
                        <FaChevronDown className="text-[#ccc] ml-2" />
                          )}
                    </p>
                  </TableCell>
                </TableRow>
                {expandedRows.includes(rowIndex) && (
                  <>
                    {row.instances && row.instances.map((instance, subRowIndex) => (
                      <TableRow key={subRowIndex}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSubRowSelected(rowIndex, subRowIndex)}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleSubRowCheckboxClick(rowIndex, subRowIndex);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="ml-10">
                            <p className="flex items-center">
                              <span
                                style={{
                                  color: icons.find((c) => c.name === row.name)
                                    ? icons.find((c) => c.name === row.name)
                                      .color
                                    : '#eee'
                                }}
                                className="mr-2"
                              >
                                {icons.find((c) => c.name === row.name)
                                  ? (
                                      icons.find((c) => c.name === row.name).icon
                                    )
                                  : (
                                  <TfiWorld />
                                    )}
                              </span>{' '}
                              {instance.name}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default observer(MyTable);

import { useState } from 'react';

import Menu from '../../components/Settings/Features/Menu';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Organisation from '../../components/Settings/Features/Organisation';
import Publishing from '../../components/Settings/Features/Publishing';
import SystemChannels from '../../components/Settings/Features/SystemChannels';
import { Container } from '@mui/material';

const Features = () => {
  const [selected, setSelected] = useState('Channels');

  return (
    <div>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Features" />
        <Menu selected={selected} setSelected={setSelected} />
        {selected === 'Channels' && <SystemChannels />}
        {selected === 'Organisation' && <Organisation />}
        {selected === 'Publishing' && <Publishing />}
      </Container>
    </div>
  );
};

export default Features;

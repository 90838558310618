import { useEffect, useState } from 'react';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import { BsCamera } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
import ImageUpload from '../../ImageUpload';
import ChannelMetadata from './ChannelMetadata';

const ChannelDetails = ({
  channelType,
  handleClose,
  isEdit,
  channeInstance,
  setStep,
  name,
  setName,
  imagePreview,
  setImagePreview,
  selectedImage,
  setSelectedImage,
  imageId,
  setImageId,
  handleRemoveImage,
  handleAddChannel,
  handleUpdateChannel,
  addMetaData,
  setAddMetaData,
  metadataList,
  setMetadataList
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showUploadImage, setShowUploadImage] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === '') {
      enqueueSnackbar('Please enter the channel name', { variant: 'error' });
      return;
    }

    let isError = false;
    let optionsError = false;

    metadataList.forEach((metadata) => {
      if (metadata.name === '' || metadata.description === '' || metadata.parameterType === '') {
        isError = true;
        return;
      }

      if (metadata.parameterType === 'TIMESTAMP' && metadata.defaultValue === 'invalid_date') {
        isError = true;
        return;
      }

      if ((metadata.parameterType === 'OPTIONS' || metadata.parameterType === 'MULTI_OPTIONS') &&
          (metadata.optionValue == null || metadata.optionValue.trim() === '')) {
        optionsError = true;
        return;
      }

      if (metadata.parameterType === 'BOOLEAN' && metadata.defaultValue === '') {
        metadata.defaultValue = false;
      }
    });

    if (isError) {
      enqueueSnackbar(
        'Please enter the channel metadata name, description and type',
        {
          variant: 'error'
        }
      );
      return;
    }

    if (optionsError) {
      enqueueSnackbar(
        'Please enter the option value',
        {
          variant: 'error'
        }
      );
      return;
    }

    isEdit || channeInstance?.id != null
      ? handleUpdateChannel()
      : handleAddChannel();
    setStep(3);
  };

  const handleImageUpload = (file) => {
    setSelectedImage(file);
    setShowUploadImage(false);
  };

  useEffect(() => {
    if (isEdit) {
      channeInstance?.imageId && setImageId(imageId);
      channeInstance?.image && setImagePreview(channeInstance.image);
    }
  }, []);

  useEffect(() => {
    selectedImage && setImagePreview(URL.createObjectURL(selectedImage));
  }, [selectedImage]);

  return (
    <>
      <form className="mx-auto">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Customize your channel</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <FormControl className="control">
              <div
                className={`rounded-full ${
                  imagePreview ? 'bg-white' : 'bg-light-blue'
                } text-blue text-lg font-bold w-32 h-32`}
              >
                {imagePreview && (
                  <img
                    className="absolute rounded-full object-cover h-full w-full"
                    src={imagePreview}
                    alt="channel"
                  />
                )}
                {!imagePreview && (
                  <img
                    className="absolute rounded-full object-cover h-full w-full"
                    src={process.env.REACT_APP_PLACEHOLDER_IMAGE}
                    alt="channel"
                  />
                )}

                <button
                  type="button"
                  onClick={() => setShowUploadImage(true)}
                  className="cursor-pointer text-white bg-blue p-2 rounded-full absolute top-[5px] right-[-5px]"
                >
                  <BsCamera />
                </button>
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>Channel Name</Typography>
          </Grid>
          <FormControl fullWidth>
            <input
              className="border-[1px] border-gray outline-none p-4 rounded"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <ChannelMetadata
            metadataList={metadataList}
            setMetadataList={setMetadataList}
            channelType={channelType}
            addMetaData={addMetaData}
            setAddMetaData={setAddMetaData}
          />
        </Grid>
      </form>
      <Box sx={{ typography: 'subtitle2', fontWeight: 'light', fontSize: 10 }}>
        *Maximum File Size: 1MB
      </Box>
      <hr className="my-5" />
      <div className="flex items-center justify-between">
        <button
          type="button"
          onClick={() => setStep(1)}
          className="text-blue underline"
          disabled={isEdit}
        >
          Back
        </button>{' '}
        <div className="flex justify-end">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className="mb-4 mrvn-sv rounded"
          >
            {isEdit || channeInstance?.id != null
              ? 'UPDATE CHANNEL'
              : 'CREATE CHANNEL'}
          </button>
        </div>
      </div>
      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}
    </>
  );
};

export default ChannelDetails;

import { useRef, useEffect, useState } from 'react';
import * as Quill from 'quill';
import * as Y from 'yjs';
import { yCursorPlugin } from 'y-prosemirror';
import { QuillBinding } from 'y-quill';
import { WebsocketProvider } from 'y-websocket';
import { createMutex } from 'lib0/mutex';
import { useAuth0 } from '@auth0/auth0-react';
import QuillCursors from 'quill-cursors';
import contentStore from '../../store/content/content';
import { useSnackbar } from 'notistack';
import * as Emoji from 'quill-emoji';
import Delta from 'quill-delta';
import 'quill-emoji/dist/quill-emoji.css';
import channelsStore from '../../store/channel/channel';
import { observer } from 'mobx-react';
import emojiMap from '../../utils/emoji/emoji-map';
import fileStore from '../../store/file';
import QuillMention from 'quill-mention';
import directoryStore from '../../store/directoryHandler/directoryHandler';

Quill.register('modules/cursors', QuillCursors);
Quill.register('modules/emoji', Emoji);
Quill.register('modules/mentions', QuillMention);

const Channel = {
  WEBHOOK: 'Webhook',
  SLACK: 'Slack'
};

const CollabEditor = ({
  docName,
  endpoint,
  setText,
  setWords,
  setCharacters,
  isEditing,
  setPlainText,
  words,
  characters,
  viewOnly,
  selectedChannels,
  setEmojis,
  hashTag,
  validateShortenerUrl,
  setHtmlContent,
  setMentions,
  selectedAiContent,
  setSelectedAiContent
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { generatePublicURL } = contentStore;
  const { state: { allChannels } } = channelsStore;
  const { setAttachment, removeAttachmentByURL, setInlineAttachment, getFileMediaInfo, createShortenerUrl } = fileStore;
  const inlineAttachmentRef = useRef([]);

  const editorRef = useRef(null);
  const yDocRef = useRef(null);
  const yProviderRef = useRef(null);
  const yBindingRef = useRef(null);
  const undoManagerRef = useRef(null);
  const editor = useRef(null);
  let yDoc = new Y.Doc();
  const { user } = useAuth0();
  const [channelType, setchannelType] = useState({
    isWebhook: false,
    isSlack: false
  });

  const { searchContactDirectory } = directoryStore;

  const embedFileInEditor = async (file, type) => {
    const fileInfo = await getFileMediaInfo(file, type);

    const fileRequest = {
      filename: file.name,
      extension: file.name.split('.').pop(),
      contentType: file.type,
      fileSize: file.size,
      fileWidth: parseInt(fileInfo.Width),
      fileHeight: parseInt(fileInfo.Height),
      aspectRatio: fileInfo.aspectRatio,
      frameRate: fileInfo.FrameRate ? parseFloat(fileInfo.FrameRate).toFixed(2) : null,
      bitRate: fileInfo.BitRate ? parseFloat(fileInfo?.BitRate).toFixed(2) : null,
      orientation: fileInfo.orientation,
      duration: fileInfo.Duration ? parseFloat(fileInfo.Duration).toFixed(2) : null,
      isInline: true
    };

    generatePublicURL(fileRequest, file, user).then((res) => {
      setAttachment([
        {
          id: res.id,
          filename: res.filename,
          extension: file.name.split('.').pop(),
          contentType: res.contentType,
          fileSize: file.size,
          url: res.url,
          fileWidth: parseInt(fileInfo?.Width),
          fileHeight: parseInt(fileInfo?.Height),
          aspectRatio: fileInfo?.aspectRatio,
          frameRate: fileInfo?.FrameRate ? parseFloat(fileInfo?.FrameRate).toFixed(2) : null,
          bitRate: fileInfo?.BitRate ? parseFloat(fileInfo?.BitRate).toFixed(2) : null,
          orientation: fileInfo?.orientation,
          duration: fileInfo?.Duration ? parseFloat(fileInfo?.Duration).toFixed(2) : null,
          isInline: true
        }]);
      setInlineAttachment([{
        id: res.id,
        filename: res.filename,
        extension: file.name.split('.').pop(),
        contentType: res.contentType,
        fileSize: file.size,
        url: res.url,
        fileWidth: parseInt(fileInfo?.Width),
        fileHeight: parseInt(fileInfo?.Height),
        aspectRatio: fileInfo?.aspectRatio,
        frameRate: fileInfo?.FrameRate ? parseFloat(fileInfo?.FrameRate).toFixed(2) : null,
        bitRate: fileInfo?.BitRate ? parseFloat(fileInfo?.BitRate).toFixed(2) : null,
        orientation: fileInfo?.orientation,
        duration: fileInfo?.Duration ? parseFloat(fileInfo?.Duration).toFixed(2) : null,
        isInline: true
      }]);

      inlineAttachmentRef.current.push(res.url);
      const quillEditor = editor.current;
      quillEditor.focus();
      const range = quillEditor.getSelection();
      const position = range ? range.index : 0;

      quillEditor.insertEmbed(position, type, res.url);
      quillEditor.setSelection(position + 1);
    });
  };

  const dropHandler = (e) => {
    e.preventDefault();

    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach((item, i) => {
        const file = item.getAsFile();
        if (item.type.split('/')[0] === 'video') {
          embedFileInEditor(file, 'video');
        }
        if (item.type.split('/')[0] === 'image') {
          embedFileInEditor(file, 'image');
        }
      });
    }
  };

  useEffect(() => {
    handleValidateShorternUrl();
  }, [validateShortenerUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValidateShorternUrl = async () => {
    const delta = editor.current?.getContents();
    if (delta) {
      delta.forEach(part => {
        if (typeof part.insert === 'string') {
          const insertSplitedValue = part.insert.replace(/\n/g, ' ').split(' ');
          insertSplitedValue.forEach(async element => {
            if (element.startsWith('http') || element.startsWith('https') || element.startsWith('www')) {
              const valueToBeReplaced = element.trim();
              const isValidUrl = shortenerUrl(valueToBeReplaced);
              if (isValidUrl) {
                const valueToBeingReplaced = await createShortenerUrl(valueToBeReplaced);
                part.insert = part.insert.replace(valueToBeReplaced, valueToBeingReplaced);
                if (part.attributes?.link) {
                  part.attributes.link = valueToBeingReplaced;
                }
                editor.current.setContents(delta);
              }
            }
          });
        }
      });
    }
  };

  const shortenerUrl = (text) => {
    try {
      return new URL(text);
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    if (selectedChannels) {
      const channelFilter = allChannels.filter(
        (x) => selectedChannels?.includes(parseInt(x.id))
      ).map(channel => channel.channelType);

      const isWebhook = channelFilter.length && channelFilter.every(channelType => channelType.channelName === Channel.WEBHOOK);
      let isSlack = channelFilter.length && channelFilter.every(channelType => channelType.channelName === Channel.SLACK);
      const isBoth = channelFilter.length && channelFilter.every(channelType => channelType.channelName === Channel.SLACK || channelType.channelName === Channel.WEBHOOK);
      if (isBoth) {
        isSlack = true;
      }

      setchannelType({ ...channelType, isWebhook, isSlack });
    }
  }, [selectedChannels]);

  useEffect(() => {
    const toolbarOptions = channelType.isWebhook || channelType.isSlack
      ? {
          container: [
            [{ list: 'ordered' },
              { list: 'bullet' }],
            channelType.isWebhook ? [{ header: '1' }, { header: '2' }] : [],
            channelType.isWebhook || channelType.isSlack ? ['bold', 'italic', 'underline', 'strike'] : [],
            channelType.isWebhook
              ? [
                  { script: 'super' },
                  { script: 'sub' }]
              : []
          ]
        }
      : null;

    let yMutex = null;
    let ySync = null;
    let yCursor = null;

    const initializeEditor = () => {
      // Initialize the Quill Editor
      editor.current = new Quill(editorRef.current, {
        modules: {
          cursors: true,
          toolbar: toolbarOptions,
          'emoji-textarea': true,
          mention: {
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            positioningStrategy: 'fixed',
            dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'disabled', 'data'],
            source: async function (searchTerm, renderList) {
              let { content } = await searchContactDirectory({ searchText: searchTerm });
              content = content.map(c => ({ id: c.id, value: c.userId, data: JSON.stringify(c) }));
              renderList(content);
            },
            onSelect: function (item, insertedItem) {
              setMentions(prevState => {
                if (prevState[item.value]) {
                  return ({ ...prevState });
                } else {
                  return ({ ...prevState, [item.value]: JSON.parse(item.data) });
                }
              });
              insertedItem(item);
            }
          }
        },
        placeholder: 'Description |',
        readOnly: viewOnly,
        theme: 'snow'
      });

      if (yDocRef.current) {
        yDoc = yDocRef.current;
      }

      yDocRef.current = yDoc;
      yMutex = createMutex();
      // ySync = new ySyncPlugin(yDoc);
      // eslint-disable-next-line new-cap
      yCursor = new yCursorPlugin(yDoc);

      // editor.updateContents(yDoc.getText('articleDocument').toDelta());

      // Set up yjs provider if in editing mode
      if (isEditing) {
        yProviderRef.current = new WebsocketProvider(endpoint, docName, yDoc);
        // OR use WebrtcProvider for peer-to-peer connections:
        // yProviderRef.current = new WebrtcProvider(docName, yDoc);
      }

      // Set up yjs binding
      yBindingRef.current = new QuillBinding(
        yDoc.getText(docName),
        editor.current,
        yProviderRef.current?.awareness,
        yMutex
      );

      // Set user information for awareness
      yProviderRef.current?.awareness.setLocalStateField('user', {
        name: user.name,
        color: [
          'blue',
          'red',
          'yellow',
          'green',
          'orange',
          'magenta',
          'cyan',
          'pink',
          'gray'
        ][Math.floor(Math.random() * 9)]
      });

      // Initialize undo/redo manager
      undoManagerRef.current = editor.history;

      const getCount = () => {
        const plainText = editor.current ? editor.current.root.textContent.trim() : '';
        setPlainText(plainText);
        setWords(plainText.length > 0 ? plainText.split(' ').length : 0);
        const contents = editor.current ? editor.current.getContents() : null;
        let characterCount = 0;
        if (contents) {
          contents.ops.forEach(op => {
            if (op.insert) {
              if (typeof op.insert === 'string') {
                characterCount += op.insert.length;
              } else if (op.insert.image) {
                characterCount += 1; // Count each image as one character
              } else if (op.insert.emoji) {
                characterCount += 1; // Count each emoji as one character
              }
            }
          });
        }
        setCharacters(characterCount);
      };

      // Add the onChange listener to the editor
      editor.current.on('text-change', function (delta, oldDelta, source) {
        const content = {};
        const { editorContent } = handleEmoji(editor.current.getContents());
        content.ops = editorContent;
        handleRemoveInlineAttachment(editor.current.getContents(), oldDelta);
        setText(JSON.stringify(content));
        setHtmlContent(editor.current.root.innerHTML);
        getCount();
      });

      editorRef.current.focus();
    };

    const handleEmoji = (content) => {
      const emojis = [];
      let emojisCharCount = 0;

      const editorContent = content.ops.map((operation) => {
        if (typeof operation.insert === 'object' && 'emoji' in operation.insert) {
          const s = emojiMap[operation.insert.emoji]?.unicode.split('-').map(str => parseInt(str, 16));
          const emoji = String.fromCodePoint(...s);
          emojis.push(emoji);
          emojisCharCount += emoji.length;
          return { insert: emoji };
        }
        return operation;
      });
      setEmojis(emojis);
      return { editorContent, emojisCharCount };
    };

    const handleRemoveInlineAttachment = (content, oldDelta) => {
      content.diff(oldDelta).ops.map((operation) => {
        if (typeof operation.insert === 'object' && ('image' in operation.insert || 'video' in operation.insert)) {
          if (operation.insert?.image) {
            removeAttachmentByURL(operation.insert.image);
            inlineAttachmentRef.current = inlineAttachmentRef.current.filter(attachment => attachment !== operation.insert.image);
          }
          if (operation.insert?.video) {
            removeAttachmentByURL(operation.insert.video);
            inlineAttachmentRef.current = inlineAttachmentRef.current.filter(attachment => attachment !== operation.insert.video);
          }
        }
        return operation;
      });
    };

    const cleanupEditor = () => {
      // Cleanup previous editor before initializing a new one
      if (editor.current) {
        editor.current.off('text-change');
        editor.current = null;

        /* const newEditorElement = document.createElement("div");
        newEditorElement.id = "editor";

        if (editorRef.current != null) {
          const editorContainer = editorRef.current.parentNode;
          editorContainer.replaceChild(newEditorElement, editorRef.current);
        }

        editorRef.current = newEditorElement; */
        const toolbar = document.querySelector('.ql-toolbar');
        if (toolbar) {
          toolbar.remove(); // Remove the toolbar element
        }
      }

      if (yBindingRef.current) {
        yBindingRef.current.destroy();
        yBindingRef.current = null;
      }

      if (yProviderRef.current && yProviderRef.current.readyState === 1) {
        yProviderRef.current.disconnect();
        yProviderRef.current = null;
      }

      if (yMutex) {
        // yMutex.destroy();
        yMutex = null;
      }

      if (ySync) {
        // ySync.destroy();
        ySync = null;
      }

      if (yCursor) {
        // yCursor.destroy();
        yCursor = null;
      }

      // openVideoRef.current = null;

      if (docName !== 'null') {
        yDocRef.current = null;
      }
    };

    // Cleanup previous editor before initializing a new one
    cleanupEditor();

    // Initialize the editor
    initializeEditor();

    const handlePaste = (event) => {
      const plainText = event.clipboardData.getData('text/plain');
      let json;
      try {
        json = JSON.parse(plainText);
      } catch (error) {
        // Ignore further processing if JSON parsing fails
        return;
      }
      const delta = new Delta(JSON.parse(json.richText.delta).ops);

      const currentDelta = editor.current.getContents();

      if (json.category === 'BODYINTRO') {
        editor.current.setContents(delta.concat(currentDelta));
      }
      if (json.category === 'BODYCLOSING') {
        editor.current.setContents(currentDelta.concat(delta));
      }
      if (json.category === 'BODYCOMPLIANCE') {
        editor.current.setContents(currentDelta.concat(delta));
      }
      if (json.category === 'TITLE') {
        enqueueSnackbar('This phrase is for title only', {
          variant: 'warning'
        });
      }
      event.preventDefault();
    };

    const editorElement = editorRef.current;
    setHtmlContent(editor.current?.root?.innerHTML);
    editorElement.addEventListener('paste', handlePaste);

    return () => {
      editorElement.removeEventListener('paste', handlePaste);
      cleanupEditor();
    };
  }, [docName, endpoint, isEditing, viewOnly, channelType]);

  useEffect(() => {
    contentStore.setYDoc(yDocRef.current);
  }, [yDoc]);

  useEffect(() => {
    // Insert hashtag at the end of editor content.
    if (editor.current && hashTag.tag) {
      const newHashTag = ' ' + hashTag.tag;
      const text = editor.current.getText(docName);
      editor.current.insertText(text.length - 1, newHashTag);
    }
  }, [hashTag]);

  useEffect(() => {
    // replace ai content with original editor
    if (editor.current && selectedAiContent !== '') {
      editor.current.deleteText(0, editor.current.getLength());
      editor.current.insertText(0, selectedAiContent);
      setSelectedAiContent('');
    }
  }, [selectedAiContent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        const mentionContainers = document.querySelectorAll('.ql-mention-list-container');
        mentionContainers.forEach(container => {
          container.style.display = 'none';
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('beforeunload', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('beforeunload', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={editorRef} onDrop={dropHandler} />
      <div className="flex gap-2 mb-3 justify-end mr-2 text-xs">
        <p>{characters} characters</p>
        <p>{words} words</p>
      </div>
    </>
  );
};

export default observer(CollabEditor);

import { useState } from 'react';
import { RxClipboardCopy } from 'react-icons/rx';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const FavouriteItem = ({
  phrase,
  phrase: { id, text, name }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  const handleCopy = () => {
    enqueueSnackbar('Copied!', { variant: 'default' });
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative bg-[#f2f2f2] rounded w-full px-3 py-2 flex justify-between items-center"
    >
      {hovered && (
        <p className="absolute z-20 top-10 bg-black rounded px-3 py-1 text-sm text-white">
          {text}
        </p>
      )}
      <p>{name}</p>
      <div className="flex gap-2">
        <CopyToClipboard text={JSON.stringify(phrase)} onCopy={handleCopy}>
          <button className="bg-light-blue text-blue rounded-full p-2 hover:bg-light-blue">
            <RxClipboardCopy />
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default FavouriteItem;

import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineIdcard,
  AiOutlineMore
} from 'react-icons/ai';
import canI from '../../utils/canI';
import DeleteConfirmationModal from '../Modal/Delete';
import ProjectNewForm from '../Modal/Organisation/ProjectNewForm';

const ProjectMoreMenu = ({ onDelete, row, onMembership }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDeleteClick = () => {
    handleClose(false);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleEditClick = () => {
    handleClose(false);
    setIsEditOpen(true);
  };

  const handleMenuOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMembership = () => {
    handleClose();
    onMembership();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="relative">
        {canI(['update:organisations', 'delete:organisations']) && (
          <>
            <Button
              onClick={handleMenuOpenClick}
              aria-controls={open ? 'more-menu-options' : undefined}
              className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
            >
              <IconContext.Provider value={{ className: 'text-xl' }}>
                <AiOutlineMore />
              </IconContext.Provider>
            </Button>
            <Menu
              id="more-menu-options"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {canI(['update:organisations']) && (
                <MenuItem
                  onClick={() => handleEditClick(row)}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillEdit className="text-green mr-2" />
                  Edit
                </MenuItem>
              )}
              <MenuItem
                onClick={handleMembership}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
              >
                <AiOutlineIdcard className="text-blue mr-2" />
                Membership
              </MenuItem>
              {canI(['delete:organisations']) && (
                <MenuItem
                  onClick={handleDeleteClick}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillDelete className="text-red mr-2" />
                  Delete
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>

      <DeleteConfirmationModal
        open={showDeleteModal}
        onDelete={onDelete}
        del={['Project']}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
      />

      {isEditOpen && (
        <ProjectNewForm
          open={true}
          handleClose={() => setIsEditOpen(false)}
          isEdit={true}
          currentProject={row}
        />
      )}
    </>
  );
};

export default ProjectMoreMenu;

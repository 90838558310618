import { Button, Menu, MenuItem, Modal } from '@mui/material';
import { useState } from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineMore } from 'react-icons/ai';
import canI from '../../../utils/canI';
import DeleteConfirmationModal from '../../Modal/Delete';
import AddPhrase from '../../Modal/ToolsandStyling/AddPhrase';

export default function PhraseMoreMenu ({ onDelete, row, type }) {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDeleteClick = () => {
    handleClose();
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleEditClick = () => {
    handleClose();
    setIsEditOpen(true);
  };

  const handleMenuOpenClick = (event) => {
    handleClose();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="relative">
        {canI(['update:phrases', 'delete:phrases']) && (
          <>
            <Button
              onClick={handleMenuOpenClick}
              aria-controls={open ? 'more-menu-options' : undefined}
              className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
            >
              <AiOutlineMore />
            </Button>
            <Menu
              id="more-menu-options"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {canI(['update:phrases']) && (
                <MenuItem
                  onClick={handleEditClick}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillEdit className="text-green mr-2" />
                  Edit
                </MenuItem>
              )}
              {canI(['delete:phrases']) && (
                <MenuItem
                  onClick={handleDeleteClick}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillDelete className="text-red mr-2" />
                  Delete
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>

      {isEditOpen && (
        <Modal open={true} handleClose={() => setIsEditOpen(false)}>
          <AddPhrase
            open={true}
            handleClose={() => setIsEditOpen(false)}
            isEdit={true}
            currentPhrase={row}
            type={type}
            fav={false}
          />
        </Modal>
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          open={showDeleteModal}
          onDelete={onDelete}
          del={['Phrase']}
          handleConfirm={handleConfirmDelete}
          handleCancel={handleCancelDelete}
        />
      )}
    </>
  );
}

import { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Grid,
  Select,
  InputBase,
  MenuItem,
  Avatar,
  Card,
  CardActions,
  CardContent,
  Stack,
  Pagination,
  Skeleton,
  Box,
  experimentalStyled as styled
} from '@mui/material';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  AddContentButton,
  PublishContentButton
} from '../../components/AddButton';
import canI from '../../utils/canI';
import ListToolbar from '../../components/Global/ListToolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import convertToUtc from '../../utils/convertToUtc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Moment from 'react-moment';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import userStore from '../../store/security/user';
import ToggleButton from '../../components/ToggleButton';
import ArticleApi from '../../api/content';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/Content/Attachment.svg';
import { ReactComponent as GroupIcon } from '../../assets/icons/Content/Group.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Content/Calendar.svg';
import { ReactComponent as EditOutlineIcon } from '../../assets/icons/Content/EditOutline.svg';
import { ReactComponent as StopWatchIcon } from '../../assets/icons/Content/StopWatch.svg';
import { ReactComponent as CloseOutlineIcon } from '../../assets/icons/Content/CloseOutline.svg';
import { ReactComponent as CopyOutlineIcon } from '../../assets/icons/Content/CopyOutline.svg';
import { ReactComponent as EyeOutlineIcon } from '../../assets/icons/Content/EyeOutline.svg';
import channelStore from '../../store/channel/channel';
import { TfiWorld } from 'react-icons/tfi';
import { icons } from '../../constants/channels';
import { observer } from 'mobx-react';
import { publicationStatuses } from '../../constants/publicationStatues';
import SchedulePublish from '../../components/ContentManagement/SchedulePublish';
import ConfirmationModal from '../../components/Modal/Confirm';
import '../../assets/css/search.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const NewSearchContent = () => {
  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');
  const defaultParams = {
    myArticles: false,
    pageIndex: 1,
    pageSize: 10,
    status: queryStatus != null ? queryStatus : '',
    startDate:
          queryStatus != null
            ? convertToUtc(dayjs().format('YYYY-MM-DD 00:00:00'))
            : '',
    startDateStr:
          queryStatus != null ? dayjs().format('YYYY-MM-DD 00:00:00') : '',
    endDate:
          queryStatus != null
            ? convertToUtc(dayjs().format('YYYY-MM-DD 23:59:59'))
            : '',
    endDateStr:
          queryStatus != null ? dayjs().format('YYYY-MM-DD 23:59:59') : '',
    sortColumn: '',
    sortDirection: '',
    searchText: '',
    showCancelled: false,
    authorId: '',
    clear: 0
  };
  const [selected, setSelected] = useState([]);
  const [params, setParams] = useState(defaultParams);
  const { state: { users }, getUsers } = userStore;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { state: { userChannels }, fetchUserChannels } = channelStore;

  const refresh = () => {
    setLoading(true);
    ArticleApi.search(params).then(({ content, page, totalElements }) => {
      setItems(content ?? []);
      setCurrentPage(page);
      setTotalPages(Math.ceil(totalElements / defaultParams.pageSize));
      setTotalElements(totalElements);
      setLoading(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  };

  useEffect(() => {
    refresh();
  }, [
  //  params.myArticles,
    params.pageIndex,
    location.key,
    params.clear
  //  params.searchText,
  //  params.status,
  //  params.startDate,
  //  params.endDate,
  //  params.sortColumn,
  //  params.sortDirection,
  //   params.showCancelled,
  //  params.authorId
  ]);

  const clearAllFilter = () => {
    setParams({
      ...params,
      myArticles: false,
      pageIndex: 1,
      pageSize: 10,
      status: '',
      startDate: '',
      startDateStr: '',
      endDate: '',
      endDateStr: '',
      sortColumn: '',
      sortDirection: '',
      searchText: '',
      showCancelled: false,
      authorId: '',
      clear: params.clear + 1
    });
  };

  useEffect(() => {
    setParams({ ...params, pageIndex: currentPage });
  }, [currentPage]);

  const onPublishContents = async (id) => {
    return true;
  };

  const handleFilterByName = (event) => {
    setParams({ ...params, searchText: event.target.value });
  };

  const handleStatus = (value) => {
    setParams({ ...params, status: value });
  };

  const onAuthorChange = (value) => {
    setParams({ ...params, authorId: value });
  };

  const onChangeStartDate = (value) => {
    setParams({
      ...params,
      startDate: convertToUtc(value.format('YYYY-MM-DD 00:00:00')),
      startDateStr: value.format('YYYY-MM-DD 00:00:00')
    });
  };

  const onChangeEndDate = (value) => {
    setParams({
      ...params,
      endDate: convertToUtc(value.format('YYYY-MM-DD 23:59:59')),
      endDateStr: value.format('YYYY-MM-DD 23:59:59')
    });
  };

  useEffect(() => {
    getUsers();
    fetchUserChannels();
    // refresh();
  }, []);

  const STATUS_LIST = [
    'DRAFT',
    'READY TO PUBLISH',
    'PUBLISH IN PROGRESS',
    'PARTIALLY PUBLISH',
    'SCHEDULED',
    'FAILED',
    'PUBLISHED',
    'RECURRING',
    'AUTHORISATION PENDING',
    'AUTHORISATION REJECTED'
  ];

  const displayMessageForPage = () => {
    if (totalElements > 0) {
      return `Showing ${((currentPage - 1) * params.pageSize + 1)} to ${items.length + ((currentPage - 1) * params.pageSize)} of ${totalElements} records`;
    } else {
      return 'No records found';
    }
  };

  const handelPageChange = (data) => {
    setCurrentPage(data);
  };

  const applyFilter = () => {
    if (params.pageIndex !== 1) {
      setParams({ ...params, pageIndex: 1 });
    } else {
      refresh();
    }
  };

  return (
    <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Content"
          actions={[
            canI(['create:articles']) && <AddContentButton />,
            selected.length > 0 && canI(['create:publish-content']) && (
              <PublishContentButton
                selected={selected}
                setSelected={setSelected}
                onConfirm={onPublishContents}
              />
            )
          ]}
        />
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
            <Grid item xs={12}>
                <Item className='bg-white text-left border border-neutral-400'>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                        <Grid item xs={6}>
                            <ListToolbar
                                placeholder={'Search Posts'}
                                numSelected={selected.length}
                                filterName={params.searchText}
                                onFilterName={handleFilterByName}
                                extraClasses={['p-0']}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="standard" className='w-full mt-6'>
                                <Select
                                    className="rounded"
                                    SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 10 } }}
                                    displayEmpty={true}
                                    value={params.status}
                                    onChange={(e) => handleStatus(e.target.value)}
                                    renderValue={(value) => {
                                      if (value) {
                                        const selectedUser = STATUS_LIST.find(u => u === value);
                                        return (selectedUser);
                                      } else {
                                        return ('Filter by status');
                                      }
                                    }}
                                    input={<BootstrapInput />}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {STATUS_LIST.map((x, i) => (
                                        <MenuItem key={i} value={x}>
                                            {x}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="standard" className='w-full mt-6'>
                                <Select
                                    className="rounded"
                                    SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 10 } }}
                                    displayEmpty={true}
                                    value={params.authorId}
                                    renderValue={(value) => {
                                      if (value) {
                                        const selectedUser = users.find(u => u.id === value);
                                        return (selectedUser.givenName + ' ' + selectedUser.familyName);
                                      } else {
                                        return ('Filter by author');
                                      }
                                    }}
                                    onChange={(e) => onAuthorChange(e.target.value)}
                                    input={<BootstrapInput />}
                                >
                                    <MenuItem value=''>
                                      <em>None</em>
                                    </MenuItem>
                                    {users.map((x, i) => (
                                      <MenuItem key={i} value={x.id}>
                                          <div className='flex align-items-center'>
                                          <Avatar src={x.picture} className="mr-2" sx={{ width: 30, height: 30 }}/>
                                          {x.givenName + ' ' + x.familyName}
                                          </div>
                                      </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl variant="standard" className='w-full'>
                              <div className='flex inline-flex gap-2'>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                      className='w-50  search-datepicker'
                                      slotProps={{
                                        actionBar: {
                                          actions: ['clear']
                                        }
                                      }}
                                      label="Start Date"
                                      onChange={onChangeStartDate}
                                      value={
                                          params.startDateStr !== '' ? dayjs(params.startDateStr) : null
                                      }
                                      />
                                      <DatePicker
                                      className='w-50  search-datepicker'
                                      slotProps={{
                                        actionBar: {
                                          actions: ['clear']
                                        }
                                      }}
                                      label="End Date"
                                      onChange={onChangeEndDate}
                                      value={
                                          params.endDateStr !== '' ? dayjs(params.endDateStr) : null
                                      }
                                      />
                                  </LocalizationProvider>
                              </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="standard" className='w-full'>
                              <div className="flex justify-between mb-2">
                                  <span className="mr-1 text-sm">Authored By Me:</span>
                                  <span>
                                      <ToggleButton
                                      toggle={params.myArticles}
                                      handleClick={(value) =>
                                        setParams({
                                          ...params,
                                          myArticles: !params.myArticles,
                                          pageIndex: 1
                                        })
                                      }
                                      />
                                  </span>
                              </div>
                              <div className="flex justify-between">
                                  <span className="mr-1 text-sm">Show Cancelled:</span>
                                  <span>
                                      <ToggleButton
                                      toggle={params.showCancelled}
                                      handleClick={(value) =>
                                        setParams({
                                          ...params,
                                          showCancelled: !params.showCancelled,
                                          pageIndex: 1
                                        })
                                      }
                                      />
                                  </span>
                              </div>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="standard" className='w-full'>
                                <div className='flex justify-between items-center'>
                                    <p className='text-center items-center'>{displayMessageForPage()}</p>
                                    <div className='flex inline-flex gap-2 w-96'>
                                        <button
                                            className={'border-[1px] border-blue text-blue px-4 py-2 rounded w-full'}
                                            onClick={() => clearAllFilter()}
                                        >
                                            Clear All
                                        </button>
                                        <button
                                            className={'border-[1px] border-blue bg-blue text-white px-4 py-2 rounded w-full'}
                                            onClick={applyFilter}
                                        >
                                            Apply Filter
                                        </button>
                                    </div>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>
            <Grid item xs={12}>
            { items.length > 0 && !loading && (
              items.map((row) => {
                const {
                  id,
                  title,
                  description,
                  authorId,
                  authorUserName,
                  noArticleFiles,
                  noArticleCount,
                  publishedAt,
                  nextScheduledAt,
                  channelId,
                  status,
                  createdOn,
                  updatedAt
                } = row;
                const selectedUser = users.find(u => u.id === authorId);
                return (
                      <Card key={id} className='border border-neutral-400 mb-5'>
                        <CardContent>
                            <div className='flex flex-col gap-4'>
                              <div className='flex inline-flex justify-between gap-2 items-center w-full'>
                                  <div className='flex inline-flex gap-2 items-center'>
                                      <div className='flex inline-flex gap-2 items-center text-xs md:text-sm w-28 2xl:w-auto'>
                                          <Avatar alt={authorUserName} src={selectedUser ? selectedUser.picture : '' } />
                                          { authorUserName }
                                      </div>
                                      <div className='flex inline-flex gap-2 items-center'>
                                          <div className={`flex inline-flex gap-0 items-center px-2.5 py-1 rounded-3xl text-white ${publicationStatuses[status]?.bgColor}`}>
                                              <span className='w-7 h-7'>{publicationStatuses[status]?.icon}</span>
                                              {status}
                                          </div>
                                          { publishedAt && status === 'PUBLISHED' && (
                                            <Moment format="MMM, DD YYYY hh:mm A" className="text-justify text-zinc-500 text-sm sm:text-xs font-normal">
                                              {publishedAt}
                                            </Moment>
                                          )}
                                          { nextScheduledAt && (
                                            <Moment format="MMM, DD YYYY hh:mm A" className="text-justify text-zinc-500 text-sm sm:text-xs font-normal">
                                              {nextScheduledAt}
                                            </Moment>
                                          )}
                                      </div>
                                  </div>
                                  <div className='flex inline-flex gap-2 items-center'>
                                      { noArticleFiles > 0 && (
                                        <div className='w-14 h-7 rounded-sm border border-black/opacity-20 justify-center items-center gap-2.5 flex'>
                                            <AttachmentIcon className='w-3.5 h-5'/>
                                            <span className='number text-neutral-400'>: {noArticleFiles}</span>
                                        </div>
                                      )}
                                      { noArticleCount > 0 && (
                                        <div className='w-14 h-7 rounded-sm border border-black/opacity-20 justify-center items-center gap-2.5 flex'>
                                            <GroupIcon className='w-3.5 h-5'/>
                                            <span className='number text-neutral-400'>: {noArticleCount}</span>
                                        </div>
                                      )}
                                      <div className='flex inline-flex items-center gap-1'>
                                          <span className='text-zinc-500 text-xs'>Created at</span>
                                          <div className="p-2 rounded border border-black/opacity-20 flex inline-flex gap-2.5 items-center">
                                            <CalendarIcon/>
                                            <Moment format="MMM, DD YYYY hh:mm A" className="text-xs text-black">
                                              {createdOn}
                                            </Moment>
                                          </div>
                                      </div>
                                      <div className='flex inline-flex items-center gap-1'>
                                          <span className='text-zinc-500 text-xs'>Modified at</span>
                                          <div className="p-2 rounded border border-black/opacity-20 flex inline-flex gap-2.5 items-center">
                                            <CalendarIcon/>
                                            <Moment format="MMM, DD YYYY hh:mm A" className="text-black text-xs">
                                              {updatedAt}
                                            </Moment>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='flex inline-flex justify-between gap-2 items-center w-full'>
                                  <h2 className='text-black text-xl font-bold'>{title}</h2>
                              </div>
                              { description && (
                                <div className='flex inline-flex justify-between gap-2 items-center w-full'>
                                  <DisplayArticleDescription
                                    description={description}
                                  />
                                </div>
                              )}
                              <div className='flex inline-flex justify-between gap-2 items-center w-full'>
                                  <div className="flex -space-x-1 ">
                                    {channelId.map((item) => {
                                      return (
                                        <ChannelImage
                                          key={item}
                                          channelId={item}
                                          userChannels={userChannels}
                                          icons={icons}
                                        />
                                      );
                                    })}
                                  </div>
                              </div>
                            </div>
                        </CardContent>
                        <CardActions sx={{
                          borderTop: '0.50px #A7A6A7 solid',
                          padding: 0
                        }}>
                            <SearchContentMenus
                              row={row}
                              refresh={refresh}
                            />
                        </CardActions>
                    </Card>
                );
              })
            )}
            { loading && (
              [...Array(2)].map((e, i) => <SkeletonLoader key={i} />)
            )}
            { !loading && (
                <Stack spacing={2} className="pagination">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    onChange={(event, value) => handelPageChange(value)}
                    color="primary"
                  />
                </Stack>
            )}
            </Grid>
        </Grid>
    </Container>
  );
};

const SkeletonLoader = () => {
  return (
    <Card sx={{ border: '1px solid', borderColor: 'neutral.400', mb: 5 }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton width="100px" height="20px" />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Skeleton width={60} height={30} sx={{ borderRadius: '50px' }} />
              <Skeleton width={40} height={30} />
              <Skeleton width={100} height={30} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center' }}>
            <Skeleton width="50%" height="30px" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton width="80%" height="20px" />
            <Skeleton width="90%" height="20px" />
            <Skeleton width="85%" height="20px" />
            <Skeleton width="60px" height="20px" />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="circular" width={20} height={20} />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const DisplayArticleDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateDescription = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + '...';
  };

  const displayText = isExpanded ? description : truncateDescription(description, 250);

  return (
    <div>
      <p className='text-neutral-400 text-lg font-normal'>{displayText}
        {displayText.length > 200 && (<button onClick={toggleExpansion} className='text-zinc-500 text-lg font-semibold'>
          {isExpanded ? 'Read Less' : 'Read more'}
        </button>)}
      </p>
    </div>
  );
};

const ChannelImage = ({ channelId, userChannels, icons }) => {
  const [image, setImage] = useState('');
  const [channel, setChannel] = useState(null);
  const [icon, setIcon] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    const data = userChannels?.find(
      (x) => parseInt(x.id) === parseInt(channelId)
    );
    setChannel(data || {});
  }, [channelId, userChannels]);

  useEffect(() => {
    setImage(null);
    channel && channel.channelImageUrl && setImage(channel.channelImageUrl);
  }, [channel]);

  const findIcon = (name) => {
    return icons.find((c) => c.name === name);
  };

  useEffect(() => {
    setIcon(null);
    if (channel && channel.channelType) {
      if (channel.channelType) {
        const icon = findIcon(channel.channelType.channelName);
        if (icon) {
          setIcon(icon.icon);
          setColor(icon.color);
        } else {
          setIcon(<TfiWorld />);
          setColor('#006ace');
        }
      }
    }
  }, [channel]);

  return (
    <>
      {image
        ? (<img className="inline-block h-8 w-8 rounded-full ring-2 ring-white channel-item" src={image} alt="" />)
        : <span style={{
          color: color ?? '#ccc',
          background: '#fff'
        }} className="inline-block h-8 w-8 rounded-full ring-2 ring-white channel-item">{icon}</span>}
    </>
  );
};

const SearchContentMenus = ({ row, refresh }) => {
  const [isOpenForCancel, setIsOpenForCancel] = useState(false);
  const [openSchedulePublish, setOpenSchedulePublish] = useState(false);

  const navigate = useNavigate();

  const handleEditItem = (data) => {
    navigate(`/content/${data.id}`);
  };

  const handlePublicationStatus = (data) => {
    navigate('/content/' + data.id + '/publication-status-history');
  };

  const onCancelEvent = (row) => {
    ArticleApi.cancelArticle(row.id).then((res) => {
      refresh();
    });
  };

  const onCloneArticle = (row) => {
    ArticleApi.cloneArticle(row.id).then((id) => {
      navigate('/content/' + id);
    });
  };

  return (
      <>
        <div className='w-full h-14 inline-flex items-center justify-between'>
          {canI(['update:articles']) && (
            <button onClick={ () => handleEditItem(row) } className='h-full border-r border-neutral-400 w-full justify-center items-center flex inline-flex gap-2.5'>
                {row.editable === true ? <EditOutlineIcon /> : <EyeOutlineIcon />}
                <span className="text-zinc-500 font-medium">{row.editable === true ? 'Edit' : 'View Only'}</span>
            </button>
          )}
          <button onClick={ () => handlePublicationStatus(row) } className='h-full border-r border-neutral-400 w-full justify-center items-center flex inline-flex gap-2.5'>
              <StopWatchIcon />
              <span className="text-zinc-500 font-medium">History</span>
          </button>
          {row.editable !== true && canI(['create:articles']) && (
            <button onClick={ () => onCloneArticle(row) } className='h-full w-full justify-center items-center flex inline-flex gap-2.5'>
                <CopyOutlineIcon />
                <span className="text-zinc-500 font-medium">Clone</span>
            </button>
          )}
          {row.editable === true && canI(['delete:publish-content']) && (
            <button
              onClick={() => {
                setIsOpenForCancel(true);
              }}
              className='w-full justify-center items-center flex inline-flex gap-2.5'>
                <CloseOutlineIcon />
                <span className="text-zinc-500 font-medium">Cancel</span>
            </button>
          )}
        </div>
        <ConfirmationModal
          selected={() => {}}
          setSelected={() => {}}
          setIsOpen={() => {}}
          open={isOpenForCancel}
          handleCancel={() => setIsOpenForCancel(false)}
          onConfirm={() => onCancelEvent(row)}
          message={[
            'Are you sure you want to proceed operation?',
            'selected item will be proceed for cancellation'
          ]}
        />
        {openSchedulePublish && (
          <SchedulePublish
            content={row}
            versions={[row]}
            open={openSchedulePublish}
            handleClose={() => setOpenSchedulePublish(false)}
          />
        )}
    </>
  );
};

export default observer(NewSearchContent);

import { PieChart } from '@mui/x-charts/PieChart';

const BarChartWidget = ({
  series,
  colors
}) => {
  return (
          <PieChart
              sx={{
                '& .MuiChartsLegend-root': {
                  display: 'none'
                }
              }}
              series={[
                {
                  data: series,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }
                }
              ]}
              colors={colors ?? ['#ffb946']}
              innerRadius={30}
              outerRadius={100}
              paddingAngle={5}
              cornerRadius={5}
              height={300}
          />
  );
};

export default BarChartWidget;

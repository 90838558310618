import { useEffect, useState } from 'react';
import PopupLayout from './../../layout/PopupLayout';
import { observer } from 'mobx-react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/CopyAll';
import { useSnackbar } from 'notistack';
import channelsStore from '../../store/channel/channel';

const ShowChannelSecrets = ({ open, handleClose, id }) => {
  const {
    getChannelInstanceSecret
  } = channelsStore;

  const { enqueueSnackbar } = useSnackbar();
  const [clientId, setClientId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (open) {
      getChannelInstanceSecret(id).then(data => {
        setClientId(data?.clientId);
        setClientSecret(data?.clientSecret);
      });
    }
  }, [open]);

  const handleClickCopyClientId = () => {
    navigator.clipboard.writeText(clientId);
    enqueueSnackbar('Client Id has been successfully copied !', { variant: 'success' });
  };

  const handleClickCopyClientSecret = () => {
    navigator.clipboard.writeText(clientSecret);
    enqueueSnackbar('Client Secret has been successfully copied !', { variant: 'success' });
  };

  // Function to show the last four characters as readable and the rest as '*'
  const showLastFour = (str) => {
    if (str?.length) {
      const visibleLength = 4;
      const hiddenLength = str.length - visibleLength;
      const hiddenChars = '*'.repeat(hiddenLength);
      const visibleChars = str.slice(-visibleLength);
      return hiddenChars + visibleChars;
    }
  };

  return (
    <PopupLayout open={open} handleClose={handleClose} heading={'Webhook Secrets'}>
      <div className="pb-5">
        {clientId
          ? (
          <div>
            <div className="secret-container">
            <TextField
                label="Client Id"
                fullWidth sx={{ m: 1 }}
                id="outlined-basic"
                value={showLastFour(clientId)}
                variant="outlined"
                InputProps={
                  { endAdornment: <InputAdornment position="end"> <IconButton aria-label="copy password" onClick={handleClickCopyClientId} edge="end"><Visibility /></IconButton></InputAdornment> }
                }
                />
            </div>
            <div className="secret-container">
                <TextField
                label="Client Secret"
                fullWidth sx={{ m: 1 }}
                id="outlined-basic"
                value={showLastFour(clientSecret)}
                variant="outlined"
                InputProps={
                  { endAdornment: <InputAdornment position="end"> <IconButton aria-label="copy password" onClick={handleClickCopyClientSecret} edge="end"><Visibility /></IconButton></InputAdornment> }
                }
                />
            </div>
          </div>
            )
          : (
          <div>
            <p>No secrets found.</p>
          </div>
            )}
        <div className="flex justify-between mt-3">
          <button
            className="bg-red rounded px-4 py-2 text-white"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </PopupLayout>
  );
};

export default observer(ShowChannelSecrets);

import { Player } from '@lottiefiles/react-lottie-player';

function NoContent (content) {
  return (
    <div>
      <Player
        src={process.env.PUBLIC_URL + '/static/gifs/NoContentFound.json'}
        className="player"
        autoplay
        loop
        speed={2}
        style={{ height: '300px', width: '300px' }}
      />
      <div className='flex flex-col items-center'>
        <p className='text-center'> { content || 'No Content Found' }</p>
      </div>
    </div>
  );
}

export default NoContent;

import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import RightMenu from './RightMenu';
import { sideMenu } from '../../constants/content';
import { observer } from 'mobx-react';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ContactForm from '../../pages/Contact/ContactForm';

const RightSideDrawer =
  ({
    distracted,
    docHistory,
    setShowVersionForm,
    setCompareVersion,
    viewOnly,
    plainText,
    contentParams,
    setContentParams,
    tags,
    setTags,
    openSidebarMenu,
    setOpenSidebarMenu,
    openSidebar,
    setOpenSidebar,
    title,
    content,
    files,
    selectedChannels,
    setHashTag,
    setCustomTag,
    mentions
  }) => {
    const [showContactForm, setShowContactForm] = useState(false);
    const filteredSideMenu = sideMenu.filter((item) => {
      if (viewOnly) {
        return item.viewOnly;
      }
      return true;
    });

    return (
      <>
          <div className="flex content-right-sidebar rounded-lg mt-[70px]">
            <Drawer
              variant='persistent'
              anchor='right'
              open={openSidebar}
              transitionDuration={{ enter: 700, exit: 700 }}
              sx={{
                width: openSidebar ? 415 : 0,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box'
                }
              }}
              classes={{ paper: 'drawer-submenu-content scrollbar-hide ' }} >
                {openSidebar && (
                  <RightMenu
                    distracted={distracted}
                    docHistory={docHistory}
                    previousVersionForm={() => setShowVersionForm(true)}
                    showVersionComparison={() => setCompareVersion(true)}
                    viewOnly={viewOnly}
                    openSidebar={openSidebar}
                    openSidebarMenu={openSidebarMenu}
                    docText={plainText}
                    contentParams={contentParams}
                    setContentParams={setContentParams}
                    tags={tags}
                    setTags={setTags}
                    title={title}
                    content={content}
                    files={files}
                    plainText={plainText}
                    selectedChannels={selectedChannels}
                    setHashTag={setHashTag}
                    setCustomTag={setCustomTag}
                    mentions={mentions}
                  />
                )}
            </Drawer>
            <List className={`z-20 bg-white ${openSidebar ? 'border-l-2 border-slate-200' : ''}`}>
                {filteredSideMenu.map((item, index) => (
                    <Tooltip key={index} title={item.label} arrow>
                        <div className='w-20 m-2'>
                          <ListItem
                            className={`rounded-md flex flex-col cursor-pointer items-center ${openSidebarMenu === item.slug ? 'active' : ''} ` + item.class}
                            key={index}
                            onClick={() => {
                              if (item.slug === 'contact') {
                                setShowContactForm(true);
                              } else if (openSidebar && openSidebarMenu === item.slug) {
                                setOpenSidebar(false);
                                setOpenSidebarMenu('');
                              } else {
                                setOpenSidebar(true);
                                setOpenSidebarMenu(item.slug);
                              }
                            }}
                          >
                            <ListItemIcon className='ml-4 w-6 h-6'>{item.icon}</ListItemIcon>
                            <ListItemText className="font-xs">{item.label}</ListItemText>
                        </ListItem>
                        </div>
                    </Tooltip>
                ))}
            </List>
            <Dialog
              open={showContactForm}
              onClose={() => setShowContactForm(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle id="alert-dialog-title">
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1} >{'Create Contact'}</Box>
                  <Box>
                      <IconButton onClick={() => setShowContactForm(false)}>
                        <CloseIcon />
                      </IconButton>
                  </Box>
                </Box>
              </DialogTitle>
              <DialogContent>
                <ContactForm onSave={() => setShowContactForm(false)} displayInDialog={true} />
              </DialogContent>
            </Dialog>
          </div>
      </>
    );
  };

RightSideDrawer.displayName = 'RightSideDrawer';

export default observer(RightSideDrawer);

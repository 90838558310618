import { useEffect, useState } from 'react';
import publicationStore from '../../store/channel/publication';
import { CIIM } from './channel-instace-info-message';

const ChannelInstanceInfo = ({ existingChannel }) => {
  const { retrieveChannelPreviewByChannelInstance } = publicationStore;
  const [existingChannelInfo, setExistingChannelInfo] = useState(null);

  useEffect(() => {
    retrieveChannelPreviewByChannelInstance(existingChannel?.id).then(
      (data) => {
        console.log(data);
        setExistingChannelInfo(data && data.length > 0 ? data[0] : null);
      });
  }, []);

  const addDefaultSrc = (ev) => {
    ev.target.src = process.env.PUBLIC_URL + '/static/images/social.png';
  };

  const getConnectedInstanceInfo = (type) => {
    const connectedInstance = CIIM.find(f => f.name === type);
    return connectedInstance ? connectedInstance.description : 'Connected Instance';
  };

  return (
    <>{existingChannel && existingChannelInfo && (
        <>
        <div className="font-black mb-3 text-muted">
            {getConnectedInstanceInfo(existingChannel.channelType?.channelName)}
          </div>
        <div className="grid grid-cols-1 gap-1">
        <div className="flex items-center justify-left">
          <div className='border-2 border-blue relative bg-white text-blue
            text-lg font-bold w-20 h-20 rounded-full flex items-center justify-center'>
              <img onError={addDefaultSrc}
                className="absolute rounded-full object-cover h-full w-full"
                src={existingChannelInfo?.profilePicUrl ?? process.env.PUBLIC_URL + '/static/images/social.png'}
                alt="profile pic"
              />
          </div>
          <a
            className='ml-3 text-sm text-blue underline'
            href={existingChannelInfo?.socialChannelLink}
            // eslint-disable-next-line react/no-unknown-property
            without
            rel="noreferrer"
            target="_blank"
          >
            {existingChannelInfo?.displayName}
          </a>
        </div>
      </div>
      </>
    )}
    </>
  );
};

export default ChannelInstanceInfo;

import { useEffect, useState } from 'react';
import channelsStore from '../../store/channel/channel';
import { MenuItem, Select } from '@mui/material';
import loadingStore from '../../store/loading';

const ChannelInstanceField = (props) => {
  const { channelTypeId, parentIndex, channelTypeList, setValue, isDisabled } = props;
  const { searchInstances } = channelsStore;
  const [channelInstances, setChannelInstances] = useState(null);
  const { setLoading } = loadingStore;

  useEffect(async () => {
    setChannelInstances(null);

    const channelName = channelTypeList.find((ch) => ch.id === channelTypeId).channelName.toLowerCase();
    if (channelName === 'slack' || channelName === 'telegram') {
      setLoading(true);
      const { content } = await searchInstances({ channelTypeId });
      setValue(
        `channelHandlerList.${parentIndex}.channelInstanceId`,
        content[0].id
      );
      setValue(
        `channelHandlerList.${parentIndex}.channelInstanceName`,
        content[0].name
      );
      setChannelInstances([...content]);
      setLoading(false);
    } else {
      setValue(`channelHandlerList.${parentIndex}.channelInstanceId`, '');
      setValue(`channelHandlerList.${parentIndex}.channelInstanceName`, '');
    }
  }, [channelTypeId]);

  const handleChannelInstanceChange = (selectedInstanceId) => {
    const selectedInstance = channelInstances.find(
      (instance) => instance.id === selectedInstanceId
    );
    setValue(`channelHandlerList.${parentIndex}.channelInstanceId`, selectedInstance.id);
    setValue(`channelHandlerList.${parentIndex}.channelInstanceName`, selectedInstance.name);
  };

  return channelInstances
    ? (
        <Select
            defaultValue={channelInstances[0].id}
            disabled={isDisabled}
            onChange={(e) => handleChannelInstanceChange(e.target.value)}
        >
            {channelInstances.map((instance, index) => (
                <MenuItem key={index} value={instance.id}>
                {instance.name}
                </MenuItem>
            ))}
        </Select>
      )
    : null;

  // return (channelInstances
  //   ? <Controller
  //     name={`channelHandlerList.${parentIndex}.channelInstance`}
  //     control={control}
  //     render={({ field }) => (
  //       <Select
  //         {...field}
  //         onChange = {(e) => {
  //           handleChannelInstanceChange(e.target.value, index);
  //           field.onChange(e);
  //         }}
  //         className='w-1/4'
  //       >
  //         {channelInstances.map((instance, index) => (
  //           <MenuItem key={index} value={instance.id}>
  //             {instance.name}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     )}
  //   >
  //   </Controller>
  //   : null
  // );
};

export default ChannelInstanceField;

import { observer } from 'mobx-react';

const TermAndCondition = () => {
  return (
    <div>
      <iframe
        src='https://sharewithmarvin.s3.eu-west-2.amazonaws.com/docs/Terms+and+Conditions.pdf#toolbar=0&navpanes=0'
        title='Terms and Conditions'
        style={{ width: '100%', height: '800px' }} // Adjust the width and height as needed
      />
    </div>
  );
};
export default observer(TermAndCondition);

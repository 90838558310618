import { useState } from 'react';
import ProductTemplate from '../Product/Product';
import { Box, Button, Grid, OutlinedInput, Stack, Typography } from '@mui/material';
import ToggleButton from '../../components/ToggleButton';
import currency from '../../utils/currency';
import productDetail from '../../utils/product';
import AnimateButton from '../AnimateButton';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth0 } from '@auth0/auth0-react';
import { MdWavingHand } from 'react-icons/md';
import usersStore from '../../store/settings/user';
import { useSnackbar } from 'notistack';

const LicenseCheck = () => {
  const { createUserSubscription } = usersStore;
  const [annualBilling, setAnnualBilling] = useState(false);
  const [defaultPlan, setDefaultPlan] = useState(null);
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({ promoCode: null });

  const { promoCode } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChangeBillingCycle = () => {
    setAnnualBilling(!annualBilling);
  };

  const handleStartSubscription = async () => {
    const data = {
      'product-name': defaultPlan.name,
      frequency: productDetail(annualBilling, defaultPlan?.prices).interval === 'one_time' ? 'one_time' : annualBilling ? 'year' : 'month',
      seats: productDetail(annualBilling, defaultPlan?.prices).interval === 'one_time' ? 1 : productDetail(annualBilling, defaultPlan?.prices).interval_count,
      promotional_code: formData?.promoCode || 'code'
    };
    const result = await createUserSubscription(data);
    console.log(result);
    if (result && result.statusCode === 200) {
      window.location = result.checkoutUrl;
    } else {
      enqueueSnackbar('Something went wrong, Please try again.', { variant: 'error' });
    }
  };

  return (
    <>
      <Box className='flex'>
        <Grid container spacing={2}>
          <Grid container item xl={12} lg={12} md={12} sm={12} justifyContent="space-evenly" alignItems="center">
            <div className="rounded-md w-3/4 mt-5 ">
              <div className="flex justify-start mb-5" style={{ fontSize: '22px' }}>
                Hey, {user.given_name} <MdWavingHand style={{ color: '#ffb946' }} />
              </div>
              <div className="rounded-md h-17 mt-5 p-2 justify-start text-base shadow-lg" style={{ backgroundColor: '#FF9141', color: '#fff' }}>
                <InfoIcon sx={{ color: '#fff' }} />
                <span> {'Uh-Oh!!'}</span>
                <br></br>
                <br></br>
                <span> {'You currently do not have any active license. Please start your subscription to enjoy Share With Marvin features!'} </span>
              </div>
            </div>
          </Grid>
          <Grid container item xl={6} lg={6} md={6} sm={12} justifyContent="space-evenly" alignItems="flex-end">
            <Grid item xs={12} md={12} alignItems={'center'} alignContent={'center'} justifyContent={'center'} textAlign={'center'} spacing={1}>
              <Typography variant='subtitle1'>
                Choose The Right Plan for Your Business
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} alignItems={'center'} alignContent={'center'} justifyContent={'center'} textAlign={'center'} spacing={1}>
              <Typography variant='subtitle2'>
                We have several powerful plans to showcase your business and get discovered as a creative entrepreneurs !!
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} alignItems={'center'} alignContent={'center'} justifyContent={'center'} spacing={1}>
              <ProductTemplate
                defaultPlan={defaultPlan}
                setDefaultPlan={setDefaultPlan}
                annualBilling={annualBilling}
              />
            </Grid>
          </Grid>
          <Grid container item xl={4} lg={4} md={4} sm={12} className='bg-white rounded-lg shadow-2xl m-5 p-10'>
            <Grid item xs={12} md={12} alignItems={'center'} alignContent={'center'} justifyContent={'center'}>
              <p className='uppercase text-center text-lg font-bold py-3 text-orange-400'>billing summary</p>
            </Grid>
            <Grid item xs={12} md={12} alignItems={'center'} alignContent={'center'} justifyContent={'center'}>
              <Stack>
                <div className='flex' style={{ justifyContent: 'end' }}>
                  <Typography variant='body2' style={{ textAlign: 'left' }}>
                    <strong> Get extra 10% off on first bill , if billed annually</strong>
                    <span className='pl-5' style={{ float: 'right' }}>
                      <ToggleButton toggle={annualBilling} handleClick={handleChangeBillingCycle} />
                    </span>
                  </Typography>
                </div>
                {defaultPlan && (
                  <>
                    <Typography variant='body' style={{ textAlign: 'left' }}>
                      <strong>{defaultPlan?.name} Plan</strong>
                      <span style={{ float: 'right' }}>{
                        currency(productDetail(annualBilling, defaultPlan?.prices).unit_amount_decimal, productDetail(annualBilling, defaultPlan?.prices).currency)
                      } per seat / {productDetail(annualBilling, defaultPlan?.prices).interval_count}</span>
                    </Typography>
                    <Typography variant='body' style={{ textAlign: 'left' }}>
                      <strong>Due Now</strong>
                      <span style={{ float: 'right' }}>£ 0 / {productDetail(annualBilling, defaultPlan?.prices).interval}</span>
                    </Typography>
                    <Typography variant='body' style={{ textAlign: 'left' }}>
                      Your card will be charged
                      <span style={{ float: 'right' }}>
                        {
                          currency((productDetail(annualBilling, defaultPlan?.prices).unit_amount_decimal * productDetail(annualBilling, defaultPlan?.prices).interval_count),
                            productDetail(annualBilling, defaultPlan?.prices).currency)
                        } + tax on.</span>
                    </Typography>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item justifyContent='flex-end' alignItems='flex-end' xs={12} md={12}>
              <Typography variant='body2'>
                <OutlinedInput
                  fullWidth
                  id='confirm-promoCode-signup'
                  type={'text'}
                  name='promoCode'
                  placeholder='Promotional Code?'
                  inputProps={{}}
                  value={promoCode}
                  onChange={onChange}
                />
              </Typography>
            </Grid>
            <Grid item justifyContent='flex-end' alignItems='flex-end' xs={12} md={12}>
              <AnimateButton>
                <Button fullWidth size='large' variant='contained' className='bg-blue rounded text-lg' onClick={handleStartSubscription}>
                  Start Subscription
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LicenseCheck;

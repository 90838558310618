import { useEffect, useState } from 'react';
import Login from './Login';
import { useSnackbar } from 'notistack';
import AuthRegister from './AuthRegister';

const Auth = () => {
  const [selected, setSelected] = useState('login');
  const [filterProduct, setFilterProduct] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const switchLogin = () => {
    selected === 'login' ? setSelected('registration') : setSelected('login');
    window.history.pushState({}, document.title, selected === 'login' ? 'signup' : 'login');
  };

  useEffect(() => {
    const contextPath = window.location.href;
    if (contextPath.includes('signup')) {
      setSelected('registration');
      setFilterProduct(contextPath.split('?id=').at(1) || null);
      // window.history.pushState({}, document.title, 'signup');
    } else {
      if (contextPath.includes('checkoutsuccess')) {
        enqueueSnackbar('your account has been registered successfully, Please login.', { variant: 'success' });
      }

      if (contextPath.includes('checkouterror')) {
        enqueueSnackbar('Your payment has been failed,  Please try again or contact the administrator.', { variant: 'error' });
      }

      setSelected('login');
      window.history.pushState({}, document.title, 'login');
    }
  }, []);

  return (
    <div className="w-full min-h-full flex overflow-auto relative">
      <div className="w-full min-h-screen relative">
        <img
          className="absolute w-full h-full object-cover"
          alt="bg"
          src={process.env.PUBLIC_URL + '/static/images/signup-bg.svg'}
        />
        <div className="relative h-full">
              {selected === 'login' && <Login switchLogin={switchLogin} />}
              {selected === 'registration' && <AuthRegister switchLogin={switchLogin} filterProduct={filterProduct} />}
        </div>
      </div>
    </div>
  );
};

export default Auth;

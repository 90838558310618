import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Fab,
  FormHelperText,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BsCamera, BsTrash } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { TfiWorld } from 'react-icons/tfi';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ImageUpload from '../../components/ImageUpload';
import { icons } from '../../constants/channels';
import channelsStore from '../../store/channel/channel';
import directoryStore from '../../store/directoryHandler/directoryHandler';
import fileStore from '../../store/file';
import './contactStyles.css';
import ChannelInstanceField from './ChannelInstanceField';
import ChannelHandlerField from './ChannelHandlerField';

function isUserIdUnique (message) {
  return this.test('isUserIdUnique', message, async function (value) {
    const { path, createError } = this;
    if (value) {
      const userId = await directoryStore.fetchUserId(value);
      if (userId.toLowerCase() === value.toLowerCase()) {
        return createError({ path, message });
      }
    }
    return true;
  });
}

Yup.addMethod(Yup.string, 'isUserIdUnique', isUserIdUnique);

const schema = Yup.object().shape({
  userId: Yup.string().label('User ID').trim().required('Required').when('$existingContact', ([existingContact], schema) => {
    if (!existingContact) {
      return schema.isUserIdUnique('User Id already exists');
    } else {
      return schema;
    }
  }).test('isValidUserIdPattern', '', function (value) {
    const userIdRegex = /^[-\w.$*!]{1,30}$/g;
    const match = userIdRegex.exec(value);
    const { path, createError } = this;
    if (!match) {
      return createError({ path, message: 'Only $, -, _, * and ! special character is allowed.' });
    } else {
      return true;
    }
  }),
  emailId: Yup.string().label('Email ID'),
  firstName: Yup.string().label('First Name').trim().when('companyName', ([companyName], schema) => {
    return companyName ? schema.notRequired() : schema.required('Required');
  }),
  companyName: Yup.string().when('firstName', ([firstName], schema) => {
    return firstName ? schema.notRequired() : schema.required('Required');
  }),
  channelHandlerList: Yup.array().of(
    Yup.object().shape({
      handlerSearchText: Yup.string().required('Required'),
      isUserValid: Yup.boolean().when('handlerSearchText', ([handlerSearchText], schema) => {
        if (handlerSearchText) {
          return schema.isTrue('Please validate user or entered user is invalid');
        } else {
          return schema;
        }
      })
    })
  )
}, [['firstName', 'companyName']]);

const ContactForm = ({ onSave = () => {}, displayInDialog = false }) => {
  const { contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { createContact, updateContactDetail, getContactDetailById } = directoryStore;
  const { state: { allChannelTypes }, fetchAllChannelTypes } = channelsStore;
  const { addImage, fetchImageByID } = fileStore;

  const defaultValues = {
    userId: '',
    firstName: '',
    familyName: '',
    emailId: '',
    companyName: '',
    bio: '',
    channelHandlerList: [{
      handlerId: '',
      channelTypeName: '',
      channelTypeId: '',
      handlerName: '',
      isUserValid: false,
      isDeleted: false
    }]
  };

  const loadExistingContactDetail = async () => {
    const response = await getContactDetailById(contactId);
    setValues(response);
    setImagePreview(response.contactPictureUrl);
    setExistingContact(response);
  };

  const [values, setValues] = useState(defaultValues);
  const [existingContact, setExistingContact] = useState(null);
  const { handleSubmit, control, setValue, formState: { errors }, trigger, watch, getValues } = useForm({
    defaultValues,
    values,
    mode: 'onBlur',
    resolver: yupResolver(schema),
    context: { existingContact }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'channelHandlerList'
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [contactImageId, setContactImageId] = useState(null);
  const [channelTypeList, setChannelTypeList] = useState(null);

  useEffect(() => {
    const channelTypes = ['slack', 'formerly twitter', 'instagram business', 'facebook page', 'linkedin', 'linkedin business', 'telegram', 'tik tok'];
    const filteredChannelTypes = allChannelTypes.filter(ch => channelTypes.includes(ch.channelName.toLowerCase()));
    if (filteredChannelTypes.length) {
      setValue('channelHandlerList.0.channelTypeId', filteredChannelTypes[0].id);
      setValue('channelHandlerList.0.channelTypeName', filteredChannelTypes[0].channelName);
      setChannelTypeList(filteredChannelTypes);
    }
  }, [allChannelTypes]);

  useEffect(() => {
    if (contactId) {
      loadExistingContactDetail();
    }
  }, [contactId]);

  useEffect(() => {
    if (contactImageId) {
      fetchImageByID(contactImageId).then((res) => {
        res !== 0 ? setImagePreview(res) : setImagePreview(null);
      });
    } else {
      setImagePreview(null);
    }
  }, [contactImageId]);

  const formOnSubmit = (data) => {
    console.log(errors);
    if (existingContact) {
      handleUpdateContactDetail(data);
    } else {
      handleAddContact(data);
    }
  };

  const handleAddContact = (data) => {
    createContact(data).then((res) => {
      if (res) {
        enqueueSnackbar('Contact added successfully', { variant: 'success' });
        onSave();
        if (!displayInDialog) {
          navigate('/toolsandstyling/manage-contact/' + res.id);
        }
        return;
      }
      enqueueSnackbar('Failed to add a contact', { variant: 'error' });
    }, error => {
      console.log(error);
      enqueueSnackbar('Failed to add a contact', { variant: 'error' });
    });
  };

  const handleUpdateContactDetail = (data) => {
    updateContactDetail(data).then((res) => {
      if (res) {
        setValues(res);
        enqueueSnackbar('Contact detail updated successfully', {
          variant: 'success'
        });
        onSave();
        return;
      }
      enqueueSnackbar('Failed to update a contact detail', {
        variant: 'error'
      });
    });
  };

  const handleChannelTypeChange = (value, index) => {
    const selectedChannel = channelTypeList.find(c => c.id === value);
    setValue(`channelHandlerList[${index}].channelTypeName`, selectedChannel.channelName);
  };

  const handleImageUpload = async (file) => {
    const image = file ? await addImage(file, 'user') : null;
    setValue('contactImageId', image);
    setContactImageId(image);
    setShowUploadImage(false);
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setValue('contactImageId', null);
  };

  useEffect(() => {
    fetchAllChannelTypes();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(formOnSubmit)}>
        <div className={`flex ${displayInDialog ? 'justify-center' : ''}`}>
          <div className={'flex flex-col align-items-center'}>
            <div
              className={`border-2 border-blue relative ${
                imagePreview ? 'bg-white' : 'bg-light-blue'
              } text-white text-sm font-medium w-32 h-32 rounded-full flex items-center justify-center mb-5`}
            >
              {!imagePreview && 'Upload picture'}
              {imagePreview && (
                <img
                  className="absolute rounded-full object-cover h-full w-full z-0"
                  src={imagePreview}
                  alt="channel"
                />
              )}
              {imagePreview && (
                <Fab
                  size='small'
                  className="text-white bg-red absolute bottom-24 right-[-8px] z-0"
                  onClick={handleRemoveImage}
                >
                  <BsTrash />
                </Fab>
              )}
              <Fab
                size="small"
                className='text-white bg-blue absolute bottom-[-8px] right-[-8px] z-0'
                onClick={() => setShowUploadImage(true)}
              >
                <BsCamera />
              </Fab>
            </div>
            <Typography color="primary">Upload Picture</Typography>
          </div>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            pt={5}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12}>
              <InputLabel className="pb-2 font-black">User ID</InputLabel>
              <Controller
                name="userId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled = {!!existingContact}
                    className="w-full"
                    placeholder="Type Here"
                    name="userId"
                    error={!!errors.userId}
                  ></TextField>
                )}
              ></Controller>
              <ErrorMessage
                errors={errors}
                name="userId"
                render={({ message }) => (
                  <span className="contactStyle">
                    <p>{message}</p>
                  </span>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className="pb-2 font-black">First Name</InputLabel>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    placeholder="Type Here"
                    name="firstName"
                    error={!!errors.firstName}
                    onBlur={() => trigger(['companyName', 'firstName'])}
                  ></TextField>
                )}
              ></Controller>
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <span className="contactStyle">
                    <p>{message}</p>
                  </span>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className="pb-2 font-black">Family Name</InputLabel>
              <Controller
                name="familyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    placeholder="Type Here"
                    name="familyName"
                  ></TextField>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className="pb-2 font-black">Email ID</InputLabel>
              <Controller
                name="emailId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    placeholder="Type Here"
                    name="emailId"
                    error={!!errors.emailId}
                  ></TextField>
                )}
              ></Controller>
              <ErrorMessage
                errors={errors}
                name="emailId"
                render={({ message }) => (
                  <span className="contactStyle">
                    <p>{message}</p>
                  </span>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className="pb-2 font-black">
                Company/Business Name
              </InputLabel>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    placeholder="Type Here"
                    name="companyName"
                    error={!!errors.companyName}
                    onBlur={() => trigger(['firstName', 'companyName'])}
                  ></TextField>
                )}
              ></Controller>
              <ErrorMessage
                errors={errors}
                name="companyName"
                render={({ message }) => (
                  <span className="contactStyle">
                    <p>{message}</p>
                  </span>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="pb-2 font-black">
                BOI (optional)
              </InputLabel>
              <Controller
                name="bio"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    placeholder="Type Here"
                    name="bio"
                  ></TextField>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12}>
              <div className='pb-2'>
                <InputLabel className="font-black">
                  Add social channel
                </InputLabel>
                <FormHelperText>
                  For Twitter and Slack validate user by clicking search icon
                </FormHelperText>
              </div>
              {channelTypeList && fields.map((item, index) => {
                const [
                  channelTypeIdWatch,
                  handlerIdWatch,
                  channelInstanceIdWatch,
                  isDeletedWatch
                ] = watch([
                  `channelHandlerList.${index}.channelTypeId`,
                  `channelHandlerList.${index}.handlerId`,
                  `channelHandlerList.${index}.channelInstanceId`,
                  `channelHandlerList.${index}.isDeleted`
                ]);
                return (
                  <ListItem
                    key={item.id}
                    disablePadding={true}
                    className="mb-3 flex flex-col align-items-start gap-2"
                  >
                    <div className="w-full flex align-items-center gap-2">
                      <Controller
                        name={`channelHandlerList.${index}.channelTypeId`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            disabled = {!!handlerIdWatch?.trim()}
                            {...field}
                            style={{ height: '56px' }}
                            onChange={(e) => {
                              handleChannelTypeChange(e.target.value, index);
                              field.onChange(e);
                            }}
                          >
                            {channelTypeList.map((channel, index) => (
                              <MenuItem key={index} value={channel.id}>
                                <p
                                  style={{
                                    backgroundColor: icons.find(
                                      (c) => c.name === channel.channelName
                                    )
                                      ? icons.find(
                                        (c) =>
                                          c.name === channel.channelName
                                      ).color
                                      : '#eee'
                                  }}
                                  className="text-white rounded-full p-1"
                                >
                                  {icons.find(
                                    (c) => c.name === channel.channelName
                                  )
                                    ? (
                                        icons.find(
                                          (c) => c.name === channel.channelName
                                        ).icon
                                      )
                                    : (
                                        <TfiWorld />
                                      )}
                                </p>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      ></Controller>
                      {channelTypeIdWatch && <ChannelInstanceField
                        control={control}
                        parentIndex={index}
                        channelTypeId={channelTypeIdWatch}
                        channelTypeList={channelTypeList}
                        setValue = {setValue}
                        isDisabled = {!!handlerIdWatch?.trim()}
                      ></ChannelInstanceField>}
                      <ChannelHandlerField
                        parentIndex={index}
                        setValue= {setValue}
                        channelInstanceId= {channelInstanceIdWatch}
                        channelTypeId={channelTypeIdWatch}
                        selectedChannelType={allChannelTypes.find(ch => ch.id === channelTypeIdWatch)}
                        trigger={trigger}
                        error = {errors}
                        defaultValues = {values}
                        isDeleted = {isDeletedWatch}
                      ></ChannelHandlerField>
                      <div className="w-6">
                        { getValues('channelHandlerList')?.length > 1 && (
                          <Button
                            className="text-blue"
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin5Line style={{ fontSize: '20px' }}/>
                          </Button>
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`channelHandlerList.${index}.handlerSearchText`}
                      render={({ message }) => (
                        <span className="contactStyle">
                          <p>{message}</p>
                        </span>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`channelHandlerList.${index}.isUserValid`}
                      render={({ message }) => (
                        <span className="contactStyle">
                          <p>{message}</p>
                        </span>
                      )}
                    />
                  </ListItem>
                );
              })}
            </Grid>
            <Grid item xs={displayInDialog ? 12 : 0}>
              <Button
                variant="contained"
                style={{ backgroundColor: 'hsla(192, 68%, 41%, 0.11)' }}
                className="flex align-items-center justify-center w-full py-3 uppercase text-blue font-black"
                onClick={() => append({
                  handlerId: '',
                  channelTypeName: channelTypeList[0].channelName,
                  channelTypeId: channelTypeList[0].id,
                  isUserValid: false,
                  isDeleted: true
                })}
              >
                Add Channel <FaPlus className="ml-3" />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: '10px' }}>
          <button
            variant="contained"
            className={`button bg-blue rounded py-3 uppercase text-white ${displayInDialog ? 'w-full' : 'w-40'}`}
            color="primary"
            type='submit'
          >
            {existingContact ? 'Update' : 'Save'}
          </button>
         </Box>
      </form>
      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}
    </>
  );
};

export default (ContactForm);

import { makeAutoObservable } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';

const PUBLICATION_CONF = process.env.REACT_APP_BASE_URL + '/channeltypes/api/v1';
const PUBLICATION_SETTINGS_CONF = process.env.REACT_APP_BASE_URL + '/publication/api/v1';

class PublicationStore {
  state = {};

  constructor () {
    makeAutoObservable(this);
  }

  fetchConnectionTypes = async (id) => {
    loadingStore.setLoading(true);

    const response = await client
      .get(PUBLICATION_CONF + '/configuration/connection-types/' + id)
      .catch((error) => {
        loadingStore.setLoading(false);
        throw error;
      });

    loadingStore.setLoading(false);

    return response.status === 200 ? response.data : [];
  };

  fetchConnectionTypeParameters = async (id, typeId) => {
    loadingStore.setLoading(true);

    const response = await client
      .get(
        PUBLICATION_CONF + '/configuration/parameter-types/' + id + '/' + typeId
      )
      .catch((error) => {
        loadingStore.setLoading(false);
        throw error;
      });

    loadingStore.setLoading(false);

    return response.status === 200 ? response.data : [];
  };

  createConnectionParameters = async (params) => {
    if (params.channelInstanceConfig.id != null) {
      loadingStore.setLoading(true);

      const response = await client
        .put(PUBLICATION_CONF + '/settings', params)
        .catch((error) => {
          loadingStore.setLoading(false);
          throw error;
        });

      loadingStore.setLoading(false);

      return response.status === 200 ? response.data : [];
    } else {
      loadingStore.setLoading(true);

      const response = await client
        .post(PUBLICATION_CONF + '/settings', params)
        .catch((error) => {
          loadingStore.setLoading(false);
          throw error;
        });

      loadingStore.setLoading(false);

      return response.status === 200 ? response.data : [];
    }
  };

  fetchSettings = async (params) => {
    loadingStore.setLoading(true);

    const response = await client
      .get(PUBLICATION_CONF + '/settings', {
        params
      })
      .catch((error) => {
        loadingStore.setLoading(false);
        throw error;
      });

    loadingStore.setLoading(false);

    return response.status === 200 ? response.data : [];
  };

  connectChannel = async (id) => {
    loadingStore.setLoading(true);

    const response = await client
      .post(PUBLICATION_SETTINGS_CONF + '/connection/create/' + id)
      .catch((error) => {
        loadingStore.setLoading(false);
        console.log(error);
        throw error;
      });

    loadingStore.setLoading(false);

    return response.status === 200 ? response.data : [];
  };

  testChannel = async (id) => {
    loadingStore.setLoading(true);

    const response = await client
      .post(PUBLICATION_SETTINGS_CONF + '/connection/test/' + id)
      .catch((error) => {
        loadingStore.setLoading(false);
        throw error;
      });

    loadingStore.setLoading(false);

    return response.status === 200 ? response.data : [];
  };

  connectChannelPage = async (pageId, redirectPages) => {
    loadingStore.setLoading(true);
    const request = {
      id: pageId,
      channelInstanceId: redirectPages.channelId
    };

    try {
      let response;
      if (redirectPages.channelType === 'FACEBOOK') {
        response = await client.post(PUBLICATION_SETTINGS_CONF + '/post/connection/facebook', request);
      } else if (redirectPages.channelType === 'INSTAGRAM') {
        response = await client.post(PUBLICATION_SETTINGS_CONF + '/post/connection/instagram', request);
      }

      if (!response || !response.status === 200) {
        throw new Error('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  retrieveChannelPreviewByChannelInstance = async (channelIds) => {
    try {
      loadingStore.setLoading(true);

      const response = await client.get(PUBLICATION_SETTINGS_CONF + '/connection/preview/' + channelIds);

      if (!response || !response.status === 200) {
        throw new Error('Something went wrong!');
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const publicationStore = new PublicationStore();

export default publicationStore;

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const PublishTimeOption = ({
  setStep,
  handleClose,
  handleAfterTimeSelection,
  whenToPublish,
  setWhenToPublish
}) => {
  return (
        <>
            <div className="h-[300px]">
                <div className='flex'>
                    <FormControl>
                        <FormLabel className='text-black' id="when-to-publish">When do you want to publish the content?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="when-to-publish"
                                value={whenToPublish}
                                onChange={(e) => setWhenToPublish(e.target.value)}
                                name="radio-buttons-group"
                                className='px-2 gap-4'
                            >
                                <FormControlLabel value="now" control={<Radio size="small" className="text-blue"/>} label="Now" />
                                <FormControlLabel value="later" control={<Radio size="small" className="text-blue" />} label="Later" />
                                <FormControlLabel value="recur" control={<Radio size="small" className="text-blue" />} label="Recur" />
                            </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <hr className="my-5" />
            <div className="flex items-center justify-between">
                <div className="flex justify-end w-full">
                    <button
                        className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                        variant="contained"
                        color="primary"
                        className="mb-4 mrvn-sv rounded"
                        onClick={() => handleAfterTimeSelection()}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
  );
};

export default PublishTimeOption;

/* eslint-disable array-callback-return */
import BarChartWidget from '../Common/BarChartWidget';

const ChannelBreakdownChart = ({ data }) => {
  return (
    <>
      {data.length > 0 && (
        <div>
          <BarChartWidget series={data} />
        </div>
      )}
    </>
  );
};

export default ChannelBreakdownChart;

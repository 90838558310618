import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dayjs from 'dayjs';

import DateTimeCalendar from './DateTimeCalendar';

const WhenToPublishOption = ({
  scheduleType,
  setScheduleType,
  setStep,
  publishDateTime,
  setPublishDateTime,
  publishDate,
  openCalendar,
  setPublishDate,
  publishTime,
  setPublishTime,
  handleClose,
  onSelectedDate,
  handleSubmitSchedule,
  dayOfWeekFormatter,
  handleCloseCalendar,
  setOpenCalendar
}) => {
  // useEffect(() => {

  //     timeSlotsArray.sort((a, b) => {
  //         if (a === publishTime) {
  //             return -1;
  //         } else if (b === publishTime) {
  //             return 1;
  //         }
  //         return a - b;
  //     });

  // },[openCalendar]);

  return (
    <>
      <div className="h-[300px]">
        <div>
          <FormControl>
            <FormLabel className="text-black" id="schedule-to-publish">
              Schedule the time you want your content to be published
            </FormLabel>
            <RadioGroup
              aria-labelledby="schedule-to-publish"
              value={scheduleType}
              name="radio-buttons-group"
              className="mt-5"
              onChange={(e) => setScheduleType(e.target.value)}
            >
              <FormControlLabel
                className="justify-between"
                labelPlacement="start"
                value="MIDNIGHT"
                control={<Radio size="small" className="text-blue" />}
                label="At midnight between 12 AM to 1 AM"
              />
              <FormControlLabel
                className="justify-between"
                labelPlacement="start"
                value="TOMORROW"
                control={<Radio size="small" className="text-blue" />}
                label="Tomorrow between 8 AM to 9 AM"
              />
              <FormControlLabel
                className="justify-between"
                labelPlacement="start"
                value="AFTER_WEEK"
                control={<Radio size="small" className="text-blue" />}
                label="After a week between 8 AM to 9 AM"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="pt-5">
          <a
            onClick={() => setOpenCalendar(true)}
            className="text-blue cursor-pointer"
          >
            Change Date and time
          </a>
          &nbsp;
          <b>{dayjs(publishDateTime).format('MMM, DD hh:mm A')}</b>
        </div>
        {openCalendar && (
          <DateTimeCalendar
            publishDate={publishDate}
            openCalendar={openCalendar}
            publishTime={publishTime}
            handleCloseCalendar={handleCloseCalendar}
            dayOfWeekFormatter={dayOfWeekFormatter}
            onSelectedDate={onSelectedDate}
            setPublishTime={setPublishTime}
          />
        )}
      </div>
      <hr className="my-5" />
      <div className="flex items-center justify-between">
        <button className="text-blue underline" onClick={() => setStep(1)}>
          Back
        </button>
        <div className="flex">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
            onClick={() => handleSubmitSchedule()}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default WhenToPublishOption;

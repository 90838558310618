import { makeAutoObservable } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';

const ENDPOINT = '/shadow/settings/integration';

class IntegrationsStore {
  state = {
    integrationConnectionType: {}
  };

  constructor () {
    makeAutoObservable(this);
  }

  getConnectionType = async () => {
    loadingStore.setLoading(true);
    try {
      const result = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.integrationConnectionType = result ? result.data : null;
      return this.state.integrationConnectionType;
    } catch (error) {
      console.error('Failed to fetch connection type :', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createConnectionType = async (postData) => {
    loadingStore.setLoading(true);
    try {
      const result = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, postData);
      this.state.integrationConnectionType = result ? result.data : null;
      loadingStore.setLoading(false);
      return this.state.integrationConnectionType;
    } catch (error) {
      console.error('Failed to fetch connection type :', error.response.data);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateConnectionType = async (id, postData) => {
    loadingStore.setLoading(true);
    try {
      const result = await client.put(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`, postData);
      this.state.integrationConnectionType = result ? result.data : null;
      return this.state.integrationConnectionType;
    } catch (error) {
      console.error('Failed to fetch connection type :', error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateConnectionTypeStatus = async (id, status) => {
    loadingStore.setLoading(true);
    try {
      const result = await client.patch(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/${status}`);
      this.state.integrationConnectionType = result ? result.data : null;
      return this.state.integrationConnectionType;
    } catch (error) {
      console.error('Failed to fetch connection type :', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const integrationsStore = new IntegrationsStore();
export default integrationsStore;

// material
import { Container, Modal } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import ModalHead from '../../../components/Modal/Header/modalHead';
import CompanyNewForm from '../../Modal/Organisation/CompanyNewForm';

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};

export default function CompanyCreate ({
  open,
  handleClose,
  isEdit,
  currentCompany
}) {
  const { themeStretch } = useSettings();

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          ...modalStyle,
          backgroundColor: 'white',
          width: '800px',
          flexDirection: 'column'
        }}
      >
        <ModalHead
          handleClose={handleClose}
          heading={!isEdit ? 'Add Company' : 'Edit Company'}
        />
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <CompanyNewForm
            isEdit={isEdit}
            currentCompany={currentCompany}
            handleClose={handleClose}
          />
        </Container>
      </div>
    </Modal>
  );
}

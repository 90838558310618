import { useEffect, useState } from 'react';

import PopupLayout from '../../../layout/PopupLayout';
import Stepbar from './Stepbar';
import PublishTimeOption from './PublishTimeOption';
import WhenToPublishOption from './WhenToPublishOption';
import ReadyToPublishOption from './ReadyToPublishOption';
import dayjs from 'dayjs';
import loadingStore from '../../../store/loading';
import ArticleApi from '../../../api/content';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { EndOnType } from '../../../constants/EndOnType';
import Recurringoption from './Recurringoption';

const SchedulePublish = ({ open, handleClose, contents, content, versions }) => {
  const [step, setStep] = useState(1);
  const [publishDateTime, setPublishDateTime] = useState('');
  const [publishDate, setPublishDate] = useState(dayjs());
  const [publishTime, setPublishTime] = useState('');
  const [whenToPublish, setWhenToPublish] = useState('now');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [scheduleType, setScheduleType] = useState('MIDNIGHT');

  const [recurringState, setRecurringState] = useState({
    recurringType: 'Week',
    noOfRepetition: 1,
    weekDaysRepetition: [],
    endingCondition: EndOnType.Never,
    endDate: '',
    endAfterOccurrence: 1
  });

  const { setLoading } = loadingStore;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setPublishDateTime(publishDate + ' ' + publishTime);
  }, [publishDate, publishTime]);

  useEffect(() => {
    if (whenToPublish === 'recur') {
      setPublishDate(dayjs().startOf('day').format('YYYY-MM-DD'));
      setPublishTime('12:00 AM');
      setRecurringState({
        ...recurringState,
        endDate: dayjs().add(1, 'day').startOf('day').format('YYYY-MM-DD')
      });
    }
  }, [whenToPublish]);

  useEffect(() => {
    if (scheduleType === 'MIDNIGHT') {
      setPublishDate(dayjs().add(1, 'day').startOf('day').format('YYYY-MM-DD'));
      setPublishTime('12:00 AM');
    } else if (scheduleType === 'TOMORROW') {
      setPublishDate(dayjs().add(1, 'day').startOf('day').format('YYYY-MM-DD'));
      setPublishTime('08:00 AM');
    } else if (scheduleType === 'AFTER_WEEK') {
      setPublishDate(dayjs().add(7, 'day').startOf('day').format('YYYY-MM-DD'));
      setPublishTime('08:00 AM');
    }
  }, [scheduleType]);

  const handleAfterTimeSelection = () => {
    if (whenToPublish === 'now') {
      setPublishDate(dayjs().format('YYYY-MM-DD'));
      setPublishTime(dayjs().format('hh:mm A'));
      setStep(4);
    } else if (whenToPublish === 'recur') {
      setStep(3);
    } else {
      setStep(2);
    }
  };

  const onSelectedDate = (date) => {
    setPublishDate(date.format('YYYY-MM-DD'));
  };

  const handleSubmitSchedule = () => {
    if (publishDate !== '' && publishTime !== '') {
      setStep(4);
    }
  };

  const dayOfWeekFormatter = (day) => {
    return day.toUpperCase();
  };

  const handleCloseCalendar = () => {
    setOpenCalendar(false);
  };

  useEffect(() => {
    if (whenToPublish === 'now' && step === 2) {
      setStep(1);
    }
    if (whenToPublish === 'recur' && step === 2) {
      setStep(3);
    }
  }, [step]);

  const handlePublish = () => {
    setLoading(true);

    const params = {};
    params.rootArticleId = null;
    params.articleId = contents ? contents[0]?.id : content.id;

    if (whenToPublish !== 'now') {
      const localDateObject = new Date(publishDate + ' ' + publishTime);
      params.scheduledStartOn = localDateObject
        .toISOString()
        .replace(/\.\d{3}Z$/, 'Z');
    }

    const rootArticle = versions && versions.find((x) => !x.parentId);

    if (whenToPublish === 'recur') {
      params.recurType = recurringState.recurringType;
      if (recurringState.endingCondition === EndOnType.EndDate) {
        const localEndDate = new Date(recurringState.endDate);
        params.recurEndOnDate = localEndDate
          .toISOString()
          .replace(/\.\d{3}Z$/, 'Z');
      }
      if (recurringState.endingCondition === EndOnType.OccurrenceNumber) {
        params.recurEndAfterOccur = recurringState.endAfterOccurrence;
      }
      params.repeatAfter = recurringState.noOfRepetition;
      if (recurringState.recurringType === 'Week') {
        params.dayOfWeek = recurringState.weekDaysRepetition;
      }

      ArticleApi.recurPublish(params).then((reponse) => {
        setLoading(false);
        if (whenToPublish === 'recur') {
          enqueueSnackbar('Your article has been scheduled to repeat', {
            variant: 'success'
          });
        }
      });
    } else {
      ArticleApi.postPublish(params).then((reponse) => {
        setLoading(false);
        if (whenToPublish !== 'now') {
          enqueueSnackbar('Your article has been scheduled for published', {
            variant: 'success'
          });
        }
      });
    }

    handleClose();
    setStep(1);
    if (rootArticle) {
      navigate('/content/' + rootArticle.id + '/publication-status-history');
    } else {
      navigate('/content/' + params.articleId + '/publication-status-history');
    }
  };

  return (
    <PopupLayout
      className="wizard"
      open={open}
      handleClose={() => {
        handleClose();
      }}
      heading="Publish contents"
      width={730}
    >
      <div className="h-full w-full bg-white pr-3">
        <Stepbar step={step} />

        {step === 1 && (
          <PublishTimeOption
            setStep={setStep}
            handleClose={() => {
              handleClose(false);
            }}
            whenToPublish={whenToPublish}
            setWhenToPublish={setWhenToPublish}
            handleAfterTimeSelection={handleAfterTimeSelection}
          />
        )}
        {step === 2 && (
          <WhenToPublishOption
            scheduleType={scheduleType}
            setScheduleType={setScheduleType}
            setStep={setStep}
            publishDateTime={publishDateTime}
            setPublishDateTime={setPublishDateTime}
            publishDate={publishDate}
            openCalendar={openCalendar}
            setPublishDate={setPublishDate}
            publishTime={publishTime}
            setPublishTime={setPublishTime}
            handleClose={handleClose}
            onSelectedDate={onSelectedDate}
            handleSubmitSchedule={handleSubmitSchedule}
            dayOfWeekFormatter={dayOfWeekFormatter}
            handleCloseCalendar={handleCloseCalendar}
            setOpenCalendar={setOpenCalendar}
          />
        )}

        {step === 3 && (
          <Recurringoption
            recurringState={recurringState}
            setRecurringState={setRecurringState}
            openCalendar={openCalendar}
            setStep={setStep}
            publishDateTime={publishDateTime}
            setPublishDateTime={setPublishDateTime}
            publishDate={publishDate}
            setPublishDate={setPublishDate}
            publishTime={publishTime}
            setPublishTime={setPublishTime}
            handleClose={handleClose}
            onSelectedDate={onSelectedDate}
            handleSubmitSchedule={handleSubmitSchedule}
            dayOfWeekFormatter={dayOfWeekFormatter}
            handleCloseCalendar={handleCloseCalendar}
            setOpenCalendar={setOpenCalendar}
          />
        )}

        {step === 4 && (
          <ReadyToPublishOption
            setStep={setStep}
            publishDateTime={publishDateTime}
            publishDate={publishDate}
            publishTime={publishTime}
            contents={contents}
            content={content}
            recurringState={recurringState}
            whenToPublish={whenToPublish}
            handlePublish={handlePublish}
            handleClose={handleClose}
          />
        )}
      </div>
    </PopupLayout>
  );
};

export default observer(SchedulePublish);

import History from './Versions';
import Phrases from '../Phrases';
import { observer } from 'mobx-react-lite';
import EntitiesList from '../OrganisationalEntities/EntitiesList';
import Tags from '../../../components/ContentManagement/Tags';
import PostPerview from '../../../components/ContentManagement/PostChannelPreview/PostPerview';

const RightMenu =
  ({
    distracted,
    showVersionComparison,
    docHistory,
    previousVersionForm,
    viewOnly,
    openSidebarMenu,
    docText,
    contentParams,
    setContentParams,
    tags,
    setTags,
    title,
    content,
    files,
    selectedChannels,
    setHashTag,
    setCustomTag,
    mentions
  }) => {
    return (
      <div className={`${distracted ? 'w-0' : 'w-[415px]'} p-4 flex flex-col gap-3`} >
        {openSidebarMenu === 'post-preview' && (
          <div>
            <PostPerview
              title={title}
              content={content}
              files={files}
              viewOnly={viewOnly}
              plainText={docText}
              selectedChannels={selectedChannels}
              mentions={mentions}
            />
          </div>
        )}
        {openSidebarMenu === 'version-history' && (
          <History
            docHistory={docHistory}
            distracted={distracted}
            showVersionComparison={showVersionComparison}
            previousVersionForm={previousVersionForm}
            viewOnly={viewOnly}
          />
        )}
        {openSidebarMenu === 'visibility' && (
          <>
            <div>
              <p className="font-bold text-xl">Visibility</p>
              <p className="font-bold text-[#666666]">Set Visibility of content</p>
              <hr className="mt-3" />
              <EntitiesList
                contentParams={contentParams}
                setContentParams={setContentParams}
                viewOnly={viewOnly}
              />
            </div>
          </>
        )}
        {!viewOnly && openSidebarMenu === 'phrases' && (
          <>
            <div>
              <Phrases docText={docText} />
            </div>
          </>
        )}
        {openSidebarMenu === 'tag' && (
          <Tags
            selectedTags={tags}
            setSelectedTags={setTags}
            plainText={docText}
            viewOnly={viewOnly}
            setHashTag={setHashTag}
            setCustomTag={setCustomTag}
          />
        )}
      </div>
    );
  };

RightMenu.displayName = 'RightMenu';

export default observer(RightMenu);

import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';

// hooks
// components

import { AddRestrictionButton } from '../../components/AddButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import { observer } from 'mobx-react';
import {
  BulkDeleteButton
} from '../../components/DeleteButton';
import channelsStore from '../../store/channel/channel';

import { useSnackbar } from 'notistack';
import RuleMoreMenu from '../../components/Security/channelList/rulesMoreMenu';
import TableSkelton from '../../components/Table/Skeleton';
import canI from '../../utils/canI';
import NoContent from '../../components/NoContent';

const Restrictions = () => {
  const {
    state: { channelRuleTypes, types, userChannels },
    fetchRuleTypes,
    fetchChannelTypes,
    fetchUserChannels,
    deleteRule,
    searchRestications
  } = channelsStore;

  const { enqueueSnackbar } = useSnackbar();

  const [selected, setSelected] = useState([]);
  const [channelInstances, setChannelInstances] = useState([]);

  // @PS
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [params, setParams] = useState({
    searchText: '',
    channelTypeId: '',
    channelInstanceTypeId: '',
    channelRuleTypeId: '',
    ruleTypeId: '',
    pageSize: 10,
    pageIndex: 1,
    sortDirection: 'desc',
    sortColumn: 'id'
  });

  const refresh = () => {
    setLoading(true);
    searchRestications(params).then((response) => {
      setItems(response.content ?? []);
      setTotalPages(response.page);
      setLoading(false);
    });
  };

  useEffect(() => {
    refresh();
  }, [
    params.searchText,
    params.channelTypeId,
    params.channelInstanceTypeId,
    params.channelRuleTypeId,
    params.ruleTypeId,
    params.pageSize,
    params.pageIndex,
    params.sortDirection,
    params.sortColumn
  ]);

  useEffect(() => {
    setParams({ ...params, pageIndex: currentPage });
  }, [currentPage]);

  const TABLE_HEAD = [
    { id: 'name', label: 'Restriction Name', isSorted: params.sortColumn === 'name' },
    {
      id: 'parameterType',
      label: 'Level',
      isSorted: params.sortColumn === 'parameterType',
      ignoreSort: true
    },
    {
      id: 'appliesTo',
      label: 'Applies to',
      ignoreSort: true
    },
    {
      id: 'entityType',
      label: 'Rule Type',
      isSorted: params.sortColumn === 'entityType',
      ignoreSort: true
    },
    {
      id: 'description',
      label: 'Rule',
      isSorted: params.sortColumn === 'description',
      ignoreSort: true
    },
    {
      id: 'moreMenu'
    }
  ];

  useEffect(() => {
    fetchRuleTypes();
    fetchChannelTypes();
    fetchUserChannels();
  }, []);

  useEffect(() => {
    if (params.channelTypeId === '') {
      setChannelInstances(userChannels);
    } else {
      setChannelInstances(
        userChannels.filter((x) => x.type_id === parseInt(params.channelTypeId))
      );
    }
  }, [userChannels, params.channelTypeId]);

  const handleRequestSort = (event, property) => {
    const isAsc = params.sortColumn === property && params.sortDirection === 'asc';
    setParams({
      ...params,
      sortDirection: isAsc ? 'desc' : 'asc',
      sortColumn: property
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.filter((item) => item.editable).map((item) => item.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    // check if this is editable
    const selectedItem = items.find((item) => item.id === name);
    if (selectedItem && selectedItem.editable === false) {
      return false;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleDelete = (id) => {
    deleteRule(id).then((res) => {
      res === 1
        ? enqueueSnackbar('Rule deleted successfully', { variant: 'success' }) && refresh()
        : enqueueSnackbar('Failed to delete rule', { variant: 'error' });

      res === 1 && setSelected(selected => selected.filter(item => item !== id));
    }
    );
  };

  const isUserNotFound = items.length === 0;

  return (
    <>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Channel Rules"
          actions={[
            canI(['delete:channelrules']) && <BulkDeleteButton
              onDelete={deleteRule}
              selected={selected}
              setSelected={setSelected}
              heading={['Rule', 'Rules']}
              refresh={refresh}
            />,
            canI(['create:channelrules']) && <AddRestrictionButton refresh={refresh} />
          ]}
        />
        <Card style={{ boxShadow: 'none' }}>
        <div className="flex items-center">
          <div style={{ width: '40%' }}>
            <ListToolbar
              numSelected={selected.length}
              filterName={params.searchText}
              onFilterName={(e) => setParams({
                ...params,
                searchText: e.target.value,
                pageIndex: 1
              })}
            />
          </div>

          <select
            className="ml-5 px-3 rounded outline-none border-[1px] border-[#ccc] py-3"
            value={params.channelTypeId}
            onChange={(e) => setParams({
              ...params,
              channelTypeId: e.target.value,
              pageIndex: 1
            })}
          >
            <option className="hidden" value="">
              Channel Type
            </option>
            <option value="">All Types</option>
            {types.map((x, i) => (
              <option key={i} value={x.id}>
                {x.channelName}
              </option>
            ))}
          </select>

          <select
            className="ml-5 px-3 rounded outline-none border-[1px] border-[#ccc] py-3 w-[150px]"
            value={params.channelRuleTypeId}
            onChange={(e) => setParams({
              ...params,
              channelRuleTypeId: e.target.value,
              pageIndex: 1
            })}
          >
            <option className="hidden" value="">
              Rule Type
            </option>
            <option value="">All Types</option>
            {/* {ruleTypes.map((x, i) => (
              <option key={i} value={x.id}>
                {x.name}
              </option>
            ))} */}
            <option value="attachment">Attachment</option>
            <option value="body">Body</option>
            <option value="tags">Tags</option>
            <option value="title">Title</option>
          </select>
          <select
            className="ml-5 px-3 rounded outline-none border-[1px] border-[#ccc] py-3"
            value={params.channelInstanceTypeId}
            onChange={(e) => setParams({
              ...params,
              channelInstanceTypeId: e.target.value,
              pageIndex: 1
            })}
          >
            <option className="hidden" value="">
              Applies To
            </option>
            <option value="">All Types</option>
            {channelInstances?.filter((x) => x.active).map((x, i) => (
              <option key={i} value={x.id}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        {items.length > 0 && (
          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
          <Table>
            <ListHead
              order={params.sortDirection}
              orderBy={params.sortColumn}
              headLabel={TABLE_HEAD}
              // rowCount={roleList.length}
              rowCount={items.filter((item) => item.editable).length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              { loading && (<TableSkelton rows={params.pageSize} columns={6}></TableSkelton>)}
              { !loading && items.map((row, index) => {
                const isItemSelected = selected.indexOf(row.id) !== -1;
                return (
                    <Row
                      key={index}
                      row={row}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                      types={types}
                      channelInstances={channelInstances}
                      ruleTypes={channelRuleTypes}
                      handleDelete={handleDelete}
                      refresh={refresh}
                    />
                );
              })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{ py: 3 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
          </TableContainer>
        )}
        </Card>
      </Container>
      {!loading && items.length < 1 && NoContent('No Channel Rules Found')}
    </>
  );
};

export default observer(Restrictions);

const Row = ({
  row,
  isItemSelected,
  handleClick,
  types,
  channelInstances,
  ruleTypes,
  handleDelete,
  refresh
}) => {
  const [channelType, setChannelType] = useState({});
  const [channelInstance, setChannelInstance] = useState({});

  useEffect(() => {
    row.channelTypeId &&
      setChannelType(
        types.find((x) => x.id === row.channelTypeId)
          ? types.find((x) => x.id === row.channelTypeId)
          : {}
      );

    row.channelInstancesId &&
      setChannelInstance(
        channelInstances.find((x) => x.id === row.channelInstancesId)
          ? channelInstances.find((x) => x.id === row.channelInstancesId)
          : {}
      );
  }, [row, types, channelInstances, ruleTypes]);

  return (
    <TableRow
      hover
      key={row.id}
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, row.id)}
        />
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">
        {row.channelTypeId && 'Channel'}
        {row.channelInstancesId && 'Channel Instance'}
      </TableCell>
      <TableCell>
        {row.channelTypeId && channelType.channelName}
        {row.channelInstancesId && channelInstance.name}
      </TableCell>
      <TableCell align="left">{row.channelRuleType.entityType}</TableCell>
      <TableCell align="left">
        {row.channelRuleType.description}
        <br />
        {row.channelRuleType.parameterType !== 'BOOLEAN' && (
          <>
          Paramter:{' '}
          {row.channelRuleType.parameterType === 'INT'
            ? row.paramInt0
            : row.channelRuleType.parameterType === 'BETWEEN'
              ? row.paramInt0 + ', ' + row.paramInt1
              : row.channelRuleType.parameterType === 'BETWEEN_DOUBLE'
                ? row.paramDouble0 + ', ' + row.paramDouble1
                : (row.channelRuleType.parameterType === 'STRING' || row.channelRuleType.parameterType === 'DROPDOWN') && row.paramString}
          </>
        )}
      </TableCell>
      <TableCell align="right">
        {row.editable && (
          <RuleMoreMenu refresh={refresh} onDelete={() => handleDelete(row.id)} row={row} />
        )}
      </TableCell>
    </TableRow>
  );
};

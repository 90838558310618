import {
  Card,
  Modal,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import ListHead from '../../../components/Global/ListHead';
import ListToolbar from '../../../components/Global/ListToolbar';

import userStore from '../../../store/security/user';

import { observer } from 'mobx-react';
import fileStore from '../../../store/file';
import ModalHead from '../Header/modalHead';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        (_user.givenName &&
          _user.givenName.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_user.familyName &&
          _user.familyName.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_user.emailAddress &&
          _user.emailAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const MembershipForm = ({
  open,
  handleClose,
  handleClickMembership,
  memberships
}) => {
  const {
    state: { users }
    // getUsers,
  } = userStore;

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('givenName');
  const [filterName, setFilterName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const rowsPerPage = 10;
  const itemsPerPage = 10;
  const page = 0;

  const TABLE_HEAD = [
    { id: 'givenName', label: 'User', isSorted: orderBy === 'givenName' },
    { id: 'read', label: 'Read Only' }
  ];

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    visibleItems.length === 0 &&
      currentPage > 1 &&
      setCurrentPage(currentPage - 1);
  }, [visibleItems]);

  // useEffect(() => {
  //   getUsers().then(
  //     (res) =>
  //       res === 0 &&
  //       enqueueSnackbar("Failed to fetch users", { variant: "error" })
  //   );
  // }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Modal open={open}>
      <div className="flex items-center h-full drop-shadow-lg">
        <div className="bg-white mx-auto rounded-lg">
          <ModalHead handleClose={handleClose} heading="Membership" />
          <div className="px-5">
            <Card style={{ boxShadow: 'none' }}>
              <ListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <TableContainer
                sx={{ minWidth: 800, maxHeight: '70vh' }}
                style={{ padding: '0 25px' }}
              >
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    onRequestSort={handleRequestSort}
                    nocheckbox
                  />
                  <TableBody className="">
                    {visibleItems
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, key) => {
                        return (
                          <Row
                            row={row}
                            key={key}
                            handleClickMembership={handleClickMembership}
                            membership={memberships.find(
                              (x) => x.user_id === row.id
                            )}
                          />
                        );
                      })}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={6}
                          sx={{ py: 3 }}
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                <Stack spacing={2} className="pagination">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                  />
                </Stack>
              </TableContainer>
            </Card>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default observer(MembershipForm);

const Row = ({
  row,
  row: { id, givenName, familyName },
  handleClickMembership,
  membership
}) => {
  const { fetchImageByID } = fileStore;
  const [image, setImage] = useState(null);

  useEffect(() => {
    row.imageId
      ? fetchImageByID(row.imageId).then((res) => {
        res !== 0 ? setImage(res) : setImage(null);
      })
      : setImage(null);
  }, [row, row.imageId]);

  const handleClick = (e) => {
    handleClickMembership(e.target.checked, id);
  };

  return (
    <Fragment>
      <TableRow hover key={row.id}>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <img
              alt={givenName}
              className="w-10 h-10 object-contain"
              src={image || process.env.REACT_APP_PLACEHOLDER_IMAGE}
            />
            <Typography variant="subtitle2" noWrap>
              {givenName} {familyName}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">
          <input
            type="checkbox"
            checked={!!(membership && (membership.is_read_only || membership.readOnly))}
            onChange={handleClick}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

/* eslint-disable array-callback-return */
/* eslint-disable quotes */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
import { useContext, useEffect, useRef, useState } from 'react';

import ToggleButton from '../../ToggleButton';
import Channel from './ChannelItem';
import InstanceList from './InstanceList';
import RestrictionList from './RestrictionList';

import { useSnackbar } from 'notistack';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import {
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react';
import channelGroupAPI from '../../../store/channel/channelGroup';
import contentStore from '../../../store/content/content';
import { CompanyChannelListContext } from '../../../pages/ContentManagement';
import { uniqBy } from 'lodash';

const ChannelList = ({
  plainText,
  title,
  selectedChannelList,
  setSelectedChannelList,
  setShowAlternate,
  viewOnly,
  selectAllChannel,
  metaCompanies,
  companyId,
  setCompanyId,
  parentId,
  contentId,
  channelGroupIds,
  setChannelGroupIds
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const companyChannelList = useContext(CompanyChannelListContext);

  const { state: { versions } } = contentStore;

  const { fetchChannelGroup } = channelGroupAPI;

  const channelsRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [sync, setSync] = useState(true);
  const [showViolationDropdown, setShowViolationDropdown] = useState(false);
  const [channelGroups, setChannelGroups] = useState([]);

  const [violationTypes, setViolationTypes] = useState([
    { name: 'Attachments', selected: true },
    { name: 'Body', selected: true },
    { name: 'Tags', selected: true },
    { name: 'Title', selected: true }
  ]);

  const [selectedChannel, setSelectedChannel] = useState(null);
  const [displayInstanceFor, setDisplayInstanceFor] = useState(null);
  const [toggleSelectAllChannel, setToggleSelectAllChannel] = useState(selectAllChannel);
  const [displayChannelList, setDisplayChannelList] = useState([]);
  const [filteredChannelList, setFilteredChannelList] = useState([]);
  const isAllSelected =
    channelGroups.length > 0 && channelGroupIds &&
    channelGroupIds.length === channelGroups.length;

  const checkOverflow = () => {
    const element = channelsRef.current;
    setIsOverflowing(
      element.offsetWidth < element.scrollWidth ||
        element.offsetHeight < element.scrollHeight
    );
  };

  useEffect(() => {
    checkOverflow();

    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (channelsRef.current) {
      resizeObserver.observe(channelsRef.current);
    }

    return () => {
      if (channelsRef.current) {
        resizeObserver.unobserve(channelsRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchChannelGroup(companyId).then((res) => {
        const groups = res.filter((r) => r.channelInstances);
        setChannelGroups(groups);
        if (groups.length === 0) {
          setFilteredChannelList([]);
          setDisplayChannelList([]);
        }
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (channelGroups.length > 0) {
      if (channelGroupIds) {
        const selectedGroups = channelGroups.filter(g => channelGroupIds.includes(g.id));
        const instances = selectedGroups.length ? uniqBy(selectedGroups.flatMap((g) => g.channelInstances), 'id') : [];
        setFilteredChannelList(instances);
        setDisplayChannelList(instances);
        const selectedInstances = instances.filter(i => selectedChannelList.includes(i.id));
        setSelectedChannelList(selectedInstances.map(i => i.id));
      } else {
        setChannelGroupIds(channelGroups.map(g => g.id));
      }
    }
  }, [channelGroupIds, channelGroups]);

  useEffect(() => {
    if (!contentId) {
      if (selectAllChannel && filteredChannelList.length > 0) {
        setSelectedChannelList(filteredChannelList.map((x) => x.id));
      } else {
        setSelectedChannelList([]);
      }
    }
  }, [contentId, selectAllChannel, filteredChannelList]);

  const handleChannelClick = (position, channel, violations, icon) => {
    selectedChannel && selectedChannel.channel.id === channel.id
      ? setSelectedChannel(null)
      : setSelectedChannel({
        position,
        channel,
        violations,
        icon
      });
  };

  const handleShowInstance = (position, channel, icon) => {
    setDisplayInstanceFor({
      position,
      channel,
      icon
    });
  };

  const handleSelectChannel = (value) => {
    if (viewOnly) {
      return;
    }

    if (sync) {
      selectedChannelList.find((x) => x === value)
        ? setSelectedChannelList((selected) =>
          selected.filter((x) => x !== value)
        )
        : setSelectedChannelList((selected) => [...selected, value]);
    } else {
      setSelectedChannelList([value]);
    }
  };

  const handleDeselectChannel = (value) => {
    if (viewOnly) {
      return;
    }

    if (sync) {
      setSelectedChannelList((selected) => selected.filter((x) => x !== value));
    } else {
      setSelectedChannelList([]);
    }
  };

  const handleToggleSync = () => {
    !sync
      ? setSync(true)
      : selectedChannelList.length > 1
        ? enqueueSnackbar(
          'Please only select one channel for this content version',
          {
            variant: 'warning'
          }
        )
        : setSync(false);
  };

  const handleCheck = (e, index) => {
    setViolationTypes((violationTypes) =>
      violationTypes.map((x, i) =>
        i === index ? { ...x, selected: e.target.checked } : x
      )
    );
  };

  const handleScrollLeft = () => {
    channelsRef.current.scrollBy({ left: -100, behavior: 'smooth' });
  };

  const handleScrollRight = () => {
    channelsRef.current.scrollBy({ left: 100, behavior: 'smooth' });
  };

  useEffect(() => {
    if (contentId && versions && versions.length > 0 && filteredChannelList.length > 0) {
      const versionChannelIds = versions.filter(v => v.id !== contentId).flatMap(v => v.channelId);
      setDisplayChannelList(filteredChannelList.filter(ch => !versionChannelIds.includes(ch.id)));
    }
  }, [contentId, versions, filteredChannelList]);

  useEffect(() => {
    setToggleSelectAllChannel(displayChannelList.length > 0 && selectedChannelList?.length === displayChannelList.length);
  }, [selectedChannelList, displayChannelList]);

  const handleSelectAllToggle = () => {
    if (!filteredChannelList.some(fch => companyChannelList.some(cch => cch.id === fch.id && cch.failed_rules))) {
      toggleSelectAllChannel
        ? setSelectedChannelList([])
        : setSelectedChannelList(filteredChannelList.map((x) => x.id));
      setToggleSelectAllChannel(!toggleSelectAllChannel);
    } else {
      enqueueSnackbar('Channels cannot be selected due to restriction ', {
        variant: 'error'
      });
    }
  };

  const filterChannelList = (event) => {
    if (event.target.value.includes(-1)) {
      setChannelGroupIds(
        channelGroupIds.length === channelGroups.length
          ? []
          : channelGroups.map((cg) => cg.id)
      );
    } else {
      setChannelGroupIds(event.target.value);
    }
  };

  return (
    <>
      <div
        className={`py-2 px-5 my-2 w-full bg-white rounded-lg flex items-center`}
      >
        <Grid container>
          <Grid container item sm={12} md={9}>
            <Grid item md={6} sm={12}>
              <FormControl fullWidth className="flex flex-row items-center">
                <label htmlFor="companyId" className="mr-2">
                  Select company
                </label>
                <Select
                  className="w-3/5"
                  value={companyId}
                  onChange={(e) => {
                    setChannelGroupIds(null);
                    setChannelGroups([]);
                    setCompanyId(e.target.value);
                  }}
                  disabled = {parentId}
                >
                  {metaCompanies.map((x, i) => (
                    <MenuItem key={i} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={12} className="pl-2">
              {channelGroups.length > 0 && channelGroupIds && (
                <div className="w-100 flex items-center">
                  <label className="mr-1">Filter By Group</label>
                  <Select
                    multiple
                    value={channelGroupIds}
                    className="w-3/5"
                    onChange={filterChannelList}
                    renderValue={(selected) => {
                      return selected
                        .map(
                          (value) =>
                            channelGroups.find((g) => g.id === value)?.groupName
                        )
                        .join(',');
                    }}
                  >
                    <MenuItem value={-1}>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          channelGroupIds.length > 0 &&
                          channelGroupIds.length < channelGroups.length
                        }
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {channelGroups.map((group, i) => (
                      <MenuItem key={i} value={group.id}>
                        <Checkbox
                          checked={channelGroupIds.indexOf(group.id) > -1}
                        />
                        <ListItemText primary={group.groupName} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container item md={3} sm={12}>
            <Grid
              item
              xs={12}
              className="flex flex-row items-center justify-end gap-2"
            >
              <p className="whitespace-nowrap text-xs">Select All Channel</p>
              <ToggleButton
                toggle={toggleSelectAllChannel}
                handleClick={handleSelectAllToggle}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className="flex flex-row items-center justify-end gap-2"
            >
              <p className="whitespace-nowrap text-xs">Multi-Channel Mode</p>
              <ToggleButton toggle={sync} handleClick={handleToggleSync} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={`p-5 w-full bg-white rounded-lg flex items-center`}>
        {isOverflowing && (
          <button
            className="bg-[#eee]/20 h-12 px-2 text-[#ccc]"
            onClick={handleScrollLeft}
          >
            <FaChevronLeft />
          </button>
        )}
        <div
          ref={channelsRef}
          className="w-full flex items-center gap-2 overflow-x-auto scrollbar-hide"
        >
          { channelGroups.length > 0
            ? (channelGroupIds?.length > 0
                ? displayChannelList.map((item) => item.id).map((x, i) => (
                  <Channel
                    key={i}
                    channelId={x}
                    handleSelect={handleSelectChannel}
                    handleDeselect={handleDeselectChannel}
                    multiSelected={selectedChannelList}
                    handleChannelClick={handleChannelClick}
                    handleShowInstance={handleShowInstance}
                    plainText={plainText}
                    title={title}
                  />
                ))
                : <Typography className='font-black'>Please select channel group</Typography>)
            : <Typography className='font-black'>No Channel Groups for selected company</Typography>
          }
        </div>
        {isOverflowing && (
          <button
            className="bg-[#eee]/20 h-12 px-2 text-[#ccc] mr-5"
            onClick={handleScrollRight}
          >
            <FaChevronRight />
          </button>
        )}
      </div>

      {selectedChannel && (
        <RestrictionList
          channelsRef={channelsRef}
          selectedChannel={selectedChannel}
          showDropdown={showViolationDropdown}
          setShowDropdown={setShowViolationDropdown}
          violationTypes={violationTypes}
          handleCheck={handleCheck}
          close={() => setSelectedChannel(null)}
          setShowAlternate={setShowAlternate}
        />
      )}

      {displayInstanceFor && (
        <InstanceList
          channelsRef={channelsRef}
          selectedChannel={displayInstanceFor}
          close={() => setDisplayInstanceFor(null)}
        />
      )}
    </>
  );
};

export default observer(ChannelList);

import { useEffect, useState } from 'react';
import notificationStore from '../../../../store/settings/notification';

export default function useNotificactionSearch (params) {
  const {
    getNotifications,
    markNotificationAsRead,
    markAllNotificationAsRead
  } = notificationStore;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const markAllAsRead = () => {
    markAllNotificationAsRead();
    const updatedItem = items.map((listItem) => {
      return {
        ...listItem,
        isRead: true
      };
    });
    setItems(updatedItem);
  };

  const markAsRead = (item) => {
    if (!item.isRead) {
      markNotificationAsRead(item.id);
      const updatedItem = items.map((listItem) => {
        if (listItem.id === item.id) {
          return {
            ...listItem,
            isRead: true
          };
        }
        return listItem;
      });
      setItems(updatedItem);
    }
  };

  useEffect(() => {
    setLoading(true);
    getNotifications(params).then((response) => {
      if (response.content) {
        setItems(prevItems => [...new Set([...prevItems, ...response?.content])]);
        setHasMore(response.content.length > 0);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    });
  }, [params, getNotifications]);

  return { loading, items, hasMore, markAllAsRead, markAsRead };
}

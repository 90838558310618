export const SUPPORTED_FILE_TYPES = {
  supported_types: [
    'video/mp4',
    'video/avi',
    'video/x-matroska',
    'video/ogg',
    'video/webm',
    'video/quicktime',
    'video/mpeg',
    'video/3gpp2',
    'video/x-ms-wmv',
    'video/3gpp',
    'image/jpeg',
    'image/png',
    'image/bmp',
    'image/webp',
    'image/gif',
    'application/pdf'
  ],
  video: {
    extensions: {
      mp4: 'video/mp4',
      avi: 'video/avi',
      mkv: 'video/x-matroska',
      ogv: 'video/ogg',
      webm: 'video/webm',
      mov: 'video/quicktime',
      mpg: 'video/mpeg',
      '3g2': 'video/3gpp2',
      wmv: 'video/x-ms-wmv',
      '3gp': 'video/3gpp'
    }
  },
  image: {
    extensions: {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      bmp: 'image/bmp',
      webp: 'image/webp',
      gif: 'image/gif'
    }
  },
  text: {
    extensions: {
      pdf: 'application/pdf'
    }
  },
  getFilterType (extension) {
    if (this.video.extensions[extension]) {
      return {
        contentType: 'video',
        mimeType: this.video.extensions[extension]
      };
    }

    if (this.image.extensions[extension]) {
      return {
        contentType: 'image',
        mimeType: this.image.extensions[extension]
      };
    }

    if (this.text.extensions[extension]) {
      return {
        contentType: 'text',
        mimeType: this.text.extensions[extension]
      };
    }

    return null;
  }
};

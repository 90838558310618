import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import AnimateButton from '../../components/AnimateButton';
import { strengthColor, strengthIndicator } from '../../utils/password-strength';
import AuthStore from '../../store/auth';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './authregister.css';
import moment from 'moment';
import ToggleButton from '../../components/ToggleButton';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconContext } from 'react-icons';
import currency from './../../utils/currency';
import ProductTemplate from '../../components/Product/Product';
import productDetail from '../../utils/product';

// ============================|| FIREBASE - REGISTER ||============================ //
const GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;

const AuthRegister = ({ switchLogin, filterProduct }) => {
  const { register } = AuthStore;
  const { enqueueSnackbar } = useSnackbar();
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [annualBilling, setAnnualBilling] = useState(false);
  const [defaultPlan, setDefaultPlan] = useState(null);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const handleChangeBillingCycle = () => {
    setAnnualBilling(!annualBilling);
  };

  useEffect(() => {
    changePassword('');
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.tolt.io/tolt.js';
    script.async = true;
    script.setAttribute(
      'data-tolt',
      process.env.REACT_APP_TOLT_KIT_ID
    );
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const productInfo = productDetail(annualBilling, defaultPlan?.prices);
  const futureDate = moment().add(defaultPlan?.months_trial, 'months').format('MMMM D, YYYY');

  return (
    <>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          company_name: '',
          password: '',
          confirmPassword: '',
          promoCode: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().max(255).required('First Name is required'),
          lastname: Yup.string().max(255).required('Last Name is required'),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
          confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
          captcha: Yup.boolean().test('is-captcha-completed', 'Please complete CAPTCHA verification', () => captchaCompleted)
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const data = {
              user: {
                first_name: values.firstname,
                last_name: values.lastname,
                email_address: values.email,
                password: values.password,
                connection_id: 'Username-Password-Authentication'
              },
              product: {
                name: defaultPlan?.name,
                frequency: productDetail(annualBilling, defaultPlan?.prices).interval === 'one_time' ? 'one_time' : annualBilling ? 'year' : 'month',
                seats: productDetail(annualBilling, defaultPlan?.prices).interval === 'one_time' ? 1 : productDetail(annualBilling, defaultPlan?.prices).interval_count
              },
              promotional_code: values.promoCode ? values.promoCode : 'code'
            };
            if (values.company_name !== '') {
              data.company_name = values.company_name;
            }
            if (window.tolt_referral) {
              data.referral = window.tolt_referral;
            }
            const result = await register(data);
            if (result && result.statusCode === 200) {
              window.location = result.checkoutUrl;
            } else {
              enqueueSnackbar('Something went wrong, Please try again.', { variant: 'error' });
            }
            setStatus({ success: false });
            setSubmitting(false);
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.response.data });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} >
            <Grid container className='flex h-full p-0 m-0'>
              <Grid container item xl={6} lg={6} md={6} sm={12}>
                <Grid item md={12} xs={12}>
                  <p className='z-10 text-lgfont-bold left-5 top-0 pl-5 pt-5'>
                    <img
                      className='h-14 object-contain'
                      src={process.env.PUBLIC_URL + '/static/icons/Marvinlogobig.svg'}
                      alt='logo'
                    />
                  </p>
                </Grid>
                <Grid item md={12} xs={12} className='content-center items-center flex justify-center'>
                  <ProductTemplate
                    defaultPlan={defaultPlan}
                    setDefaultPlan={setDefaultPlan}
                    annualBilling={annualBilling}
                    filterProduct={filterProduct}
                  />
                </Grid>
              </Grid>
              <Grid container item xl={5} lg={6} md={6} sm={12} className='bg-white p-10' spacing={1}>
                <Grid item xs={12} md={12}>
                  <p className='text-center text-2xl font-bold py-3 text-blue'>Signup for Marvin</p>
                </Grid>
                <Grid item xs={12}>
                  <hr></hr>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p className='uppercase text-center text-lg font-bold py-3 text-orange-400'>personal information</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='firstname-signup'>First Name*</InputLabel>
                    <OutlinedInput
                      id='firstname-login'
                      type='firstname'
                      value={values.firstname}
                      name='firstname'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='John'
                      fullWidth
                      error={Boolean(touched.firstname && errors.firstname)}
                    />
                    {touched.firstname && errors.firstname && (
                      <FormHelperText error id='helper-text-firstname-signup'>
                        {errors.firstname}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='lastname-signup'>Last Name*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.lastname && errors.lastname)}
                      id='lastname-signup'
                      type='lastname'
                      value={values.lastname}
                      name='lastname'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Doe'
                      inputProps={{}}
                    />
                    {touched.lastname && errors.lastname && (
                      <FormHelperText error id='helper-text-lastname-signup'>
                        {errors.lastname}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='email-signup'>Email Address*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      id='email-login'
                      type='email'
                      value={values.email}
                      name='email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='demo@company.com'
                      inputProps={{}}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id='helper-text-email-signup'>
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='company-name-signup'>Company Name</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.company_name && errors.company_name)}
                      id='company-name-signup'
                      type='company_name'
                      value={values.company_name}
                      name='company_name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Share With Marvin'
                      inputProps={{}}
                    />
                    {touched.company_name && errors.company_name && (
                      <FormHelperText error id='helper-text-company-name-signup'>
                        {errors.company_name}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='password-signup'>Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      id='password-signup'
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        changePassword(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                            size='large'
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder='******'
                      inputProps={{}}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id='helper-text-password-signup'>
                        {errors.password}
                      </FormHelperText>
                    )}
                  </Stack>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item>
                        <Box sx={{ bgcolor: level?.color, width: 185, height: 8, borderRadius: '7px' }} />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1' fontSize='0.75rem'>
                          {level?.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='password-signup'>Confirm Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      id='confirm-password-signup'
                      type={showPassword ? 'text' : 'password'}
                      value={values.confirmPassword}
                      name='confirmPassword'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                            size='large'
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder='******'
                      inputProps={{}}
                    />
                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormHelperText error id='helper-text-password-signup'>
                        {errors.confirmPassword}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <hr></hr>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p className='uppercase text-center text-lg font-bold py-3 text-orange-400'>billing information</p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack direction='row-reverse' spacing={1} justifyContent='flex-start'>
                    <div className='flex'>
                      <Typography variant='body2' style={{ textAlign: 'left' }}>
                        <strong> Get extra 10% off on first bill , if billed annually</strong>
                        <span className='pl-5' style={{ float: 'right' }}><ToggleButton toggle={annualBilling} handleClick={handleChangeBillingCycle} /></span>
                      </Typography>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    {defaultPlan && (
                      <>
                        <Typography variant='body' style={{ textAlign: 'left' }}>
                          <strong>{defaultPlan?.name} Plan</strong>
                          <span style={{ float: 'right' }}>
                            {productInfo.interval === 'one_time' && (
                              currency(productInfo.unit_amount_decimal, productInfo.currency) + ' for 1 seat for lifetime'
                            )}
                            {productInfo.interval !== 'one_time' && (
                              currency(productInfo.unit_amount_decimal, productInfo.currency) + ' per seat'
                            )}
                          </span>
                        </Typography>
                        <Typography variant='body' style={{ textAlign: 'left' }}>
                          <strong>Due Now</strong>
                          <span style={{ float: 'right' }}>
                            {productInfo.interval === 'one_time' && (
                              currency(productInfo.unit_amount_decimal, productInfo.currency)
                            )}
                            {productInfo.interval !== 'one_time' && (
                              '£ 0'
                            )}
                          </span>
                        </Typography>
                        <Typography variant='body' style={{ textAlign: 'left' }}>
                          Your card will be charged
                          <span style={{ float: 'right' }}>
                            {
                              currency((productDetail(annualBilling, defaultPlan?.prices).unit_amount_decimal * productDetail(annualBilling, defaultPlan?.prices).interval_count),
                                productDetail(annualBilling, defaultPlan?.prices).currency)
                            } + tax on {futureDate}.</span>
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack direction='row-reverse' spacing={1} justifyContent='flex-start'>
                    <Button className='p-2 rounded-full hover:bg-gray-200 focus:outline-none' hidden>
                      <IconContext.Provider value={{ className: 'text-xl' }}>
                        <CheckBoxIcon />
                      </IconContext.Provider>
                    </Button>
                    <Typography variant='body2'>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.promoCode && errors.promoCode)}
                        id='confirm-promoCode-signup'
                        type={'text'}
                        value={values.promoCode}
                        name='promoCode'
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder='Promotional Code?'
                        inputProps={{}}
                      />
                      {touched.promoCode && errors.promoCode && (
                        <FormHelperText error id='helper-promoCode-signup'>
                          {errors.promoCode}
                        </FormHelperText>
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <hr></hr>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <ReCAPTCHA
                      sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                      onChange={() => setCaptchaCompleted(true)}
                    />
                    {touched.captcha && errors.captcha && (
                      <FormHelperText error id='helper-text-captcha-signup'>
                        {errors.captcha}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    By Signing up, you agree to our &nbsp;
                    <Link variant='subtitle2'>
                      <a style={{ color: '#2193B0', textDecoration: 'underline' }}
                        // eslint-disable-next-line react/no-unknown-property
                        without
                        rel='noreferrer'
                        href='https://sharewithmarvin.s3.eu-west-2.amazonaws.com/docs/Terms+and+Conditions.pdf' target='_blank'> Terms and Conditions</a>
                    </Link>
                    &nbsp; and &nbsp;
                    <Link variant='subtitle2'>
                      <a style={{ color: '#2193B0', textDecoration: 'underline' }}
                        // eslint-disable-next-line react/no-unknown-property
                        without
                        rel='noreferrer'
                        href='https://sharewithmarvin.io/privacy-policy/' target='_blank'> Privacy Policy</a>
                    </Link>
                  </Typography>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error className='text-sm'>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div className='mt-5'>
                    <AnimateButton>
                      <Button disabled={isSubmitting} fullWidth size='large' type='submit' variant='contained' className='bg-blue rounded text-lg'>
                        Create Account
                      </Button>
                    </AnimateButton>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className='text-center mt-5'>
                    <p>Already have an account ?
                      <a
                        style={{ color: '#2193B0', textDecoration: 'underline', fontWeight: 'bold' }}
                        href='javascript:void(0)'
                        onClick={switchLogin}>
                        Login
                      </a>
                    </p>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthRegister;

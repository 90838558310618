// material
import { Modal } from '@mui/material';
// components
import ModalHead from '../components/Modal/Header/modalHead';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};

export default function PopupLayout ({
  open,
  handleClose,
  children,
  heading,
  className,
  width
}) {
  return (
    <Modal
      className={className || ''}
      open={open}
      onClose={handleClose}
    >
      <div
        style={{
          ...modalStyle,
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            width: width || 'auto'
          }}
          className="bg-white min-w-[500px] mx-auto rounded-lg"
        >
          <ModalHead handleClose={handleClose} heading={heading} />
          <div className="px-5">{children}</div>
        </div>
      </div>
    </Modal>
  );
}

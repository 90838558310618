import { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

import { BsUpload } from 'react-icons/bs';

const Upload = ({ handleChange, handleClose }) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    }
  });
  return (
    <>
      <div
        {...getRootProps()}
        className="cursor-pointer border-gray border-[1px] border-dashed w-full p-10 rounded flex justify-center items-center flex-col"
      >
        <input {...getInputProps()} accept="image/png" />
        {isDragActive
          ? (
          <p>Drop files here</p>
            )
          : (
          <>
            <BsUpload className="text-3xl text-blue" />
            <p className="text-blue font-bold my-1">Browse</p>
            <p>Drag and drop files here, or click to select files</p>
          </>
            )}
      </div>

      <hr className="my-5" />
      <div className="flex justify-end">
        <button
          onClick={handleClose}
          type="button"
          className="uppercase text-blue border-[1px] border-blue rounded px-4 py-2 mr-2"
        >
          Cancel
        </button>
        <button
          type="button"
          className={'uppercase text-white rounded px-4 py-2 mr-2 bg-[#ccc]'}
          disabled
        >
          Upload
        </button>
      </div>
    </>
  );
};

export default Upload;

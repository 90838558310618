/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { icons } from '../../../constants/channels';
import { VscTrash } from 'react-icons/vsc';

import channelsStore from '../../../store/channel/channel';
import contentStore from '../../../store/content/content';
import { observer } from 'mobx-react';
import DeleteConfirmationModal from '../../Modal/Delete';
import ChannelsList from '../../../components/ContentManagement/VersionTabsList/ChannelsList';

const Version = ({
  data,
  data: { channelId, defaultVersion, version },
  selected,
  remove,
  contentId
}) => {
  const navigate = useNavigate();
  const { setVersion, deleteContent } = contentStore;

  const [showDelete, setShowDelete] = useState(false);

  const handleSelect = (e) => {
    e.preventDefault();
    if (contentId && data.id !== contentId) {
      setVersion(data);
      navigate(`/content/${data.id}`);
    }
  };
  const handleRemove = () => {
    deleteContent(data.id);
    setShowDelete(false);
    navigate(`/content/${data.parentId}`);
  };

  return (
    <button
      onClick={handleSelect}
      className="bg-[#eee] py-2 px-5 flex gap-3 rounded relative min-w-[200px]"
    >

      <>{channelId && <ChannelsList channels={channelId ? channelId : []} />}</>
      {(!contentId || data.id === contentId) && (
        <div className="absolute top-0 left-0 w-full h-full bg-white py-2 px-5 rounded border-2 border-blue border-dashed flex justify-between items-center gap-3">
          <p className="text-blue font-bold whitespace-nowrap">
            Active Version
          </p>
          {data.parentId && (
            <span
              onClick={() => setShowDelete(true)}
              className="p-2 rounded-full text-red bg-light-red"
            >
              <VscTrash />
            </span>
          )}
        </div>
      )}

      <DeleteConfirmationModal
        open={showDelete}
        onDelete={handleRemove}
        del={['Version', '']}
        handleConfirm={handleRemove}
        handleCancel={() => setShowDelete(false)}
      />
    </button>
  );
};

export default Version;

const _Channel = observer(({ id }) => {
  const {
    state: { allChannels }
  } = channelsStore;

  const [icon, setIcon] = useState({});
  const [channel, setChannel] = useState({});

  useEffect(() => {
    const data = allChannels.find((x) => parseInt(x.id) === parseInt(id));
    setChannel(data ? data : {});
  }, [id, allChannels]);

  useEffect(() => {
    const data = icons.find((c) => c.name === channel.name);
    setIcon(data ? data : {});
  }, [channel]);

  return (
    <div className="p-2 rounded-full bg-[#ccc]/50">
      {icon.colorIcon}
    </div>
  );
});

import client from '../../utils/axios';
import { makeAutoObservable } from 'mobx';
import loadingStore from '../loading';

const ENDPOINT = '/feature/settings';
const USER_ENDPOINT = '/user';

class SettingsStore {
  state = {
    userSettings: [],
    orgSettings: [],
    pubSettings: [],
    loaded: false
  };

  constructor () {
    makeAutoObservable(this);
  }

  fetchSettings = async () => {
    loadingStore.setLoading(true);
    try {
      const userResponse = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user`);
      const orgResponse = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/type/organisation`);
      const pubResponse = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/type/publishing`);
      this.state.userSettings = userResponse.data;
      this.state.orgSettings = orgResponse.data;
      this.state.pubSettings = pubResponse.data;
      this.state.loaded = true;
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateSettings = async (updatedSettings, id) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/type/${updatedSettings.name}`,
        updatedSettings
      );

      if (updatedSettings.settingType === 'publishing') {
        this.state.pubSettings = this.state.pubSettings.map((x) =>
          x.id === id
            ? {
                ...updatedSettings,
                value: updatedSettings.value === true ? 'true' : 'false'
              }
            : x
        );
      } else {
        this.state.orgSettings = this.state.orgSettings.map((x) =>
          x.id === id
            ? {
                ...updatedSettings,
                value: updatedSettings.value === true ? 'true' : 'false'
              }
            : x
        );
      }
    } catch (error) {
      console.error('Failed to update settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createSettings = async (newSettings) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/type`, newSettings);
      if (newSettings.settingType === 'publishing') {
        this.state.pubSettings = [...this.state.pubSettings, response.data];
      } else {
        this.state.orgSettings = [...this.state.orgSettings, response.data];
      }
    } catch (error) {
      console.error('Failed to create settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateSettingsDontSave = async (updatedSettings) => {
    loadingStore.setLoading(true);
    try {
      this.state.userSettings = this.state.userSettings.map((x) =>
        x.id === updatedSettings.id
          ? {
              ...updatedSettings,
              value: updatedSettings.value === true ? 'true' : 'false'
            }
          : x
      );
    } catch (error) {
      console.error('Failed to update settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateUserSettings = async (updatedSettings) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user/${updatedSettings.name}`,
        updatedSettings
      );
      this.state.userSettings = this.state.userSettings.map((x) =>
        x.name === updatedSettings.name
          ? {
              ...updatedSettings,
              value: updatedSettings.type === 'BOOLEAN' ? String(updatedSettings.value) : updatedSettings.value
            }
          : x
      );
      return 1;
    } catch (error) {
      console.error('Failed to update settings:', error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createUserSettings = async (newSettings) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user`,
        newSettings
      );
      this.state.userSettings = [...this.state.userSettings, response.data];
      return 1;
    } catch (error) {
      console.error('Failed to create settings:', error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  storeDeviceToken = async (token) => {
    try {
      await client.post(`${process.env.REACT_APP_BASE_URL}${USER_ENDPOINT}/notification/push`, {
        deviceType: 'WEB',
        deviceToken: token
      }
      );
    } catch (error) {
      console.error('Failed to store device token :', error);
    }
  };

  deleteDeviceToken = async () => {
    try {
      const deviceToken = localStorage.getItem('deviceToken');
      if (deviceToken) {
        await client.delete(
          `${process.env.REACT_APP_BASE_URL}${USER_ENDPOINT}/notification/push/${deviceToken}`
        );
      }
    } catch (error) {
      console.error('Failed to delete device token :', error);
    }
  };
}

const settingsStore = new SettingsStore();
export default settingsStore;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyASHpYwNPrpDMmeqFgxKdUTNYqyL9h6Tb0',
  authDomain: 'share-with-marvin.firebaseapp.com',
  projectId: 'share-with-marvin',
  storageBucket: 'share-with-marvin.appspot.com',
  messagingSenderId: '214182275122',
  appId: '1:214182275122:web:a136d75b643fa296ce342b',
  measurementId: 'G-0R722D8755'
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';
import client from '../../../utils/axios';
import { useAuth0 } from '@auth0/auth0-react';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { SUPPORTED_FILE_TYPES } from '../../../utils/supported-files-types';
import fileStore from '../../../store/file';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css';
import { Drawer } from '@mui/material';
import GifPicker from 'gif-picker-react';
import { useState } from 'react';
import loadingStore from '../../../store/loading';
import GifIcon from '@mui/icons-material/Gif';
import { FaFigma } from 'react-icons/fa';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import thirdPartyIntegrationStore from '../../../store/settings/thirdPartyIntegration';
import { useSnackbar } from 'notistack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import canI from '../../../utils/canI';
import TextField from '@mui/material/TextField';
import FigmaConnectionDialog from './FigmaConnectionDialog';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginMediaPreview, FilePondPluginPdfPreview);

function Attachments ({
  files,
  setFiles,
  displayFiles,
  setDisplayFiles,
  content,
  viewOnly
}) {
  const { user } = useAuth0();
  const { getFileMediaInfo, setAttachment, deleteFile, removeAttachment } = fileStore;
  const [filepondMap, setFilepondMap] = useState([]);
  const [openGiph, setOpenGiph] = useState(false);
  const [openFigma, setOpenFigma] = useState(false);
  const [figmaLink, setFigmaLink] = useState('');
  const [figmaImages, setFigmaImages] = useState([]);
  const [hasFigmaConnection, setFigmaConnection] = useState(false);
  const {
    getConnections,
    fetchImagesFromFigmaLink
  } = thirdPartyIntegrationStore;
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = async (fieldName, file, metadata, load, error, progress, abort) => {
    try {
      const fileType = SUPPORTED_FILE_TYPES.getFilterType(file.name.split('.').pop().toLowerCase());
      const fileInfo = await getFileMediaInfo(file, fileType.contentType);

      const fileRequest = {
        filename: file.name,
        extension: file.name.split('.').pop().toLowerCase(),
        contentType: fileType.mimeType,
        fileSize: file.size,
        fileWidth: parseInt(fileInfo?.Width),
        fileHeight: parseInt(fileInfo?.Height),
        aspectRatio: fileInfo?.aspectRatio,
        frameRate: fileInfo?.FrameRate ? parseFloat(fileInfo?.FrameRate).toFixed(2) : null,
        bitRate: fileInfo?.BitRate ? parseFloat(fileInfo?.BitRate).toFixed(2) : null,
        orientation: fileInfo?.orientation,
        duration: fileInfo?.Duration ? parseFloat(fileInfo?.Duration).toFixed(2) : null,
        isInline: false
      };

      const { data } = await client.post(`${process.env.REACT_APP_BASE_URL}/files/files/pre-signed-url`, fileRequest);
      if (data && data.fileId && data.preSignedUrl) {
        const uploadResponse = await client.put(data.preSignedUrl, file, {
          headers: {
            Authorization: null,
            'Content-Type': fileType.mimeType,
            'Content-Disposition': `inline; filename="${fileRequest.filename}"`,
            'x-amz-meta-author': user.sub
          },
          onUploadProgress: (progressEvent) => {
            const uploadProgress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            progress(uploadProgress);
          }
        });

        setFiles((files) => [
          {
            id: data.fileId,
            fileKey: data.fileKey,
            url: `${process.env.REACT_APP_BASE_URL}/files/pub/file/` + data.fileKey,
            ...fileRequest
          },
          ...files
        ]);
        setFilepondMap((filepondMap) => [
          {
            filepondId: metadata.id,
            fileKey: data.fileKey
          },
          ...filepondMap
        ]);
        setAttachment([{ id: data.fileId, filename: data.filename, contentType: data.contentType, url: `${process.env.REACT_APP_BASE_URL}/files/pub/file/` + data.fileKey, isInline: false }]);

        load(uploadResponse.data);
      } else {
        error('Something went wrong!');
      }
    } catch (error) {
      error('Something went wrong!');
    }

    return {
      abort: () => {
        abort();
      }
    };
  };

  const handleRemoveFile = (fileItem) => {
    if (fileItem) {
      let fileKey;
      if (fileItem.source.fileKey) {
        fileKey = fileItem.source.fileKey;
      } else {
        fileKey = filepondMap.find(filepond => filepond.filepondId === fileItem.id).fileKey;
      }

      const removedFile = files.find((_file) => _file.fileKey === fileKey);
      setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.fileKey !== fileKey));
      removeAttachment(removedFile.id);
      if (content && content.id && removedFile.id) {
        deleteFile(content.id, removedFile.id);
      }
      return true;
    }
    return false;
  };

  const handleUpdateFile = (fileItems) => {
    setDisplayFiles(fileItems.map(fileItem => {
      fileItem.setMetadata('id', fileItem.id);
      return fileItem;
    }));
  };

  const handleReorderFile = (filePondFiles) => {
    const reorderedFiles = [];
    filePondFiles.forEach(filePondFile => {
      let fileKey;
      if (filePondFile.source.fileKey) {
        fileKey = filePondFile.source.fileKey;
      } else {
        fileKey = filepondMap.find(filepond => filepond.filepondId === filePondFile.id).fileKey;
      }
      const matchedFile = files.find((_file) => _file.fileKey === fileKey);
      if (matchedFile) {
        reorderedFiles.push(matchedFile);
      }
    });
    setFiles(reorderedFiles);
  };

  const toggleAndLoadGiph = () => {
    if (openGiph) {
      setOpenGiph(false);
    } else {
      setOpenGiph(true);
    }
  };

  const toggleAndLoadFigma = () => {
    if (openFigma) {
      setOpenFigma(false);
    } else {
      // check if the connection is there or not
      loadingStore.setLoading(true);
      setFigmaImages([]);
      getConnections().then((response) => {
        const hasFimgaConnection = response ? response.find((item) => item.name === 'FIGMA' && item.active) : false;
        if (hasFimgaConnection) {
          setOpenFigma(true);
        } else {
          setFigmaConnection(true);
        }
      });
    }
  };

  const selectGiph = (selectedGiph) => {
    setOpenGiph(false);
    loadingStore.setLoading(true);
    const myRequest = new Request(selectedGiph.url);
    fetch(myRequest).then(function (response) {
      response.blob().then(function (myBlob) {
        const myFile = new File([myBlob], selectedGiph.description + '.gif', {
          type: myBlob.type
        });
        setDisplayFiles(prev => [...prev, { source: myFile }]);
        loadingStore.setLoading(false);
      });
    });
  };

  const getLastPathSegment = (url) => {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1);
    return lastSegment;
  };

  const selectFigmaFile = (url) => {
    setOpenFigma(false);
    loadingStore.setLoading(true);
    const myRequest = new Request(url);
    const filename = getLastPathSegment(url);
    fetch(myRequest).then(function (response) {
      response.blob().then(function (myBlob) {
        const myFile = new File([myBlob], filename + '.png', {
          type: myBlob.type
        });
        setDisplayFiles(prev => [...prev, { source: myFile }]);
        loadingStore.setLoading(false);
      });
    });
  };

  const purifyFigmaLink = async () => {
    try {
      const urlObj = new URL(figmaLink);
      const pathSegments = urlObj.pathname.split('/');
      const fileId = pathSegments[2];
      const searchParams = new URLSearchParams(urlObj.search);
      const nodeId = searchParams.get('node-id');

      const response = await fetchImagesFromFigmaLink(fileId, {
        imageIds: nodeId
      });

      const newImages = Object.values(response);
      setFigmaImages(newImages);
      if (newImages.length === 0) {
        enqueueSnackbar('We could not find any images from above url', {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar(error ? error.response.data : 'Invalid URL', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <div className="flex gap-2 border-y-[1px] border-blue" style={{ backgroundColor: '#f1f0ef' }}>
        <button onClick={() => toggleAndLoadGiph()}><GifIcon fontSize="large"/></button>
        { canI('read:integration') && (<button onClick={() => toggleAndLoadFigma()}><FaFigma fontSize="large"/></button>)}
      </div>
      <div className="App">
        <FilePond
          name="files"
          files={displayFiles}
          onupdatefiles={handleUpdateFile}
          onreorderfiles={handleReorderFile}
          beforeRemoveFile={handleRemoveFile}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          allowMultiple={true}
          allowReorder={true}
          maxParallelUploads={5}
          styleItemPanelAspectRatio={0.5625}
          acceptedFileTypes={SUPPORTED_FILE_TYPES.supported_types}
          fileValidateTypeLabelExpectedTypes={'This file type is not supported by Marvin'}
          dropOnPage={false}
          dropOnElement={true}
          disabled={viewOnly}
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort) => {
              handleFileUpload(fieldName, file, metadata, load, error, progress, abort);
            },
            load: async (source, load, error, progress, abort, headers) => {
              const filename = source.filename;
              const myRequest = new Request(source.url);
              fetch(myRequest).then(function (response) {
                response.blob().then(function (myBlob) {
                  const blobWithFilename = new Blob([myBlob], { type: myBlob.type, endings: 'transparent' });
                  blobWithFilename.name = filename;
                  load(blobWithFilename);
                });
              });
            }
          }}
          credits={false}
          />
      </div>
      <Drawer
      anchor='bottom'
      open={openGiph}
      onClose={() => setOpenGiph(false)}
      >
       <div>
        <GifPicker tenorApiKey={process.env.REACT_APP_TENOR_API_KEY} width="100%" onGifClick={res => selectGiph(res)}/>
      </div>
      </Drawer>
      <FigmaConnectionDialog hasFigmaConnection={ hasFigmaConnection } setFigmaConnection={ setFigmaConnection } />
      <Drawer
       anchor='bottom'
       open={openFigma}
       onClose={() => setOpenFigma(false)}
      >
       <div className='flex flex-col gap-4 p-2 h-96'>
          <div className='flex inline-flex mt-2'>
            <TextField
              className='w-full'
              id="outlined-controlled"
              label="Enter your figma link"
              value={figmaLink}
              onChange={(e) => setFigmaLink(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFigmaLink(e.target.value);
                  purifyFigmaLink();
                }
              }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton onClick={ purifyFigmaLink } color="primary" sx={{ p: '10px' }} aria-label="directions">
              <DirectionsIcon />
            </IconButton>
          </div>
          {figmaImages && figmaImages.length === 0 && (
            <div className='w-full'>
              Enter a Figma link into the provided input box and submit it to fetch images from the link. The application will retrieve the images and display them below the input box. You can then view the fetched images and select any of them to use as attachments. This tool simplifies the process of extracting and utilizing images from Figma links.
            </div>
          )}
          <div className='w-full h-96 overflow-x-auto'>
            <div className='grid grid-cols-6 gap-2'>
              { figmaImages && figmaImages.length > 0 && (
                figmaImages.map((item, index) => (
                  <Card className="border border-blue shadow-2xl transition-shadow duration-300 cursor-pointer" key={index} onClick={() => selectFigmaFile(item)}>
                    <CardContent>
                      <img
                        srcSet={`${item}`}
                        src={`${item}`}
                        width={250}
                        height={250}
                        alt={''}
                        loading="lazy"
                      />
                    </CardContent>
                  </Card>
                ))
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Attachments;

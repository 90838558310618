import { useEffect, useState } from 'react';
import { TfiWorld } from 'react-icons/tfi';
import { icons } from '../../../constants/channels';
import channelsStore from '../../../store/channel/channel';

const ChannelsList = ({ channels }) => {
  const {
    state: { allChannels, allChannelTypes },
    fetchAllChannelTypes
  } = channelsStore;

  useEffect(() => {
    if (!allChannelTypes.length) {
      fetchAllChannelTypes();
    }
  }, [allChannelTypes]);

  return (
    <>
      {channels.map((x, i) => (
        <Item
          key={i}
          id={x}
          channels={allChannelTypes}
          itemAllChannels={allChannels}
        />
      ))}
    </>
  );
};

export default ChannelsList;

const Item = ({ id, channels, itemAllChannels }) => {
  const [channel, setChannel] = useState(null);
  const [icon, setIcon] = useState(<TfiWorld />);
  const [image, setImage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [color, setColor] = useState('#006ace');

  const {
    getChannelInstance
  } = channelsStore;

  useEffect(() => {
    const channelInstance = itemAllChannels.find((c) => c.id === id);
    if (channelInstance) {
      setChannel(channelInstance);
    } else {
      getChannelInstance(id).then(response => {
        setChannel(response);
      });
    }
  }, [id]);

  const findIcon = (name) => {
    return icons.find((c) => c.name === name);
  };

  useEffect(() => {
    if (channel) {
      if (channel.channelImageUrl) {
        setImage(channel.channelImageUrl);
      } else {
        const channelType = channels.find((c) => c.id === channel.type_id);
        if (channelType) {
          const icon = findIcon(channelType.channelName);
          if (icon) {
            setIcon(icon.icon);
            setColor(icon.color);
          } else {
            setIcon(<TfiWorld />);
            setColor('#006ace');
          }
        }
      }
    }
  }, [channel, channels]);

  return (
    <>
      <p
        className={` ${image ? 'p-0' : 'p-2'} rounded-full bg-[#ccc]/50`}
      >
        { image ? (<img src={image} alt="icon" className={'w-8 h-8 object-cover rounded-full'}/>) : icon }
      </p>
    </>
  );
};

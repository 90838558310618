import client from '../utils/axios';

const ORGANISATION_ENDPOINT = process.env.REACT_APP_BASE_URL + '/organisations';

export default {
  me: async () => {
    const { data } = await client.get(ORGANISATION_ENDPOINT + '/me');
    return data;
  },
  updateMe: async (params) => {
    const { data } = await client.patch(ORGANISATION_ENDPOINT + '/me', params);
    return data;
  },
  updatePassword: async (params) => {
    const { data } = await client.patch(ORGANISATION_ENDPOINT + '/me/password', params);
    return data;
  }
};

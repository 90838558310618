const PreviewAiContent = ({
  handleClose,
  aiContentPreview,
  doRegenerateAiContent,
  useAiContent
}) => {
  return (
    <div className="flex items-center h-full drop-shadow-lg">
        <div className="w-full">
          <div className="h-64 overflow-y-scroll p-4 border border-gray-200">
            <p>{aiContentPreview}</p>
          </div>
          <hr className="my-5" />
          <div className="flex justify-end gap-2">
            <button
              variant="contained"
              color="primary"
              type="submit"
              className="mb-4 p-2 text-white bg-slate-700 rounded"
              onClick={doRegenerateAiContent}
            >
              {'Regenerate'}
            </button>
            <button
              variant="contained"
              color="primary"
              type="submit"
              className="mb-4 mrvn-sv px-2 text-white rounded"
              onClick={useAiContent}
            >
              {'Use as Content'}
            </button>
          </div>
        </div>
    </div>
  );
};

export default PreviewAiContent;

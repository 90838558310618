import { Container } from '@mui/material';
import FullCalendarBox from '../../components/calendar/FullCalendarBox';
import useSettings from '../../hooks/useSettings';
import content from '../../api/content';

const Calendar = () => {
  const { themeStretch } = useSettings();
  return (
    <>
      <Container maxWidth={themeStretch ? 'lg' : false}>
        <FullCalendarBox content={content} />
      </Container>
    </>
  );
};

export default Calendar;

import {
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid,
  Typography
} from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFieldArray } from 'react-hook-form';

const ConnectionParams = ({ control, errors, existingChannel }) => {
  const { fields } = useFieldArray({
    control,
    name: 'channelInstanceConfig.configParams'
  });
  return (
    <>
      {fields.map((item, index) => {
        return (
          <Grid key={index} item className='mt-8' xs={12} style={existingChannel?.channelInstanceConfig?.status ? { pointerEvents: 'none' } : {}}>
            <InputLabel className="font-black mb-2">
              { item.parameterTypes ? item.parameterTypes.parameterName : item.parameterName }
            </InputLabel>
            { (item.parameterTypes
              ? item.parameterTypes.parameterType === 'BOOLEAN'
              : item.parameterType === 'BOOLEAN')
              ? (
                  <Controller
                    name={`channelInstanceConfig.configParams.${index}.parameterValue`}
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        className="text-gray-900"
                        error={errors.channelInstanceConfig?.configParams ? !!errors.channelInstanceConfig.configParams[index]?.parameterValue : false}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              sx={{
                                '&, &.Mui-checked': {
                                  color: 'gray'
                                }
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              sx={{
                                '&, &.Mui-checked': {
                                  color: 'gray'
                                }
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                )
              : (
                  <Controller
                    name={`channelInstanceConfig.configParams.${index}.parameterValue`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        { ...field }
                        type={ item.sensitive === true ? 'password' : 'text' }
                        className="w-full"
                        error={errors.channelInstanceConfig?.configParams ? !!errors.channelInstanceConfig.configParams[index]?.parameterValue : false}
                      />
                    )}
                  />
                )
            }
            <ErrorMessage
              name={`channelInstanceConfig.configParams.${index}.parameterValue`}
              errors={errors}
              render={({ message }) => (
                <Typography className="text-red">{message}</Typography>
              )}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default ConnectionParams;

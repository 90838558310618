import {
  FacebookEmbed,
  InstagramEmbed,
  LinkedInEmbed,
  TikTokEmbed,
  XEmbed,
  YouTubeEmbed
} from 'react-social-media-embed';

const SocialMediaPost = ({ type, content, width }) => {
  switch (type) {
    case 'Instagram Business':
      return <InstagramEmbed url={content} />;
    case 'Formerly Twitter':
    case 'Premium':
      return <XEmbed url={content} />;
    case 'Linkedin':
    case 'Linkedin Business':
      return <LinkedInEmbed url={content}/>;
    case 'Tik Tok':
      return <TikTokEmbed url={content} />;
    case 'YouTube Shorts':
      return <YouTubeEmbed url={content} />;
    default:
      return <FacebookEmbed url={content} />;
  }
};

export default SocialMediaPost;

const TagsList = ({ data, color }) => {
  return (
    <div className="inline-flex items-center text-blue">
      {data
        .map((x, i) => (
            <TagItem key={i} name={x} color={color} />
        ))}
    </div>
  );
};

export default (TagsList);

const TagItem = ({ name, color }) => {
  let css = 'border-[1px] rounded-full p-2 m-1';
  if (color) {
    css = 'border-[1px] bg-' + color + ' rounded-full p-2 m-1';
  }

  return (
    <>
        <div className={css}>
          {name}
        </div>
    </>
  );
};

import { ReactComponent as YouTubeLike } from '../../../assets/icons/YouTubeShortsPreview/like.svg';
import { ReactComponent as YouTubeDislike } from '../../../assets/icons/YouTubeShortsPreview/dislike.svg';
import { ReactComponent as YouTubeComment } from '../../../assets/icons/YouTubeShortsPreview/comment.svg';
import { ReactComponent as YouTubeShare } from '../../../assets/icons/YouTubeShortsPreview/share.svg';
import { ReactComponent as YouTubeMore } from '../../../assets/icons/YouTubeShortsPreview/more.svg';

function YouTubeShortsPreview ({
  plainText,
  files,
  previewData,
  user,
  renderDefaultPreview
}) {
  const videoFile = files && files.find(file => file.contentType.startsWith('video/'));

  if (!videoFile) {
    return renderDefaultPreview();
  }

  const handleClick = () => {
    if (previewData.socialChannelLink) {
      window.open(previewData.socialChannelLink, '_blank');
    }
  };

  return (
    <div className='bg-gray-light p-3 rounded-md'>
      <div className='flex items-end'>
        <div key={videoFile.fileKey} className='relative items-center'>
          <video className='rounded-md relative w-11/12 h-[600px] object-cover'>
            <source src={videoFile.url} type={videoFile.contentType} />
            Your browser does not support the video tag.
          </video>
          <div id="custom-controls" className='absolute' style={{
            bottom: '2%',
            left: '2%',
            color: 'white'
          }}>
            <div className='flex items-center mt-3 mb-2 gap-1'>
              <img
                src={previewData?.profilePicUrl ? previewData?.profilePicUrl : user.picture}
                alt='User'
                className='w-6 h-6 rounded-full'
              />
              <p className='text-xs font-semibold cursor-pointer' onClick={handleClick}>{previewData?.displayName ?? user.nickname}</p>
              <div className='bg-white text-black rounded-full p-1 font-semibold'>
                <p className='text-xs'>Subscribe</p>
              </div>
            </div>
            {plainText && <p className='text-[10px] mt-2 break-words'>{plainText.length > 40 ? plainText.substring(0, 40) + '...more' : plainText}</p>}
          </div>
        </div>
        <div className='ml-[-24px]'>
          <div className='flex flex-col items-center mb-2'>
            <div className='rounded-full bg-gray'>
              <YouTubeLike className='p-3 w-10 h-10'/>
            </div>
            <p className='text-sm'>Like</p>
          </div>
          <div className='flex flex-col items-center mb-2'>
            <div className='rounded-full bg-gray'>
              <YouTubeDislike className='p-3 w-10 h-10'/>
            </div>
            <p className='text-sm'>Dislike</p>
          </div>
          <div className='flex flex-col items-center mb-2'>
            <div className='rounded-full bg-gray'>
              <YouTubeComment className='p-3 w-10 h-10'/>
            </div>
            <p className='text-sm'>Comment</p>
          </div>
          <div className='flex flex-col items-center mb-2'>
            <div className='rounded-full bg-gray'>
              <YouTubeShare className='p-3 w-10 h-10'/>
            </div>
            <p className='text-sm'>Share</p>
          </div>
          <div className='flex flex-col items-center mb-2'>
            <div className='rounded-full bg-gray'>
              <YouTubeMore className='p-3 w-10 h-10'/>
            </div>
          </div>
          <div className='flex flex-col items-center mb-2'>
            <img
              src={previewData?.profilePicUrl ? previewData?.profilePicUrl : 'https://randomuser.me/api/portraits/men/68.jpg'}
              alt='User'
              className='w-10 h-10 rounded-md'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default YouTubeShortsPreview;

import { useState } from 'react';

import PopupLayout from '../../../layout/PopupLayout';
import ChannelSelection from './ChannelSelection';
import ConfirmDetails from './ConfirmDetails';
import ContentSelection from './ContentSelection';
import Stepbar from './Stepbar';

import { useSnackbar } from 'notistack';
import contentStore from '../../../store/content/content';
import { useNavigate } from 'react-router-dom';

const CreateAlternateVersion = ({
  open,
  handleClose,
  saveContent,
  bodyExist,
  tagsExist,
  attachmentsExist,
  contentId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    addVersion,
    getLastError
  } = contentStore;

  const [step, setStep] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [content, setContent] = useState({
    title: true,
    body: false,
    tags: false,
    attachments: false
  });

  const reset = () => {
    setStep(1);
    setSelectedRows([]);
    setSelectedChannels([]);
    setSelectedVersion(null);
    setContent({
      title: true,
      body: false,
      tags: false,
      attachments: false
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      alternate: {
        channel_ids: selectedChannels.map((x) => parseInt(x.id)),
        fields: Object.keys(content).filter((key) => content[key])
      }
    };

    content.body &&
      !bodyExist &&
      enqueueSnackbar(
        "unable to copy as no 'Body' being present on the previous version",
        { variant: 'warning' }
      );

    content.tags &&
      !tagsExist &&
      enqueueSnackbar(
        "unable to copy as no 'User tags' being present on the previous version",
        { variant: 'warning' }
      );

    content.attachments &&
      !attachmentsExist &&
      enqueueSnackbar(
        "unable to copy as no 'Attachments' being present on the previous version",
        { variant: 'warning' }
      );

    // save content before fork
    saveContent().then((res) => {
      if (res !== 0 && res !== -1) {
        let whatToCopy = res.id;
        if (contentId) {
          whatToCopy = selectedVersion;
        }
        addVersion(data, whatToCopy).then((result) => {
          if (result === 0) {
            const lastError = getLastError();
            if (lastError.response) {
              if (lastError.response.status === 400 && lastError.response.data) {
                enqueueSnackbar(
                  lastError.response.data,
                  { variant: 'error' }
                );
              } else {
                enqueueSnackbar(
                  'Unable to fork article with current request.',
                  { variant: 'error' }
                );
              }
            } else {
              enqueueSnackbar(
                'Unable to fork article with current request.',
                { variant: 'error' }
              );
            }
          } else {
            navigate('/content/' + result.id);
          }
        });
      } else {
        handleClose();
      }
    });

    reset();
    handleClose();
  };

  return (
    <PopupLayout
      className="wizard"
      open={open}
      handleClose={() => {
        reset();
        handleClose();
      }}
      heading="Create Alternate Version"
      width={730}
    >
      <div className="h-full w-full bg-white pr-3">
        <Stepbar step={step} />

        {step === 1 && (
          <ChannelSelection
            setStep={setStep}
            handleClose={() => {
              reset();
              handleClose();
            }}
            setSelectedChannels={setSelectedChannels}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        )}

        {step === 2 && (
          <ContentSelection
            setStep={setStep}
            handleClose={() => {
              reset();
              handleClose();
            }}
            version={selectedVersion}
            setVersion={setSelectedVersion}
            content={content}
            setContent={setContent}
          />
        )}

        {step === 3 && (
          <ConfirmDetails
            selectedVersion={selectedVersion}
            content={content}
            handleClose={() => {
              reset();
              handleClose();
            }}
            setStep={setStep}
            selectedChannels={selectedChannels}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </PopupLayout>
  );
};

export default CreateAlternateVersion;

import { useRef, useState, useEffect } from 'react';
import { BiChevronDown } from 'react-icons/bi';

const Filter = ({ categoryFilters, setCategoryFilters }) => {
  const categoryRef = useRef(null);
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);

  const handleCategoryFilterChange = (e) => {
    categoryFilters.includes(e.target.value)
      ? setCategoryFilters((categoryFilters) =>
        categoryFilters.filter((x) => x !== e.target.value)
      )
      : setCategoryFilters((categoryFilters) => [
        ...categoryFilters,
        e.target.value
      ]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        setShowCategoryFilter(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex">
      <div ref={categoryRef} className="relative">
        <button
          onClick={() => setShowCategoryFilter(!showCategoryFilter)}
          className={`${showCategoryFilter ? 'border-blue rounded-b-none' : 'border-[#ccc]'
            } border-[1px] rounded px-4 py-3 outline-none w-52 ml-2 text-start flex justify-between items-center`}
        >
          Filter By Category
          <BiChevronDown />
        </button>

        {showCategoryFilter && (
          <div className="absolute border-[1px] border-blue w-52 p-3 bg-white z-10 right-0 border-t-0 rounded-b">
            <div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="TITLE"
                  name="filter"
                  value="TITLE"
                  checked={categoryFilters.includes('TITLE')}
                  onChange={handleCategoryFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="TITLE" className="ml-1">
                  Title
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="BODYINTRO"
                  name="filter"
                  value="BODYINTRO"
                  checked={categoryFilters.includes('BODYINTRO')}
                  onChange={handleCategoryFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="BODYINTRO" className="ml-1">
                  Body - Intro
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="BODYCLOSING"
                  name="filter"
                  value="BODYCLOSING"
                  checked={categoryFilters.includes('BODYCLOSING')}
                  onChange={handleCategoryFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="BODYCLOSING" className="ml-1">
                  Body - Closing
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="BODYCOMPLIANCE"
                  name="filter"
                  value="BODYCOMPLIANCE"
                  checked={categoryFilters.includes('BODYCOMPLIANCE')}
                  onChange={handleCategoryFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="BODYCOMPLIANCE" className="ml-1">
                  Body - Compliance
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;

import { useState, useEffect } from 'react';
import client from '../../../utils/axios';
import { observer } from 'mobx-react';

import EntitiesItem from './EntitiesItem';

const ENDPOINT = '/feature/settings/type/organisation';

const EntitiesList = ({
  contentParams,
  setContentParams,
  viewOnly
}) => {
  const [orgSettings, setOrgSettings] = useState(null);

  const [options, setOptions] = useState([
    {
      title: 'Companies',
      slug: 'companies',
      visibility: false
    },
    {
      title: 'Locations',
      slug: 'locations',
      visibility: false
    },
    {
      title: 'Departments',
      slug: 'departments',
      visibility: false
    },
    {
      title: 'Teams',
      slug: 'teams',
      visibility: false
    },
    {
      title: 'Programmes',
      slug: 'programmes',
      visibility: false
    },
    {
      title: 'Projects',
      slug: 'projects',
      visibility: false
    },
    {
      title: 'Folders',
      slug: 'folders',
      visibility: false
    }
  ]);

  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
        setOrgSettings(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (orgSettings) {
      const updatedOptions = options.map((option) => {
        const setting = orgSettings.find((setting) => setting.name === option.title);
        if (setting) {
          return { ...option, visibility: setting.value === 'true' };
        }
        return option;
      });
      setOptions(updatedOptions);
    }
  }, [orgSettings]);

  return (
    <div>
      {options.map((x, i) => {
        return x.visibility && (
          <EntitiesItem
            key={i}
            title={x.title}
            slug={x.slug}
            activeMenu={activeMenu}
            isSelected={activeMenu === x.slug}
            onClick={() => setActiveMenu(x.slug)}
            contentParams={contentParams}
            setContentParams={setContentParams}
            viewOnly={viewOnly}
          />
        );
      })}
    </div>
  );
};

export default observer(EntitiesList);

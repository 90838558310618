// material
import { Container, Modal } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import ModalHead from '../../Modal/Header/modalHead';
import GroupNewForm from '../../Modal/Security/GroupNewForm';

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  margin: '10px',
  height: '95vh',
  border: '5px',
  overflow: 'auto',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};

export default function GroupCreate ({
  open,
  handleClose,
  isEdit,
  currentGroup
}) {
  const { themeStretch } = useSettings();

  return (
    <Modal open={open} onClose={handleClose} className="flex justify-center">
      <div
        style={{
          ...modalStyle,
          backgroundColor: 'white',
          width: '800px',
          flexDirection: 'column'
        }}
      >
        <ModalHead
          handleClose={handleClose}
          heading={!isEdit ? 'Add Group' : 'Edit Group'}
        />
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <GroupNewForm
            isEdit={isEdit}
            currentGroup={currentGroup}
            handleClose={handleClose}
          />
        </Container>
      </div>
    </Modal>
  );
}

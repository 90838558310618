import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import currency from '../../utils/currency';
import productDetail from '../../utils/product';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AuthStore from '../../store/auth';
import { useEffect, useState } from 'react';

const ProductTemplate = ({
  defaultPlan,
  setDefaultPlan,
  annualBilling,
  filterProduct
}) => {
  const { getProducts } = AuthStore;
  const [plans, setPlans] = useState(null);

  useEffect(() => {
    getProducts().then((data) => {
      const filteredData = data.filter(f => f.id === filterProduct);
      setPlans(filteredData?.length > 0 ? filteredData : data);
      setDefaultPlan(filteredData?.length > 0 ? filteredData[0] : data[0]);
    });
  }, []);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 3, sm: 2 }}
        useFlexGap
        flexWrap='wrap'
        justifyContent='space-evenly'
        alignItems='stretch'
        mt={5}
      >
        {plans?.map((plan) => (
          <Card
            key={plan.name}
            className={`p-5 shadow-lg ${plan.name === defaultPlan?.name ? 'bg-orange-200' : 'bg-white'
              } ''}`}
            onClick={() => setDefaultPlan(plan)}
            style={{ cursor: 'pointer', position: 'relative' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <div className='pr-5'>
                <div className='text-x font-bold'>{plan.description}</div>
                <div className='text-base font-bold tracking-tight pt-2'>
                  {plan.name}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <img className='h-20' src={plan.image} alt='logo' />
                </div>
                <p className='text-base font-bold tracking-tight pt-5 pb-5'>
                  {productDetail(annualBilling, plan.prices).interval === 'one_time' && (
                    currency(productDetail(annualBilling, plan.prices).unit_amount_decimal, plan.currency) + ' for 1 seat for lifetime'
                  )}
                  {productDetail(annualBilling, plan.prices).interval !== 'one_time' && (
                    currency(
                      productDetail(annualBilling, plan.prices).unit_amount_decimal,
                      productDetail(annualBilling, plan.prices).currency
                    ) + ' ' + 'per seat / ' + productDetail(annualBilling, plan.prices).interval
                  )}
                </p>
                {plan.months_trial && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/static/images/free-trial.png'}
                      alt='logo'
                      style={{
                        zIndex: 10,
                        width: 'auto',
                        height: '80px',
                        borderRadius: '5px'
                      }}
                    />
                  </div>
                )}
                <p className='text-base font-bold tracking-tight pt-5 pb-5'>
                  Maximum Users : {plan.max_seats ? plan.max_seats : 'Unlimited'}
                </p>
                <div style={{ textAlign: 'start' }}>
                  <List dense={true} className='p-0 m-0'>
                    {plan.features?.map((feature, index) => (
                      <ListItem className='p-0 m-0'>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default ProductTemplate;

import { useCallback, useRef, useState } from 'react';
import useNotificactionSearch from './useNotificactionSearch';
import { Menu, MenuItem, Skeleton, Stack } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import NotificationItem from './NotificationItem';
import NoContent from '../../../NoContent';

export default function Notification ({ handleClose, anchorEl }) {
  const [params, setParams] = useState({ pageSize: 7, pageIndex: 1 });
  const observer = useRef();

  const { items, loading, hasMore, markAllAsRead, markAsRead } = useNotificactionSearch(params);

  const lastItem = useCallback(node => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setParams(prevParam => {
          return { ...prevParam, pageIndex: prevParam.pageIndex + 1 };
        });
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [loading, hasMore]);

  return (
    <div>
      <Menu
        className='h-screen overflow-scroll scrollbar-hide text-overflow: ellipsis'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '400px',
            minWidth: '400x',
            maxHeight: '90%',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        style={{ zIndex: '999' }}
      >
        <div>
          <ul className="p-4">
            <li className="flex justify-between items-center mb-3">
              <div className="font-bold text-3xl">
                Notifications
              </div>
              <div className="">
                {(items?.length > 0 &&
                  <MarkEmailReadIcon onClick={markAllAsRead} style={{ color: 'gray' }} />
                )}
              </div>
            </li>

            {items.map((item, index) => {
              if (items.length === index + 1) {
                return <MenuItem style={{ whiteSpace: 'normal' }} className='rounded-lg' ref={lastItem} onClick={handleClose}>
                  <NotificationItem item={item} markAsRead={markAsRead} />
                </MenuItem>;
              } else {
                return <MenuItem style={{ whiteSpace: 'normal' }} className='rounded-lg' onClick={handleClose}>
                  <NotificationItem item={item} markAsRead={markAsRead} />
                </MenuItem>;
              }
            })}

          {!loading && items.length < 1 && NoContent('No Notifications Found')}

            {loading && (
              <Stack spacing={1} direction='row' className='items-center'>
                <Skeleton animation="wave" variant="circular" width={50} height={50} />
                <Stack direction='column' className='grow'>
                  <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                  <Skeleton animation='wave' width='90%' sx={{ fontSize: '1rem' }} />
                </Stack>
              </Stack>
            )}

          </ul>
        </div>
      </Menu>
    </div>
  );
}

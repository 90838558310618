import { Container } from '@mui/material';
import DashboardBox from '../../components/Dashboard';
import useSettings from '../../hooks/useSettings';

const Dashboard = () => {
  const { themeStretch } = useSettings();
  return (
    <>
      <Container maxWidth={themeStretch ? 'lg' : false}>
        <DashboardBox />
      </Container>
    </>
  );
};

export default Dashboard;

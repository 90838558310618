const Stepbar = ({ step }) => {
  return (
    <div className="arrow-steps clearfix mb-5">
      <div className={`step ${step === 1 ? 'current' : ''}`}>
        <span>Channel Selection</span>
      </div>
      <div className={`step ${step === 2 ? 'current' : ''}`}>
        <span>Content Selection</span>
      </div>
      <div className={`step ${step === 3 ? 'current' : ''}`}>
        <span>Confirm Details</span>
      </div>
    </div>
  );
};

export default Stepbar;

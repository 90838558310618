import { useState, useRef } from 'react';
import { Slider } from '@mui/material';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { BsCamera } from 'react-icons/bs';

const Resize = ({
  src,
  submitImage,
  handleClose,
  setSelectedImage,
  handleRemove
}) => {
  const imageRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [initialCrop, setInitialCrop] = useState({ width: 0, height: 0 });
  const [crop, setCrop] = useState({
    x: 25,
    y: 25,
    aspect: 1 / 1
  });

  const onCropComplete = (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement('canvas');
      const image = imageRef.current;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      let pixelWidth, pixelHeight, pixelX, pixelY;

      if (crop.unit === '%') {
        pixelWidth = Math.floor((crop.width * image.width) / 100);
        pixelHeight = Math.floor((crop.height * image.height) / 100);
        pixelX = Math.floor((crop.x * image.width) / 100);
        pixelY = Math.floor((crop.y * image.height) / 100);
      } else if (crop.unit === 'px') {
        pixelWidth = Math.floor(crop.width);
        pixelHeight = Math.floor(crop.height);
        pixelX = Math.floor(crop.x);
        pixelY = Math.floor(crop.y);
      } else {
        // Handle other units if needed
        return;
      }

      canvas.width = pixelWidth;
      canvas.height = pixelHeight;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        pixelX * scaleX,
        pixelY * scaleY,
        pixelWidth * scaleX,
        pixelHeight * scaleY,
        0,
        0,
        pixelWidth,
        pixelHeight
      );

      canvas.toBlob((blob) => {
        const file = new File([blob], 'cropped-image.jpeg', {
          type: 'image/jpeg'
        });
        // setCroppedImageFile(file);
        submitImage(file);
      }, 'image/jpeg');
    }
  };

  function onImageLoad (e) {
    try {
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
      const crop = makeAspectCrop(
        {
          unit: '%',
          width: 100
        },
        1 / 1,
        width,
        height
      );

      setCrop(crop);
      setInitialCrop({ height: crop.height, width: crop.width });
      setLoaded(true);
    } catch (err) {}
  }

  const handleResize = (e) => {
    setCrop({
      ...crop,
      unit: '%',
      x: 0,
      y: 0,
      height:
        initialCrop.height -
        (initialCrop.height * (101 - e.target.value)) / 100,
      width:
        initialCrop.width - (initialCrop.width * (101 - e.target.value)) / 100
    });
  };

  const handleSubmit = () => {
    onCropComplete(crop);
  };

  return (
    <>
      <div>
        <div className="relative">
          <ReactCrop
            aspect={1 / 1}
            crop={crop}
            onChange={(c) => setCrop(c)}
            circularCrop
            locked
          >
            <img
              ref={imageRef}
              src={URL.createObjectURL(src)}
              className="h-[250px]"
              onLoad={(e) => {
                !loaded && onImageLoad(e);
              }}
            />
          </ReactCrop>
          <label className="absolute bg-blue rounded-full p-2 text-white bottom-[-5px]">
            <input
              className="hidden"
              type="file"
              onChange={(e) => {
                setSelectedImage(e.target.files[0]);
                setLoaded(false);
              }}
            />
            <BsCamera />
          </label>
        </div>

        <Slider
          sx={{
            color: '#2596be'
          }}
          defaultValue={100}
          onChange={handleResize}
        />
      </div>
      <hr className="my-5" />
      <div className="flex justify-between">
        <button
          onClick={handleRemove}
          type="button"
          className="uppercase text-red border-[1px] border-red rounded px-4 py-2 mr-2"
        >
          Remove
        </button>
        <div>
          <button
            onClick={handleClose}
            type="button"
            className="uppercase text-blue border-[1px] border-blue rounded px-4 py-2 mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className={'uppercase text-white rounded px-4 py-2 mr-2 bg-blue'}
            onClick={handleSubmit}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
};

export default Resize;

import { useEffect, useState } from 'react';
import ModalHead from '../../Header/modalHead';

import { useSnackbar } from 'notistack';
import channelsStore from '../../../../store/channel/channel';
import { observer } from 'mobx-react';

import StepBar from './StepBar';
import Level from './Level';
import AppliesTo from './AppliesTo';
import Details from './Details';
import Name from './Name';

const AddRestriction = ({ handleClose, isEdit, currentRule, refresh }) => {
  const {
    state: { types, userChannels, channelRuleTypes },
    fetchChannelTypes,
    fetchUserChannels,
    createChannelRule,
    updateChannelRule
  } = channelsStore;
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');
  const [level, setLevel] = useState(1);
  const [channel, setChannel] = useState(null);
  const [param, setParam] = useState('');
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState('');
  const [doubleValue1, setDoubleValue1] = useState(0);
  const [doubleValue2, setDoubleValue2] = useState(0);
  const [ruleType, setRuleType] = useState('');

  const [step, setStep] = useState(1);

  const fetchChannels = () => {
    level === 1 && fetchChannelTypes();
    level === 2 && fetchUserChannels();
  };

  const add = () => {
    let data = {
      channelRuleTypeId: param,
      name,
      paramInt0: value1,
      paramInt1: value2,
      paramString: value3,
      paramDouble0: doubleValue1,
      paramDouble1: doubleValue2
    };

    data =
      level === 1
        ? { ...data, channelTypeId: channel }
        : { ...data, channelInstancesId: channel };

    createChannelRule(data).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Rule added successfully', { variant: 'success' });
        if (typeof refresh === 'function') {
          refresh();
        }
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a rule', { variant: 'error' });
      }
    });
  };

  const update = () => {
    let data = {
      id: currentRule.id,
      channelRuleTypeId: param,
      name,
      paramInt0: value1,
      paramInt1: value2,
      paramString: value3,
      paramDouble0: doubleValue1,
      paramDouble1: doubleValue2
    };

    data =
      level === 1
        ? { ...data, channelTypeId: channel }
        : { ...data, channelInstancesId: channel };

    updateChannelRule(data).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Rule updated successfully', { variant: 'success' });
        if (typeof refresh === 'function') {
          refresh();
        }
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to update a rule', { variant: 'error' });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    isEdit ? update() : add();
  };

  useEffect(() => {
    if (isEdit) {
      const rule = currentRule.channelRuleType;

      setName(currentRule.name);
      setLevel(currentRule.channelTypeId ? 1 : 2);
      setChannel(
        currentRule.channelTypeId
          ? currentRule.channelTypeId
          : currentRule.channelInstancesId
      );
      setValue1(currentRule.paramInt0);
      setValue2(currentRule.paramInt1);
      setValue3(currentRule.paramString);
      setDoubleValue1(currentRule.paramDouble0);
      setDoubleValue2(currentRule.paramDouble1);
      setParam(rule.id);
      setRuleType(rule?.entityType.toLowerCase());
    }
  }, []);

  return (
    <div className="flex items-center h-full">
      <form
        onSubmit={onSubmit}
        className="bg-white w-[800px] mx-auto rounded-lg"
      >
        <ModalHead
          handleClose={handleClose}
          heading={isEdit ? 'Edit Restriction' : 'Add Restriction'}
        />
        <div className="px-5">
          <StepBar step={step} />

          {step === 1 && (
            <Level
              setStep={setStep}
              handleClose={handleClose}
              level={level}
              setLevel={setLevel}
            />
          )}
          {step === 2 && (
            <AppliesTo
              setStep={setStep}
              handleClose={handleClose}
              fetchChannels={fetchChannels}
              level={level}
              types={types}
              userChannelsAppliesTo={userChannels}
              channel={channel}
              setChannel={setChannel}
            />
          )}
          {step === 3 && (
            <Details
              setStep={setStep}
              handleClose={handleClose}
              value1={value1}
              setValue1={setValue1}
              value2={value2}
              setValue2={setValue2}
              param={param}
              setParam={setParam}
              ruleType={ruleType}
              setRuleType={setRuleType}
              detailsRuleType={channelRuleTypes}
              value3={value3}
              setValue3={setValue3}
              doubleValue1={doubleValue1}
              setDoubleValue1={setDoubleValue1}
              doubleValue2={doubleValue2}
              setDoubleValue2={setDoubleValue2}
            />
          )}
          {step === 4 && (
            <Name
              setStep={setStep}
              handleClose={handleClose}
              name={name}
              setName={setName}
              onSubmit={onSubmit}
              isEdit={isEdit}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default observer(AddRestriction);

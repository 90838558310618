// eslint-disable-next-line camelcase
import { diff_match_patch } from 'diff-match-patch';
import ChannelsList from '../../../components/ContentManagement/VersionComparison/ChannelsList';
import { observer } from 'mobx-react';

const ChannelsDiff = ({ originalChannels, modifiedChannels }) => {
  if (originalChannels === undefined && modifiedChannels === undefined) {
    return (
          <>
              <div className="text-diff-original col-span-3 p-3"></div>
              <div className="col-span-3 text-diff-modified p-3 border-l-[1px] border-l-[#eee]"></div>
          </>
    );
  }

  const originalText = (originalChannels === undefined) ? '' : originalChannels.sort().join('|');
  const modifiedText = (modifiedChannels === undefined) ? '' : modifiedChannels.sort().join('|');

  // eslint-disable-next-line new-cap
  const dmp = new diff_match_patch();

  const diff = dmp.diff_main(originalText, modifiedText);
  dmp.diff_cleanupSemantic(diff);

  const highlightedText = diff.map((part, index) => {
    const _key = `${index}-${part[0]}`;
    const colour =
        part[0] === 1 ? 'light-green' : part[0] === -1 ? 'light-red' : undefined;
    const data = part[1].split('|');

    return (
        <ChannelsList key={index} isCompare={true} channels={data.filter(Boolean)} colour={colour}/>
    );
  });

  return (
      <>
        <div className="text-diff-original col-span-3 p-3"><ChannelsList channels={originalChannels} /></div>
        <div className="col-span-3 text-diff-modified p-3 border-l-[1px] border-l-[#eee]">
          {highlightedText}
        </div>
      </>
  );
};

export default observer(ChannelsDiff);

import { formatValue } from 'react-currency-input-field';

const currency = (v, cv) => {
  const value = v ?? 0;
  const locale = LOCALE.find(f => f.code === (cv ? cv.toUpperCase() : 'GBP'));
  return formatValue({
    value: value.toString(),
    intlConfig: { locale: locale.locale, currency: locale.currency }
  });
};

const LOCALE = [
  {
    code: 'GBP',
    locale: 'en-GB',
    currency: 'GBP'
  },
  {
    code: 'USD',
    locale: 'en-US',
    currency: 'USD'
  },
  {
    code: 'INR',
    locale: 'en-IN',
    currency: 'INR'
  }
];

export default currency;

import { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import RestrictionDetail from './RestrictionDetail';

const RestrictionItem = ({ violation, channel }) => {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <div className="flex items-center gap-2 text-xs">
            <span className="text-blue">{violation.icon}</span>
            {violation.name}
          </div>
        </TableCell>
        <TableCell align="right">
          <button
            onClick={() => setShowDetail(true)}
            className="text-blue text-xs underline"
          >
            View Detail
          </button>
        </TableCell>
      </TableRow>
      <RestrictionDetail
        open={showDetail}
        violation={violation}
        channel={channel}
        handleClose={() => setShowDetail(false)}
      />
    </>
  );
};

export default RestrictionItem;

import { FormControl } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import loadingStore from '../../../store/loading';
import tagStore from '../../../store/tags/tag';
import ModalHead from '../Header/modalHead';

const UploadCsvTag = ({
  handleClose,
  refresh
}) => {
  const {
    setLoading
  } = loadingStore;

  const {
    uploadCsvFile
  } = tagStore;

  const { enqueueSnackbar } = useSnackbar();

  const [uploading, setUploading] = useState(false);
  const [csvBase64, setCsvBase64] = useState('');
  const [uploadFinish, setUploadFinish] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file && file.type !== 'text/csv') {
        enqueueSnackbar('Only csv file is allowed!', { variant: 'error' });
        event.target.value = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = function (e) {
        const csvContent = e.target.result;
        const base64String = btoa(csvContent);
        setCsvBase64(base64String);
      };
      reader.readAsText(file);
    }
  };

  const handleUpload = () => {
    if (csvBase64 !== '') {
      setLoading(true);
      setUploading(true);
      uploadCsvFile(csvBase64).then((response) => {
        setUploadResponse(response);
        setUploadFinish(true);
        setLoading(false);
      });
    } else {
      enqueueSnackbar('Please select csv file before upload!', { variant: 'error' });
    }
  };

  return (
    <div className="flex items-center h-full bg-black/10">
      <form
        onSubmit={(e) => e.preventDefault()}
        className="bg-white w-[500px] mx-auto rounded-lg drop-shadow-lg"
      >
        <ModalHead
          handleClose={handleClose}
          heading={'Upload CSV File'}
        />
        <div className="px-5">
          {!uploadFinish && (
            <FormControl fullWidth>
                <label htmlFor="company">File</label>
                <input
                type="file"
                accept=".csv"
                className="border-[1px] border-gray outline-none p-4 rounded"
                onChange={handleFileChange}
                disabled={uploading}
                />
            </FormControl>
          )}

          {uploadFinish && (
            <div className="bg-blue-100 p-4 rounded-md border border-blue-300">
                <p className="text-blue-800 text-red">
                Total failed tags: {uploadResponse?.failureCount}
                </p>
                <p className="text-blue-800 text-blue">
                Total uploaded tags: {uploadResponse?.successCount}
                </p>
            </div>
          )}

          <hr className="my-5" />
          <div className="flex justify-end">
            <button
              className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
              onClick={handleClose}
            >
              Cancel
            </button>
            {!uploadFinish && (
                <button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="mb-4 mrvn-sv rounded"
                    onClick={handleUpload}
                >
                    Upload CSV
                </button>
            )}
            {uploadFinish && (
                <button
                    variant="contained"
                    color="primary"
                    className="mb-4 mrvn-sv rounded"
                    onClick={handleClose}
                >
                    Ok
                </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadCsvTag;

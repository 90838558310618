import {
  Breadcrumbs,
  Card,
  Link,
  Typography
} from '@mui/material';
import { Container } from 'react-bootstrap';
import { RxArrowLeft } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import ContactForm from './ContactForm';

const AddOrEditContact = () => {
  const { contactId } = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      <Card className="p-8">
        <div className="flex items-center gap-2 py-5">
          <button
            className="text-xl"
            onClick={() => navigate('/toolsandstyling/manage-contact')}
          >
            <RxArrowLeft />
          </button>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component="button"
              underline="hover"
              color="inherit"
              onClick={() => navigate('/toolsandstyling/manage-contact')}
            >
              Manage Contact
            </Link>
            <Typography color="text.primary">{ contactId ? 'Update Contact' : 'Add Contact' }</Typography>
          </Breadcrumbs>
        </div>
        <ContactForm/>
      </Card>
    </Container>
  );
};

export default (AddOrEditContact);

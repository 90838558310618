import { useEffect, useState } from 'react';
import ShowSecretsForm from './Secrets/ShowSecrets';
import ToggleButton from '../../ToggleButton';
import InfoButton from '../../InfoButton';
import integrationsStore from '../../../store/settings/integrations';
import Visibility from '@mui/icons-material/VisibilityOffSharp';
import { Button, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';

const eventsList = [
  {
    name: 'Save Article',
    eventType: 'SAVE_ARTICLE',
    eventDescription: 'Create, Update or Cancellation of Article',
    value: false
  },
  {
    name: 'Clone Article',
    eventType: 'CLONE_ARTICLE',
    eventDescription: 'When an article is cloned / duplicated',
    value: false
  },
  {
    name: 'Publish Article',
    eventType: 'PUBLISH_ARTICLE',
    eventDescription: 'An article is send for publishing',
    value: false
  },
  {
    name: 'Publish Post',
    eventType: 'PUBLISH_POST',
    eventDescription:
      'An article is successfully or partially posted to any channel / channels',
    value: false
  },
  {
    name: 'Delete Post',
    eventType: 'DELETE_POST',
    eventDescription: 'Particular post is deleted from a channel',
    value: false
  },
  {
    name: 'Authorization Pending',
    eventType: 'AUTHORIZATION_PENDING',
    eventDescription: 'When article is send for authorization',
    value: false
  },
  {
    name: 'Authorization Update',
    eventType: 'AUTHORIZATION_UPDATE',
    eventDescription: 'An article is either authored or rejected',
    value: false
  }
];

const IntegrationsSetting = () => {
  const {
    getConnectionType,
    updateConnectionTypeStatus,
    createConnectionType,
    updateConnectionType,
    state: { integrationConnectionType }
  } = integrationsStore;

  const { enqueueSnackbar } = useSnackbar();
  const [deleted, setDeleted] = useState(false);
  const [connectionType, setConnectionType] = useState('WEBHOOK');
  const [connectionParam1, setConnectionParam1] = useState('');
  const [settings, setSettings] = useState({ checkboxes: eventsList });

  useEffect(() => {
    getConnectionType().then((data) => {
      if (data) {
        setDeleted(data.deleted);
        setConnectionType(data.connectionType);
        setConnectionParam1(data.connectionParam1);
        if (data.shadowEventTypes?.length > 0) {
          data.shadowEventTypes.forEach((m) =>
            handleCheckboxChange(m.eventType)
          );
        }
      }
    });
  }, []);

  const handleSaveAndUpdate = async () => {
    try {
      const postData = {
        id: integrationConnectionType && integrationConnectionType.id ? integrationConnectionType.id : null,
        connectionType,
        connectionParam1,
        shadowEventTypes: settings?.checkboxes
          ? settings.checkboxes
            .filter((f) => f.value)
            .map((m) => {
              return { eventType: m.eventType };
            })
          : []
      };
      if (integrationConnectionType && integrationConnectionType.id) {
        await updateConnectionType(integrationConnectionType.id, postData);
      } else {
        await createConnectionType(postData);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      } else {
        enqueueSnackbar('Error creating / updating connection, please contact support.', { variant: 'error' });
      }
    }
  };

  const handleToggleButton = () => {
    setDeleted(!deleted);
    updateConnectionTypeStatus(integrationConnectionType.id, !deleted);
  };

  const handleCheckboxChange = (eventType) => {
    setSettings((prevState) => ({
      checkboxes: prevState.checkboxes.map((checkbox) =>
        checkbox.eventType === eventType
          ? { ...checkbox, value: !checkbox.value }
          : checkbox
      )
    }));
  };

  const ShowSecretsButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
      setIsOpen(true);
    };
    const handleClose = () => {
      setIsOpen(false);
    };
    return (
      <>
        <Button
          variant="outlined"
          endIcon={<Visibility />}
          className="rounded px-4 py-2"
          color="primary"
          onClick={handleOpen}
        >
          Show Secrets
        </Button>
        <ShowSecretsForm open={isOpen} handleClose={handleClose} integrationConnectionType={integrationConnectionType}/>
      </>
    );
  };

  return (
    <div>
      <div className="p-5 bg-white rounded">
        <p className="mb-5 font-bold text-lg">Connection details</p>
        <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div className="flex mb-3 gap-2">
          {/* <p>Connection Deleted</p> */}
          <label>Enable Integration</label>
          <ToggleButton toggle={!deleted} handleClick={handleToggleButton} />
        </div>
        { integrationConnectionType && integrationConnectionType.id
          ? (
          <ShowSecretsButton></ShowSecretsButton>
            )
          : null}
      </div>
        {/* <div className="flex items-center mb-3">
          <label>Connection Type</label>
          <input
            id="WEBHOOK"
            name="connectionType"
            type="radio"
            value="WEBHOOK"
            className="mt-[-8px] ml-6"
            disabled={deleted}
            checked={connectionType === 'WEBHOOK'}
            onChange={(e) => setConnectionType(e.target.value)}
          />
          <label htmlFor="type" className="mr-3 ml-1">
            Webhook
          </label>
          <input
            id="MESSAGING"
            name="connectionType"
            type="radio"
            value="MESSAGING"
            className="mt-[-8px]"
            checked={connectionType === 'MESSAGING'}
            disabled={true}
            onChange={(e) => setConnectionType(e.target.value)}
          />
          <label htmlFor="instance" className="ml-1">
            Messaging
          </label>
        </div> */}
        <div className="flex items-center">
          <label>Endpoint URL</label>
          <input
            className="outline-none w-50 bg-light-gray px-3 py-2 rounded-r ml-5"
            placeholder="Connection String"
            name="connectionParam1"
            value={connectionParam1}
            disabled={deleted}
            onChange={(e) => setConnectionParam1(e.target.value)}
          />
        </div>
      </div>

      <div className="p-5 bg-white rounded mt-3">
        <p className="mb-5 font-bold text-lg">Event Types</p>
        <div>
          {settings?.checkboxes?.map((x, i) => (
            <div
              className={`flex justify-between items-center py-3 ${
                i > 0 && 'border-t-[1px] border-t-[#eee]'
              }`}
            >
              <div>
                {x.name}
                <InfoButton message={x.eventDescription}></InfoButton>
              </div>
              <label key={i}>
                <Checkbox
                  name="settings"
                  type="checkbox"
                  value={x.eventType}
                  checked={x.value}
                  disabled={deleted}
                  onChange={(e) => handleCheckboxChange(x.eventType)}
                />
              </label>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div></div>
        <button
          variant="contained"
          className="bg-blue rounded px-4 py-2 mt-3 mb-5 uppercase text-white"
          color="primary"
          disabled={deleted}
          onClick={handleSaveAndUpdate}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default IntegrationsSetting;

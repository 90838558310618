import { Fragment } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import convertToUtc from '../../../utils/convertToUtc';

const parameterEnum = [
  'TEXT',
  'BOOLEAN',
  'INTEGER',
  'OPTIONS',
  'MULTI_OPTIONS',
  'TIMESTAMP',
  'ATTACHMENTS'
];

const defaultMetadata = {
  name: '',
  description: '',
  parameterType: '',
  defaultValue: '',
  optionValue: '',
  isMandatory: false
};

const useStyles = makeStyles({
  metadata: {
    '& label.MuiFormLabel-root': {
      color: 'gray',
      fontSize: '15px'
    },
    '&.MuiOutlinedInput-root, & .MuiOutlinedInput-root, & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      height: '50px',
      fontSize: '15px',
      borderColor: 'gray',
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        borderColor: 'gray'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray'
      }
    },
    width: '100%'
  }
});

export default function ChannelMetadata ({
  metadataList,
  setMetadataList,
  channelType,
  addMetaData,
  setAddMetaData
}) {
  const classes = useStyles();

  const handleChange = (index, e) => {
    const newMetadataList = [...metadataList];
    newMetadataList[index][e.target.name] = e.target.value;

    setMetadataList(newMetadataList);
  };

  const handleChangeCheckbox = (index, e) => {
    const newMetadataList = [...metadataList];
    newMetadataList[index][e.target.name] = e.target.checked;
    setMetadataList(newMetadataList);
  };

  const handleTimestamp = (index, date) => {
    const newMetadataList = [...metadataList];
    if (isNaN(date)) {
      date = 'invalid_date';
      newMetadataList[index].defaultValue = date;
    } else {
      newMetadataList[index].defaultValue = convertToUtc(dayjs(date));
    }
    setMetadataList(newMetadataList);
  };

  const addMetadataList = () => {
    setMetadataList([...metadataList, { ...defaultMetadata }]);
  };

  const removeMetadata = (index) => {
    const newMetadataList = [...metadataList];
    newMetadataList.splice(index, 1);
    setMetadataList(newMetadataList);
  };

  const handleClearMetadata = () => {
    const newMetadataList = metadataList.map((metadata) => {
      return { ...metadata, ...defaultMetadata };
    });
    setMetadataList(newMetadataList);
  };

  return (
    <Grid container spacing={1} className="mt-1 overflow-y-auto scrollbar-hide max-h-[350px]">
      <Grid item xs="auto" className="mt-2">
        <Button
          variant="outlined"
          color="blue"
          onClick={() => {
            addMetadataList();
            setAddMetaData(true);
          }}
          className="mb-2 rounded"
        >
          Add Metadata +
        </Button>
      </Grid>
      {addMetaData && (
        <Grid item xs={10} className="mt-2">
          <Button
            variant="outlined"
            color="blue"
            onClick={handleClearMetadata}
            className="mb-2 rounded"
          >
            CLEAR
          </Button>
        </Grid>
      )}

      {addMetaData &&
        metadataList.map((metadata, index) => (
          <Fragment key={index}>
            <Grid item xs={2} className="mb-2">
              <TextField
                name="name"
                label="name"
                variant="outlined"
                className={classes.metadata}
                value={metadata.name}
                onChange={(e) => handleChange(index, e)}
                style={{ height: '40px' }}
              />
            </Grid>
            <Grid item xs={3} className="mb-2">
              <TextField
                name="description"
                label="description"
                variant="outlined"
                className={classes.metadata}
                value={metadata.description}
                onChange={(e) => handleChange(index, e)}
              />
            </Grid>
            <Grid item xs={2.5} className="mb-2">
              <FormControl fullWidth>
                <InputLabel className="text-gray" id="type_id">
                  Type
                </InputLabel>
                <Select
                  name="parameterType"
                  className={classes.metadata + ' h-11'}
                  labelId="type_id"
                  id="type_id"
                  value={metadata.parameterType}
                  label="Type"
                  onChange={(e) => handleChange(index, e)}
                >
                  {parameterEnum.map((param, i) => (
                    <MenuItem key={i} value={param}>
                      {param}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {metadata.parameterType !== 'OPTIONS' &&
              metadata.parameterType !== 'MULTI_OPTIONS' &&
              metadata.parameterType !== 'ATTACHMENTS' && (
                <Grid item xs={2.5} className="mb-2">
                  {metadata.parameterType === 'INTEGER'
                    ? (
                    <TextField
                      name="defaultValue"
                      label="Default value"
                      variant="outlined"
                      type="number"
                      className={classes.metadata}
                      value={metadata.defaultValue}
                      onChange={(e) => handleChange(index, e)}
                    />
                      )
                    : metadata.parameterType === 'BOOLEAN'
                      ? (
                    <FormControlLabel
                      name='defaultValue'
                      control={
                        <Checkbox
                          checked={
                            !!(metadata.defaultValue === true ||
                            metadata.defaultValue === 'true')
                          }
                          onChange={(e) => handleChangeCheckbox(index, e)}
                        />
                      }
                      label="True/False"
                    />
                        )
                      : metadata.parameterType === 'TIMESTAMP'
                        ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Default value"
                        className={classes.metadata}
                        value={dayjs(metadata.defaultValue)}
                        onChange={(e) => handleTimestamp(index, e)}
                      />
                    </LocalizationProvider>
                          )
                        : (
                    <TextField
                      name="defaultValue"
                      label="Default value"
                      variant="outlined"
                      type="text"
                      className={classes.metadata}
                      value={metadata.defaultValue}
                      onChange={(e) => handleChange(index, e)}
                    />
                          )}
                </Grid>
            )}
            {(metadata.parameterType === 'OPTIONS' ||
              metadata.parameterType === 'MULTI_OPTIONS') && (
              <Grid item xs={2.5} className="mb-2 w-full">
                <TextField
                  name="optionValue"
                  id="option_value"
                  label="Options(, separated)"
                  variant="outlined"
                  className={classes.metadata}
                  value={metadata.optionValue}
                  onChange={(e) => handleChange(index, e)}
                />
              </Grid>
            )}
            <Grid item xs={metadata.parameterType === 'ATTACHMENTS' ? 4.5 : 2}>
              <Stack direction="row" className='' alignItems="center" justifyContent='flex-end' spacing={1}>
                <FormControlLabel
                  name="isMandatory"
                  className=""
                  control={
                    <Checkbox
                      checked={
                        !!(metadata.isMandatory === true ||
                        metadata.isMandatory === 'true')
                      }
                      onChange={(e) => handleChangeCheckbox(index, e)}
                    />
                  }
                  label="Required"
                />
                <DeleteOutlineIcon
                  style={{ marginBottom: '8px', color: 'red' }}
                  onClick={() => removeMetadata(index)}
                />
              </Stack>
            </Grid>
          </Fragment>
        ))}
    </Grid>
  );
}

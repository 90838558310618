import { makeAutoObservable } from 'mobx';
import client from '../utils/axios';
import loadingStore from './loading';
import { getInfo } from 'sharewithmarvin-mediainfo';

// const ENDPOINT = "/channeltypes/channels";

const getOrientation = (width, height) => {
  let orientation;
  if (width > height) {
    orientation = 'landscape';
  } else if (width < height) {
    orientation = 'portrait';
  } else {
    orientation = 'square';
  }

  return orientation;
};

const getGcd = (width, height) => {
  if (!height) {
    return width;
  }
  return getGcd(height, width % height);
};

class FileStore {
  // attachments include both inline and external attachment, whereas inlineAttachments only includes inline attachment.
  state = { attachments: [], inlineAttachments: [] };

  constructor (arg) {
    makeAutoObservable(this);
  }

  setAttachment = (attachments) => {
    if (attachments.length !== 0) {
      this.state.attachments = [...this.state.attachments, ...attachments];
    } else {
      this.state.attachments = [];
    }
  };

  setAttachments = (attachments) => {
    this.state.attachments = attachments;
  };

  setInlineAttachment = (attachment) => {
    if (attachment.length !== 0) {
      this.state.inlineAttachments = [...this.state.inlineAttachments, ...attachment];
    } else {
      this.state.inlineAttachments = [];
    }
  };

  removeAttachment = (id) => {
    this.state.attachments = this.state.attachments.filter(attachment => attachment.id !== id);
  };

  removeAttachmentByURL = (url) => {
    this.state.attachments = this.state.attachments.filter(attachment => attachment?.url !== url);
    this.state.inlineAttachments = this.state.inlineAttachments.filter(attachment => attachment?.url !== url);
  };

  fetchImageByID = async (id) => {
    loadingStore.setLoading(true);
    try {
      const res = await client.get(process.env.REACT_APP_BASE_URL + '/assets/assets/images/' + id);
      return res.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchFileByID = async (id) => {
    loadingStore.setLoading(true);
    try {
      const res = await client.get(process.env.REACT_APP_BASE_URL + '/files/files/' + id);
      return res.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addImage = (file, type) => {
    return new Promise((resolve, reject) => {
      const data = {
        base: '',
        filename: file.name,
        extension: file.name.split('.').pop(),
        contentType: file.type
      };

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        const base64Data = base64String.substring(
          base64String.indexOf(',') + 1
        );
        data.base = base64Data;
        data.filename = file.name.slice(0, file.name.lastIndexOf('.'));

        client
          .post(process.env.REACT_APP_BASE_URL + '/assets/assets/images/' + type, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  deleteImage = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(process.env.REACT_APP_BASE_URL + '/assets/assets/images/' + id);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addFilesByArticle (articleId, files) {
    let currentIndex = 0;

    function uploadNextFile () {
      if (currentIndex >= files.length) {
        return;
      }

      const file = files[currentIndex];
      const reader = new FileReader();

      reader.onload = function (event) {
        const base64Data = event.target.result.split(',')[1];

        const payload = {
          base: base64Data,
          filename: file.name,
          extension: file.name.split('.').pop(),
          contentType: file.type
        };

        client
          .post(`${process.env.REACT_APP_BASE_URL}/files/files/${articleId}`, payload)
          .then((response) => {
            if (response.status === 200) {
              // File uploaded successfully, do something
            } else {
              // Handle upload error
            }
            currentIndex++;
            uploadNextFile();
          })
          .catch((_error) => {
            currentIndex++;
            uploadNextFile();
          });
      };

      reader.readAsDataURL(file);
    }

    uploadNextFile();
  }

  deleteFile = async (articleId, id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}/contents/articles/files/${articleId}/${id}`);
      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getFileMediaInfo = async (file, contentType) => {
    const { media } = await getInfo(file);
    const fileInfo = media.track.filter((info) => {
      if (info['@type'].toLowerCase() === contentType) {
        return true;
      } else {
        return false;
      }
    })[0];

    if (fileInfo) {
      const orientation = getOrientation(fileInfo.Width, fileInfo.Height);
      const gcd = getGcd(fileInfo.Width, fileInfo.Height);
      let aspectRatio;
      if (fileInfo.Width && fileInfo.Height) {
        aspectRatio = `${(fileInfo.Width / gcd)}:${(fileInfo.Height / gcd)}`;
      }
      return { ...fileInfo, orientation, aspectRatio };
    }

    return null;
  };

  createShortenerUrl = async (link) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}/shortener/url/shorten`, { url: link });
      return response.data?.shortUrl;
    } catch (error) {
      return link;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const fileStore = new FileStore();
export default fileStore;

import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { AddRoleButton } from '../../components/AddButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import RoleMoreMenu from '../../components/Security/roleList/RoleMoreMenu';

import { observer } from 'mobx-react';
import { BulkDeleteButton } from '../../components/DeleteButton';
import roleStore from '../../store/security/role';

import { useSnackbar } from 'notistack';
import canI from '../../utils/canI';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Role = () => {
  const {
    state: { roles },
    deleteRole,
    getRoles
  } = roleStore;

  const { enqueueSnackbar } = useSnackbar();

  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 10;
  const itemsPerPage = 10;

  const filteredUsers = applySortFilter(
    roles,
    getComparator(order, orderBy),
    filterName
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const TABLE_HEAD = [
    { id: 'name', label: 'Role Name', isSorted: orderBy === 'name' },
    { id: 'roleType', label: 'Role Type' },
    {
      id: 'description',
      label: 'Description',
      isSorted: orderBy === 'description'
    },
    { id: 'moreMenu' }
  ];

  useEffect(() => {
    getRoles().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch roles', { variant: 'error' })
    );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (roleId) => {
    deleteRole(roleId).then((res) =>
      res === 1
        ? enqueueSnackbar('Role deleted successfully', { variant: 'success' })
        : enqueueSnackbar('Failed to delete role', { variant: 'error' })
    );
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Role"
        actions={[
          canI(['delete:roles']) && <BulkDeleteButton
            onDelete={deleteRole}
            selected={selected}
            setSelected={setSelected}
            heading={['Role', 'Roles']}
          />,
          canI(['create:roles']) && <AddRoleButton />
        ]}
      />

      <Card style={{ boxShadow: 'none' }}>
        <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={roles.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {visibleItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, name, type, description } = row;
                  const isItemSelected = selected.indexOf(row.id) !== -1;
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{type}</TableCell>
                      <TableCell align="left" className="col-md-5">
                        {description}
                      </TableCell>
                      <TableCell align="right">
                        {type !== 'system' && (
                          <RoleMoreMenu
                            onDelete={() => handleDeleteUser(id)}
                            row={row}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    sx={{ py: 3 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default observer(Role);

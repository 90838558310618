import { Box } from '@mui/material';
import React from 'react';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '5px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
};

const ModalBox = ({ children, forwardedRef, ...props }) => {
  return (
      <Box
        {...props}
        ref={forwardedRef}
        style={{
          ...modalStyle
        }}
      >
        {children}
      </Box>
  );
};

export default React.forwardRef((props, ref) => <ModalBox {...props} forwardedRef={ref} />);

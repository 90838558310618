import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow
} from '@mui/material';
import { useEffect, useRef } from 'react';

const InstanceList = ({
  selectedChannel,
  channelsRef,
  selectedChannel: {
    channel,
    position,
    violations,
    icon: { icon, color }
  },
  close
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !channelsRef.current.contains(event.target)
      ) {
        close();
      }
    };

    if (selectedChannel) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [selectedChannel]);

  return (
    <div
      ref={ref}
      style={{ top: position.y + 10, left: position.x + 10 }}
      className="absolute bg-white drop-shadow-lg p-3 w-[400px] z-20"
    >
      <div className="flex items-center gap-3">
        <p className="font-bold">{channel.name + ' User Defined Channels:'}</p>
        <p style={{ color }} className="text-xl">
          {icon}
        </p>
      </div>
      <TableContainer>
        <Table>
          <TableBody>
            {channel.instances.map((x, i) => (
              <TableRow key={i}>
                <TableCell>
                  <div className="flex items-center gap-2 text-xs">
                    {x.name}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex items-center gap-3">
        <button onClick={() => close()} className="text-blue underline text-sm">
          Close
        </button>
      </div>
    </div>
  );
};

export default InstanceList;

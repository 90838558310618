import { AccordionDetails, AccordionSummary, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, ListItemText, Select, TextField, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MuiAccordion from '@mui/material/Accordion';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';
import dayjs from 'dayjs';
import fileStore from '../../store/file';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { observer } from 'mobx-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={2} square {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: '20px',
  padding: '10px',
  marginBottom: '10px',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export default function MetadataQuestions (
  {
    channelMetadataForm,
    metadataValue,
    register,
    control,
    handleSubmit,
    formState,
    onMetadataFormSubmit,
    onMetadataFormError,
    viewOnly
  }
) {
  return (channelMetadataForm.length > 0 &&
    <div className="bg-white my-5 p-3 rounded-md">
      <form className='p-3' onSubmit={handleSubmit(onMetadataFormSubmit, onMetadataFormError)}>
        <h4 className="text-lg text-center font-bold mb-4">Channel Metadata</h4>
        <p className='text-xs text-center mb-4'>Some of the channels needs extra information for publishing</p>
          {channelMetadataForm.map((metadata, i) => (
            <MetadataByChannels
              key={metadata.channelInstanceId ?? metadata.channelTypeId}
              metadata={metadata}
              register={register}
              control={control}
              formState={formState}
              metadataValue={metadataValue}
              viewOnly={viewOnly}
            />
          ))}
      </form>
    </div>
  );
}

const MetadataByChannels = observer(({
  metadata,
  register,
  control,
  formState,
  metadataValue,
  viewOnly
}) => {
  const isValidDate = (date) => {
    if (isNaN(date)) {
      return false;
    }
    return true;
  };

  const { state: { attachments } } = fileStore;
  const { errors } = formState;

  const getFormValue = (channelId, metadataId, isMultiOption, isAttachment) => {
    if (metadataValue == null || metadataValue.length === 0) {
      return null;
    }

    const innerObj = getValueByChannelAndMetadataId(metadataValue, channelId, metadataId);
    if (!innerObj) {
      return null;
    }

    if (isMultiOption) {
      return innerObj;
    } else if (isAttachment) {
      return attachments ? attachments.find(file => file.url === innerObj[0]) ?? '' : '';
    } else {
      return innerObj[0];
    }
  };

  function getValueByChannelAndMetadataId (state, channelId, metadataId) {
    const channel = state.find(c => c.channelInstanceId === channelId || c.channelTypeId === channelId);
    if (channel) {
      const metadataType = channel.metaDataTypes.find(meta => meta.id === metadataId);
      return metadataType ? metadataType.value : null;
    }
    return null;
  }

  const renderInputField = ({ id, name, description, parameterType, value, isMandatory, optionValue }, channelId, channelType, viewOnly) => {
    switch (parameterType) {
      case 'INTEGER' :
        return (
          <TextField
              className='w-full'
              name={channelId + ',' + id + ',' + channelType}
              label={description}
              variant="outlined"
              type="number"
              defaultValue={getFormValue(channelId, id) !== null ? parseInt(getFormValue(channelId, id)) : parseInt(value) }
              {...register(channelId + ',' + id + ',' + channelType, {
                required: isMandatory === 'true'
              })}
              error={!!errors[channelId + ',' + id + ',' + channelType]}
              helperText={errors[channelId + ',' + id + ',' + channelType] ? 'This field is marked mandatory' : ''}
              inputProps={{ readOnly: viewOnly }}
          />
        );

      case 'BOOLEAN' :
        return (
          <Controller
            name={channelId + ',' + id + ',' + channelType}
            control={control}
            defaultValue={getFormValue(channelId, id) !== null ? (getFormValue(channelId, id) === 'true') : (value === 'true') }
            render={({ field }) => (
              <FormControlLabel
                name={name}
                className='w-full'
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => !viewOnly && field.onChange(e.target.checked)}
                  />
                }
                label={description}
              />
            )}
        />
        );

      case 'TIMESTAMP' :
        return (
          <Controller
              control={control}
              name={channelId + ',' + id + ',' + channelType}
              rules={{ required: isMandatory === 'true', validate: { isValidDate } }}
              defaultValue={getFormValue(channelId, id) !== null ? dayjs(getFormValue(channelId, id)) : dayjs(value)}
              render={({ field }) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          readOnly={viewOnly}
                          className="w-full"
                          label={description}
                          value={field.value}
                          inputRef={field.ref}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          slotProps={{
                            textField: {
                              error: !!errors[channelId + ',' + id + ',' + channelType],
                              helperText: errors[channelId + ',' + id + ',' + channelType] ? 'This field is marked mandatory' : ''
                            }
                          }}
                      />
                  </LocalizationProvider>
                );
              }}
          />);

      case 'OPTIONS' :
        return (
          <Controller
            id={name}
            name={channelId + ',' + id + ',' + channelType}
            control={control}
            rules={{ required: isMandatory === 'true' ? 'This field is marked mandatory' : false }}
            defaultValue={getFormValue(channelId, id) !== null ? getFormValue(channelId, id) : ''}
            render={({ field }) => (
              <FormControl className='w-full' error={!!errors[channelId + ',' + id + ',' + channelType]}>
                <InputLabel id={name}>
                    {description}
                </InputLabel>
                <Select
                  {...field}
                  readOnly={viewOnly}
                  labelId={name}
                  label={description}
                  inputRef={field.ref}
                  value={field.value}
                >
                  {optionValue?.split(',').map((param, i) => (
                    <MenuItem key={i} value={param}>
                        {param}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors[channelId + ',' + id + ',' + channelType] && errors[channelId + ',' + id + ',' + channelType].message}
                </FormHelperText>
              </FormControl>
            )}
          />
        );

      case 'MULTI_OPTIONS' :
        return (
          <Controller
              control={control}
              id={name}
              name={channelId + ',' + id + ',' + channelType + ',' + 'multioptions'}
              rules={{ required: isMandatory === 'true' ? 'This field is marked mandatory' : false }}
              defaultValue={getFormValue(channelId, id, true) !== null ? getFormValue(channelId, id, true) : [] }
              render={({ field, ref }) => {
                return (
                  <FormControl className='w-full' error={!!errors[channelId + ',' + id + ',' + channelType + ',' + 'multioptions']}>
                  <InputLabel id={name}>
                      {description}
                  </InputLabel>
                  <Select
                      {...field}
                      readOnly={viewOnly}
                      labelId={name}
                      label={description}
                      multiple
                      inputRef={ref}
                      value={field.value && field.value.length > 0 ? field.value : []}
                      renderValue={(selected) => selected && selected.length > 0 ? selected.join(', ') : []}
                      MenuProps={MenuProps}
                  >
                      {optionValue.split(',').map((param, i) => (
                        <MenuItem key={i} value={param}>
                            <Checkbox checked={field.value?.indexOf(param) >= 0} />
                            <ListItemText primary={param} />
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                        {errors[channelId + ',' + id + ',' + channelType + ',' + 'multioptions'] && errors[channelId + ',' + id + ',' + channelType + ',' + 'multioptions'].message}
                  </FormHelperText>
                  </FormControl>
                );
              }}
          />);

      case 'ATTACHMENTS' :
        return (
          <Controller
            control={control}
            id={name}
            name={channelId + ',' + id + ',' + channelType + ',' + 'attachments'}
            rules={{
              required: isMandatory === 'true'
            }}
            defaultValue={getFormValue(channelId, id, false, true)}
            render={({ field }) => {
              return (
                  <Autocomplete
                    {...field}
                    readOnly={viewOnly}
                    className='w-full'
                    name={name}
                    options={attachments}
                    autoHighlight
                    getOptionLabel={(option) => {
                      if (option === '') {
                        return '';
                      } else {
                        return option.filename;
                      }
                    }}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    defaultValue={getFormValue(channelId, id, false, true)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          className="h-10 w-10 rounded-full"
                          loading="lazy"
                          width="20"
                          src={option.url || process.env.REACT_APP_PLACEHOLDER_IMAGE}
                          srcSet={`${option.url} 2x` || process.env.REACT_APP_PLACEHOLDER_IMAGE}
                          alt={process.env.REACT_APP_PLACEHOLDER_IMAGE}
                        />
                        {option.filename}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={description}
                        error={!!errors[channelId + ',' + id + ',' + channelType + ',' + 'attachments']}
                        helperText={errors[channelId + ',' + id + ',' + channelType + ',' + 'attachments'] ? 'This field is marked mandatory' : ''}
                      />
                    )}
                  />
              );
            }}
          />);

      default:
        return (
          <TextField
              name={channelId + ',' + id + ',' + channelType}
              label={description}
              variant="outlined"
              type="text"
              className='w-full'
              defaultValue={getFormValue(channelId, id) !== null ? getFormValue(channelId, id) : value }
              {...register(channelId + ',' + id + ',' + channelType, {
                required: isMandatory === 'true'
              })}
              error={!!errors[channelId + ',' + id + ',' + channelType]}
              helperText={errors[channelId + ',' + id + ',' + channelType] ? 'This field is marked mandatory' : ''}
              inputProps={{ readOnly: viewOnly }}
          />);
    }
  };

  const [expandedMetadata, setExpandMetadata] = useState(true);

  return (
    <>
      {metadata.channelInstanceId && (
          <Accordion expanded={expandedMetadata} onChange={() => setExpandMetadata(!expandedMetadata)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className='mb-2 w-full'
            >
              <p className='font-semibold w-full'>{metadata.channelInstanceName}</p>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems='center'>
                {metadata.metaDataTypes.map((metadataFields, i) => (
                  <Grid item xs={metadataFields.fieldSize === 0 ? 6 : metadataFields.fieldSize} key={metadataFields.id}>
                    {renderInputField(metadataFields, metadata.channelInstanceId, 'INSTANCE', viewOnly)}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
      )}
      {metadata.channelTypeId && (
        <Accordion expanded={expandedMetadata} onChange={() => setExpandMetadata(!expandedMetadata)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='mb-2 w-full'
          >
            <Grid item xs={12}>
              <p className='font-semibold'>{metadata.channelTypeName}</p>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} alignItems='center'>
              {metadata.metaDataTypes.map((metadataFields, i) => (
                <Grid item xs={metadataFields.fieldSize === 0 ? 6 : metadataFields.fieldSize} key={metadataFields.id}>
                  {renderInputField(metadataFields, metadata.channelTypeId, 'TYPE', viewOnly)}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
});

import { useEffect, useState } from 'react';

import { FormControl, Modal } from '@mui/material';
import ModalHead from '../Header/modalHead';
import { useSnackbar } from 'notistack';

import departmentStore from '../../../store/organisation/department';

const DepartmentNewForm = ({
  open,
  handleClose,
  isEdit,
  currentDepartment
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addDepartment, updateDepartment } = departmentStore;

  const [name, setName] = useState('');

  const handleAdd = () => {
    addDepartment({ name }).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Department added successfully', {
          variant: 'success'
        });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a department', { variant: 'error' });
      }

      if (res === 2) {
        enqueueSnackbar('Given department name already exists, department name should be unique', { variant: 'error' });
      }
    });
  };

  const handleEdit = () => {
    updateDepartment({ ...currentDepartment, name }).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Department updated successfully', {
          variant: 'success'
        });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to update a department', { variant: 'error' });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    isEdit ? handleEdit() : handleAdd();
  };

  useEffect(() => {
    if (isEdit) {
      setName(currentDepartment.name);
    }
  }, []);

  return (
    <Modal open={open}>
      <div className="flex items-center h-full drop-shadow-lg">
        <form
          onSubmit={onSubmit}
          className="bg-white w-[500px] mx-auto rounded-lg"
        >
          <ModalHead
            handleClose={handleClose}
            heading={isEdit ? 'Edit Department' : 'Add Department'}
          />
          <div className="px-5">
            <FormControl className="mt-2" fullWidth>
              <label htmlFor="company">Department</label>
              <input
                className="border-[1px] border-gray outline-none p-4 rounded"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <hr className="my-5" />

            <div className="flex justify-end">
              <button
                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              <button
                variant="contained"
                color="primary"
                type="submit"
                className="mb-4 mrvn-sv rounded"
              >
                {!isEdit ? 'Add Department' : 'Save Changes'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DepartmentNewForm;

import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';

const ChooseConnectionOption = ({
  handleClose,
  setStep,
  channelType,
  connectionType,
  setConnectionType,
  connectionTypes,
  fields,
  setFields,
  connectToChannel
}) => {
  const updateFieldChanged = (index) => (e) => {
    const newArr = [...fields];
    newArr[index][e.target.name] = e.target.value;
    setFields(newArr);
  };

  return (
    <>
        <Box className="h-[400px]">
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Choose your connection type
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <RadioGroup
                  aria-labelledby="connection-type"
                  value={connectionType ? connectionType.connectionType : ''}
                  name="radio-buttons-group"
                  onChange={(e) => {
                    const selectedConnectionType = e.target.value;
                    const connectionType = connectionTypes.find(
                      (item) => item.connectionType === selectedConnectionType
                    );
                    setConnectionType(connectionType);
                  }}
                >
                  {connectionTypes.map((item, index) => (
                    <Box key={index}>
                      <p className="py-1 text-sm font-medium">
                        {item.connectionTypeDesc}
                      </p>
                      <FormControlLabel
                        value={item.connectionType}
                        control={
                          <Radio size="small" className="mr-1 text-blue" />
                        }
                        label={item.connectionType}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </Stack>
            </Grid>
          </Grid>
          <Grid item>
            {fields != null &&
              fields.length !== 0 &&
              fields.map((item, key) => {
                return item.parameterType === 'BOOLEAN'
                  ? (
                  <FormControl key={key} className="my-3" fullWidth>
                    <label htmlFor="conumer-key">{item.parameterName}</label>
                    <RadioGroup
                      name="parameterValue"
                      defaultValue={'Yes'}
                      onChange={updateFieldChanged(key)}
                      className="my-2 p-2 text-gray-900"
                    >
                      <FormControlLabel
                        checked={item.parameterValue === 'Yes'}
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              '&, &.Mui-checked': {
                                color: 'gray'
                              }
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        checked={item.parameterValue === 'No'}
                        value="No"
                        control={
                          <Radio
                            sx={{
                              '&, &.Mui-checked': {
                                color: 'gray'
                              }
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                    )
                  : (
                  <FormControl key={key} className="my-3" fullWidth>
                    <label htmlFor="conumer-key">{item.parameterName}</label>
                    <input
                      name="parameterValue"
                      value={item.parameterValue ?? ''}
                      onChange={updateFieldChanged(key)}
                      type={item.sensetive === true ? 'password' : 'text'}
                      className="border-[1px] border-gray outline-none p-4 rounded"
                    />
                  </FormControl>
                    );
              })}
          </Grid>
          {connectionType && (
            <Stack direction="row" justifyContent="center">
              <Chip
                className="rounded-md w-1/2 h-12 gap-1 text-white hover:bg-blue"
                variant="filled"
                onClick={() => {
                  connectToChannel();
                }}
                label={'CLICK TO CONNECT NOW'}
                color="blue"
              />
            </Stack>
          )}
        </Box>

        <hr className="my-5" />
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={() => setStep(2)}
            className="text-blue underline"
          >
            Back
          </button>{' '}
          <div className="flex justify-end">
            {/* <button
              type="button"
              className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
              onClick={handleClose}
            >
              Cancel
            </button> */}
            <button
              type="button"
              onClick={handleClose}
              className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            >
              CONNECT LATER
            </button>
          </div>
        </div>
    </>
  );
};

export default ChooseConnectionOption;

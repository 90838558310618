// material
import { Box, Modal } from '@mui/material';
// components
import ModalHead from '../../components/Modal/Header/modalHead';
import UserNewForm from '../Modal/Security/UserNewForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24
};

export default function UserCreate ({ open, handleClose, isEdit, currentUser }) {
  return (
    <Modal open={open} onClose={handleClose} className="flex justify-center">
      <Box sx={style} className='rounded-lg max-h-screen overflow-x-auto'>
        <ModalHead
          handleClose={handleClose}
          heading={!isEdit ? 'Add User' : 'Edit User'}
          links={[{ name: !isEdit ? 'New user' : 'Edit user' }]}
        />
        <UserNewForm
          isEdit={isEdit}
          currentUser={currentUser}
          handleClose={handleClose}
        />
      </Box>
    </Modal>
  );
}

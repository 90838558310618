import { ReactComponent as TwitterLike } from '../../../assets/icons/Twitter/like.svg';
import { ReactComponent as TwitterComment } from '../../../assets/icons/Twitter/comment.svg';
import { ReactComponent as TwitterRepost } from '../../../assets/icons/Twitter/repost.svg';
import { ReactComponent as TwitterBluetick } from '..//../../assets/icons/Twitter/bluetick.svg';
import { ReactComponent as TwitterView } from '../../../assets/icons/Twitter/view.svg';
import { ReactComponent as TwitterShare } from '../../../assets/icons/Twitter/share.svg';
import GridlayoutPreview from './GridlayoutPreview';
import { useEffect, useRef } from 'react';
import mentionReplaceUtil from './MentionReplaceUtil';

function TwitterPreview ({
  plainText,
  files,
  previewData,
  user,
  mentions
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    if (mentions) {
      const content = mentionReplaceUtil(mentions, plainText, 'Formerly Twitter', 'https://www.twitter.com/');
      contentRef.current.innerHTML = content;
    }
  }, [mentions, plainText]);

  const handleClick = () => {
    if (previewData.socialChannelLink) {
      window.open(previewData.socialChannelLink, '_blank');
    }
  };

  return (
    <div className='bg-gray-light p-3 rounded-md'>
      <div className='flex items-start mt-3 mb-2'>
        <img
          src={previewData?.profilePicUrl ? previewData?.profilePicUrl : user?.picture}
          alt='User'
          className='w-8 h-8 rounded-full mr-2'
        />
        <div className='w-11/12'>
          <div className='flex items-center gap-1'>
            <p className='text-sm font-semibold cursor-pointer' onClick={handleClick}>{previewData?.displayName ? previewData?.displayName : user.nickname}</p>
            <TwitterBluetick className='w-4 h-4' />
          </div>
          <p className='break-words mb-4' ref={contentRef}></p>
        </div>
      </div>

      {files && files.length > 0 && (
        <>
          {files.find(file => file.contentType.startsWith('image/'))
            ? (
                <GridlayoutPreview isTwitter={true} countFrom={4} images={files.filter(file => file.contentType.startsWith('image/')).map(file => file.url)} />
              )
            : (
                files.find(file => file.contentType.startsWith('video/'))
                  ? (
                      <video controls width='100%' className='h-[400px] object-cover'>
                        <source src={files.find(file => file.contentType.startsWith('video/')).url} type={files.find(file => file.contentType.startsWith('video/')).contentType} />
                        Your browser does not support the video tag.
                      </video>)
                  : <></>
              )}
        </>
      )}
      <div className='flex items-center gap-2 justify-between mt-7'>
        <TwitterComment />
        <TwitterRepost />
        <TwitterLike />
        <TwitterView />
        <TwitterShare />
      </div>
    </div>
  );
}

export default TwitterPreview;

import { publicationStatuses } from '../../../constants/publicationStatues';
import { useEffect, useState } from 'react';
import {
  Tooltip
} from '@mui/material';

import {
  BsFillTrash3Fill
} from 'react-icons/bs';

const PublicationStatusNode = ({ item }) => {
  const [previewIcon, setPreviewIcon] = useState();
  const [deletedIcon] = useState(<BsFillTrash3Fill/>);

  useEffect(() => {
    setPreviewIcon(publicationStatuses[item.status]?.icon);
  }, [item]);

  return (
    <>
        <Tooltip title={item.error ? item.error : item.referenceId ? item.referenceId : ''} >
          <div className={'absolute right-0 top-5 h-10 w-16 items-center'}>
              <div
                className={`
                  flex
                  items-center
                  justify-around
                  absolute
                  transform
                  rounded-md 
                  -rotate-90 
                  ${item.deleted ? 'bg-orange-600' : publicationStatuses[item.status]?.bgColor}
                  text-xs
                  text-center
                  items-center
                  text-white
                  font-semibold
                  py-2
                  left-[-8px]
                  top-5 
                  w-28`
                }>
                <span className={'stroke-0 w-4 '}>{ item.deleted ? deletedIcon : previewIcon}</span>
                {item.entityType === 'ROOT' ? 'DOCUMENTS' : item.entityType}
              </div>
            </div>
        </Tooltip>
    </>
  );
};

export default PublicationStatusNode;

import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { GrGraphQl } from 'react-icons/gr';
import { icons } from '../../../constants/channels';
import channelsStore from '../../../store/channel/channel';
import canI from '../../../utils/canI';
import ToggleButton from '../../ToggleButton';

const SystemChannels = () => {
  const {
    state: { socialChannels },
    fetchSocialChannels
  } = channelsStore;

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(!canI('update:features'));
    fetchSocialChannels();
  }, []);

  return (
        <div className="px-5 bg-white rounded mt-10">
            {socialChannels.map((x, i) => (
                <Item
                    key={i}
                    index={i}
                    item={x}
                    isDisabled={isDisabled}
                    icon={icons.find((c) => x.channelName === c.name)}
                />
            ))}
        </div>
  );
};

export default observer(SystemChannels);

const Item = ({
  item: {
    id,
    channelName,
    active
  },
  index,
  isDisabled,
  icon
}) => {
  const { enableChannel, disableChannel } = channelsStore;

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(!!active);
  }, [active]);

  const handleChange = () => {
    active ? disableChannel(id, 'social') : enableChannel(id, 'social');
  };

  return (
        <div
        className={`flex justify-between items-center py-5 ${index > 0 && 'border-t-[1px] border-t-[#eee]'
            }`}
        >
            <div className="flex justify-between items-center">
                <div className="text-white bg-blue p-3 rounded relative text-xl mr-2">
                    {icon ? icon.icon : <GrGraphQl />}
                </div>
                <p>{channelName}</p>
            </div>
            <ToggleButton disabled={isDisabled} toggle={enabled} handleClick={handleChange} />
        </div>
  );
};

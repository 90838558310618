import {
  ReactComponent as Phrases
} from '../assets/icons/Content/Phrases.svg';

import {
  ReactComponent as IconTags
} from '../assets/icons/Content/Tags.svg';

import {
  ReactComponent as IconHistory
} from '../assets/icons/Content/History.svg';

import {
  ReactComponent as IconVisibility
} from '../assets/icons/Content/Visibility.svg';

import PreviewIcon from '@mui/icons-material/Preview';

import {
  ReactComponent as UserActivity
} from '../assets/icons/Content/UserActivity.svg';

export const sideMenu = [
  {
    label: 'Post Preview',
    slug: 'post-preview',
    class: 'post-preview',
    icon: <PreviewIcon className='w-7 h-7'/>,
    viewOnly: true
  },
  {
    label: 'Tags',
    slug: 'tag',
    class: 'icon-tag',
    icon: <IconTags className='w-7 h-7'/>,
    viewOnly: false
  }, {
    label: 'History',
    slug: 'version-history',
    class: 'icon-version-history',
    icon: <IconHistory className='w-7 h-7'/>,
    viewOnly: true
  }, {
    label: 'Visibility',
    slug: 'visibility',
    class: 'icon-visibility',
    icon: <IconVisibility className='w-7 h-7'/>,
    viewOnly: true
  }, {
    label: 'Phrases',
    slug: 'phrases',
    class: 'icon-phrases',
    bgColor: '#FEE9E9',
    icon: <Phrases className='w-7 h-7'/>,
    viewOnly: false
  }, {
    label: 'Contact',
    slug: 'contact',
    class: 'icon-contact',
    bgColor: '#FEE9E9',
    icon: <UserActivity className='w-7 h-7'/>,
    viewOnly: false
  }
];

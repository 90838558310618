import {
  Box,
  List,
  ListItem,
  Pagination,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { auto } from '@popperjs/core';
import dayjs from 'dayjs';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { publicationStatuses } from '../../constants/publicationStatues';
import convertToUtc from '../../utils/convertToUtc';
import SearchContentMoreMenu from '../Content/SearchContentMoreMenu';

const renderEventContent = (eventInfo) => {
  return (
    <>
      <div
        className={`w-full flex items-center justify-evenly h-6 rounded-md p-2 ${
          publicationStatuses[eventInfo.event.title]?.bgColor
        }`}
      >
        <div className="flex items-center justify-between w-full">
          <div className="w-6">
            {publicationStatuses[eventInfo.event.title]?.icon}
          </div>
          <div>
            <Typography className="text-white text-[10px] font-black">
              {publicationStatuses[eventInfo.event.title]?.name}
            </Typography>
          </div>
        </div>
        <div className="rounded-full h-4 w-4 bg-white ml-1">
          <Typography className="text-center text-black text-[10px] font-black">
            {eventInfo.event._def.extendedProps.count}
          </Typography>
        </div>
      </div>
    </>
  );
};

const FullCalendarBox = ({ content }) => {
  const [openList, setOpenList] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [setDateRangeEl] = useState(null);
  const dateRangeElRef = useRef(null);
  const calendarRef = useRef(null);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [params, setParams] = useState({
    startDate: null,
    endDate: null,
    calendar: 'Month'
  });

  const [listParams, setListParams] = useState({
    startDate: '',
    endDate: '',
    status: '',
    pageIndex: 1,
    pageSize: 7
  });

  useEffect(() => {
    setListParams({ ...listParams, pageIndex: currentPage });
  }, [currentPage]);

  const handelPageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();

  const fetchSchedule = () => {
    content.schedule(params).then((response) => {
      const item = [];
      Object.keys(response).forEach((v, i) => {
        response[v].map((itemk, itemi) => {
          return item.push({
            title: itemk.status,
            start: v,
            status: itemk.status,
            count: itemk.count
          });
        });
      });

      // Tactical fix to group event basis on event type
      const groupItem = item.reduce((unique, o) => {
        const other = unique.filter((obj) => {
          return (
            obj.status === o.status &&
            dayjs(obj.start).format('YYYY-MM-DD') ===
              dayjs(o.start).format('YYYY-MM-DD')
          );
        });
        if (other.length === 0) {
          unique.push(o);
        } else {
          unique.map((obj) =>
            obj.status === o.status &&
            dayjs(obj.start).format('YYYY-MM-DD') ===
              dayjs(o.start).format('YYYY-MM-DD')
              ? { ...obj, count: (obj.count += o.count) }
              : obj
          );
        }
        return unique;
      }, []);
      setEvents(groupItem);
    });
  };

  const fetchEventLists = () => {
    setListLoading(true);
    content.search(listParams).then(({ content, page, totalElements }) => {
      setEventList(content ?? []);
      setCurrentPage(page);
      setTotalPages(Math.ceil(totalElements / listParams.pageSize));
      setListLoading(false);
    });
  };

  useEffect(() => {
    if (params.startDate !== null || params.endDate !== null) {
      fetchSchedule();
    }
  }, [params.startDate, params.endDate, params.calendar]);

  const filterDateSet = (target) => {
    setParams({
      ...params,
      startDate: new Date(dayjs(target.startStr).startOf('date'))
        .toISOString()
        .replace(/\.\d{3}Z$/, 'Z'),
      endDate: new Date(dayjs(target.endStr).endOf('date'))
        .toISOString()
        .replace(/\.\d{3}Z$/, 'Z')
    });
  };

  useEffect(() => {
    fetchEventLists();
  }, [
    listParams.startDate,
    listParams.endDate,
    listParams.status,
    listParams.pageIndex
  ]);

  const clickOnEventBox = (eventInfo) => {
    if (
      listParams.startDate ===
      convertToUtc(dayjs(eventInfo.dateStr).startOf('date'))
    ) {
      setListParams({
        ...listParams,
        pageIndex: 1
      });
      setOpenList(!openList);
    } else {
      setListParams({
        ...listParams,
        pageIndex: 1,
        startDate: convertToUtc(dayjs(eventInfo.dateStr).startOf('date')),
        endDate: convertToUtc(dayjs(eventInfo.dateStr).endOf('date')),
        status: ''
      });
      setOpenList(true);
    }
  };

  const handleEventClick = (eventInfo) => {
    if (
      listParams.startDate ===
        convertToUtc(dayjs(eventInfo.event.start).startOf('date')) &&
      listParams.status === eventInfo.event._def.extendedProps.status
    ) {
      setListParams({
        ...listParams,
        pageIndex: 1
      });
      setOpenList(!openList);
    } else {
      setListParams({
        ...listParams,
        pageIndex: 1,
        startDate: convertToUtc(dayjs(eventInfo.event.start).startOf('date')),
        endDate: convertToUtc(dayjs(eventInfo.event.start).endOf('date')),
        status: eventInfo.event._def.extendedProps.status
      });
      setOpenList(true);
    }
  };

  const handleEditItem = (data) => {
    navigate(`/content/${data.id}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeElRef.current &&
        !dateRangeElRef.current.contains(event.target)
      ) {
        setDateRangeEl(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex">
        <div className="calendar flex-grow h-full">
          <FullCalendar
            selectable={true}
            height={auto}
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              end: 'prev,next'
            }}
            events={events}
            eventContent={renderEventContent}
            dateClick={clickOnEventBox}
            eventClick={handleEventClick}
            datesSet={filterDateSet}
            firstDay={1}
          />
        </div>
        {openList && (
          <div className="bg-white event-item-list-cal px-2">
            <div className="flex items-center justify-between gap-2">
              <div className="gap-2">
                <select
                  name="status"
                  onChange={(e) =>
                    setListParams({ ...listParams, status: e.target.value })
                  }
                  className="outline-none bg-light-gray px-1 py-2 rounded drop-shadow-sm"
                  value={listParams.status}
                >
                  <option className="hidden" value="">
                    Filter by status
                  </option>
                  <option value="">All Status</option>
                  {Object.keys(publicationStatuses).map((status, i) => {
                    return (
                      <option key={i} value={status}>
                        {status}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <Box className="overflow-auto">
              {listLoading
                ? (
                <ListItemSkelton limit={7} />
                  )
                : (
                <ListItemEvents
                  handelPageChange={handelPageChange}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  eventList={eventList}
                  handleEditItem={handleEditItem}
                />
                  )}
            </Box>
          </div>
        )}
      </div>
    </>
  );
};

export default FullCalendarBox;

const ListItemSkelton = ({ limit }) => {
  const numbers = Array.from({ length: limit }, (_, index) => index + 1);

  return (
    <>
      {numbers.map((number) => (
        <List key={number} aria-labelledby="ellipsis-list-demo">
          <ListItem disableGutters className="items-center justify-between">
            <div className="flex items-center">
              <span
                className={'inline-flex items-center justify-center w-8 h-8 mr-1 text-white rounded-full'}
              >
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={40}
                  height={40}
                />
              </span>
              <div className="flex items-center">
                <div className="flex-row">
                  <Typography component="div" variant="body1" className="w-52">
                    <Skeleton />
                  </Typography>
                  <Typography component="div" variant="body1" className="w-52">
                    <Skeleton />
                  </Typography>
                </div>
              </div>
              <div className="right-0 p-5 items-center">
                <Skeleton
                  animation="wave"
                  variant="string"
                  width={4}
                  height={15}
                />
              </div>
            </div>
          </ListItem>
        </List>
      ))}
    </>
  );
};

const ListItemEvents = ({
  eventList,
  handleEditItem,
  handelPageChange,
  currentPage,
  totalPages
}) => {
  return (
    <List aria-labelledby="ellipsis-list-demo">
      {eventList.map((k, i) => {
        return (
          <ListItem
            key={i}
            disableGutters
            className="items-center justify-between border-b-2 border-slate-200"
          >
            <div className="flex items-center">
              <span
                className={
                  'inline-flex items-center justify-center w-8 h-8 mr-1 text-white rounded-full ' +
                  publicationStatuses[k.status]?.bgColor
                }
              >
                {publicationStatuses[k.status]?.icon}
              </span>
              <div className="flex items-center">
                <div className="flex-row">
                  <b className="text-sm w-48">{k.title.substr(0, 20)}</b>
                  <p className="text-xs w-48">
                    <Moment format="MMM, DD YYYY hh:mm A">{k.updatedAt}</Moment>
                  </p>
                </div>
              </div>
            </div>
            <div className="right-0 p-5 items-center rounded-full hover:bg-gray-200 focus:outline-none">
              <SearchContentMoreMenu
                handleEdit={() => handleEditItem(k)}
                row={k}
              />
            </div>
          </ListItem>
        );
      })}
      <Stack spacing={2} className="pagination">
        <Pagination
          count={totalPages}
          page={currentPage}
          variant="outlined"
          onChange={(event, value) => handelPageChange(value)}
          color="primary"
        />
      </Stack>
    </List>
  );
};

export const industryList = [
  'Communication Services',
  'Consumer Discretionary',
  'Consumer Staples',
  'Energy',
  'Financials',
  'Healthcare',
  'Industrials',
  'Information Technology',
  'Materials',
  'Real Estate',
  'Utilities'
];

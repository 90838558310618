import { useState, useEffect } from 'react';
import { Grid, FormControl, Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import companyStore from '../../../store/organisation/company';
import { observer } from 'mobx-react';

import { BsCamera } from 'react-icons/bs';
import { industryList } from '../../../constants/industry';
import ImageUpload from '../../ImageUpload';

const CompanyNewForm = ({ isEdit, currentCompany, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addCompany, updateCompany } = companyStore;

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    displayName: '',
    description: 'This is my organization',
    logoUrl: 'https://myorg.com/logo.png',
    primaryColor: '#0088FF',
    industry: '',
    accessOption: 'ALL_USERS',
    imageId: null
  });

  const {
    name,
    displayName,
    description,
    logoUrl,
    primaryColor,
    industry,
    accessOption,
    imageId
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const adding = () => {
    const data = {
      name,
      displayName,
      industry,
      accessOption,
      metadata: {
        description
      },
      branding: {
        logoUrl,
        primaryColor
      },
      countryCode: '234'
    };

    addCompany(data, selectedImage).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Company added successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a company', { variant: 'error' });
      }
      if (res === 2) {
        enqueueSnackbar('Given company name already exists, company name should be unique', { variant: 'error' });
      }
    });
  };

  const editing = () => {
    const data = {
      id: currentCompany.id,
      name,
      displayName,
      industry,
      accessOption,
      imageId: imageId || null,
      metadata: {
        description
      },
      branding: {
        logoUrl,
        primaryColor
      },
      countryCode: '234'
    };

    updateCompany(data, selectedImage).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Company updated successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to update a company', { variant: 'error' });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    isEdit ? editing() : adding();
  };

  const handleRemoveImage = () => {
    setFormData((formData) => (formData = { ...formData, imageId: null }));
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handleImageUpload = (file) => {
    setSelectedImage(file);
    setShowUploadImage(false);
  };

  useEffect(() => {
    if (isEdit) {
      setFormData({
        ...formData,
        name: currentCompany.name,
        description: currentCompany.description,
        id: currentCompany.id,
        displayName: currentCompany.displayName,
        logoUrl: currentCompany.logoUrl,
        primaryColor: currentCompany.primaryColor,
        industry: currentCompany.industry,
        accessOption: currentCompany.accessOption,
        imageId: currentCompany.imageId
      });

      currentCompany.image && setImagePreview(currentCompany.image);
    }
  }, []);

  useEffect(() => {
    selectedImage && setImagePreview(URL.createObjectURL(selectedImage));
  }, [selectedImage]);

  return (
    <>
      <form onSubmit={() => false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={4}>
              <Box>
                <FormControl>
                  <div
                    className={`border-2 border-blue relative ${
                      imagePreview ? 'bg-white' : 'bg-light-blue'
                    } text-blue text-lg font-bold w-32 h-32 rounded-full flex items-center justify-center`}
                  >
                    {!imagePreview && 'Add Logo'}
                    {imagePreview && (
                      <img
                        className="absolute rounded-full object-cover h-full w-full"
                        src={imagePreview}
                        alt="channel"
                      />
                    )}
                    <button
                      type="button"
                      onClick={() => setShowUploadImage(true)}
                      className="cursor-pointer text-white bg-blue p-2 rounded-full absolute bottom-[-5px] right-[-5px]"
                    >
                      <BsCamera />
                    </button>
                  </div>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <label htmlFor="firstname">Company Name</label>
              <input
                className="border-[1px] border-gray outline-none p-4 rounded"
                name="name"
                variant="outlined"
                value={name}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <label htmlFor="firstname">Display Name</label>
              <input
                className="border-[1px] border-gray outline-none p-4 rounded"
                variant="outlined"
                name="displayName"
                value={displayName}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <label htmlFor="firstname">Industry</label>

              <select
                className="border-[1px] border-gray outline-none p-4 rounded"
                value={industry}
                onChange={(e) =>
                  setFormData({ ...formData, industry: e.target.value })
                }
              >
                {industryList.map((x, i) => (
                  <option key={i} value={x}>{x}</option>
                ))}
              </select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <label htmlFor="company">Access</label>
              <select
                className="border-[1px] border-gray outline-none p-4 rounded"
                value={accessOption}
                onChange={(e) =>
                  setFormData({ ...formData, accessOption: e.target.value })
                }
              >
                <option key={'ALL_USERS'} value="ALL_USERS">All Users</option>
                <option key={'CUSTOM'} value="CUSTOM">Custom</option>
                <option key={'MEMBERS_ONLY'} value="MEMBERS_ONLY">Members Only</option>
              </select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <hr className="mb-5" />
            <div className="flex justify-end">
              <button
                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              <button className="mb-4 mrvn-sv rounded" onClick={onSubmit}>
                {!isEdit ? 'Add Company' : 'Save Changes'}
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}
    </>
  );
};

export default observer(CompanyNewForm);

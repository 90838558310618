import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';

import loadingStore from '../loading';
import fileStore from '../file';

const ENDPOINT = '/organisations/companies';

class CompanyStore {
  state = {
    companies: [],
    metaCompanies: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getCompanies: action,
      getCompany: action,
      addCompany: action,
      updateCompany: action,
      deleteCompany: action,
      fetchUserMetaCompanies: action
    });
  }

  getCompanies = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.companies = response.data;
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getCompany = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getCompanyByIds = async (ids) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/list/${ids}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addCompany = async (company, file) => {
    loadingStore.setLoading(true);

    try {
      const image = file ? await fileStore.addImage(file, 'company') : null;
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, {
        ...company,
        imageId: image
      });
      this.state.companies = [...this.state.companies, response.data];
      return 1;
    } catch (error) {
      if (error.response.data !== 'undefined') {
        const regex = /already exists/;
        const str = error.response.data;
        if (regex.test(str)) {
          return 2;
        }
      }
      return error.response ? error.response.code : 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateCompany = async (company, file) => {
    loadingStore.setLoading(true);
    try {
      const image = file
        ? await fileStore.addImage(file, 'company')
        : company.imageId;
      const response = await client.put(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${company.id}`, {
        ...company,
        imageId: image
      });
      const index = this.state.companies.findIndex((c) => c.id === company.id);
      this.state.companies[index] = response.data;
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteCompany = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.companies = this.state.companies.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      return error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchUserMetaCompanies = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/list`);
      this.state.metaCompanies = response.data;
      return response.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const companyStore = new CompanyStore();
export default companyStore;

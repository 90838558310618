import client from '../../utils/axios';
import loadingStore from '../loading';

const CHANNEL_GROUP_ENDPOINT =
  process.env.REACT_APP_BASE_URL + '/channeltypes/channel-group';

class ChannelGroupAPI {
  createChannelGroup = async (channelGroupDetails) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.post(
        CHANNEL_GROUP_ENDPOINT,
        channelGroupDetails
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateChannelGroup = async (channelGroupDetails) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.put(
        CHANNEL_GROUP_ENDPOINT,
        channelGroupDetails
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchChannelGroupByIdsAndCompanyId = async (ids, companyId) => {
    try {
      loadingStore.setLoading(true);
      const FETCH_URL = CHANNEL_GROUP_ENDPOINT + '/' + ids.toString() + (companyId ? '?companyId=' + companyId : '');
      const response = await client.get(FETCH_URL);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchChannelGroup = async (companyId) => {
    try {
      loadingStore.setLoading(true);
      const FETCH_URL = CHANNEL_GROUP_ENDPOINT + (companyId ? '?companyId=' + companyId : '');
      const response = await client.get(FETCH_URL);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  searchChannelGroup = async (searchParams) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.get(CHANNEL_GROUP_ENDPOINT + '/search', {
        params: searchParams
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteChannelGroup = async (id) => {
    try {
      loadingStore.setLoading(true);
      await client.delete(CHANNEL_GROUP_ENDPOINT + '/' + id);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const channelGroupAPI = new ChannelGroupAPI();
export default channelGroupAPI;

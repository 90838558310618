import { useEffect, useRef } from 'react';
import ModalHead from '../Header/modalHead';
import { BsShieldX, BsShieldCheck } from 'react-icons/bs';

const TestConnectionStatus = ({
  handleClose,
  status,
  statusText
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={ref} className="flex items-center h-full drop-shadow-lg" >
        <div className="bg-white mx-auto rounded-lg">
          <ModalHead
            handleClose={handleClose}
            heading={'Test Response'}
          />
          <div className="px-5 my-5">
            <div className="w-[674px]">
              <div className="grid grid-cols-1 items-center">
                <div className="p-6 text-center">
                  {!status && (<BsShieldX className="text-red mx-auto mb-4 w-20 h-20" />)}
                  {status && (<BsShieldCheck className="text-blue mx-auto mb-4 w-20 h-20" />)}
                  <h3 className="mb-5 text-lg font-normal">{statusText}</h3>
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="flex items-center justify-end">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className="mb-4 mrvn-sv rounded"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestConnectionStatus;

import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';
import SessionStorage from '../../utils/marvinSession';

const MENUS_ENDPOINT = process.env.REACT_APP_BASE_URL + '/security/menus';
const PERMISSION_MENU_ENDPOINT = process.env.REACT_APP_BASE_URL + '/security/role';

class MenuStore {
  state = {
    menus: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getMenus: action,
      getMenusByRole: action
    });
  }

  getMenus = async () => {
    loadingStore.setLoading(true);

    try {
      const cachedMenus = SessionStorage.getSessionData('menus');
      if (cachedMenus) {
        this.state.menus = JSON.parse(cachedMenus);
        return this.state.menus;
      }
      const response = await client.get(`${MENUS_ENDPOINT}`);
      this.state.menus = response.data;
      SessionStorage.saveData('menus', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getMenusByRole = async (roleId) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.get(`${MENUS_ENDPOINT}/${roleId}`);

      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateMenuForRole = async (roleId, params) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(`${PERMISSION_MENU_ENDPOINT}/${roleId}`, params);

      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteMenuForRole = async (roleId, params) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.delete(`${PERMISSION_MENU_ENDPOINT}/${roleId}`, { data: params });

      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const menuStore = new MenuStore();

export default menuStore;

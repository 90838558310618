import { makeAutoObservable } from 'mobx';
import client from '../utils/axios';
import loadingStore from './loading';
class AuthStore {
  state = {
    userToken: null
  };

  constructor () {
    makeAutoObservable(this);
  }

  setUserToken = (value) => {
    this.state.userToken = value;
  };

  register = async (formData) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(process.env.REACT_APP_BASE_URL + '/register/signup', formData);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getProducts = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(process.env.REACT_APP_BASE_URL + '/register/signup/products');
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthStore();

import FavouriteItem from './FavouriteItem';

const FavouriteList = ({ data }) => {
  return (
    <>
      {data.length > 0 && (
        <div className="flex flex-col gap-3 max-h-[500px] overflow-unset scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded ">
          {data.map((x, i) => (
            <FavouriteItem x={i + 1} key={i} phrase={x} />
          ))}
        </div>
      )}
    </>
  );
};

export default FavouriteList;

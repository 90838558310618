import { Table, TableBody, TableContainer, Pagination } from '@mui/material';

import { Stack } from '@mui/system';
import { useEffect, useState, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import RestrictionItem from './RestrictionItem';

const RestrictionList = ({
  selectedChannel,
  channelsRef,
  selectedChannel: {
    channel,
    position,
    violations,
    icon: { icon, color }
  },
  showDropdown,
  setShowDropdown,
  violationTypes,
  handleCheck,
  close,
  setShowAlternate
}) => {
  const ref = useRef(null);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);

  const itemsPerPage = 5;
  const page = 0;
  const rowsPerPage = 5;

  const filterData = () => {
    setFilteredData((filteredData) =>
      violations.filter((item1) => {
        const matchItem2 = violationTypes.find(
          (item2) =>
            item1.channelRuleType.entityType.toLowerCase() ===
              item2.name.toLowerCase() && item2.selected === true
        );
        return matchItem2 !== undefined;
      })
    );
  };

  useEffect(() => {
    setVisibleItems((visibleItems) =>
      filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [filteredData, currentPage]);

  useEffect(() => {
    setTotalPages((totalPages) => Math.ceil(filteredData.length / 5));
  }, [filteredData]);

  useEffect(() => {
    filterData();
  }, [violationTypes, violations]);

  useEffect(() => {
    visibleItems.length === 0 && currentPage > 1 && setCurrentPage(page - 1);
  }, [visibleItems]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !channelsRef.current.contains(event.target)
      ) {
        const details = document.querySelector('.details');
        if (details && details.contains(event.target)) {
          return; // Don't close if clicked on the absolute div
        }

        // const wizard = document.querySelector(".wizard");
        // if (wizard && wizard.contains(event.target)) {
        //   return; // Don't close if clicked on the absolute div
        // }
        close();
      }
    };

    if (selectedChannel) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [selectedChannel]);

  return (
    <div
      ref={ref}
      style={{ top: position.y + 10, left: position.x + 10 }}
      className="absolute bg-white drop-shadow-lg p-3 w-[400px] z-20"
    >
      <div className="flex items-center gap-3">
        <p className="font-bold">
          Use of this channel is unavailable due to following restriction:
        </p>
        <p style={{ color }} className="text-xl">
          {icon}
        </p>
      </div>
      <div className="flex justify-between mt-3">
        <div className="relative drop-shadow-lg rounded">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className={`${
              showDropdown ? 'rounded-t' : 'rounded'
            } p-2 border-[1px] border-blue flex gap-3 items-center`}
          >
            Violation type {showDropdown ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          <div
            className={`${
              showDropdown ? 'h-auto border-[1px]' : 'h-0'
            } absolute border-blue w-full bg-white border-t-0 rounded-b overflow-hidden`}
          >
            {violationTypes.map((x, i) => (
              <div
                key={i}
                className={`px-2 my-1 pt-2 flex items-center gap-2 ${
                  x.selected ? 'bg-[#eee]' : 'bg-white'
                }`}
              >
                <input
                  id={x.name}
                  type="checkbox"
                  className="mt-[-7px]"
                  checked={x.selected}
                  onChange={(e) => handleCheck(e, i)}
                />
                <label htmlFor={x.name}>{x.name}</label>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={() => setShowAlternate(true)}
          className="text-blue underline text-sm"
        >
          Create alternate versions
        </button>
      </div>

      <TableContainer>
        <Table>
          <TableBody>
            {visibleItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((x, i) => (
                <RestrictionItem key={i} violation={x} channel={channel} />
              ))}
          </TableBody>
        </Table>
        <Stack spacing={2} className="pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            variant="outlined"
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
          />
        </Stack>
      </TableContainer>
    </div>
  );
};

export default RestrictionList;

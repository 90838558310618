const convertToUtc = (dateString) => {
  const localDateObject = new Date(dateString);
  const _utcDateObject = new Date(
    localDateObject.getUTCFullYear(),
    localDateObject.getUTCMonth(),
    localDateObject.getUTCDate(),
    localDateObject.getUTCHours(),
    localDateObject.getUTCMinutes(),
    localDateObject.getUTCSeconds()
  );

  return localDateObject.toISOString().replace(/\.\d{3}Z$/, 'Z');
};

export default convertToUtc;

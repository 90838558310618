const Preferences = ({ selected, setSelected }) => {
  const menu = [
    'Authoring & Editing',
    'Notification'
  ];

  return (
      <div className="flex gap-5 border-b-[1px] border-b-[#eee]">
        {menu.map((x, i) => (
          <Item value={x} key={i} selected={selected} setSelected={setSelected} />
        ))}
      </div>
  );
};

export default Preferences;

const Item = ({ value, selected, setSelected }) => {
  return (
      <button
        onClick={() => setSelected(value)}
        className={`${
          selected === value
            ? 'border-b-2 border-b-blue text-blue'
            : 'border-b-2 border-b-[rgba(0,0,0,0)]'
        } pb-5`}
      >
        {value}
      </button>
  );
};

import { useEffect, useState } from 'react';

import { FaCheck } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';

import contentStore from '../../../store/content/content';
import channelsStore from '../../../store/channel/channel';
import { icons } from '../../../constants/channels';
import { observer } from 'mobx-react';

const ConfirmDetails = ({
  setStep,
  handleClose,
  selectedChannels,
  onSubmit,
  selectedVersion,
  content
}) => {
  const {
    state: { versions }
  } = contentStore;

  const [version, setVersion] = useState({});

  useEffect(() => {
    const data = versions.find((x) => x.version === selectedVersion);
    setVersion(data || {});
  }, [selectedVersion]);

  return (
    <div>
      <div className="h-[400px] overflow-auto">
        <p className="text-[#888] font-bold">Channel Selected</p>

        <div className="bg-[#fafafa] p-3 rounded my-3 flex items-start gap-3">
          <div className="flex-1 flex flex-col gap-3">
            {selectedChannels.map((x, i) => (
              <div key={i} className="flex items-center gap-3">
                <Channel id={x.id} />
                <p className="flex-1 font-bold">{x.name}</p>
              </div>
            ))}
          </div>

          <button
            onClick={() => setStep(1)}
            className="text-xs text-[#888] underline"
          >
            Change
          </button>
        </div>

        <p className="text-[#888] font-bold">Content Creation Method</p>
        <div className="bg-[#fafafa] p-3 rounded my-3 flex items-center gap-3">
          <p className="flex-1 font-bold">Copy Content from Existing Version</p>
          <button
            onClick={() => setStep(2)}
            className="text-xs text-[#888] underline"
          >
            Change
          </button>
        </div>

        <p className="text-[#888] font-bold">Content Version</p>

        <div className="bg-[#fafafa] p-3 rounded my-3">
          <div className="flex items-center gap-3">
            {versions && versions.find((x) => x.id === selectedVersion)
              ? versions.find((x) => x.id === selectedVersion).title
              : 'Current Version'}
            <div className="flex">
              {version.channelId &&
                version.channelId.map((x, i) => <Channel key={i} id={x} />)}
            </div>
          </div>
          <div className="flex items-center gap-3 mt-2">
            {content.title
              ? (
              <FaCheck className="text-green" />
                )
              : (
              <RxCross2 className="text-red" />
                )}
            <p>Title</p>
            {content.body
              ? (
              <FaCheck className="text-green" />
                )
              : (
              <RxCross2 className="text-red" />
                )}
            <p>Body</p>
            {content.tags
              ? (
              <FaCheck className="text-green" />
                )
              : (
              <RxCross2 className="text-red" />
                )}
            <p>Tags</p>
            {content.attachments
              ? (
              <FaCheck className="text-green" />
                )
              : (
              <RxCross2 className="text-red" />
                )}
            <p>Attachments</p>
          </div>
        </div>
      </div>

      <hr className="my-5" />
      <div className="flex justify-between">
        <button
          className="text-blue underline"
          onClick={() => setStep(2)}
        >
          Back
        </button>

        <div className="flex">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
          >
            Create Version
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDetails;

const Channel = observer(({ id }) => {
  const {
    state: { allChannels }
  } = channelsStore;

  const [channel, setChannel] = useState({});
  const [icon, setIcon] = useState({});

  useEffect(() => {
    const data = allChannels.find((x) => parseInt(x.id) === parseInt(id));
    setChannel(data || {});
  }, [id]);

  useEffect(() => {
    const data = icons.find((c) => c.name === channel.name);
    setIcon(data || {});
  }, [channel]);

  return (
    <span
      className="mr-2"
    >
      {icon.colorIcon}
    </span>
  );
});

import { useState } from 'react';
import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import ModalHead from '../Header/modalHead';
import { useSnackbar } from 'notistack';
import tagStore from '../../../store/tags/tag';
import { Controller, useForm } from 'react-hook-form';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontSize: '14px'
    }
  }
};

const options = ['HashTag', 'Custom'];

const AddTagType = ({
  handleClose,
  isEdit,
  row,
  refresh
}) => {
  const { register, handleSubmit, control, formState, getValues } = useForm({
    defaultValues: {
      tagName: isEdit ? row.name : '',
      tagCategory: isEdit ? row.category === 'HashTag' ? 'HashTag' : 'Custom' : '',
      customCategory: isEdit && row.category !== 'HashTag' ? row.category : '',
      tagKeywords: isEdit ? row.keywords : ''
    }
  });
  const { errors } = formState;

  const [tagCategory, setTagCategory] = useState(getValues('tagCategory') ? getValues('tagCategory') : null);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async ({ tagName, tagCategory, customCategory, tagKeywords }) => {
    const category = tagCategory === 'HashTag' ? tagCategory : customCategory;

    const tags = {
      name: tagName,
      category,
      keywords: Array.isArray(tagKeywords) ? tagKeywords : tagKeywords.split(',').map(keyword => keyword.trim())
    };

    try {
      isEdit ? await tagStore.updateTag(row.id, tags) : await tagStore.createTag(tags);
      enqueueSnackbar('Tag added successfully', {
        variant: 'success'
      });
      refresh();
    } catch (error) {
      enqueueSnackbar(error?.response?.data ? error?.response?.data : 'Failed to add tag', {
        variant: 'error'
      });
    } finally {
      handleClose();
    }
  };

  return (
    <div className="flex items-center h-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white mx-auto rounded-lg drop-shadow-lg"
      >
        <ModalHead
          handleClose={handleClose}
          heading={isEdit ? 'Edit Tag Type' : 'Add Tag Type'}
        />
        <div className="px-5 w-[500px]">
          <label className='font-medium' htmlFor="tagName">Tag Name</label>
          <TextField
              className='w-full'
              name='tagName'
              placeholder='Tag Name'
              variant="outlined"
              sx={{
                fontSize: 2,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 }
              }}
              inputProps={{ style: { padding: '10px', fontSize: '14px' } }}
              {...register('tagName', { required: 'true' })}
              error={!!errors.tagName}
              helperText={errors.tagName ? 'Please enter tag name' : ''}
          />
          <label className='mt-4 font-medium' htmlFor="tagCategory">Tag Category</label>
          <Controller
            name='tagCategory'
            control={control}
            rules={{ required: 'Please enter tag category' }}
            render={({ field }) => (
              <FormControl className='w-full' error={!!errors.tagCategory}>
                <Select
                  {...field}
                  labelId='Tag Category'
                  placeholder='Tag Category'
                  inputRef={field.ref}
                  value={field.value}
                  MenuProps={MenuProps}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 }
                  }}
                  SelectDisplayProps={{ style: { padding: 8, fontSize: '14px' } }}
                  onChange={(e) => {
                    setTagCategory(e.target.value);
                    field.onChange(e.target.value);
                  }}
                >
                  {options.map((option, i) => (
                    <MenuItem key={i} value={option}>
                        {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.tagCategory && errors.tagCategory.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {tagCategory === 'Custom' && (
            <>
              <label className='mt-4 font-medium' htmlFor="customCategory">Custom Category</label>
              <TextField
                className='w-full'
                name='customCategory'
                placeholder='Custom Category'
                variant="outlined"
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 }
                }}
                inputProps={{ style: { padding: '10px', fontSize: '14px' } }}
                {...register('customCategory', {
                  required: 'true'
                })}
                error={!!errors.customCategory}
                helperText={errors.customCategory ? 'Please enter custom category' : ''}
              />
            </>
          )}
          <label className='mt-4 font-medium' htmlFor="tagKeywords">Tag Keyword</label>
          <TextField
            className='w-full'
            name='tagKeywords'
            placeholder='Tag Keyword (comma separated)'
            variant="outlined"
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 }
            }}
            inputProps={{ style: { padding: '10px', fontSize: '14px' } }}
            {...register('tagKeywords', {
              required: 'true'
            })}
            error={!!errors.tagKeywords}
            helperText={errors.tagKeywords ? 'Please enter keywords' : ''}
          />
        </div>
        <hr className="my-5 mx-2" />
        <div className="flex justify-end">
          <button
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className="mb-4 mrvn-sv rounded mr-2"
          >
            {!isEdit ? 'Save' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTagType;

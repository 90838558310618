import { useState, useEffect } from 'react';

import { BsPlus } from 'react-icons/bs';
import { Modal } from '@mui/material';

import RecommendedList from './RecommendedList';
import FavouriteList from './FavouriteList';
import Search from './Search';
import AddPhrase from '../../Modal/ToolsandStyling/AddPhrase';

import phraseStore from '../../../store/toolsandstyling/phrase';
import { observer } from 'mobx-react';

const Phrases = ({ docText }) => {
  const {
    state: { userPhrases, orgPhrases, myFavPhrases },
    getMyFavoritePhrases,
    getOrganisationPhrases,
    getUserPhrases
  } = phraseStore;

  // const [searchText, setSearchText] = useState('');
  const [add, setAdd] = useState(false);
  const [params, setParams] = useState({
    searchText: null,
    pageIndex: 1,
    pageSize: 10
  });

  useEffect(() => {
    getMyFavoritePhrases();
  }, []);

  useEffect(() => {
    getMyFavoritePhrases();
  }, [userPhrases]);

  useEffect(() => {
    if (params.searchText && params.searchText.length > 2) {
      getOrganisationPhrases(params);
      getUserPhrases(params);
    }
  }, [params.searchText]);

  const setSearchText = (data) => {
    setParams({ ...params, searchText: data });
  };

  return (
    <div>
      <p className="font-bold text-xl">Frequently Used Phrases</p>
      <Search
        searchText={params.searchText}
        setSearchText={setSearchText}
      />
      {params.searchText && params.searchText?.length > 2 && (
        <div>
          <p className="my-3">Suggestions</p>
          <RecommendedList
            data={[...userPhrases, ...orgPhrases]}
          /> </div>
      )
      }

      {!(params.searchText?.length > 2) && (
        <div>
          <div className="flex justify-between my-3">
            <p>My Favourites</p>
            <button
              onClick={() => setAdd(true)}
              className="flex items-center text-blue"
            >
              <BsPlus /> Add
            </button>
          </div>
          <FavouriteList
            data={myFavPhrases}
          />
        </div>)
      }

      <Modal open={add} onClose={() => setAdd(false)}>
        <>
          <AddPhrase open={add} handleClose={() => setAdd(false)} type="user" fav={true} />
        </>
      </Modal>
    </div>
  );
};

export default observer(Phrases);

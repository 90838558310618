import { Box, Button, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalBox from './Modal/Box';
import AddRestriction from './Modal/Channel/AddRestriction';
import AddUserChannel from './Modal/Channel/AddUserChannel';
import ConfirmationModal from './Modal/Confirm';
import DepartmentNewForm from './Modal/Organisation/DepartmentNewForm';
import FolderNewForm from './Modal/Organisation/FolderNewForm';
import LocationNewForm from './Modal/Organisation/LocationNewForm';
import ProgramNewForm from './Modal/Organisation/ProgramNewForm';
import ProjectNewForm from './Modal/Organisation/ProjectNewForm';
import TeamNewForm from './Modal/Organisation/TeamNewForm';
import AddTagType from './Modal/Tag/AddTagType';
import UploadCsvTag from './Modal/Tag/UploadCsvTag';
import AddPhrase from './Modal/ToolsandStyling/AddPhrase';
import CompanyCreate from './Organisations/companyList/CompanyCreate';
import UserCreate from './Security/UserCreate';
import GroupCreate from './Security/groupList/GroupCreate';
import GroupMembershipCreate from './Security/groupMembershipList/GroupMembershipCreate';
import RoleCreate from './Security/roleList/RoleCreate';
import userStore from '../store/settings/user';
import Loader from '../components/Layout/Loader';
import ModalHead from './Modal/Header/modalHead';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24
};

const AddUserButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const { isMaxSeatsExhausted } = userStore;
  const [seatCheck, setSeatCheck] = useState(null);

  useEffect(() => {
    isMaxSeatsExhausted().then((data) => {
      setSeatCheck(data);
    });
  }, []);

  console.log(seatCheck);

  const handleOpen = () => {
    if (seatCheck) {
      setIsOpenError(true);
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    if (seatCheck) {
      setIsOpenError(false);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <>
      {seatCheck === null
        ? (
          <Loader />
          )
        : (
          <Button
            variant="contained"
            className="bg-blue rounded px-4 py-2 uppercase text-white"
            color="primary"
            onClick={handleOpen}
          >
            Add User
          </Button>
          )}
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <UserCreate open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
      <Modal
        open={isOpenError}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='rounded-lg max-h-screen overflow-x-auto'>
          <ModalHead
            handleClose={handleClose}
            heading='Inconvenience caused is sincerely regretted'
          />
          <div className='p-3'>
            <Typography id="modal-modal-title" variant="h6">
              Sorry
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Your current plan does not allow to add more users.
              <br></br>
              Please reach out to us at
              <span className='text-blue'> support@sharewithmarvin.io </span>
              for change in plan.
            </Typography>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const AddGroupButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Group
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <GroupCreate open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddGroupMembershipButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Group
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <GroupMembershipCreate open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddContentButton = (props) => {
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate('/content/create');
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleAdd}
      >
        Create Content
      </button>
    </>
  );
};

const PublishContentButton = ({ selected, setSelected, onConfirm }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Publish
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <ConfirmationModal
            selected={selected}
            setSelected={setSelected}
            setIsOpen={setIsOpen}
            open={isOpen}
            handleCancel={handleClose}
            onConfirm={onConfirm}
            message={[
              'Do you want to proceed ahead with Publishing of the selected articles?',
              `${selected.length} items will be processed for Publishing`
            ]}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddRoleButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Role
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <RoleCreate open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddLocationButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Location
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <LocationNewForm open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddDepartmentButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Department
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <DepartmentNewForm open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddTeamButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Team
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <TeamNewForm open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddProgramButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Programme
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <ProgramNewForm open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

export const AddProjectButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Project
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <ProjectNewForm open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

export const AddFolderButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Add Folder
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <FolderNewForm open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddCompanyButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <span
        className={`bg-blue rounded px-4 py-2 uppercase text-white relative cursor-pointer ${toggle ? 'rounded-b-none' : ''
          } `}
        color="primary"
        onClick={() => setToggle(!toggle)}
      >
        Add Company
        {toggle && (
          <div className="absolute w-full left-0 top-9 text-[#000] z-10 border-2 border-blue border-t-0 rounded-b">
            <button
              className="w-full bg-white hover:bg-white p-2"
              onClick={handleOpen}
            >
              Federated
            </button>
            <button
              className="w-full bg-[#eee] hover:bg-[#eee] p-2"
              onClick={handleOpen}
            >
              Licensed
            </button>
          </div>
        )}
      </span>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <CompanyCreate open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddTagButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        ADD TAG
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <AddTagType
            refresh={props.refresh}
            open={isOpen}
            handleClose={handleClose}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddUserChannelButton = ({ loadInstances }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (loadInstances) {
      loadInstances();
      return;
    }

    navigate(-1);
  };

  useEffect(() => {
    if (loadInstances) setIsOpen(false);
  }, []);

  return (
    <>
      {loadInstances && (
        <button
          variant="contained"
          className="bg-blue rounded px-4 py-2 uppercase text-white"
          color="primary"
          onClick={handleOpen}
        >
          ADD CHANNEL
        </button>
      )}
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <AddUserChannel open={isOpen} handleClose={handleClose} />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddRestrictionButton = ({ refresh }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        ADD RESTRICTION
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <AddRestriction
            refresh={refresh}
            open={isOpen}
            handleClose={handleClose}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddPhraseButton = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        ADD PHRASE
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <AddPhrase
            open={isOpen}
            handleClose={handleClose}
            type={type}
            fav={false}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

const UploadCsvTagButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-2 py-2 uppercase text-white"
        color="primary"
        onClick={handleOpen}
      >
        Upload
      </button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox>
          <UploadCsvTag
            refresh={props.refresh}
            open={isOpen}
            handleClose={handleClose}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

export {
  AddCompanyButton, AddContentButton, AddDepartmentButton, AddGroupButton, AddGroupMembershipButton, AddLocationButton, AddPhraseButton, AddProgramButton, AddRestrictionButton, AddRoleButton, AddTagButton, AddTeamButton, AddUserButton, AddUserChannelButton, PublishContentButton,
  UploadCsvTagButton
};

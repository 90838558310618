import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  Container
} from 'reactstrap';
import { useEffect, useState } from 'react';
import notificationStore from '../../../store/settings/notification';
import { Avatar, Badge, SwipeableDrawer, Tooltip } from '@mui/material';
import Quickaccess from '../quickaccess';
import { MdOutlineNotifications } from 'react-icons/md';
import Notification from '../quickaccess/Notification/Notification';
import { BsFillCalendarWeekFill, BsPencilSquare } from 'react-icons/bs';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import getThemeStyleByEnv from '../../../utils/getThemeStyleByEnv';
const AdminNavbar = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const {
    state: { notificationCount },
    getNotificationUnreadCount
  } = notificationStore;
  const { user } = useAuth0();
  const [count, setCount] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSidebarMenu, setOpenSidebarMenu] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    countNotification();
    setInterval(async () => {
      countNotification();
    }, 60000);
  }, []);

  const countNotification = () => {
    getNotificationUnreadCount().then((c) => setCount(c));
  };

  useEffect(() => {
    setCount(notificationCount);
  }, [notificationCount]);
  const toggleDrawer = () => {
    setOpenSidebar(!openSidebar);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <>
      <Navbar className='d-md-block navbar-top navbar-dark bg-blue p-0 fixed-top md:shadow-none' expand='md' id='navbar-main'
        style={ getThemeStyleByEnv('navbar') }
      >
        <Container fluid>
          <div className='d-none d-lg-inline-block d-md-flex'>
            <div className='d-md-flex'>
              <Nav className='align-items-center d-none d-md-flex m-3 text-white text-xl' navbar onClick={toggleSidebar}>
                <Link
                  className='md:block'
                  to='/'
                >
                  <img
                    className='h-12 scale-110'
                    src='/static/icons/Marvinlogobig.svg'
                    alt='logo'
                  />
                </Link>
              </Nav>
              <Nav className='align-items-center d-none d-md-flex ml-10 text-white text-xl' navbar onClick={toggleSidebar}>
                <i className={'fas fa-sliders-h'}></i>
              </Nav>
              <Nav className='align-items-center d-none d-md-flex ml-10 text-white text-xl' navbar onClick={toggleFullScreen}>
                <Tooltip title='Full Screen' arrow placement='left'>
                  {isFullScreen ? <i className='fas fa-compress'></i> : <i className='fas fa-expand'></i>}
                </Tooltip>
              </Nav>
            </div>
          </div>
          <div className='d-md-flex'>
          </div>
          <div className='d-md-flex'>
            <Nav className='align-items-center d-none d-md-flex mr-5' navbar>
              <Tooltip title='Add Connection' arrow placement='left'>
                <Link
                  to='/channels/manage-channels/new'
                  state={{
                    backgroundLocation: null
                  }}
                >
                  <AiOutlineAppstoreAdd className='text-white h-10' fontSize={'30px'} />
                </Link>
              </Tooltip>
            </Nav>
            <Nav className='align-items-center d-none d-md-flex mr-5' navbar>
              <Tooltip title='Create Content' arrow placement='left'>
                <Link
                  to='/content/create'
                  state={{
                    backgroundLocation: null
                  }}
                >
                  <BsPencilSquare className='text-white h-10' fontSize={'30px'} />
                </Link>
              </Tooltip>
            </Nav>
            <Nav className='align-items-center d-none d-md-flex mr-5' navbar>
              <Tooltip title='Post Schedule' arrow placement='left'>
                <Link
                  to='/calendar'
                  state={{
                    backgroundLocation: null
                  }}
                >
                  <BsFillCalendarWeekFill className='text-white h-10' fontSize={'30px'} />
                </Link>
              </Tooltip>
            </Nav>
            <Nav className='align-items-center d-none d-md-flex mr-5' navbar>
              <Badge badgeContent={count} color='error' onClick={handleClick}>
                <MdOutlineNotifications className='text-white h-10' fontSize={'30px'} />
              </Badge>
            </Nav>
            <Nav className='align-items-center d-none d-md-flex mr-10' navbar>
              <Tooltip title={user.given_name} arrow placement='bottom'>
                <Avatar
                  onClick={() => {
                    if (openSidebar && openSidebarMenu === 'user-profile') {
                      setOpenSidebar(false);
                      setOpenSidebarMenu('');
                    } else {
                      setOpenSidebar(true);
                      setOpenSidebarMenu('user-profile');
                    }
                  }}
                  alt='Profile'
                  src={user.picture}
                />
              </Tooltip>
            </Nav>
          </div>
        </Container>
      </Navbar>
      {open && <Notification handleClose={handleClose} anchorEl={anchorEl} />}
      <div>
        {openSidebar && (
          <SwipeableDrawer
            anchor='right'
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            open={openSidebar}
            style={{ width: '300px' }}
          >
            <Quickaccess
              onClose={toggleDrawer}
              openSidebar={openSidebar}
              openSidebarMenu={openSidebarMenu}
            />
          </SwipeableDrawer>
        )}
      </div>
    </>
  );
};

export default AdminNavbar;

import { Box, Grid, FormControl, Divider, Stack } from '@mui/material';

import roleStore from '../../../store/security/role';
import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';

const RoleNewForm = ({ isEdit, currentRole, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addRole, updateRole } = roleStore;
  const [formData, setFormData] = useState({
    id: Math.floor(Math.random() * 900) + 100,
    name: '',
    type: 'user',
    description: ''
  });

  const { name, type, description } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const adding = () => {
    addRole(formData).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Role added successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a role', { variant: 'error' });
      }
    });
  };

  const editing = () => {
    updateRole(formData).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Role updated successfully', { variant: 'success' });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to update a role', { variant: 'error' });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    isEdit ? editing() : adding();
  };

  useEffect(() => {
    if (isEdit) {
      setFormData({
        ...formData,
        name: currentRole.name,
        type: currentRole.type,
        description: currentRole.description,
        id: currentRole.id
      });
    } else {
      setFormData({
        ...formData,
        type: 'user'
      });
    }
  }, []);

  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack spacing={3}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <FormControl fullWidth>
                <label htmlFor="roleName">Role Name</label>
                <input
                  placeholder="Type Here"
                  className="border-[1px] border-gray outline-none p-4 rounded"
                  name="name"
                  value={name}
                  onChange={onChange}
                  required
                />
              </FormControl>
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <FormControl fullWidth>
                <label htmlFor="role">Role Type</label>
                <select
                  name="type"
                  value={type}
                  onChange={onChange}
                  className="border-[1px] border-gray outline-none p-4 rounded">
                  <option disabled value="system">System</option>
                  <option value="user">User</option>
                </select>
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <FormControl fullWidth>
                <label htmlFor="roleDesc">Description</label>
                <textarea
                  placeholder="Type Here"
                  className="resize-none border-[1px] border-gray outline-none p-4 rounded"
                  name="description"
                  value={description}
                  onChange={onChange}
                  required
                />
              </FormControl>
            </Stack>

            <Divider />

            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'flex-end',
                pb: 3
              }}
            >
              <button className="mrvn-cln rounded" onClick={handleClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="mrvn-sv rounded bg-blue"
                style={{
                  color: 'white',
                  padding: '6px 15px'
                }}
              >
                {!isEdit ? 'Add Role' : 'Save Changes'}
              </button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(RoleNewForm);

import { makeObservable, observable, action } from 'mobx';

class Loading {
  isloading = false;

  constructor () {
    makeObservable(this, {
      isloading: observable,
      setLoading: action
    });
  }

  setLoading = (value) => {
    this.isloading = value;
  };
}

const loadingStore = new Loading();

export default loadingStore;

/* eslint-disable quotes */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable spaced-comment */
import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';
import * as buffer from 'buffer';
import * as Y from 'yjs';
import encodeQueryParam from '../../utils/queryParamEncoder';
import channelsStore from '../../store/channel/channel';
import ArticleApi from '../../api/content';
import { WebsocketProvider } from 'y-websocket';

const ENDPOINT = '/contents/articles';
const LIVE_DOC_ENDPOINT = '/documents/live/';
const INSTANCES_ENDPOINT = process.env.REACT_APP_BASE_URL + '/channeltypes' + '/validate/article';

class ContentStore {
  state = {
    contents: [],
    totalSize: 0,
    yDoc: null,
    wsProvider: null,
    validations: {},
    channelValidations: {},
    versions: [],
    version: {},
    docHistory: [],
    fireValidation: false,
    validationFired: false,
    channelMetadataForm: [],
    lastError: null
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getContents: action,
      getFilesByContentId: action,
      getFilesByArticle: action,
      addContent: action,
      updateContent: action,
      deleteContent: action,
      getContentValidation: action,
      setYDoc: action,
      getVersions: action,
      addVersion: action,
      setVersions: action,
      setVersion: action,
      setDefaultVersion: action,
      getDocHistory: action,
      setDocHistory: action,
      setFireValidation: action,
      setValidationFired: action,
      getContentValidationByArticle: action,
      handlePublishEvent: action,
      sendForApproval: action,
      doApprove: action,
      restorePreviousVersion: action,
      getChannelMetadataForm: action,
      resetChannelMetadataForm: action,
      resetArticleContents: action,
      getContentByIdFromStore: action,
      getLastError: action
    });
  }

  setYDoc = (value) => {
    this.state.yDoc = value;
  };

  setWsProvider = (value) => {
    this.state.wsProvider = value;
  };

  getContents = async (page, itemsPerPage, queryParam) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${page}/${itemsPerPage}?${encodeQueryParam(
          queryParam
        )}`
      );
      this.state.totalSize = response.data.totalSize;
      this.state.contents = response.data.content ? response.data.content : [];
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getContentById = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getContentHistoryById = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/history/${id}`);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getFilesByContentId = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BASE_URL}/files/files/by/article/${id}`
      );
      return response.data;
    } catch (error) {
      return error.response;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getFilesByArticle = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BASE_URL}/contents/articles/files/${id}`
      );
      return response.data;
    } catch (error) {
      return error.response;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getContentValidation = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(INSTANCES_ENDPOINT + '/' + id);
      this.state.channelValidations = response.data.validation;
    } catch (error) {
      return error.response.data;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getContentValidationByArticle = async (content) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(`${INSTANCES_ENDPOINT}`, content);
      channelsStore.setAllChannels(response.data);
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getContentValidationByArticleOnSave = async (content) => {
    try {
      const response = await client.post(`${INSTANCES_ENDPOINT}`, content);
      return response.data;
    } catch (error) {
      return [];
    }
  };

  addContent = async (content) => {
    loadingStore.setLoading(true);

    try {
      //save our document to the content repo
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, content);
      // As this is the first time and we have just created the document we need to populate the
      // live doc store which will manage the YJS doc on the Websocket when we go to edit

      // Make sure our Y.Doc off the Quill editor is populated with the ID.
      // Essential for correct binding after websocket retrieval!
      this.state.yDoc.share.set(
        response.data.id,
        this.state.yDoc.share.get('null')
      );
      this.state.yDoc.share.delete('null');
      this.state.yDoc.name = response.data.id;

      //encode the current YDoc binary state as an update in base64 format
      const document = buffer.Buffer.from(
        Y.encodeStateAsUpdate(this.state.yDoc)
      ).toString('base64');
      //post our newly saved doc to the live doc binary store.
      await client.post(
        `${process.env.REACT_APP_BASE_URL}${LIVE_DOC_ENDPOINT}` + response.data.id,
        document,
        {
          headers: {
            'Content-Type': 'text/plain'
          }
        }
      );

      this.state.contents.push(response.data);
      this.state.versions = [response.data];
      this.state.version = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  restorePreviousVersion = async (articleId, historyId) => {
    loadingStore.setLoading(true);

    try {
      //save our document to the content repo
      const response = await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/restore/${articleId}/${historyId}`
      );

      await this.updateEditorContent(response.data.body, response.data.id);

      return response.data;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateEditorContent = async (data, articleId) => {
    const wsProvider = new WebsocketProvider(process.env.REACT_APP_DOC_COLLAB_SERVER, articleId, this.state.yDoc);
    if (wsProvider) {
      wsProvider.on('status', async event => {
        if (event.status === 'connected') {
          const ytext = this.state.yDoc.getText(articleId);
          ytext.delete(0, ytext.length);
          ytext.applyDelta(JSON.parse(data).ops);

          const document = buffer.Buffer.from(
            Y.encodeStateAsUpdate(this.state.yDoc)
          ).toString('base64');

          await client.post(`${process.env.REACT_APP_BASE_URL}${LIVE_DOC_ENDPOINT}` + articleId, document, {
            headers: {
              'Content-Type': 'text/plain'
            }
          });
          wsProvider.disconnect();
        }
      });
    }
  };

  updateContent = async (data) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}`,
        data
      );

      this.state.contents = this.state.contents.map((x) =>
        x.id === data.id ? response.data : x
      );

      this.state.versions = this.state.versions.map((x) =>
        x.id === data.id ? response.data : x
      );

      this.state.version = response.data;

      return response.data;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteContent = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);

      let version = this.state.versions.find((x) => !x.parentId);

      this.state.contents = this.state.contents.filter(content => content.id !== id);
      this.state.contents = this.removeChildArticle(id, this.state.contents);

      this.state.version = version ? version : {};
      this.state.versions = this.state.versions.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  removeChildArticle = (parentId, contents) => {
    const childArticle = contents.find(content => content.parentId === parentId);
    if (childArticle) {
      contents = contents.filter(content => content.parentId !== parentId);
      return this.removeChildArticle(childArticle.id, contents);
    } else {
      return contents;
    }
  };

  // eslint-disable-next-line no-dupe-class-members
  getContentByIdFromStore = (id) => {
    this.state.version = this.state.contents.find((content) => content.id === id);
    return this.state.contents.find((content) => content.id === id);
  };

  getVersions = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BASE_URL}/contents/articles/${id}/children`
      );

      this.state.contents = response.data;
      this.state.versions = response.data;

      this.state.version = response.data.find((x) => x.id === id)
        ? response.data.find((x) => x.id === id)
        : {};

      return response.data;
    } catch (error) {
      return error.response;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getDocHistory = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BASE_URL}/contents/articles/history/parent/${id}`
      );

      this.state.docHistory =
        response.data.length > 0
          ? [...response.data].sort((a, b) => b.version - a.version)
          : [];
    } catch (error) {
      return error.response;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addVersion = async (data, id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.put(
        process.env.REACT_APP_BASE_URL + '/contents/articles/' + id + '/fork',
        data
      );
      this.state.versions = [...this.state.versions, response.data];
      this.state.contents = [...this.state.contents, response.data];

      return response.data;
    } catch (error) {
      console.error(error);
      this.state.lastError = error;
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  resetArticleContents = () => {
    this.state.contents = [];
  };

  setDefaultVersion = (channels) => {
    const defaultVersion = {
      channelId: channels,
      selected: true,
      default_version: true,
      version: 1,
      id: '2',
      title: 'Current Doc'
    };
    this.state.version = defaultVersion;
    this.state.versions = [defaultVersion];
  };

  setVersions = (data) => {
    this.state.versions = data;
  };

  setDocHistory = (data) => {
    this.state.docHistory = data;
  };

  setVersion = (data) => {
    this.state.version = data;
  };

  setFireValidation = (value) => {
    this.state.fireValidation = value;
  };

  setValidationFired = (value) => {
    this.state.validationFired = value;
  };

  handlePublishEvent = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = ArticleApi.postPublish({
        rootArticleId: null,
        articleId: id
      }).then((response) => {
        return response;
      });

      return response.data;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  sendForApproval = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await ArticleApi.requestAuthorise(id).then(
        (response) => {
          return response;
        }
      );

      return response;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  doApprove = async (params) => {
    loadingStore.setLoading(true);
    try {
      const response = await ArticleApi.authorise(params).then((response) => {
        return response;
      });

      return response;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  generatePublicURL = async (fileRequest, file, user) => {
    return new Promise((resolve, reject) => {
      client
        .post(`${process.env.REACT_APP_BASE_URL}/files/files/pre-signed-url`, fileRequest)
        .then((response) => {
          let { data } = response;
          if (data && data.fileId && data.preSignedUrl) {
            client
              .put(data.preSignedUrl, file, {
                headers: {
                  Authorization: null,
                  'Content-Type': file.type,
                  'x-amz-meta-author': user.sub
                }
              })
              .then((res) => {
                const url = `${process.env.REACT_APP_BASE_URL}/files/pub/file/` + data.fileKey;
                resolve({ url, id: data.fileId, filename: data.filename, contentType: data.contentType });
              })
              .catch((error) => {
                reject(error); // Reject the Promise if there is an error during the upload
              });
          } else {
            reject(new Error("Unable to get pre-signed URL"));
          }
        })
        .catch((error) => {
          reject(error); // Reject the Promise if there is an error getting the pre-signed URL
        });
    });
  };

  getChannelMetadataForm = async (ids) => {
    loadingStore.setLoading(true);
    try {
      const responseData = await client.get(`${process.env.REACT_APP_BASE_URL}/channeltypes/metadata/checks/` + ids).then((res) => {
        this.state.channelMetadataForm = res.data;
        return res.data;
      });
      return responseData;
    } catch (error) {
      return null;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  resetChannelMetadataForm = () => (
    this.state.channelMetadataForm = []
  );

  getLastError = () => {
    return this.state.lastError;
  };
}

// eslint-disable-next-line no-unused-vars
class Document extends Y.Doc {
  constructor (name, yDoc) {
    super();
    this.name = name;
    Y.applyUpdate(this, Y.encodeStateAsUpdate(yDoc));
  }
}

const contentStore = new ContentStore();
export default contentStore;

import canI from '../../../utils/canI';
import Approve from '../../Modal/Content/Approve';
import { Modal } from '@mui/material';
import ModalBox from '../../Modal/Box';
import { useState } from 'react';

const ApproveButton = ({
  doApproval,
  status,
  doApproveEvent,
  isMyArticle
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = (params) => {
    doApproveEvent(params);
    handleClose();
  };

  return (
        <>
            { canI('authorised:articles') && !isMyArticle && status === 'AUTHORISATION PENDING' && (
                <>
                    <button
                        onClick={handleOpen}
                        className={'bg-blue rounded px-4 py-2 uppercase text-white'}
                    >
                        Approve
                    </button>
                    <Modal open={isOpen} onClose={handleClose}>
                        <ModalBox>
                            <Approve onSubmit={onSubmit} handleClose={handleClose} />
                        </ModalBox>
                    </Modal>
                </>
            )}
        </>
  );
};

export default ApproveButton;

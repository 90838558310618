import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import useSettings from '../../hooks/useSettings';

import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import GroupMembershipMoreMenu from '../../components/Security/groupMembershipList/GroupMembershipMoreMenu';
import GroupUserList from './GroupMemberList';

import { observer } from 'mobx-react';
import groupStore from '../../store/security/group';
import userStore from '../../store/security/user';

import { BulkDeleteButton } from '../../components/DeleteButton';

// ----------------------------------------------------------------------

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.owner_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const GroupMembership = () => {
  const {
    state: { groups },
    getGroups,
    deleteGroup
  } = groupStore;

  const rowsPerPage = 10;
  const itemsPerPage = 10;

  const { themeStretch } = useSettings();
  //   const dispatch = useDispatch();
  //   const { groupList } = useSelector((state) => state.group);
  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const filteredUsers = applySortFilter(
    groups,
    getComparator(order, orderBy),
    filterName
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Group Name',
      isSorted: orderBy === 'name'
    },
    {
      id: 'owner_name',
      label: 'Owner',
      isSorted: orderBy === 'owner_name'
    },
    {
      id: 'last_modified',
      label: 'Last Modified',
      isSorted: orderBy === 'last_modified'
    },
    {
      id: 'member_count',
      label: 'Members',
      isSorted: orderBy === 'member_count'
    },
    { id: 'moreMenu' }
  ];

  useEffect(() => {
    getGroups();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = groups.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Group Membership"
          actions={[
            <BulkDeleteButton
              onDelete={deleteGroup}
              selected={selected}
              setSelected={setSelected}
              heading={['Group membership', 'Group memberships']}
            />,
            <button className="border-[1px] border-blue text-blue rounded px-4 py-2">
              Export CSV
            </button>
          ]}
        />

        <Card style={{ boxShadow: 'none' }}>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={groups.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {visibleItems
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    const isItemSelected = selected.indexOf(row.id) !== -1;
                    return (
                      <Row
                        row={row}
                        key={key}
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                        setSelectedGroup={setSelectedGroup}
                      />
                    );
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{ py: 3 }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <Stack spacing={2} className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
              />
            </Stack>
          </TableContainer>
        </Card>
      </Container>

      {selectedGroup && (
        <GroupUserList
          group={selectedGroup}
          close={() => setSelectedGroup(null)}
        />
      )}
    </>
  );
};

export default observer(GroupMembership);

const Row = ({ row, setSelectedGroup, isItemSelected, handleClick }) => {
  const { getUser } = userStore;
  const [owner, setOwner] = useState({});

  useEffect(() => {
    getUser(row.owner_user_id).then((res) => setOwner(res));
  }, [row]);

  return (
    <TableRow
      hover
      key={row.id}
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, row.id)}
        />
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">
        <Stack direction="row" alignItems="center" spacing={2}>
          {owner.givenName} {owner.familyName}
        </Stack>
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell component="th" scope="row" padding="none">
        {/* {member_count} */}-
      </TableCell>

      <TableCell align="right">
        <GroupMembershipMoreMenu
          // onDelete={() => handleDeleteUser(id)}
          row={row}
          selectGroup={() =>
            setSelectedGroup({
              ...row,
              owner_name: owner.givenName + ' ' + owner.familyName
            })
          }
        />
      </TableCell>
    </TableRow>
  );
};

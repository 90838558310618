import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import channelsStore from '../../../store/channel/channel';
import { icons } from '../../../constants/channels';
import {
  Grid,
  Card,
  CardHeader
} from '@mui/material';
import { BsInfoCircle } from 'react-icons/bs';

const ListChannels = ({
  setStep,
  handleClose,
  channelType,
  setChannelType,
  setMetadataList
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { types },
    fetchChannelTypes
  } = channelsStore;

  const onSubmit = () => {
    if (channelType && channelType !== '') {
      setStep(2);
    } else {
      enqueueSnackbar('Please select channel before proceed', {
        variant: 'error'
      });
    }
  };

  const getChannelIcon = (type) => {
    const icon = icons.filter((icon) => icon.name === type.channelName);
    return icon[0];
  };

  useEffect(() => {
    fetchChannelTypes();
  }, []);

  return (
    <>
      <div className="h-[370px]">
        <Grid container spacing={2}>
          {types.map((type) => (
            <Grid item xs={4} key={type.id}>
              <Card
                variant="filled"
                onClick={() => {
                  setChannelType(type);
                  setMetadataList([]);
                }}
                className={`rounded-md h-16 justify-center items-center content-center cursor-pointer + 
                      ${channelType?.channelName === type.channelName
                    ? 'bg-blue text-white text-sm'
                    : 'bg-gray-light text-black text-sm'
                  }
                  `}
              >
                <CardHeader
                  avatar={
                    channelType?.channelName === type.channelName
                      ? getChannelIcon(type).contrastIcon
                      : getChannelIcon(type).colorIcon
                  }
                  title={
                    type.channelName
                  }
                />
              </Card>
            </Grid>
          ))}
        </Grid>
        {
          (channelType?.channelName === 'Slack' &&
            <div className="rounded-md w-full h-17 mt-5 text-black bg-orange-200 p-2 text-center content-center text-xs">
              <b><BsInfoCircle/></b>
              <span><b>{'Thank you for your support !'}</b></span>
              <br></br>
              <br></br>
              <span>{'We\'re making progress toward getting the Slack App signed off.'}</span>
              <br></br>
              <br></br>
              <span>
                {
                  'Currently, it may appear as unapproved, but there\'s no need to worry.' +
                  'We\'ll notify you as soon as the approved version becomes available.'
                }
              </span>
            </div>
          )
        }
      </div>
      <hr className="my-5" />
      <div className="flex items-center justify-end">
        <div className="flex justify-end">
          <button
            type="button"
            className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            className="mb-4 mrvn-sv rounded"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default observer(ListChannels);

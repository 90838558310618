import { useEffect, useState } from 'react';
import userStore from '../../../store/security/user';
import { motion } from 'framer-motion';

const History = ({
  docHistory,
  distracted,
  showVersionComparison,
  previousVersionForm,
  viewOnly
}) => {
  return (
    <motion.div
      animate={{
        translateY: distracted ? -100 : 0,
        opacity: distracted ? 0 : 1
      }}
      transition={{ delay: distracted ? 0 : 0.4 }}
      className="w-full bg-white rounded-lg p-5"
    >
      <div className="flex justify-between">
        <p className="font-bold">Version History</p>
      </div>
      <hr className="my-3" />

      <div className="flex items-center text-xs my-5">
        <p className="flex-1">
          {docHistory.length} Version{docHistory.length !== 1 && <>s</>}
        </p>
        {docHistory.length > 0 && (
          <>
            <button
              onClick={showVersionComparison}
              className="mx-1 text-blue underline"
            >
              Compare versions
            </button>
            {!viewOnly && (
              <button
                onClick={previousVersionForm}
                className="mx-1 text-blue underline"
              >
                Reinstate old versions
              </button>
            )}
          </>
        )}
      </div>
      <div>
        {docHistory.map((x, i) => (
          <Item key={i} x={x} />
        ))}
      </div>
    </motion.div>
  );
};

export default History;

const Item = ({ x }) => {
  const { getUser } = userStore;

  const [_user, setUser] = useState({});

  useEffect(() => {
    getUser(x.updatedBy).then((res) => setUser(res));
  }, []);

  return (
    <div>
      <div className="flex items-start gap-2">
        <div className="flex-1">
          <p>
            <b>{x.title}</b>
          </p>
          <p className="text-xs mt-1">
            {x.documentText && x.documentText.substring(0, 75)}...
          </p>
        </div>
        <p className="text-xs">
          {x.updatedAt &&
            x.updatedAt.length >= 2 &&
            x.updatedAt[2] + '/' + x.updatedAt[1] + '/' + x.updatedAt[0]}
        </p>
      </div>
      <hr className="my-2 border-light-gray" />
    </div>
  );
};

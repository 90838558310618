import { useAuth0 } from '@auth0/auth0-react';
import { Player } from '@lottiefiles/react-lottie-player';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const Login = ({ switchLogin }) => {
  const { loginWithRedirect } = useAuth0();

  const login = async () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
      responseType: 'token'
    });
  };

  const handleTermsClick = () => {
    window.open('https://sharewithmarvin.io/terms-and-conditions', '_blank');
  };

  const handlePrivacyClick = () => {
    window.open('https://sharewithmarvin.io/privacy-policy', '_blank');
  };

  return (
    <>
      <div className='content-center items-center flex justify-center h-full p-5'>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 5, sm: 5, md: 12 }}>
          <Grid item xs={12}>
              <p className='absolute z-10 text-lgfont-bold left-5 top-5'>
                <img
                  className='h-14 object-contain'
                  src={process.env.PUBLIC_URL + '/static/icons/Marvinlogobig.svg'}
                  alt='logo'
                />
              </p>
          </Grid>
          <Grid item md={2} xs={0}></Grid>
          <Grid item md={4} xs={12}>
              <Player
                src={process.env.PUBLIC_URL + '/static/lottie/login.json'}
                className='player'
                autoplay
                loop
                speed={2}
              />
          </Grid>
          <Grid item md={'auto'} xs={12} className='align-middle items-center justify-center content-center'>
            <Item>
              <div className='flex flex-col px-20 py-10 bg-white rounded-lg flex-grow'>
                <p className='text-center text-4xl font-bold py-5 text-[#2193b0]'>
                  Welcome to Marvin !!
                </p>
                <p className='text-center text'>
                  All your shared content in one place.
                </p>
                <button
                  type='button'
                  onClick={login}
                  className='mt-10 bg-blue hover:bg-blue w-full rounded-full text-white py-2 drop-shadow-sm'
                >
                  Login
                </button>
                <p className='text-center mt-5'>
                  <p>
                    Don&apos;t have an account?{' '}
                    <a
                      style={{
                        color: '#2193B0',
                        textDecoration: 'underline',
                        fontWeight: 'bold'
                      }}
                      href='javascript:void(0)'
                      onClick={switchLogin}
                    >
                      Signup
                    </a>
                  </p>
                </p>
                <p className='text-center mt-10 text-xs'>
                  By continuing, you agree to Marvin&apos;s{' '}
                  <button
                    className='font-bold underline'
                    onClick={handleTermsClick}
                  >
                    Terms of Service
                  </button>{' '}
                  <br></br>
                  and acknowledge you&apos;ve read our{' '}
                  <button
                    className='font-bold underline'
                    onClick={handlePrivacyClick}
                  >
                    Privacy Policy
                  </button>
                </p>
              </div>
            </Item>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;

import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import thirdPartyIntegrationStore from '../../../store/settings/thirdPartyIntegration';
import canI from '../../../utils/canI';

const FigmaIntegration = () => {
  const {
    state: { connections },
    getConnections,
    createConnection
  } = thirdPartyIntegrationStore;

  useEffect(() => {
    getConnections();
  }, []);

  const doConnect = async () => {
    const response = await createConnection({
      name: 'FIGMA'
    });

    if (response && response.redirectUrl) {
      location.href = response.redirectUrl;
    } else {
      getConnections();
    }
  };

  const doDisconnect = async () => {
    if (canI(['create:integration'])) {
      await doConnect();
    }
  };

  return (
    <>
        <Card variant="outlined" sx={{ maxWidth: 360 }}>
            <Box sx={{ p: 2 }}>
                <img
                    src="/static/icons/figma_logo.svg"
                    title="figma"
                />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography gutterBottom variant="h5" component="div">
                    Figma
                </Typography>
                </Stack>
                <Typography color="text.secondary" variant="body2">
                Figma design is for people to create, share, and test designs for websites, mobile apps, and other digital products and experiences.
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                { connections && connections.find((item) => item.name === 'FIGMA' && item.active)
                  ? (<Button onClick={doDisconnect} className='bg-green rounded px-4 py-2 uppercase text-white w-full' variant="contained">On</Button>)
                  : (canI(['create:integration']) && (<Button onClick={doConnect} className='bg-blue rounded px-4 py-2 uppercase text-white w-full' variant="contained">Link Account</Button>))
                }
            </Box>
        </Card>
    </>);
};

export default FigmaIntegration;

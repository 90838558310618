const Menu = ({ selected, setSelected }) => {
  const menu = [
    'Profile',
    'Change Password',
    'Billing',
    'Terms of Service',
    'Privacy'
  ];

  return (
    <div className="flex gap-5 border-b-[1px] border-b-[#eee]">
      {menu.map((x, i) => (
        <Item value={x} key={i} selected={selected} setSelected={setSelected} />
      ))}
    </div>
  );
};

export default Menu;

const Item = ({ value, selected, setSelected }) => {
  return (
    <button
      onClick={() => setSelected(value)}
      className={`${
        selected === value
          ? 'border-b-2 border-b-blue text-blue'
          : 'border-b-2 border-b-[rgba(0,0,0,0)]'
      } pb-5`}
    >
      {value}
    </button>
  );
};

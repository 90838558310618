/* eslint-disable array-callback-return */
/* eslint-disable quotes */
import { makeAutoObservable } from "mobx";
import client from "../../utils/axios";
import loadingStore from "../loading";
import fileStore from "../file";

const CHANNEL_ENDPOINT = process.env.REACT_APP_BASE_URL + "/channeltypes" + "/channels";
const INSTANCE_ENDPOINT = process.env.REACT_APP_BASE_URL + "/channeltypes" + "/instance";
const RULES_ENDPOINT = process.env.REACT_APP_BASE_URL + "/channeltypes" + "/rules";
const RULETYPES_ENDPOINT = process.env.REACT_APP_BASE_URL + "/channeltypes" + "/ruletypes";
const TIKTOK_CREATOR_ENDPOINT = process.env.REACT_APP_BASE_URL + "/channeltypes" + "/information/tiktok";

class ChannelsStore {
  state = {
    userChannels: [],
    socialChannels: [],
    types: [],
    rules: [],
    channelRuleTypes: [],
    allChannels: [],
    allChannelTypes: [],
    originalChannels: []
  };

  constructor () {
    makeAutoObservable(this);
  }

  fetchUserChannels = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(INSTANCE_ENDPOINT);
      this.state.userChannels = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchSocialChannels = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(CHANNEL_ENDPOINT + "/SOCIAL/false");
      this.state.socialChannels = response.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchChannelTypes = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(CHANNEL_ENDPOINT + "/SOCIAL/true");
      this.state.types = response.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchAllChannels = async (companyId) => {
    loadingStore.setLoading(true);
    try {
      const FETCH_URL = INSTANCE_ENDPOINT + "/withChannelRules" + (companyId ? '?companyId=' + companyId : '');
      const response = await client.get(FETCH_URL);
      if (!companyId) {
        this.state.allChannels = response.data ?? [];
      }
      return (response.data ?? []);
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchAllChannelTypes = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        CHANNEL_ENDPOINT
      );
      this.state.allChannelTypes = response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchOriginalChannels = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(
        CHANNEL_ENDPOINT + "/SOCIAL/true"
      );

      const result = [];

      response.data.map((x) => {
        result.push({
          id: x.id,
          name: x.channelName,
          type: x.type
        });
      });

      this.state.allChannels = result;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createChannel = async (channel, file) => {
    loadingStore.setLoading(true);
    try {
      const image = file ? await fileStore.addImage(file, "channel") : null;
      const response = await client.post(INSTANCE_ENDPOINT, {
        ...channel,
        imageId: image
      });
      this.state.userChannels.push(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  enableChannel = async (id, type) => {
    loadingStore.setLoading(true);
    try {
      if (type === "social") {
        const response = await client.post(
          CHANNEL_ENDPOINT + "/" + id + "/enable"
        );
        this.state.socialChannels = this.state.socialChannels.map((c) =>
          c.id === id ? response.data : c
        );
      }

      if (type === "user") {
        await client.patch(
          INSTANCE_ENDPOINT + "/" + id + "/true"
        );

        this.state.userChannels = this.state.userChannels.map((c) =>
          c.id === id ? { ...c, active: true } : c
        );
      }
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateChannel = async (channel, file) => {
    loadingStore.setLoading(true);
    try {
      if (file) {
        const image = await fileStore.addImage(file, "channel");
        channel.imageId = image;
      }
      const response = await client.put(
        INSTANCE_ENDPOINT,
        { ...channel }
      );

      this.state.userChannels = this.state.userChannels.map((x) =>
        x.id === channel.id ? { ...channel } : x
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createChannelRule = async (channel) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(
        RULES_ENDPOINT,
        channel
      );
      this.state.rules.push(response.data);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchRuleTypes = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(RULETYPES_ENDPOINT);
      this.state.channelRuleTypes = response.data ?? [];
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateChannelRule = async (rule) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        RULES_ENDPOINT + "/" + rule.id,
        rule
      );
      const index = this.state.rules.findIndex((c) => c.id === rule.id);
      if (index >= 0) {
        this.state.rules[index] = rule;
      }
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  disableChannel = async (id, type) => {
    loadingStore.setLoading(true);
    try {
      if (type === "social") {
        await client.delete(
          CHANNEL_ENDPOINT + "/" + id
        );
        this.state.socialChannels = this.state.socialChannels.map((c) =>
          c.id === id ? { ...c, active: false } : c
        );
      }

      if (type === "user") {
        await client.patch(
          INSTANCE_ENDPOINT + "/" + id + "/false"
        );
        this.state.userChannels = this.state.userChannels.map((c) =>
          c.id === id ? { ...c, active: false } : c
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteChannel = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.patch(INSTANCE_ENDPOINT + "/" + id);
      this.state.userChannels = this.state.userChannels.filter(
        (c) => c.active
      );
    } catch (error) {
      console.error(error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteRule = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(RULES_ENDPOINT + "/" + id);
      this.state.rules = this.state.rules.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      console.error(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  setAllChannels = (channels) => {
    this.state.allChannels = channels;
  };

  setAllOriginalChannels = (channels) => {
    this.state.originalChannels = channels;
  };

  getChannelInstance = async (id) => {
    try {
      const response = await client.get(
        INSTANCE_ENDPOINT + "/" + id
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  searchInstances = async (params) => {
    try {
      const response = await client.get(
        INSTANCE_ENDPOINT + "/search", {
          params
        });
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  searchRestications = async (params) => {
    try {
      const response = await client.get(
        RULES_ENDPOINT + "/search", {
          params
        });
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getChannelInstanceSecret = async (id) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.get(
        INSTANCE_ENDPOINT + "/secret/" + id
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getTiktokCreatorInfo = async (id) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.get(
        TIKTOK_CREATOR_ENDPOINT + "/" + id
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const channelsStore = new ChannelsStore();
export default channelsStore;

import { useEffect, useState } from 'react';

import { FormControl, Modal } from '@mui/material';
import ModalHead from '../Header/modalHead';
import { useSnackbar } from 'notistack';
import folderStore from '../../../store/organisation/folder';

const FolderNewForm = ({ open, handleClose, isEdit, currentFolder }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addFolder, updateFolder } = folderStore;

  const [name, setName] = useState('');

  const handleAdd = () => {
    addFolder({
      folder: {
        name
      }
    }).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Folder added successfully', {
          variant: 'success'
        });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to add a Folder', { variant: 'error' });
      }

      if (res === 2) {
        enqueueSnackbar('Given folder name already exists, folder name should be unique', { variant: 'error' });
      }
    });
  };

  const handleEdit = () => {
    updateFolder({ ...currentFolder, name }).then((res) => {
      if (res === 1) {
        enqueueSnackbar('Folder updated successfully', {
          variant: 'success'
        });
        handleClose();
      }
      if (res === 0) {
        enqueueSnackbar('Failed to update a Folder', { variant: 'error' });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    isEdit ? handleEdit() : handleAdd();
  };

  useEffect(() => {
    if (isEdit) {
      setName(currentFolder.name);
    }
  }, []);

  return (
    <Modal open={open}>
      <div className="flex items-center h-full drop-shadow-lg">
        <form
          onSubmit={onSubmit}
          className="bg-white w-[500px] mx-auto rounded-lg"
        >
          <ModalHead
            handleClose={handleClose}
            heading={isEdit ? 'Edit Folder' : 'Add Folder'}
          />
          <div className="px-5">
            <FormControl className="mt-2" fullWidth>
              <label htmlFor="folder">Folder</label>
              <input
                className="border-[1px] border-gray outline-none p-4 rounded"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <hr className="my-5" />

            <div className="flex justify-end">
              <button
                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              <button
                variant="contained"
                color="primary"
                type="submit"
                className="mb-4 mrvn-sv rounded"
              >
                {!isEdit ? 'Add Folder' : 'Save Changes'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FolderNewForm;

import {
  Avatar,
  Card,
  Container,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import TableSkelton from '../../components/Table/Skeleton';
import directoryStore from '../../store/directoryHandler/directoryHandler';
import canI from '../../utils/canI';
import ContactMoreMenu from './ContactMoreMenu';
import channelsStore from '../../store/channel/channel';
import NoContent from '../../components/NoContent';

const ManageContact = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [directory, setDirectory] = useState([]);
  const [searchFilter, setSearchFilter] = useState({
    searchText: '',
    sortDirection: 'desc',
    pageIndex: 1,
    pageSize: 8
  });

  const fetchContactDirectory = async () => {
    try {
      setPageLoading(true);
      const { content, totalElements } =
        await directoryStore.searchContactDirectory(searchFilter);
      setDirectory(content || []);
      setTotalPages(Math.ceil(parseInt(totalElements) / searchFilter.pageSize));
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      enqueueSnackbar('Error fetching contact directory', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchContactDirectory();
  }, [searchFilter]);

  const deleteContactDetail = async (id) => {
    try {
      await directoryStore.deleteContactDetail(id);
      enqueueSnackbar('Contact Detail deleted successfully', {
        variant: 'success'
      });
      await fetchContactDirectory();
    } catch (error) {
      enqueueSnackbar('Failed to delete contact detail', { variant: 'error' });
    }
  };

  const handlePageChange = (data) => {
    setCurrentPage(data);
    setSearchFilter({ ...searchFilter, pageIndex: data });
  };

  const tableHeader = [
    {
      id: 'userId',
      label: 'User ID'
    },
    {
      id: 'firstName',
      label: 'First Name'
    },
    {
      id: 'familyName',
      label: 'Family/Business Name'
    },
    {
      id: 'socialChannelCount',
      label: 'Social Channels'
    },
    {
      id: 'moreOptions'
    }
  ];

  return (
    <>
      <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Manage Contacts"
        actions={[canI(['create:directory']) && <AddContactDetailButton />]}
      />
      <Card>
        <div className="flex items-center">
          <div style={{ width: '60%' }}>
            <ListToolbar
              placeholder={'Search by Contact first/family/company name'}
              filterName={searchFilter.searchText}
              onFilterName={(e) =>
                setSearchFilter({
                  ...searchFilter,
                  searchText: e.target.value,
                  pageIndex: 1
                })
              }
            />
          </div>
        </div>
        {directory.length > 0 && (
          <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
            <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeader.map((header) => (
                  <TableCell key={header.id}>{header.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageLoading && (
                <TableSkelton
                  rows={searchFilter.pageSize}
                  columns={6}
                ></TableSkelton>
              )}
              {!pageLoading &&
                directory.map((row, index) => {
                  return (
                    <Row
                      contactDetail={row}
                      key={index}
                      deleteContactDetail={deleteContactDetail}
                    />
                  );
                })}
            </TableBody>
            </Table>
            <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => handlePageChange(value)}
              color="primary"
            />
            </Stack>
          </TableContainer>
        )}
      </Card>
      </Container>
      {!pageLoading && directory.length < 1 && NoContent('No Contacts Found')}

    </>
  );
};

export default observer(ManageContact);

const Row = observer(
  ({
    contactDetail: {
      id,
      userId,
      firstName,
      familyName,
      companyName,
      channelHandlerCount,
      contactPictureUrl
    },
    contactDetail,
    deleteContactDetail
  }) => {
    return (
      <TableRow hover tabIndex={-1}>
        <TableCell align="left" className='flex align-items-center'>
          <Avatar src={contactPictureUrl} className='mr-2'/>
          {userId}
        </TableCell>
        <TableCell align="left">
          {firstName}
        </TableCell>
        <TableCell align="left">
          {firstName ? familyName : companyName}
        </TableCell>
        <TableCell align="left">{channelHandlerCount}</TableCell>
        <TableCell align="right">
          <ContactMoreMenu
            onDelete={() => deleteContactDetail(id)}
            contactDetail={{ ...contactDetail }}
          />
        </TableCell>
      </TableRow>
    );
  }
);

const AddContactDetailButton = () => {
  const navigate = useNavigate();
  const { fetchAllChannelTypes } = channelsStore;

  const handleAdd = async () => {
    await fetchAllChannelTypes();
    navigate('/toolsandstyling/manage-contact/new');
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleAdd}
      >
        Create Contact
      </button>
    </>
  );
};

import { events } from '../../../../constants/notificationevent';
import { Link } from 'react-router-dom';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import Moment from 'react-moment';

export default function NotificationItem ({ item, markAsRead }) {
  return (
    <li key={item.id} className='flex grow justify-between rounded-lg'>
      <Link
          to={'/' + item.appUrl}
          style={{ textDecoration: 'none' }}
          onClick={() => markAsRead(item)}
      >
        <div className={'flex items-center min-w-0 gap-x-1 ml-[-14px]'} style={{ maxWidth: '500px', cursor: 'pointer' }}>
          <span>
            {events.find((c) => c.name === item.event)
              ? (
                  events.find((c) => c.name === item.event)?.icon
                )
              : (
                  events.find((c) => c.name === 'INSTANCE_CONNECTED')?.icon
                )
            }
          </span>
          <div className="min-w-0">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              {item.body ? (item.body) : ('N/A')}
            </p>
            {item.title && <p className='font-semibold'>{item.title}</p>}
          </div>
        </div>
      </Link>
      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end p-2">
          {item.isRead
            ? <p className="text-sm leading-6 text-gray-900">
                  <MarkChatReadIcon style={{ color: 'gray' }} onClick={(event) => {
                    event.stopPropagation();
                  }} />
              </p>
            : <p className="text-sm leading-6 text-gray-900">
                  <MarkChatUnreadIcon className='text-blue' onClick={(event) => {
                    event.stopPropagation();
                    markAsRead(item);
                  }} />
              </p>
          }
          <Moment format="MMM, DD hh:mm A" className="text-sm">{item.createdOn}</Moment>
      </div>
    </li>
  );
}

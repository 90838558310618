import client from '../../utils/axios';
import { makeAutoObservable } from 'mobx';
import loadingStore from '../loading';

const ENDPOINT = '/register';

class UsersStore {
  state = {
    subscription: [],
    license: {},
    customerPortal: {}
  };

  constructor () {
    makeAutoObservable(this);
  }

  fetchSubscription = async () => {
    loadingStore.setLoading(true);
    try {
      const subscription = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user/fetch-subscription`);
      this.state.subscription = subscription.data;
      return subscription.data;
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  isMaxSeatsExhausted = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user/seat-check`);
      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchCustomerPortal = async () => {
    loadingStore.setLoading(true);
    try {
      const customerPortal = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user/fetch-customer-portal`);
      this.state.customerPortal = customerPortal.data;
      return customerPortal.data;
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchLicense = async () => {
    loadingStore.setLoading(true);
    try {
      const license = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user/license`);
      this.state.license = license.data;
      return license.data;
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createUserSubscription = async (formData) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/user/subscribe`, formData);
      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const usersStore = new UsersStore();
export default usersStore;

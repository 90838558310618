import { useState, useEffect } from 'react';

import { BsCamera, BsTrash } from 'react-icons/bs';

import organisation from '../../../api/organisation';
import loadingStore from '../../../store/loading';
import Moment from 'react-moment';
import ImageUpload from '../../ImageUpload';
import fileStore from '../../../store/file';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

const ProfileForm = () => {
  const [imagePreview, setImagePreview] = useState(null);

  const [user, setUser] = useState({
    givenName: '',
    familyName: '',
    emailAddress: ''
  });

  const [showUploadImage, setShowUploadImage] = useState(false);
  const { fetchImageByID } = fileStore;

  const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [error, setError] = useState({
    givenName: null,
    familyName: null,
    emailAddress: null
  });

  const { setLoading } = loadingStore;
  const { enqueueSnackbar } = useSnackbar();

  const testError = () => {
    if (user.givenName.length === 0) {
      setError({
        ...error,
        givenName:
          'Please enter given name'
      });
      return false;
    }

    if (user.familyName.length === 0) {
      setError({
        ...error,
        familyName:
          'Please enter family name'
      });
      return false;
    }

    if (!EMAIL_REGEX.test(user.emailAddress)) {
      setError({
        ...error,
        emailAddress:
          'Please enter correct email'
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (testError()) {
      setLoading(true);
      organisation.updateMe(user).then((_res) => {
        setLoading(false);
        enqueueSnackbar('Profile setting has been updated.', { variant: 'success' });
      }).catch((_error) => {
        setLoading(false);
        enqueueSnackbar('Oops, something went wrong, please contact administrator.', { variant: 'error' });
      });
    }
  };

  const handleRemoveImage = (e) => {
    setImagePreview(null);
    setUser({ ...user, imageId: null });
  };

  useEffect(() => {
    setLoading(true);
    organisation.me().then((response) => {
      setUser(response);
      setImagePreview(response.picture);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (user && user.imageId) {
      fetchImageByID(user.imageId).then((res) => {
        res !== 0 ? setImagePreview(res) : setImagePreview(null);
      });
    }
  }, [user]);

  const handleImageUpload = async (file) => {
    const image = file ? await fileStore.addImage(file, 'user') : null;
    setUser({ ...user, imageId: image });
    setShowUploadImage(false);
  };

  return (
    <form
      onFocus={() =>
        setError({
          givenName: null,
          familyName: null,
          emailAddress: null
        })
      }
    >
      <div className="grid grid-cols-5 gap-5">
        <div className="flex items-center justify-center">
          <div
            className={`border-2 border-blue relative ${
              imagePreview ? 'bg-white' : 'bg-light-blue'
            } text-blue text-lg font-bold w-32 h-32 rounded-full flex items-center justify-center`}
          >
            {!imagePreview && 'Add Image'}
            {imagePreview && (
              <img
                className="absolute rounded-full object-cover h-full w-full"
                src={imagePreview}
                alt="channel"
              />
            )}
            {imagePreview && user.imageId && (
              <button
                type="button"
                onClick={handleRemoveImage}
                className="cursor-pointer text-white bg-red p-2 rounded-full absolute bottom-20 right-[-10px]"
              >
                <BsTrash />
              </button>
            )}
            <label className="cursor-pointer text-white bg-blue p-2 rounded-full absolute bottom-[-5px] right-[-5px]">
              <button
                type="button"
                onClick={() => setShowUploadImage(true)}
                className="hidden"
              />
              <BsCamera />
            </label>
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-col mt-5">
            <label>Given Name</label>
            <input value={user ? user.givenName : ''} onChange={(e) => setUser({ ...user, givenName: e.target.value })} className="bg-[#eee] px-4 py-2 rounded" placeholder="" />
            <p className="text-red">{error.givenName}</p>
          </div>
          <div className="flex flex-col mt-5">
            <label>Email</label>
            <input value={user ? user.emailAddress : ''} onChange={(e) => setUser({ ...user, emailAddress: e.target.value })} className="bg-[#eee] px-4 py-2 rounded" placeholder="" />
            <p className="text-red">{error.emailAddress}</p>
          </div>
          <div className="flex flex-col mt-5">
            <label>Last Login</label>
            <Moment format="MMM, DD YYYY hh:mm A"><input value={user ? user.lastLogin : ''} className="bg-[#eee] px-4 py-2 rounded" readOnly placeholder="" /></Moment>
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-col mt-5">
            <label>Family Name</label>
            <input value={user ? user.familyName : ''} onChange={(e) => setUser({ ...user, familyName: e.target.value })} className="bg-[#eee] px-4 py-2 rounded" placeholder="" />
            <p className="text-red">{error.familyName}</p>
          </div>
          <div className="flex flex-col mt-5">
            <label>Company</label>
            <input value={user ? user.companyName : ''} className="bg-[#eee] px-4 py-2 rounded" readOnly placeholder="" />
          </div>
        </div>
      </div>
      {showUploadImage && (
        <ImageUpload
          onClose={() => setShowUploadImage(false)}
          handleSubmit={handleImageUpload}
          handleRemoveImage={handleRemoveImage}
        />
      )}

      <hr className="mt-5" />

      <div className="flex justify-between">
        <Box sx={{ typography: 'subtitle2', fontWeight: 'light', fontSize: 10 }}>
          *Changes to the profile will take effect once you login again
        </Box>
        <button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className="mt-5 mrvn-sv rounded"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default ProfileForm;

import { makeStyles } from '@mui/styles';
import { Grid, Stack } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    textAlign: 'center',
    margin: 'auto',
    width: '100%',
    padding: '10px'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    '& *': {
      margin: 0,
      padding: 0
    }
  },
  background: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  border: {
    border: '2px solid white',
    borderRadius: '6px'
  },
  heightone: {
    width: '100%',
    paddingTop: '100%'
  },
  twitterHeightone: {
    width: '50%',
    paddingTop: '100%'
  },
  heighttwo: {
    width: '50%',
    paddingTop: '50%'
  },
  twitterHeighttwo: {
    height: '50%',
    width: '100%',
    paddingTop: '100%'
  },
  heightthree: {
    width: '33.3333%',
    paddingTop: '33.3333%'
  },
  heightfour: {
    height: '50%',
    width: '50%',
    paddingTop: '80%'
  },
  cover: {
    backgroundColor: '#222',
    opacity: 0.8,
    position: 'absolute',
    right: 0,
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: '6px'
  },
  coverText: {
    right: 0,
    left: 0,
    bottom: 0,
    color: 'white',
    fontSize: '7%',
    position: 'absolute',
    top: '50%',
    WebkitTransform: `translate(${0}%, ${-50}%')`,
    msTransform: `translate(${0}%, ${-50}%')`,
    transform: `translate(${0}%, ${-50}%')`,
    textAlign: 'center'
  },
  text: {
    margin: 0,
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: `translate(${-50}%, ${-50}%')`
  }
}));

function Images ({
  isTwitter,
  images,
  countFrom
}) {
  const classes = useStyles();
  const imageLimit = countFrom > 0 && countFrom < 5 ? countFrom : 5;
  const imagesToShow = images.slice(0, imageLimit);

  function renderOne (images) {
    const overlay = images.length > countFrom && countFrom === 1 && renderCountOverlay(true);
    const size = isTwitter && images.length > 1 ? 6 : 12;

    return (
        <Grid item xs={size} md={size} className={`relative ${classes.border} ${isTwitter ? classes.twitterHeightone : classes.heightone} ${classes.background}`} style={{ backgroundImage: `url(${images[0]})` }}>
          {overlay}
        </Grid>
    );
  };

  function renderTwo (images) {
    const overlay = images.length > countFrom && [2, 3].includes(+countFrom) && renderCountOverlay(true);
    const conditionalRender = [3, 4].includes(images.length) || (images.length > +countFrom && [3, 4].includes(+countFrom));

    return (
      isTwitter && images.length > 2
        ? (
          <Grid item xs={6} md={6}>
            <Stack direction='column' className='w-full'>
              <div className={`w-full ${classes.border} ${classes.twitterHeighttwo} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[1] : images[0]})` }}>
              </div>
              <div className={`w-full ${classes.border} ${classes.twitterHeighttwo} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[2] : images[1]})` }}>
              {overlay}
              </div>
            </Stack>
          </Grid>
          )
        : (
        <>
          <Grid item xs={6} md={6} className={`${classes.border} ${classes.heighttwo} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[1] : images[0]})` }}>
          </Grid>
          <Grid item xs={6} md={6} className={`relative ${classes.border} ${classes.heighttwo} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[2] : images[1]})` }}>
            {overlay}
          </Grid>
        </>
          )
    );
  }

  function renderThree (images) {
    const overlay = (!countFrom || countFrom > 5 || (images.length > countFrom && [4, 5].includes(+countFrom))) && renderCountOverlay(true);
    const conditionalRender = images.length === 4 || (images.length > +countFrom && +countFrom === 4);

    return (
      <>
        <Grid item xs={4} className={`${classes.border} ${classes.heightthree} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[1] : images[2]})` }}>
        </Grid>
        <Grid item xs={4} className={`${classes.border} ${classes.heightthree} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[2] : images[3]})` }}>
        </Grid>
        <Grid item xs={4} className={`relative ${classes.border} ${classes.heightthree} ${classes.background}`} style={{ background: `url(${conditionalRender ? images[3] : images[4]})` }}>
          {overlay}
        </Grid>
      </>
    );
  }

  function renderFour () {
    return (
      <>
        <Grid item xs={6} className={`${classes.border} ${classes.heightfour} ${classes.background}`} style={{ background: `url(${images[0]})` }}>
        </Grid>
        <Grid item xs={6} className={`${classes.border} ${classes.heightfour} ${classes.background}`} style={{ background: `url(${images[1]})` }}>
        </Grid>
        <Grid item xs={6} className={`${classes.border} ${classes.heightfour} ${classes.background}`} style={{ background: `url(${images[2]})` }}>
        </Grid>
        <Grid item xs={6} className={`relative ${classes.border} ${classes.heightfour} ${classes.background}`} style={{ background: `url(${images[3]})` }}>
        </Grid>
      </>
    );
  }

  function renderCountOverlay (more) {
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);
    return [more && <div key="count" className={classes.cover}></div>, more && <div key="count-sub" className={classes.coverText} style={{ fontSize: '200%' }}><p className={classes.text}>+{extra}</p></div>];
  }

  return (
    <div className={classes.gridContainer}>
      <Grid container>
      {!isTwitter && [1, 3, 4].includes(imagesToShow.length) && renderOne(images)}
      {isTwitter && [1, 3].includes(imagesToShow.length) && renderOne(images)}

      {imagesToShow.length >= 2 && imagesToShow.length !== 4 && renderTwo(images)}

      {!isTwitter && imagesToShow.length >= 4 && renderThree(images)}
      {isTwitter && imagesToShow.length === 4 && renderFour(images)}
      </Grid>
    </div>
  );
}

export default Images;

const generateTimeSlots = () => {
  const timeSlots = [];
  const startDate = new Date(); // Use the desired date here
  startDate.setHours(0, 0, 0, 0); // Set the time to midnight

  const endDate = new Date(); // Use the desired end date here
  endDate.setHours(23, 59, 59, 999); // Set the time to almost midnight

  const interval = 60 * 60 * 1000; // 1 hour in milliseconds

  // eslint-disable-next-line no-unmodified-loop-condition
  while (startDate <= endDate) {
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(startDate);

    timeSlots.push(formattedTime);
    startDate.setTime(startDate.getTime() + interval);
  }

  return timeSlots;
};

const timeSlotsArray = generateTimeSlots();

export default timeSlotsArray;

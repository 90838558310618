import { makeAutoObservable } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';

const ENDPOINT = '/integration/api/v1/connection';
const FIGMA_ENDPOINT = '/integration/api/v1/connection/figmaImage';

class ThirdPartyIntegrationStore {
  state = {
    connections: []
  };

  constructor () {
    makeAutoObservable(this);
  }

  getConnections = async () => {
    loadingStore.setLoading(true);
    try {
      const result = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.connections = result ? result.data : [];
      return this.state.connections;
    } catch (error) {
      console.error('Failed to fetch connections :', error);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  createConnection = async (postData) => {
    loadingStore.setLoading(true);
    try {
      const result = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, postData);
      loadingStore.setLoading(false);
      return result ? result.data : [];
    } catch (error) {
      console.error('Failed to fetch connections :', error.response.data);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchImagesFromFigmaLink = async (file, postData) => {
    loadingStore.setLoading(true);
    try {
      const result = await client.get(`${process.env.REACT_APP_BASE_URL}${FIGMA_ENDPOINT}/${file}`, { params: postData });
      loadingStore.setLoading(false);
      return result ? result.data : [];
    } catch (error) {
      console.error('Failed to fetch connections :', error.response.data);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const thirdPartyIntegrationStore = new ThirdPartyIntegrationStore();
export default thirdPartyIntegrationStore;

import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineMore } from 'react-icons/ai';
import canI from '../../utils/canI';
import DeleteConfirmationModal from '../../components/Modal/Delete';
import { useNavigate } from 'react-router-dom';
import channelsStore from '../../store/channel/channel';

export default function ContactMoreMenu ({ onDelete, contactDetail }) {
  const navigate = useNavigate();
  const { fetchAllChannelTypes } = channelsStore;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDeleteClick = () => {
    handleClose();
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleEditClick = async () => {
    handleClose();
    await fetchAllChannelTypes();
    navigate('/toolsandstyling/manage-contact/' + contactDetail.id);
  };

  const handleMenuOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="relative">
        {canI(['create:tags', 'update:tags', 'delete:tags']) && (
          <>
            <Button
              onClick={handleMenuOpenClick}
              aria-controls={open ? 'more-menu-options' : undefined}
              className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
            >
              <AiOutlineMore />
            </Button>
            <Menu
              id="more-menu-options"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {canI(['update:directory']) && (
                <MenuItem
                  onClick={handleEditClick}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillEdit className="text-green mr-2" />
                  Edit
                </MenuItem>
              )}
              {canI(['delete:directory']) && (
                <MenuItem
                  onClick={handleDeleteClick}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-[#eee] w-full hover:text-gray-900"
                >
                  <AiFillDelete className="text-red mr-2" />
                  Delete
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>

      <DeleteConfirmationModal
        open={showDeleteModal}
        onDelete={onDelete}
        del={['Contact Detail']}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
      />
    </>
  );
}

import { FormControl } from '@mui/material';
import { useState } from 'react';
import ModalHead from '../Header/modalHead';

const Approve = ({
  handleClose,
  onSubmit
}) => {
  const [remarks, setRemarks] = useState('');

  const rejectArticle = () => {
    onSubmit({
      authorise: false,
      remarks
    });
  };

  const approveArticle = () => {
    onSubmit({
      authorise: true,
      remarks
    });
  };

  return (
    <div className="flex items-center h-full drop-shadow-lg">
      <form
        onSubmit={onSubmit}
        className="bg-white w-[500px] mx-auto rounded-lg"
      >
        <ModalHead
          handleClose={handleClose}
          heading={'Approve Article'}
        />
        <div className="px-5">
          <FormControl className="mt-2" fullWidth>
            <label htmlFor="remark">Remarks</label>
            <textarea
              id="remark"
              className="border-[1px] border-gray outline-none p-4 rounded"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </FormControl>

          <hr className="my-5" />

          <div className="flex justify-end gap-2">
            <button
              variant="contained"
              color="danger"
              type="submit"
              className="mb-4 p-2 text-white bg-red rounded"
              onClick={rejectArticle}
            >
              {'Reject'}
            </button>
            <button
              variant="contained"
              color="primary"
              type="submit"
              className="mb-4 mrvn-sv rounded"
              onClick={approveArticle}
            >
              {'Approve'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Approve;

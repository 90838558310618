import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { Container, Modal } from '@mui/material';
import FigmaIntegration from '../../components/Settings/ThirdPartyIntegrations/FigmaIntegration';
import OpenAiIntegration from '../../components/Settings/ThirdPartyIntegrations/OpenAiIntegration';
import ModalBox from '../../components/Modal/Box';
import RedirectChannelStatus from '../../components/Modal/Channel/RedirectChannelStatus';
import { useEffect, useState } from 'react';

const connections = [{
  id: 1,
  name: 'Figma'
},
{
  id: 2,
  name: 'OPENAI'
}];

const ThirdPartyIntegrations = () => {
  const [redirectConnectionStatusOpen, setRedirectConnectionStatusOpen] = useState(false);
  const [redirectConnectionStatus, setRedirectConnectionStatus] = useState(false);
  const [redirectConnectionText, setRedirectConnectionText] = useState('');

  useEffect(() => {
    const contextPath = window.location.href;
    if (contextPath.includes('?success=') || contextPath.includes('?failed=')) {
      // handle connection success or failure.
      const message = contextPath.split('?success=').at(1) || contextPath.split('?failed=').at(1);
      setRedirectConnectionStatusOpen(true);
      setRedirectConnectionStatus(contextPath.includes('?success='));
      setRedirectConnectionText(decodeURIComponent(message).replace(/\+|#_=_/g, ' '));
    }
    window.history.pushState({}, document.title, window.location.pathname);
  }, []);

  return (
    <div>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Integration" />
          <div className='grid grid-cols-3 gap-2 pb-6'>
              { connections.map((item, key) => {
                if (item.name === 'Figma') {
                  return <FigmaIntegration key={key}/>;
                } else if (item.name === 'OPENAI') {
                  return <OpenAiIntegration key={key}/>;
                } else {
                  return null;
                }
              })}
          </div>
        <Modal
          open={redirectConnectionStatusOpen}
          onClose={() => setRedirectConnectionStatusOpen(false)}
         >
          <ModalBox>
            <RedirectChannelStatus
              open={redirectConnectionStatusOpen}
              text={redirectConnectionText}
              status={redirectConnectionStatus}
              handleClose={() => setRedirectConnectionStatusOpen(false)}
            />
          </ModalBox>
        </Modal>
      </Container>
    </div>
  );
};

export default ThirdPartyIntegrations;

import {
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Modal,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import { TfiWorld } from 'react-icons/tfi';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import UserDefinedMoreMenu from '../../components/Channel/UserDefinedMoreMenu';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import ModalBox from '../../components/Modal/Box';
import TestConnectionStatus from '../../components/Modal/Channel/TestConnectionStatus';
import NoContent from '../../components/NoContent';
import TableSkelton from '../../components/Table/Skeleton';
import { icons } from '../../constants/channels';
import channelsStore from '../../store/channel/channel';
import canI from '../../utils/canI';

const ManageChannel = () => {
  const {
    state: { types },
    fetchChannelTypes,
    searchInstances
  } = channelsStore;

  const [loading, setLoading] = useState(false);
  const [instances, setInstances] = useState([]);
  const [statusText, setStatusText] = useState('');
  const [status, setStatus] = useState(false);
  const [isTestStatusOpen, setIsTestStatusOpen] = useState(false);

  const [params, setParams] = useState({
    searchText: '',
    channelTypeId: 'All Types',
    active: 'All Types',
    pageSize: 10,
    pageIndex: 1,
    sortDirection: 'desc',
    sortColumn: 'id'
  });

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const loadInstances = (afterDelete) => {
    setLoading(true);
    if (params.active === 'All Types') params.active = null;
    if (params.channelTypeId === 'All Types') params.channelTypeId = '';

    if (afterDelete === true && instances.length === 1 && params.pageIndex > 1) {
      setParams({ ...params, pageIndex: params.pageIndex - 1 });
      return false;
    }

    searchInstances(params).then((response) => {
      setInstances(response.content ?? []);
      setTotalPages(Math.ceil(parseInt(response.totalElements) / params.pageSize));
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchChannelTypes();
  }, []);

  useEffect(() => {
    loadInstances();
  }, [
    params.searchText,
    params.channelTypeId,
    params.active,
    params.pageSize,
    params.pageIndex,
    params.sortDirection,
    params.sortColumn
  ]);

  useEffect(() => {
    setParams({
      ...params,
      pageIndex: currentPage
    });
  }, [currentPage]);

  const handleRequestSort = (event, property) => {
    if (property === 'name') {
      const isAsc =
        params.sortColumn === property && params.sortDirection === 'asc';
      setParams({
        ...params,
        sortColumn: property,
        sortDirection: isAsc === true ? 'desc' : 'asc'
      });
    } else { /* empty */ }
  };

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Channel Name',
      orderBy: params.sortColumn === 'name'
    },
    {
      id: 'channel_type',
      label: 'Channel Type'
    },
    {
      id: 'connection_detail',
      label: 'Connection Detail'
    },
    {
      id: 'connection_status',
      label: 'Connection Status'
    },
    {
      id: 'moreMenu',
      label: 'Actions'
    }
  ];

  return (
    <>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading="Manage Channels"
          actions={[canI(['create:channelinstance']) && <AddChannelButton />]}
        />
        <Card style={{ boxShadow: 'none' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <ListToolbar
                filterName={params.searchText}
                onFilterName={(e) =>
                  setParams({
                    ...params,
                    searchText: e.target.value,
                    pageIndex: 1
                  })
                }
              />
            </Grid>
            <Grid item xs="auto">
              <FormControl sx={{ minWidth: 160 }}>
                <InputLabel className="text-gray" id="channel-type-label">
                  Channel Type
                </InputLabel>
                <Select
                  sx={{
                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.2) '
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.2) '
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.2) '
                      }
                    }
                  }}
                  className="h-12"
                  labelId="channel-type-label"
                  id="channel-type"
                  label="Channel Type"
                  onChange={(e) =>
                    setParams({
                      ...params,
                      channelTypeId: e.target.value,
                      pageIndex: 1
                    })
                  }
                >
                  <MenuItem key={-1} value="All Types">
                    All Types
                  </MenuItem>
                  {types.map((x, i) => (
                    <MenuItem key={i} value={x.id}>
                      {x.channelName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="auto">
              <Box>
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel className="text-gray" id="channel-status">
                    Channel Status
                  </InputLabel>
                  <Select
                    sx={{
                      '&.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.2) '
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.2) '
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.2) '
                        }
                      }
                    }}
                    className="h-12"
                    labelId="channel-status-label"
                    id="channel-status"
                    autoWidth={false}
                    label="Channel Status"
                    onChange={(e) =>
                      setParams({
                        ...params,
                        active: e.target.value,
                        pageIndex: 1
                      })
                    }
                  >
                    <MenuItem value="All Types">All Types</MenuItem>
                    <MenuItem value="true">Connected</MenuItem>
                    <MenuItem value="false">Disconnected</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item xs={3} className="ml-auto">
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ padding: '0 25px' }}
            >
              {canI(['create:channelinstance']) && (
                <AddChannelButton></AddChannelButton>
              )}
            </Box>
          </Grid> */}
          </Grid>
          {instances.length > 0 && (
            <>
              <Grid>
                <TableContainer
                  sx={{ minWidth: 800 }}
                  style={{ padding: '0 25px' }}
                >
                  <Table sx={{ minWidth: 650 }}>
                    <ListHead
                      order={params.sortDirection ?? ''}
                      orderBy={params.sortColumn ?? ''}
                      headLabel={TABLE_HEAD}
                      nocheckbox={true}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {loading && (
                        <TableSkelton
                          rows={params.pageSize}
                          columns={5}
                        ></TableSkelton>
                      )}
                      {!loading &&
                        instances.map((row, index) => {
                          return (
                            <Row
                              item={row}
                              key={index}
                              icon={icons.find(
                                (icon) =>
                                  icon.name === row.channelType.channelName
                              )}
                              loadInstances={loadInstances}
                              setStatusText={setStatusText}
                              setStatus={setStatus}
                              setIsTestStatusOpen={setIsTestStatusOpen}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Stack spacing={2} className="pagination">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  variant="outlined"
                  onChange={(event, value) => setCurrentPage(value)}
                  color="primary"
                />
              </Stack>
            </>
          )}
        </Card>
        <TestConnectionStatusModal
          isTestStatusOpen={isTestStatusOpen}
          handleTestStatusClose={() => setIsTestStatusOpen(false)}
          statusText={statusText}
          status={status}
        />
      </Container>
      {!loading &&
        instances.length < 1 &&
        NoContent('No Social Channels Found')}
    </>
  );
};

export default observer(ManageChannel);

const Row = ({ item, icon, loadInstances, setIsTestStatusOpen, setStatus, setStatusText }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    item.channelImageUrl ? setImage(item.channelImageUrl) : setImage(null);
  }, [item]);
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div className="flex items-center gap-2">
          <div>
            <Avatar
              className="text-white"
              alt={item.name}
              src={image || process.env.REACT_APP_PLACEHOLDER_IMAGE}
            />
          </div>
          <div>{item.name}</div>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <div>
            {icon ? icon.colorIcon : <TfiWorld />}
          </div>
          <div>{item.channelType.channelName}</div>
        </div>
      </TableCell>
      <TableCell>
        Connection Type :{' '}
        {item.channelInstanceConfig !== undefined
          ? item.channelInstanceConfig.connectionTypes.connectionType
          : 'NA'}
      </TableCell>
      <TableCell>
        {item.channelInstanceConfig?.status && (
          <span className="flex items-center">
            <BsCheck2Circle className="w-5 h-5 text-green mr-2" />
            CONNECTED
          </span>
        )}
        {!item.channelInstanceConfig?.status && (
          <span className="flex items-center">
            <BsXCircle className="w-5 h-5 text-red mr-2" />
            DISCONNECTED
          </span>
        )}
      </TableCell>
      <TableCell>
        <UserDefinedMoreMenu
          row={item}
          image={image}
          loadInstances={loadInstances}
          setStatusText={setStatusText}
          setStatus={setStatus}
          setIsTestStatusOpen={setIsTestStatusOpen}
        />
      </TableCell>
    </TableRow>
  );
};

const TestConnectionStatusModal = ({
  isTestStatusOpen,
  handleTestStatusClose,
  statusText,
  status
}) => {
  return (
    <>
      <Modal open={isTestStatusOpen} onClose={handleTestStatusClose}>
        <ModalBox>
          <TestConnectionStatus
            open={isTestStatusOpen}
            handleClose={handleTestStatusClose}
            statusText={statusText}
            status={status}
          />
        </ModalBox>
      </Modal>
    </>
  );
};

const AddChannelButton = () => {
  const navigate = useNavigate();

  const handleAdd = async () => {
    navigate('/channels/manage-channels/new');
  };

  return (
    <>
      <button
        variant="contained"
        className="bg-blue rounded px-4 py-2 uppercase text-white"
        color="primary"
        onClick={handleAdd}
      >
        Add Channel
      </button>
    </>
  );
};

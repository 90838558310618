/* eslint-disable no-unused-vars */
import { useAuth0 } from '@auth0/auth0-react';
import { deltaToMarkdown } from 'marvin-delta-to-markdown';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { createContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ChannelList from '../../components/ContentManagement/ChannelList';
import CreateAlternateVersion from '../../components/ContentManagement/CreateAlternateVersion';
import Editor from '../../components/ContentManagement/Editor';
import Navbar from '../../components/ContentManagement/Navbar';
import RightSideDrawer from '../../components/ContentManagement/RightSideDrawer';
import SchedulePublish from '../../components/ContentManagement/SchedulePublish';
import CompareVersionForm from '../../components/ContentManagement/VersionComparison/CompareVersionForm';
import VersionList from '../../components/ContentManagement/VersionTabsList';
import channelsStore from '../../store/channel/channel';
import contentStore from '../../store/content/content';
import fileStore from '../../store/file';
import loadingStore from '../../store/loading';
import companyStore from '../../store/organisation/company';
import settingsStore from '../../store/settings/settings';
import MetadataQuestions from './MetadataQuestions';
import TiktokCreatorInfo from './TikTokCreatorInfo';
import thirdPartyIntegrationStore from '../../store/settings/thirdPartyIntegration';

export const CompanyChannelListContext = createContext(null);

const ContentManagement = () => {
  const { contentId } = useParams();
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    state: { userSettings, pubSettings }
  } = settingsStore;

  const {
    state: { validations, docHistory, versions, contents, channelMetadataForm },
    addContent,
    updateContent,
    getContentById,
    setVersions,
    setVersion,
    getVersions,
    setDocHistory,
    getDocHistory,
    setDefaultVersion,
    getContentValidationByArticle,
    sendForApproval,
    doApprove,
    getChannelMetadataForm,
    resetChannelMetadataForm,
    getContentValidationByArticleOnSave,
    resetArticleContents
  } = contentStore;

  const {
    setAttachments,
    setInlineAttachment,
    state: { inlineAttachments }
  } = fileStore;

  const { state: { metaCompanies }, fetchUserMetaCompanies } = companyStore;

  const { fetchAllChannels } = channelsStore;

  const [content, setContent] = useState(null);
  const [htmlContent, setHtmlContent] = useState(null);
  const [distracted, setDistracted] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [tags, setTags] = useState([]);
  const [plainText, setPlainText] = useState('');
  const [showAlternate, setShowAlternate] = useState(false);
  const [mentions, setMentions] = useState({});
  const [companyId, setCompanyId] = useState('');
  const [parentId, setParentId] = useState(null);

  const [selectedChannels, setSelectedChannels] = useState([]);
  const [contentParams, setContentParams] = useState({
    locations: [],
    departments: [],
    teams: [],
    programms: [],
    projects: [],
    folders: []
  });
  const [files, setFiles] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [compareVersion, setCompareVersion] = useState(false);
  const [showVersionForm, setShowVersionForm] = useState(false);
  const [pingValidation, setPingValidation] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [openSchedulePublish, setOpenSchedulePublish] = useState(false);
  const [doApproval, setDoApproval] = useState(false);
  const [isMyArticle, setIsMyArticle] = useState(false);
  const [isMetadataRequired, setIsMetadataRequired] = useState(false);
  const [metadataValue, setMetadataValue] = useState([]);
  const [emojis, setEmojis] = useState([]);
  const [characters, setCharacters] = useState(0);
  const [words, setWords] = useState(0);
  const [hashTag, setHashTag] = useState({ tag: '' });
  const [customTag, setCustomTag] = useState([]);
  const [toggleSelectAllChannel, setToggleSelectAllChannel] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openSidebarMenu, setOpenSidebarMenu] = useState('post-preview');
  const [channelGroupIds, setChannelGroupIds] = useState(null);
  const [companyChannelList, setCompanyChannelList] = useState([]);

  const [newFiles, setNewFiles] = useState({
    images: [],
    pdfs: [],
    gifs: [],
    videos: []
  });

  const [articleVersion, setArticleVersion] = useState({
    version: 0,
    revision: '0'
  });

  const [publishNow, setPublishNow] = useState({
    isArticleSaved: false,
    publish: false
  });
  const {
    getValues,
    register,
    handleSubmit,
    control,
    formState,
    trigger,
    reset
  } = useForm();
  const [resetForm, setResetForm] = useState(true);
  const [creatorInfoParams, setCreatorInfoParams] = useState([]);
  const [isEnableOpenAI, setIsEnableOpenAI] = useState(false);
  const { getConnections } = thirdPartyIntegrationStore;

  useEffect(() => {
    fetchUserMetaCompanies().then((res) => {
      if (!contentId) {
        setCompanyId(res[0].id);
      }
    });
    async function loadContents () {
      resetArticleContents();
      resetContentState();
      if (contentId) {
        const contents = await getVersions(contentId);
        getDocHistory(contents[0].id);
      }
    }
    loadContents();
    getConnections().then((response) => {
      const openAiconnection = response.find((item) => item.name === 'OPENAI' && item.active === true);
      if (openAiconnection) {
        setIsEnableOpenAI(true);
      }
    });

    return () => {
      setVersion({});
      setVersions([]);
      resetArticleContents();
    };
  }, []);

  useEffect(() => {
    const distractionSetting = userSettings.find(
      (x) => x.name === 'distraction-free-mode'
    );

    const contentValidationFq = userSettings.find(
      (x) => x.name === 'content-validation-timer-frequency'
    );

    const selectAllChannel = userSettings.find(
      (x) => x.name === 'select-all-channel'
    );

    setDistracted(distractionSetting && distractionSetting.value === 'true');

    if (contentValidationFq && contentValidationFq.value === -1) {
      setPingValidation(true);
    }

    if (selectAllChannel?.value === 'true') {
      setToggleSelectAllChannel(false);
    } else {
      setToggleSelectAllChannel(true);
    }
  }, [userSettings]);

  useEffect(() => {
    const canAuthorise = pubSettings.find(
      (x) => x.name === 'Publication Authorisation'
    );

    setDoApproval(canAuthorise ? canAuthorise.value === 'true' : false);
  }, [pubSettings]);

  useEffect(() => {
    async function loadContent () {
      if (contentId) {
        resetContentState();
        resetArticleContents();
        await getVersions(contentId);
        const content = await getContentById(contentId);
        getDocHistory(content.data.id);
        updateContentArgs(content.data);
        setCreatorInfoParams(content.data.tiktokCreatorInfos ?? []);
      }
    }
    loadContent();
  }, [contentId]);

  useEffect(() => {
    if (companyId) {
      fetchAllChannels(companyId).then((res) => {
        setCompanyChannelList(res);
        if (typeof contentId === 'undefined') {
          setDefaultVersion(res.map(r => r.id));
        }
      });
    }
  }, [companyId]);

  const resetContentState = () => {
    setDocHistory([]);
    setVersions([]);
    setVersion({});
    setSelectedChannels([]);
    setAttachments([]);
    setInlineAttachment([]);
    setFiles([]);
    setDisplayFiles([]);
  };

  const updateContentArgs = (data) => {
    if (data.articleFiles) {
      setFiles(data.articleFiles);
      setDisplayFiles(
        data.articleFiles.map((file) => {
          return {
            source: file,
            options: {
              type: 'local'
            }
          };
        })
      );
    }
    setCustomTag(data.usersTags ?? []);
    setContent(data);
    setTitle(data.title ?? '');
    setPlainText(data.documentText ? data.documentText : '');
    setBody(data.body);
    setWords(data.bodyWordCount);
    setCharacters(data.bodyCharacterCount);
    setContentParams({
      ...contentParams,
      ...data.organisationEntities
    });
    setViewOnly(!data.editable);
    setIsMyArticle(
      data.authorId ===
        user[process.env.REACT_APP_AUTH_USER_META_DATA_KEY].userId
    );
    setArticleVersion({ version: data.version, revision: data.revision });
    setSelectedChannels(data.channelId);
    setCompanyId(data.companyId);
    setParentId(data.parentId);
    setChannelGroupIds(data.channelGroupIds);
    if (resetForm) {
      reset();
    }
    setMetadataValue(data.channelMetadataDto);

    if (data.articleFiles && data.inlineAttachments) {
      setAttachments([...data.articleFiles, ...data.inlineAttachments]);
      setInlineAttachment(data.inlineAttachments);
    } else if (data.articleFiles) {
      setAttachments(data.articleFiles);
    } else if (data.inlineAttachments) {
      setAttachments(data.inlineAttachments);
      setInlineAttachment(data.inlineAttachments);
    }

    data.tags
      ? setTags(
        data.tags.map(
          (x) =>
            (x = {
              name: x
            })
        )
      )
      : setTags([]);
  };

  useEffect(() => {
    if (typeof contentId === 'undefined') {
      setVersion({});
      setVersions([]);

      if (versions.length !== 0) {
        setDefaultVersion(selectedChannels);
      }
    }
    async function checkMetadataRequired () {
      if (selectedChannels.length === 0) {
        resetChannelMetadataForm();
        setIsMetadataRequired(false);
        return;
      }
      const channelId = selectedChannels.map((id) => parseInt(id));
      setIsMetadataRequired(await updateMetadataInput(channelId));
    }
    checkMetadataRequired();
  }, [selectedChannels]);

  const updateMetadataInput = async (channelId) => {
    const metadataInput = await getChannelMetadataForm(channelId);
    if (metadataInput && metadataInput.length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleDistractionMode = async () => {
    setDistracted(!distracted);
  };

  const onSubmit = async () => {
    // HANDLE SAVE DRAFT
    const res = await onValidateAndSave();
    setResetForm(false);
    if (contentId) {
      if (res === 0 || res === -1) {
        setPublishNow((publishNow) => {
          return { ...publishNow, isArticleSaved: false };
        });
      } else {
        enqueueSnackbar('Article updated successfully', {
          variant: 'success'
        });
        setPublishNow((publishNow) => {
          return { ...publishNow, isArticleSaved: true };
        });
        getDocHistory(contentId);
        setArticleVersion({ version: res.version, revision: res.revision });
      }
    } else {
      if (res === 0 || res === -1) {
        setPublishNow((publishNow) => {
          return { ...publishNow, isArticleSaved: false };
        });
      } else {
        enqueueSnackbar('Article added successfully', {
          variant: 'success'
        });
        setPublishNow((publishNow) => {
          return { ...publishNow, isArticleSaved: true };
        });
        navigate('/content/' + res.id);
      }
    }
  };

  const onValidateAndSave = async () => {
    loadingStore.setLoading(true);

    const isArticleValid = validateArticle();
    if (!isArticleValid) {
      loadingStore.setLoading(false);
      return -1;
    }

    let channelId = selectedChannels;
    let isMetadataValueRequired = isMetadataRequired;
    let metadata;
    isMetadataValueRequired = await updateMetadataInput(channelId);
    if (isMetadataValueRequired) {
      // OPEN METADATA SIDEBAR
      const res = await trigger();
      if (!res) {
        enqueueSnackbar('Please update channel metadata', {
          variant: 'error'
        });
        loadingStore.setLoading(false);
        return -1;
      }
      metadata = updateMetadataTypes(getValues());
    }
    const markdownParam = body ? deltaToMarkdown(JSON.parse(body).ops ?? '') : '';
    const channelValidationRes = await getContentValidationByArticleOnSave({
      title,
      documentText: plainText,
      body,
      characterLength: characters,
      markdownLength: Array.from(markdownParam).length,
      tags: tags.map((x) => x.name),
      status: 'DRAFT',
      articleFiles: files,
      channelId: companyChannelList.map(ch => ch.id),
      metaValues: metadata?.flatMap(m => m.metaDataTypes).flatMap(m => m.value || ''),
      mentions: body ? updateMentions(JSON.parse(body).ops) : null,
      alternativeText: {
        MARKDOWN: markdownParam,
        HTML: htmlContent
      }
    });

    const validChannels = channelValidationRes.filter(
      (channel) => channel.validationStatus && selectedChannels.includes(channel.id)
    );

    if (selectedChannels.length !== validChannels.length) {
      enqueueSnackbar(
        'Some of the channels have been unselected due to voilations of rules',
        {
          variant: 'warning'
        }
      );

      channelId = validChannels.map((channel) => channel.id);
      loadingStore.setLoading(true);
      setIsMetadataRequired(isMetadataValueRequired);
    }
    if (validChannels.length === 0) {
      loadingStore.setLoading(false);
      setCompanyChannelList(channelValidationRes);
      enqueueSnackbar('Please select any channel to publish', {
        variant: 'error'
      });
      return -1;
    }

    return (await contentId)
      ? handleUpdate(metadata, channelId).then((res) => {
        loadingStore.setLoading(false);
        setCompanyChannelList(channelValidationRes);
        return res;
      })
      : handleAdd(metadata, channelId).then((res) => {
        loadingStore.setLoading(false);
        setCompanyChannelList(channelValidationRes);
        return res;
      });
  };

  const validateArticle = () => {
    if (!title) {
      enqueueSnackbar('Please add article title', {
        variant: 'error'
      });
      return false;
    }
    return true;
  };

  const updateMetadataTypes = (data) => {
    const currentState = [...channelMetadataForm];

    for (const [key, value] of Object.entries(data)) {
      const [channelId, metadataId, channelType, type] = key.split(',');
      let updatedValue;
      if (type === 'multioptions' && value.length > 0) {
        updatedValue = [...value];
      } else if (type === 'attachments' && value && value.url) {
        updatedValue = [value.url];
      } else if (value && !Array.isArray(value)) {
        updatedValue = [value.toString()];
      } else if (value === false) {
        updatedValue = [value.toString()];
      }
      if (updatedValue) {
        const channelIndex = currentState.findIndex(
          (channel) =>
            channel.channelInstanceId === parseInt(channelId) ||
            channel.channelTypeId === parseInt(channelId)
        );
        if (channelIndex !== -1) {
          const metadataIndex = currentState[
            channelIndex
          ].metaDataTypes.findIndex((meta) => meta.id === parseInt(metadataId));
          if (metadataIndex !== -1) {
            currentState[channelIndex].metaDataTypes[metadataIndex].value =
              updatedValue;
          }
        }
      }
    }
    setMetadataValue(currentState);
    return currentState;
  };

  const handleUpdate = async (metadata, channelId) => {
    let articleBody = body;
    if (!articleBody) {
      articleBody = '""';
    }

    const res = await updateContent({
      ...{
        id: contentId,
        title,
        body,
        companyId: companyId > 0 ? companyId : metaCompanies[0]?.id || 0,
        alternativeText: {
          MARKDOWN: deltaToMarkdown(JSON.parse(articleBody).ops ?? ''),
          HTML: htmlContent
        },
        // body: '{"ops":[{"insert":"testing\\n"}]}',
        channelId: [...new Set(channelId)],
        status: content.status,
        articleFiles: orderedFiles(files),
        usersTags: customTag,
        channelMetadataDto: metadata,
        inlineAttachments,
        bodyCharacterCount: characters,
        mentions: updateMentions(JSON.parse(articleBody).ops),
        channelGroupIds,
        tiktokCreatorInfos: creatorInfoParams.filter((item) => channelId.includes(item.channelId))
      },
      ...{
        organisationEntities: contentParams
      }
    });

    return res;
  };

  const handleAdd = async (metadata, channelId) => {
    let articleBody = body;
    if (!articleBody) {
      articleBody = '""';
    }

    const res = await addContent({
      ...{
        title,
        body,
        tags: tags.map((x) => x.name),
        companyId: companyId > 0 ? companyId : metaCompanies[0]?.id || 0,
        channelId: [...new Set(channelId)],
        alternativeText: {
          MARKDOWN: deltaToMarkdown(JSON.parse(articleBody).ops ?? ''),
          HTML: htmlContent
        },
        status: 'DRAFT',
        articleFiles: orderedFiles(files),
        usersTags: customTag,
        channelMetadataDto: metadata,
        inlineAttachments,
        bodyCharacterCount: characters,
        mentions: updateMentions(JSON.parse(articleBody).ops),
        channelGroupIds,
        tiktokCreatorInfos: creatorInfoParams.filter((item) => channelId.includes(item.channelId))
      },
      ...{
        organisationEntities: contentParams
      }
    });

    return res;
  };

  const orderedFiles = (files) => {
    files.forEach((v, i) => {
      v.order = i;
    });
    return files;
  };

  const updateMentions = (ops) => {
    const userHandler = {};
    ops?.forEach((value) => {
      if (value.insert?.mention) {
        userHandler[value.insert.mention.value] = JSON.parse(
          value.insert.mention.data
        );
      }
    });
    return userHandler;
  };

  const onPublishEvent = async () => {
    await onSubmit();
    setPublishNow((publishNow) => {
      return { ...publishNow, publish: true };
    });
  };

  useEffect(() => {
    if (publishNow.isArticleSaved && publishNow.publish) {
      setOpenSchedulePublish(true);
      setPublishNow((publishNow) => {
        return { ...publishNow, isArticleSaved: false, publish: false };
      });
    }
  }, [publishNow]);

  const sendForApprovalEvent = () => {
    const response = sendForApproval(contentId);
    response.then((result) => {
      if (result === 0) {
        enqueueSnackbar(
          'Unable to send article for authorize , please contact administrator',
          {
            variant: 'error'
          }
        );
      } else {
        enqueueSnackbar('Article has been send for authorize', {
          variant: 'success'
        });
        setContent({
          ...content,
          status: result.status,
          editable: result.editable
        });
        setViewOnly(!result.editable);
      }
    });
  };

  const doApproveEvent = (params) => {
    const response = doApprove({ ...params, id: contentId });
    response.then((result) => {
      if (result === 0) {
        enqueueSnackbar(
          'Unable to authorize article , please contact administrator',
          {
            variant: 'error'
          }
        );
      } else {
        enqueueSnackbar('Article has been approved succesfully', {
          variant: 'success'
        });
        setContent({
          ...content,
          status: result.status,
          editable: result.editable
        });
        setViewOnly(!result.editable);
      }
    });
  };

  const fireValidationChannels = async () => {
    const channelId = companyChannelList.map(c => c.id);
    const metadata = updateMetadataTypes(getValues());
    const markdownParam = body ? deltaToMarkdown(JSON.parse(body)?.ops ?? '') : '';
    await getContentValidationByArticle({
      title,
      documentText: plainText,
      body,
      characterLength: characters,
      markdownLength: Array.from(markdownParam).length,
      tags: tags.map((x) => x.name),
      status: 'DRAFT',
      articleFiles: files,
      channelId,
      metaValues: metadata?.flatMap(m => m.metaDataTypes).flatMap(m => m.value || ''),
      mentions: body ? updateMentions(JSON.parse(body).ops) : null,
      alternativeText: {
        MARKDOWN: markdownParam,
        HTML: htmlContent
      }
    }).then(res => setCompanyChannelList(res));
  };

  return (
    <CompanyChannelListContext.Provider value={companyChannelList}>
      <div className="bg-[#f1f4f7] h-screen">
        <Navbar
          distracted={distracted}
          handleDistractionMode={handleDistractionMode}
          publish={onSubmit}
          publishEvent={onPublishEvent}
          viewOnly={viewOnly}
          id={contentId}
          status={content ? content.status : ''}
          doApproval={doApproval}
          sendForApprovalEvent={sendForApprovalEvent}
          doApproveEvent={doApproveEvent}
          isMyArticle={isMyArticle}
          goBack={() => {
            navigate('/contents/search');
          }}
        />
        <div className="flex">
          <div
            className={`flex-1 p-5 pt-20 h-[100vh] overflow-scroll scrollbar-hide ${
              distracted ? 'pr-0' : ''
            }`}
          >
            <VersionList
              setShowAlternate={setShowAlternate}
              contentId={content ? contentId : null}
              viewOnly={viewOnly}
            />
            <ChannelList
              validations={validations}
              plainText={plainText}
              title={title}
              selectedChannelList={selectedChannels}
              setSelectedChannelList={setSelectedChannels}
              setShowAlternate={setShowAlternate}
              pingValidation={pingValidation}
              viewOnly={viewOnly}
              selectAllChannel={toggleSelectAllChannel}
              metaCompanies={metaCompanies}
              companyId={companyId}
              setCompanyId={setCompanyId}
              parentId={parentId}
              contentId={contentId}
              channelGroupIds = {channelGroupIds}
              setChannelGroupIds = {setChannelGroupIds}
            />
            <div className="flex gap-2">
              <div className="w-full">
                <Editor
                  title={title}
                  setTitle={setTitle}
                  setContent={setBody}
                  content={content}
                  setPlainText={setPlainText}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  files={files}
                  setFiles={setFiles}
                  displayFiles={displayFiles}
                  setDisplayFiles={setDisplayFiles}
                  pingValidation={pingValidation}
                  setPingValidation={setPingValidation}
                  fireValidationChannels={fireValidationChannels}
                  viewOnly={viewOnly}
                  articleVersion={articleVersion}
                  selectedChannels={selectedChannels}
                  setEmojis={setEmojis}
                  characters={characters}
                  setCharacters={setCharacters}
                  words={words}
                  setWords={setWords}
                  setHtmlContent={setHtmlContent}
                  hashTag={hashTag}
                  customTag={customTag}
                  setCustomTag={setCustomTag}
                  setMentions={setMentions}
                  isEnableOpenAI={isEnableOpenAI}
                />
                <TiktokCreatorInfo
                  selectedChannels={selectedChannels}
                  companyChannelList={companyChannelList}
                  creatorInfoParams={creatorInfoParams}
                  setCreatorInfoParams={setCreatorInfoParams}
                />
                <MetadataQuestions
                  channelMetadataForm={channelMetadataForm}
                  metadataValue={metadataValue}
                  register={register}
                  control={control}
                  handleSubmit={handleSubmit}
                  formState={formState}
                  onMetadataFormSubmit={updateMetadataTypes}
                  viewOnly={viewOnly}
                />
              </div>
            </div>
          </div>
          {!distracted && (
            <RightSideDrawer
              distracted={distracted}
              openSidebar={openSidebar}
              setOpenSidebar={setOpenSidebar}
              docHistory={docHistory}
              setShowVersionForm={setShowVersionForm}
              setCompareVersion={setCompareVersion}
              viewOnly={viewOnly}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
              plainText={plainText}
              contentParams={contentParams}
              setContentParams={setContentParams}
              tags={tags}
              setTags={setTags}
              title={title}
              content={content}
              files={files}
              selectedChannels={selectedChannels}
              setHashTag={setHashTag}
              setCustomTag={setCustomTag}
              mentions={mentions}
            />
          )}

          <CreateAlternateVersion
            open={showAlternate}
            saveContent={onValidateAndSave}
            handleClose={() => setShowAlternate(false)}
            contentId={content ? contentId : null}
            titleExist={title && title.length > 0}
            bodyExist={plainText && plainText.length > 0}
            tagsExist={customTag.length > 0}
            attachmentsExist={files.length > 0}
          />

          {compareVersion && (
            <CompareVersionForm
              heading="Select previous version to compare"
              submitButton="Compare"
              open={compareVersion}
              handleClose={() => setCompareVersion(false)}
              docHistory={docHistory}
              currentId={contentId}
              viewOnly={viewOnly}
            />
          )}

          {showVersionForm && (
            <CompareVersionForm
              heading="Select previous version to restore"
              submitButton="Restore"
              open={showVersionForm}
              handleClose={() => setShowVersionForm(false)}
              docHistory={docHistory}
              currentId={contentId}
              viewOnly={viewOnly}
              updateContentArgs={updateContentArgs}
            />
          )}

          <SchedulePublish
            contents={contents}
            versions={versions}
            open={openSchedulePublish}
            handleClose={() => setOpenSchedulePublish(false)}
          />
        </div>
      </div>
    </CompanyChannelListContext.Provider>
  );
};

export default observer(ContentManagement);

import { useEffect } from 'react';
import integrationsSecretsStore from '../../../../store/settings/integrationsSecrets';
import PopupLayout from '../../../../layout/PopupLayout';
import { observer } from 'mobx-react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/CopyAll';
import { useSnackbar } from 'notistack';

const ShowSecretsForm = ({ open, handleClose, integrationConnectionType }) => {
  const {
    getConnectionTypeSecrets,
    regenerateConnectionSecrets,
    state: { integrationsSecrets }
  } = integrationsSecretsStore;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open && integrationConnectionType.id) {
      getConnectionTypeSecrets(integrationConnectionType.id);
    }
  }, [open, integrationConnectionType.id, getConnectionTypeSecrets]);

  const handleClickCopyClientSecret = () => {
    navigator.clipboard.writeText(integrationsSecrets.connectionParam3);
    enqueueSnackbar('Client Secret has been successfully copied !', { variant: 'success' });
  };
  const handleClickCopyClientId = () => {
    navigator.clipboard.writeText(integrationsSecrets.connectionParam2);
    enqueueSnackbar('Client Id has been successfully copied !', { variant: 'success' });
  };

  // Function to show the last four characters as readable and the rest as '*'
  const showLastFour = (str) => {
    const visibleLength = 4;
    const hiddenLength = str.length - visibleLength;
    const hiddenChars = '*'.repeat(hiddenLength);
    const visibleChars = str.slice(-visibleLength);
    return hiddenChars + visibleChars;
  };

  return (
    <PopupLayout open={open} handleClose={handleClose} heading={'Integration Secrets'}>
      <div className="pb-5">
        {integrationsSecrets.connectionParam2
          ? (
          <div>
            <div className="secret-container">
            <TextField
                label="Client Id"
                fullWidth sx={{ m: 1 }}
                id="outlined-basic"
                value={showLastFour(integrationsSecrets.connectionParam2)}
                variant="outlined"
                InputProps={
                  { endAdornment: <InputAdornment position="end"> <IconButton aria-label="copy password" onClick={handleClickCopyClientId} edge="end"><Visibility /></IconButton></InputAdornment> }
                }
                />
            </div>
            <div className="secret-container">
                <TextField
                label="Client Secret"
                fullWidth sx={{ m: 1 }}
                id="outlined-basic"
                value={showLastFour(integrationsSecrets.connectionParam3)}
                variant="outlined"
                InputProps={
                  { endAdornment: <InputAdornment position="end"> <IconButton aria-label="copy password" onClick={handleClickCopyClientSecret} edge="end"><Visibility /></IconButton></InputAdornment> }
                }
                />
            </div>
          </div>
            )
          : (
          <div>
            <p>No secrets found.</p>
          </div>
            )}
        <div className="flex justify-between mt-3">
          <button
            className="bg-red rounded px-4 py-2 text-white"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="bg-green rounded px-4 py-2 text-white"
            onClick={() => regenerateConnectionSecrets(integrationConnectionType.id)}
          >
            {integrationsSecrets.connectionParam2
              ? ('Regenerate')
              : ('Generate')
            }
          </button>
        </div>
      </div>
    </PopupLayout>
  );
};

export default observer(ShowSecretsForm);

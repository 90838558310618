import {
  Card,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import channelGroupAPI from '../../store/channel/channelGroup';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import companyStore from '../../store/organisation/company';
import { useSnackbar } from 'notistack';

const ChannelGroupModal = ({ onSave, onCancel }) => {
  const { channelGroupId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchUserMetaCompanies } = companyStore;
  const defaultValues = {
    groupName: '',
    companyId: '',
    channelInstances: []
  };
  const loadExistingChannelGroup = async () => {
    const response = await channelGroupAPI.fetchChannelGroupByIdsAndCompanyId(
      channelGroupId.toString(), null
    );
    setValues(response[0]);
    setExistingChannelGroup(response[0]);
    setSelectedChannelInstances(response[0].channelInstances);
  };
  const [values, setValues] = useState(defaultValues);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues, values, mode: 'onBlur' });
  const [existingChannelGroup, setExistingChannelGroup] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [setSelectedChannelInstances] = useState([]);
  useEffect(() => {
    if (channelGroupId) {
      loadExistingChannelGroup();
    }
  }, [channelGroupId]);
  useEffect(async () => {
    const response = await fetchUserMetaCompanies();
    setCompanies(response);
    if (!channelGroupId) {
      setValue('companyId', response[0].id);
    }
  }, []);
  const formOnSubmit = (values) => {
    if (existingChannelGroup) {
      updateChannelGroup(values);
    } else {
      createChannelGroup(values);
    }
  };
  const updateChannelGroup = (values) => {
    channelGroupAPI.updateChannelGroup(values).then((res) => {
      if (res) {
        setValues(res);
        enqueueSnackbar('Channel group updated successfully', {
          variant: 'success'
        });
        onSave();
        return;
      }
      enqueueSnackbar('Failed to update a channel group', {
        variant: 'error'
      });
    });
  };
  const createChannelGroup = (values) => {
    channelGroupAPI.createChannelGroup(values).then((res) => {
      if (res) {
        enqueueSnackbar('Channel group created successfully', {
          variant: 'success'
        });
        onSave();
      } else {
        enqueueSnackbar('Failed to create channel group', {
          variant: 'error'
        });
      }
    });
  };
  return (
        <form onSubmit={handleSubmit(formOnSubmit)}>
            <Card className="w-100 my-5">
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <InputLabel className="font-black">
                        Channel Group Name*
                        </InputLabel>
                        <FormHelperText className="mb-2">
                        This name would be the reference for this channel throughout
                        the Marvin application
                        </FormHelperText>
                        <Controller
                        control={control}
                        name="groupName"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                            {...field}
                            className="w-full"
                            placeholder="Enter your channel group name"
                            error={!!errors.groupName}
                            />
                        )}
                        />
                        <ErrorMessage
                        errors={errors}
                        name="groupName"
                        render={() => (
                            <Typography className="text-red">Required</Typography>
                        )}
                        />
                    </Grid>
                    <Grid item sm={12} md={12}>
                        <InputLabel className="font-black">Select Company</InputLabel>
                        <FormHelperText className="mb-2">
                        This name would be the reference for this channel throughout
                        the Marvin application
                        </FormHelperText>
                        <Controller
                        control={control}
                        name="companyId"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                            {...field}
                            displayEmpty
                            className="w-full"
                            error={!!errors.companyId}
                            renderValue={(value) => {
                              if (value) {
                                return (<span className='capitalize'>{companies.find((c) => c.id === value)?.name}</span>);
                              } else {
                                return (
                                <span style={{ color: '#ada6a6' }}>
                                Choose the company to link this Channel Group
                                </span>
                                );
                              }
                            }}
                            >
                            {companies.length > 0 &&
                                companies.map((company, index) => (
                                <MenuItem value={company.id} key={index} className='capitalize'>
                                    {company.name}
                                </MenuItem>
                                ))}
                            </Select>
                        )}
                        />
                        <ErrorMessage
                        errors={errors}
                        name="companyId"
                        render={() => (
                            <Typography className="text-red">Required</Typography>
                        )}
                        />
                    </Grid>
                </Grid>
            </Card>
            <Stack
            direction={'row'}
            spacing={2}
            justifyContent={'flex-end'}
            className="align-self-end"
            >
            <button
                variant="contained"
                className="p-2 bg-white border-blue border-2 text-blue rounded-md w-40"
                onClick={() => onCancel()}
            >
                Cancel
            </button>
            <button
                type="submit"
                variant="contained"
                className="p-2 text-white bg-blue rounded-md w-40"
            >
                {existingChannelGroup ? 'Update' : 'Create'}
            </button>
            </Stack>
        </form>
  );
};

export default ChannelGroupModal;

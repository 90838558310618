import { ErrorMessage } from '@hookform/error-message';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Card, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller, useFieldArray } from 'react-hook-form';
import convertToUtc from '../../utils/convertToUtc';

const parameterEnum = [
  'TEXT',
  'BOOLEAN',
  'INTEGER',
  'OPTIONS',
  'MULTI_OPTIONS',
  'TIMESTAMP',
  'ATTACHMENT'
];

const ChannelMetadata = ({ control, watch, errors, existingChannel }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'channelInstanceMetaDataTypeSet'
  });

  return (
    <Grid container className='p-8' style={existingChannel?.channelInstanceConfig?.status ? { pointerEvents: 'none' } : {}}>
        <Grid item xs={12} className='flex justify-content-end'>
            <Button
              className="bg-blue rounded px-4 py-2 uppercase text-white font-normal"
              onClick={() => append({
                parameterType: '',
                isMandatory: false,
                name: '',
                description: '',
                defaultValue: '',
                optionValue: '',
                fieldSize: 6,
                sortOrder: 1
              })}
            >
              Add Metadata +
            </Button>
        </Grid>
        <Grid container>
            {fields.map((item, index) => {
              const parameterTypeWatch = watch(`channelInstanceMetaDataTypeSet.${index}.parameterType`);
              return (
                <Card key={item.id} className='w-full ml-0 p-4 mt-4' elevation={0} style={{ backgroundColor: '#f2f2f2' }}>
                    <Typography className='font-black mb-2 flex align-items-center'>
                        Metadata {index + 1}
                        <button
                          onClick={() => remove(index)}
                        >
                          <DeleteOutlineIcon fontSize='small' className='ml-6' style = {{ color: 'hsla(191, 69%, 47%, 1)' }}/>
                        </button>
                    </Typography>
                    <Grid container rowSpacing={2} columnSpacing={4}>
                        <Grid item md={6} sm={12}>
                          <Controller
                              name={`channelInstanceMetaDataTypeSet.${index}.parameterType`}
                              control={ control }
                              rules={{ required: true }}
                              render = {({ field }) => (
                                <Select
                                  { ...field }
                                  displayEmpty
                                  className='w-full bg-white'
                                  error={errors.channelInstanceMetaDataTypeSet ? !!errors.channelInstanceMetaDataTypeSet[index]?.parameterType : false}
                                  renderValue={(value) => {
                                    if (value) {
                                      return value;
                                    } else {
                                      return (<span style={{ color: '#ada6a6' }}>Type</span>);
                                    }
                                  }}
                                >
                                  {parameterEnum.map((param, index) => (
                                      <MenuItem key={index} value={param}>{param}</MenuItem>
                                  ))}
                                </Select>
                              )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`channelInstanceMetaDataTypeSet.${index}.parameterType`}
                            render={({ message }) => (
                              <Typography className='text-red'>{message}</Typography>
                            )}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Controller
                            name={`channelInstanceMetaDataTypeSet.${index}.isMandatory`}
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel { ...field } className='w-full' control={<Checkbox/>} label='is Mandatory'></FormControlLabel>
                            )}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Controller
                              name={`channelInstanceMetaDataTypeSet.${index}.name`}
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <TextField
                                  { ...field }
                                  placeholder="Name"
                                  className='w-full bg-white rounded-lg'
                                  error={errors.channelInstanceMetaDataTypeSet ? !!errors.channelInstanceMetaDataTypeSet[index]?.name : false}
                                />
                              )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`channelInstanceMetaDataTypeSet.${index}.name`}
                            render={({ message }) => (
                              <Typography className='text-red'>{message}</Typography>
                            )}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Controller
                            name={`channelInstanceMetaDataTypeSet.${index}.description`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                { ...field }
                                placeholder="Description"
                                className='w-full bg-white rounded-lg'
                                error={errors.channelInstanceMetaDataTypeSet ? !!errors.channelInstanceMetaDataTypeSet[index]?.description : false}
                              />
                            )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`channelInstanceMetaDataTypeSet.${index}.description`}
                              render={({ message }) => (
                                <Typography className='text-red'>{message}</Typography>
                              )}
                          />
                        </Grid>
                        { parameterTypeWatch && parameterTypeWatch !== 'ATTACHMENT' &&
                          <Grid item xs={12}>
                            { parameterTypeWatch === 'OPTIONS' ||
                              parameterTypeWatch === 'MULTI_OPTIONS'
                              ? (
                                  <Controller
                                    name={`channelInstanceMetaDataTypeSet.${index}.optionValue`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        { ...field }
                                        label='Options(, separated)'
                                        className='w-full bg-white rounded-lg'
                                      />
                                    )}
                                  />
                                )
                              : (parameterTypeWatch === 'BOOLEAN'
                                  ? (
                                      <Controller
                                        name={`channelInstanceMetaDataTypeSet.${index}.defaultValue`}
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel { ...field } label='True/False' control={<Checkbox/>}/>
                                        )}
                                      />
                                    )
                                  : (parameterTypeWatch === 'TIMESTAMP'
                                      ? (
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Controller
                                              name={`channelInstanceMetaDataTypeSet.${index}.defaultValue`}
                                              control={control}
                                              render={({ field }) => (
                                                  <DatePicker
                                                    { ...field }
                                                    className='w-full bg-white rounded-lg'
                                                    value={field.value ? dayjs(field.value) : null}
                                                    onChange={(date) => {
                                                      if (isNaN(date)) {
                                                        date = 'invalid_date';
                                                        field.onChange(date);
                                                      } else {
                                                        field.onChange(convertToUtc(dayjs(date)));
                                                      }
                                                    }}
                                                  />
                                              )}
                                            />
                                          </LocalizationProvider>
                                        )
                                      : (parameterTypeWatch === 'INTEGER'
                                          ? (
                                              <Controller
                                                name={`channelInstanceMetaDataTypeSet.${index}.defaultValue`}
                                                control={control}
                                                render={({ field }) => (
                                                  <TextField
                                                    { ...field }
                                                    placeholder="Default value"
                                                    variant="outlined"
                                                    type="number"
                                                    className='w-full bg-white rounded-lg'
                                                  />
                                                )}
                                              />
                                            )
                                          : (
                                              <Controller
                                                name={`channelInstanceMetaDataTypeSet.${index}.defaultValue`}
                                                control={control}
                                                render={({ field }) => (
                                                  <TextField
                                                    { ...field }
                                                    placeholder="Default value"
                                                    variant="outlined"
                                                    type='text'
                                                    className='w-full bg-white rounded-lg'
                                                  />
                                                )}
                                              />
                                            )
                                        )
                                    )
                                )
                            }
                          </Grid>
                        }
                        <Grid item md={6} sm={12}>
                          <Controller
                            name={`channelInstanceMetaDataTypeSet.${index}.fieldSize`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                              { ...field }
                                placeholder="Field Size"
                                className='w-full bg-white rounded-lg'
                                type='number'
                                error={errors.channelInstanceMetaDataTypeSet ? !!errors.channelInstanceMetaDataTypeSet[index]?.fieldSize : false}
                              />
                            )}
                          />
                          <ErrorMessage
                              errors={errors}
                              name={`channelInstanceMetaDataTypeSet.${index}.fieldSize`}
                              render={({ message }) => (
                                <Typography className='text-red'>{message}</Typography>
                              )}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Controller
                            name={`channelInstanceMetaDataTypeSet.${index}.sortOrder`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                { ...field }
                                placeholder="Sort Order"
                                className='w-full bg-white rounded-lg'
                                type='number'
                              />
                            )}
                          />
                        </Grid>
                    </Grid>
                </Card>
              );
            })}
          </Grid>
    </Grid>
  );
};

export default ChannelMetadata;

import { Container } from '@mui/material';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import ProfileAnalytics from '../../components/Analytics/ProfileAnalytics';

const Analytics = () => {
  return (
    <>
      <div>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs heading="Profile Analytics" />
          <ProfileAnalytics/>
      </Container>
      </div>
    </>
  );
};

export default Analytics;

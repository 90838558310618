import { Button, Typography, Box } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { useState } from 'react';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useHover = () => {
  const [hovering, setHovering] = useState(false);
  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false)
  };

  return [hovering, onHoverProps];
};

function WatchVideoActionButton ({ link, color }) {
  const [buttonIsHovering, buttonHoverProps] = useHover();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
        <>
            <Button onClick={handleClickOpen} {...buttonHoverProps} sx={ { minWidth: 'auto' } } size="small" disableRipple={true} style={{ background: 'linear-gradient(0deg, #F46B45 0%, #EEA849 100%)', pointerEvents: 'visible' }}>
                { !buttonIsHovering && (<HelpOutline style={{ color: color || 'white' }} />)}
                { buttonIsHovering && (<><Typography className='ml-2' style={{ color: color || 'white', fontWeight: 'bold' }} >Watch Tutorial</Typography><PlayArrowOutlinedIcon style={{ color: color || 'white' }} /></>) }
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
            >   <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          m: 'auto',
                          width: 'fit-content'
                        }}
                    >
                        <iframe
                            src={link || 'https://www.youtube.com/embed/4w72c_TWEPY?si=E4tToM_05nFsPadc'}
                            title="Video"
                            width={640}
                            height={315}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
  );
}

export default WatchVideoActionButton;

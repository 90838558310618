import React, { useEffect, useState } from 'react';
import ModalHead from '../Header/modalHead';
import StepBar from './StepBar';
import ListChannels from './ListChannels';
import ChooseConnectionOption from './ChooseConnectionOption';
import channelsStore from '../../../store/channel/channel';
import ChannelDetails from './ChannelDetails';
import publicationStore from '../../../store/channel/publication';
import { enqueueSnackbar } from 'notistack';

const AddUserChannel = ({ handleClose, isEdit, currentChannel, stepNo }) => {
  const { createChannel, updateChannel } = channelsStore;

  const {
    fetchConnectionTypes,
    fetchConnectionTypeParameters,
    createConnectionParameters,
    connectChannel
  } = publicationStore;

  const [step, setStep] = useState(1);
  const [connectionType, setConnectionType] = useState(null);

  const [channelType, setChannelType] = useState(null);
  const [name, setName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [connectionTypes, setConnectionTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const [channeInstance, setChannelInstance] = useState(null);

  const [addMetaData, setAddMetaData] = useState(false);
  const [metadataList, setMetadataList] = useState([]);

  useEffect(() => {
    if (isEdit) {
      setName(currentChannel.name);
      setChannelType(currentChannel.channelType);
      setChannelInstance(currentChannel);

      // channel metadata
      if (currentChannel.channelInstanceMetaDataTypeSet != null) {
        setMetadataList(currentChannel.channelInstanceMetaDataTypeSet);
        setAddMetaData(true);
      }
      if (currentChannel.channelInstanceConfig != null) {
        setConnectionType(currentChannel.channelInstanceConfig.connectionTypes);

        if (currentChannel.channelInstanceConfig.configParams) {
          const config = currentChannel.channelInstanceConfig.configParams.map(
            (configParams) => {
              const { parameterType, parameterName, sensitive, mandate, hidden } =
                configParams.parameterTypes;
              return {
                ...configParams,
                parameterType,
                parameterName,
                sensitive,
                mandate,
                hidden
              };
            }
          );
          setFields(config);
        }
      }
      currentChannel.imageId && setImageId(imageId);
      currentChannel?.image && setImagePreview(currentChannel.image);
      setStep(stepNo);
    }
  }, []);

  useEffect(() => {
    selectedImage && setImagePreview(URL.createObjectURL(selectedImage));
  }, [selectedImage]);

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
    setImageId(null);
  };

  useEffect(() => {
    if (channelType != null) {
      fetchConnectionTypes(channelType.id).then((res) => {
        setConnectionTypes(res);
        setConnectionType(res[0]);
      });
    }
    if (!isEdit) {
      setConnectionType(null);
      setFields(null);
    }
  }, [channelType]);

  useEffect(() => {
    if (connectionType != null) {
      fetchConnectionTypeParameters(channelType.id, connectionType.id).then(
        (connParam) => {
          if ((!isEdit || !currentChannel.channelInstanceConfig) && connParam && connParam.length > 0) {
            setFields(connParam);
          }
        }
      );
    }
  }, [connectionType]);

  const handleAddChannel = () => {
    let data = {
      name,
      channelType: { id: channelType.id }
    };

    if (addMetaData) {
      data = { ...data, channelInstanceMetaDataTypeSet: metadataList };
    }
    createChannel(data, selectedImage).then((res) => {
      if (res !== 0) {
        enqueueSnackbar('Channel added successfully', { variant: 'success' });
        setChannelInstance(res);
        return;
      }
      enqueueSnackbar('Failed to add a channel', { variant: 'error' });
      handleClose();
    });
  };

  const handleUpdateChannel = () => {
    let data = {
      id: channeInstance.id,
      name,
      channelType: { id: channelType.id },
      imageId: imageId || null,
      channelInstanceConfig: channeInstance.channelInstanceConfig
    };

    if (addMetaData) {
      data = { ...data, channelInstanceMetaDataTypeSet: metadataList };
    }
    updateChannel(data, selectedImage).then((res) => {
      if (res !== 0) {
        enqueueSnackbar('Channel updated successfully', { variant: 'success' });
        setChannelInstance(res);
        return;
      }
      enqueueSnackbar('Failed to update a channel', { variant: 'error' });
      handleClose();
    });
  };

  const connectToChannel = () => {
    const hasFailed = fields && fields.length > 0 && fields.find((item) => !item.parameterValue);

    if (hasFailed) {
      enqueueSnackbar('Please enter value for ' + hasFailed.parameterName, {
        variant: 'error'
      });
    } else {
      const params = {
        ...channeInstance,
        channelInstanceConfig: {
          connectionTypes: {
            id: connectionType.id
          }
        }
      };

      if (fields && fields.length > 0) {
        params.channelInstanceConfig.configParams = [];
        fields.map((item) => (
          params.channelInstanceConfig.configParams.push({
            parameterTypes: {
              id: item.parameterTypes == null ? item.id : item.parameterTypes.id
            },
            parameterValue: item.parameterValue
          })
        ));
      }
      if (isEdit) {
        params.channelInstanceConfig.id = channeInstance.channelInstanceConfig
          ? channeInstance.channelInstanceConfig.id
          : null;
      }
      createConnectionParameters(params)
        .then((response) => {
          enqueueSnackbar('Publication setting has been stored succesfully!', {
            variant: 'success'
          });
          connect(channeInstance.id);
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.data, {
            variant: 'error'
          });
        });
    }
  };

  const connect = (channelinstanceId) => {
    connectChannel(channelinstanceId)
      .then((response) => {
        if (response.redirectUrl) {
          window.location.replace(response.redirectUrl);
        } else {
          if (response.status === true) {
            enqueueSnackbar('Channel connection has been established', {
              variant: 'success'
            });
          } else {
            enqueueSnackbar('Channel connection has been disconnected', {
              variant: 'success'
            });
          }
          handleClose();
        }
      })
      .catch((_error) => {
        enqueueSnackbar('Failed to connect channel', { variant: 'error' });
        handleClose();
      });
  };

  return (
    <div className="flex items-center h-full drop-shadow-lg">
      <form className={'bg-white mx-auto rounded-lg'}>
        <ModalHead
          handleClose={handleClose}
          heading={'Connect Channel'}
          className="h-[400px]"
        />
        <div className={'px-5 ' + (addMetaData ? 'w-[1000px]' : 'w-[600px]')}>
          <StepBar step={step} />
          <hr className="my-5" />

          {step === 1 && (
            <ListChannels
              setStep={setStep}
              handleClose={handleClose}
              channelType={channelType}
              setChannelType={setChannelType}
              setMetadataList={setMetadataList}
            />
          )}

          {step === 2 && (
            <ChannelDetails
              setStep={setStep}
              isEdit={isEdit}
              channeInstance={channeInstance}
              channelType={channelType}
              setChannelType={setChannelType}
              handleClose={handleClose}
              name={name}
              setName={setName}
              imagePreview={imagePreview}
              setImagePreview={setImagePreview}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              imageId={imageId}
              setImageId={setImageId}
              handleRemoveImage={handleRemoveImage}
              handleAddChannel={handleAddChannel}
              handleUpdateChannel={handleUpdateChannel}
              addMetaData={addMetaData}
              setAddMetaData={setAddMetaData}
              metadataList={metadataList}
              setMetadataList={setMetadataList}
            />
          )}

          {step === 3 && (
            <ChooseConnectionOption
              setStep={setStep}
              channelType={channelType}
              connectionType={connectionType}
              setConnectionType={setConnectionType}
              connectionTypes={connectionTypes}
              fields={fields}
              setFields={setFields}
              handleClose={handleClose}
              connectToChannel={connectToChannel}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <AddUserChannel {...props} forwardedRef={ref} />
));

import { CiSearch } from 'react-icons/ci';

const Search = ({ searchText, setSearchText }) => {
  return (
    <div className="items-center mt-3">
      <div className="flex border-[1px] border-[#ccc] rounded">
        <CiSearch className="rounded-l p-3 text-5xl bg-white" />
        <form className="flex-1 h-full">
          <input
            className="py-[14px] rounded-r w-full outline-none"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </form>
      </div>

      {/* <div className="relative">
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="border-[1px] border-[#ccc] rounded p-3 text-lg text-[#ccc]"
        >
          <CiFilter />
        </button>
        {showFilters && (
          <div
            ref={ref}
            className="absolute w-[200px] left-[-205px] border-2 border-blue top-0 z-10 p-3 rounded bg-white drop-shadow-lg"
          >
            <p className="font-bold mb-2">Filters</p>
            <div className="">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="TITLE"
                  name="filter"
                  value="TITLE"
                  checked={filter.includes('TITLE')}
                  onChange={handleFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="TITLE" className="ml-1">
                  Title
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="BODYINTRO"
                  name="filter"
                  value="BODYINTRO"
                  checked={filter.includes('BODYINTRO')}
                  onChange={handleFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="BODYINTRO" className="ml-1">
                  Body - Intro
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="BODYCLOSING"
                  name="filter"
                  value="BODYCLOSING"
                  checked={filter.includes('BODYCLOSING')}
                  onChange={handleFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="BODYCLOSING" className="ml-1">
                  Body - Closing
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="BODYCOMPLIANCE"
                  name="filter"
                  value="BODYCOMPLIANCE"
                  checked={filter.includes('BODYCOMPLIANCE')}
                  onChange={handleFilterChange}
                  className="mt-[-8px]"
                />
                <label htmlFor="BODYCOMPLIANCE" className="ml-1">
                  Body - Compliance
                </label>
              </div>
            </div>

            <hr className="my-5" />

            <div className="flex justify-end">
              <button
                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                onClick={() => setShowFilters(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => setShowFilters(false)}
                variant="contained"
                color="primary"
                className="mb-4 mrvn-sv rounded"
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Search;

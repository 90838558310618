export default {
  control: {
    fontSize: 16,
    borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    height: '56px'
    // fontWeight: 'normal',
  },
  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent'
    },
    input: {
      padding: 9,
      border: '1px solid silver'
    }
  },
  '&singleLine': {
    display: 'inline-flex',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    highlighter: {
      padding: '16.5px 14px'
    },
    input: {
      padding: '16.5px 14px'
    }
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5'
      }
    }
  }
};

import channelsStore from '../../store/channel/channel';
import analyticsStore from '../../store/analytics/analytics';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import AnalyticsTable from './AnalyticsTable';
import { Select, MenuItem, FormControl, Skeleton } from '@mui/material';
import AnalyticsAreaLineChart from './charts/AnalyticsAreaLineChart';
import './profileanalytics.css';
import Loader from '../Layout/Loader';

const SkeletonLoader = () => (
  <div className="flex-row">
    <Skeleton animation='wave' width='100%' sx={{ fontSize: '1rem' }} />
    <Skeleton animation='wave' width='100%' sx={{ fontSize: '1rem' }} />
    <Skeleton animation='wave' width='100%' sx={{ fontSize: '1rem' }} />
    <Skeleton animation='wave' width='100%' sx={{ fontSize: '1rem' }} />
    <Skeleton animation='wave' width='100%' sx={{ fontSize: '1rem' }} />
  </div>
);

const desiredChannelTypes = ['Facebook Page', 'Instagram Business', 'Linkedin Business', 'YouTube Shorts'];
const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  // Ensure two-digit format
  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;
  return `${formattedDay}/${formattedMonth}/${year}`;
};

const ProfileAnalytics = () => {
  const [selectedMonth, setSelectedMonth] = useState('THIS_MONTH');
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { state: { allChannels }, fetchAllChannels } = channelsStore;
  const { state: { analytics }, setAnalytics } = analyticsStore;

  useEffect(() => {
    fetchAllChannels(null);
  }, []);

  useEffect(() => {
    fetchData();
  }, [allChannels, selectedMonth]);

  const fetchData = async () => {
    setLoading(true);
    setAnalytics([]);
    if (allChannels.length > 0) {
      const filteredChannels = allChannels.filter(channel => desiredChannelTypes.includes(channel.channelType.channelName));
      const filteredChannelIds = filteredChannels.map(channel => channel.id);
      if (filteredChannelIds.length > 0) {
        try {
          await analyticsStore.fetchChannelAnalytics(filteredChannelIds, true, selectedMonth);
        } catch (error) {
          console.error('Error fetching analytics data:', error);
        }
      }
    }
    setLoading(false);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    if (event.target.value === 'THIS_MONTH') {
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      setEndDate(new Date());
    } else if (event.target.value === 'LAST_MONTH') {
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const lastMonthEndDate = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth() + 1, 0);
      setStartDate(new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth(), 1));
      setEndDate(lastMonthEndDate);
    }
  };

  return (
    <>
      { loading && (<Loader />) }
      <div className="bg-white rounded drop-shadow-md p-5">
        <div className="container">
            <FormControl className="formControl">
                <label htmlFor="month-select" className="label">Select Month</label>
                <Select
                    padding="none"
                    labelId="month-select-label"
                    id="month-select"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="select"
                >
                    <MenuItem value="THIS_MONTH">This Month</MenuItem>
                    <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                </Select>
            </FormControl>
            <div className="dateDisplay">
                <p className="dateText text-gray-700 font-bold">
                    The following analysis is from {formatDate(startDate)} till {formatDate(endDate)}.
                </p>
            </div>
        </div>
        { loading && <SkeletonLoader />}
        { (!loading && analytics?.pageAnalytics != null && analytics?.availableDates != null)
          ? <AnalyticsTable data={analytics.pageAnalytics} />
          : (!loading && (<p className='text-center'>No analysis records found in current search criteria.</p>))
        }
      </div>
      <div>
        <div className="bg-white rounded drop-shadow-md mt-10 p-5">
          { loading && <SkeletonLoader />}
          { (!loading && analytics?.pageAnalytics != null && analytics?.availableDates != null)
            ? <AnalyticsAreaLineChart keyword={'impressions'} pageAnalytics={analytics.pageAnalytics} availableDates={analytics.availableDates} heading='Analyzing Page Impressions Across Channels.'/>
            : (!loading && (<p className='text-center'>No impressions records found in current search criteria.</p>))
          }
        </div>
        <div className="bg-white rounded drop-shadow-md mt-10 p-5">
          { loading && <SkeletonLoader />}
          { (!loading && analytics?.pageAnalytics != null && analytics?.availableDates != null)
            ? <AnalyticsAreaLineChart keyword={'engagements'} pageAnalytics={analytics.pageAnalytics} availableDates={analytics.availableDates} heading='Engagement Trends Over Time.'/>
            : (!loading && (<p className='text-center'>No engagements records found in current search criteria.</p>))
          }
        </div>
      </div>
    </>
  );
};

export default observer(ProfileAnalytics);

import { filter } from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import {
  Card,
  Checkbox,
  Container,
  Pagination, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';

import { useSnackbar } from 'notistack';

import { AddProjectButton } from '../../components/AddButton';
import { BulkDeleteButton } from '../../components/DeleteButton';
import ListHead from '../../components/Global/ListHead';
import ListToolbar from '../../components/Global/ListToolbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import { observer } from 'mobx-react';
import MembershipForm from '../../components/Modal/Organisation/MembershipForm';
import ProjectMoreMenu from '../../components/Organisations/ProjectMoreMenu';
import projectStore from '../../store/organisation/project';
import userStore from '../../store/security/user';
import canI from '../../utils/canI';
import NoContent from '../../components/NoContent';

function descendingComparator (b, a, orderBy) {
  const lowerA = a[orderBy] ? a[orderBy].toLowerCase() : '';
  const lowerB = b[orderBy] ? b[orderBy].toLowerCase() : '';

  if (lowerB < lowerA) {
    return -1;
  }
  if (lowerB > lowerA) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  if (orderBy === 'name') {
    return order === 'desc'
      ? (a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase())
      : (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter (array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((b, a) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

const Project = () => {
  const {
    state: { projects },
    getProjects,
    deleteProject
  } = projectStore;

  const {
    state: { users },
    getUsers
  } = userStore;

  const { enqueueSnackbar } = useSnackbar();

  const [page] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage] = useState(10);
  const [itemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [companySortDirection, setCompanySortDirection] = useState('desc');

  const filteredUsers = applySortFilter(
    projects,
    getComparator(order, orderBy),
    filterName
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const visibleItems = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getUsers();
    getProjects().then(
      (res) =>
        res === 0 &&
        enqueueSnackbar('Failed to fetch projects', { variant: 'error' })
    );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (property === 'company') {
      setCompanySortDirection(companySortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = userList.map((user) => user.id);
      const newSelecteds = projects.map((user) => user.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Project', isSorted: orderBy === 'name' },
    { id: 'access', label: 'Access' },
    { id: 'moreMenu' }
  ];

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (id) => {
    // dispatch(deleteUser(id));
    deleteProject(id).then((res) =>
      res === 1
        ? enqueueSnackbar('Project deleted successfully', {
          variant: 'success'
        })
        : enqueueSnackbar('Failed to delete project', { variant: 'error' })
    );
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Projects"
        actions={[
          canI(['delete:organisations']) && <BulkDeleteButton
            onDelete={deleteProject}
            selected={selected}
            setSelected={setSelected}
            heading={['Project', 'Projects']}
          />,
          canI(['create:organisations']) && <AddProjectButton />
        ]}
        selected={selected}
      />

      <Card style={{ boxShadow: 'none' }}>
        <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        {filteredUsers.length > 0 && (
        <TableContainer sx={{ minWidth: 800 }} style={{ padding: '0 25px' }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              // rowCount={userList.length}
              rowCount={projects.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {visibleItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, name } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;
                  return (
                    <Row
                      row={row}
                      id={id}
                      key={id}
                      name={name}
                      handleDeleteUser={handleDeleteUser}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      users={users}
                    />
                  );
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    sx={{ py: 3 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Stack spacing={2} className="pagination">
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
        </TableContainer>
        )}
      </Card>
    </Container>
    {filteredUsers.length < 1 && NoContent('No Projects Found')}
    </>
  );
};
export default observer(Project);

const Row = observer(
  ({
    row: { id, name, members },
    row,
    handleClick,
    handleDeleteUser,
    isItemSelected,
    users
  }) => {
    const [showMembership, setShowMembership] = useState(false);
    const [memberships, setMemberships] = useState([]);

    const {
      // state: { memberships },
      getProjectMemberships,
      addProjectMembership,
      updateProjectMembership
      // deleteProjectMembership,
    } = projectStore;

    const handleShowMemberships = async () => {
      getProjectMemberships(id).then((res) => {
        setShowMembership(true);
        setMemberships(res);
      });
    };

    const handleClickMembership = async (value, userId) => {
      const data = memberships.find((x) => x.user_id === userId);
      if (data) {
        updateProjectMembership({ ...data, readOnly: value });
        setMemberships((memberships) =>
          memberships.map((x) =>
            x.id === data.id ? { ...data, readOnly: value, is_read_only: value } : x
          )
        );
      } else {
        const res = await addProjectMembership(
          { readOnly: value, userId },
          id
        );
        setMemberships((memberships) => [...memberships, res]);
      }
    };

    useEffect(() => {
      members && setMemberships(members);
    }, []);

    return (
      <>
        <TableRow
          hover
          key={id}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => handleClick(event, id)}
            />
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            </Stack>
          </TableCell>

          <TableCell align="left">
            {memberships.map((x, index) => (
              <User isLast={memberships.length - 1 === index} key={index} id={x.userId ?? x.user_id} users={users} readOnly={x.readOnly} />
            ))}
          </TableCell>
          <TableCell align="right">
            <ProjectMoreMenu
              onDelete={() => handleDeleteUser(id)}
              onMembership={handleShowMemberships}
              row={row}
            />
          </TableCell>
        </TableRow>
        <MembershipForm
          open={showMembership}
          memberships={memberships}
          handleClose={() => setShowMembership(false)}
          handleClickMembership={handleClickMembership}
        />
      </>
    );
  }
);

const User = ({ id, users, readOnly, isLast }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const data = users.find((x) => x.id === id);
    data && setUser(data);
  }, [id, users]);

  if (readOnly) {
    return (
      <Fragment>
        {user.givenName} {user.familyName}
        {!isLast ? ', ' : ''}
      </Fragment>
    );
  } else {
    return null;
  }
};

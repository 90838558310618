import { makeAutoObservable, observable, action } from 'mobx';
import client from '../../utils/axios';
import loadingStore from '../loading';
import fileStore from '../file';
const ENDPOINT = '/groups/groups';

class GroupStore {
  state = {
    groups: [],
    members: []
  };

  constructor () {
    makeAutoObservable(this, {
      state: observable,
      getGroups: action,
      addGroup: action,
      updateGroup: action,
      deleteGroup: action,
      getGroupMembers: action,
      addGroupMembers: action,
      deleteGroupMembers: action
    });
  }

  getGroups = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.groups = response.data;
      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getGroupMembers = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`);
      this.state.members = response.data;
      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addGroup = async (group, file) => {
    loadingStore.setLoading(true);

    try {
      const image = file ? await fileStore.addImage(file, 'group') : null;
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}`,
        { ...group, group: { ...group.group, image_id: image } },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      this.state.groups.push(response.data);
      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addGroupMembers = async (members, id) => {
    loadingStore.setLoading(true);

    try {
      await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        members,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      this.state.members = [
        ...this.state.members,
        ...members.users.map((user) => ({ user_id: user.user_id }))
      ];

      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateGroup = async (group, file) => {
    loadingStore.setLoading(true);

    try {
      const image = file
        ? await fileStore.addImage(file, 'group')
        : group.group.image_id;
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${group.group.id}`,
        { ...group, group: { ...group.group, image_id: image } }
      );

      this.state.groups = this.state.groups.map((x) =>
        x.id === group.group.id ? { ...group.group, image_id: image } : x
      );

      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteGroup = async (groupId) => {
    loadingStore.setLoading(true);

    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${groupId}`);
      this.state.groups = this.state.groups.filter((g) => g.id !== groupId);

      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteGroupMembers = async (members, id) => {
    loadingStore.setLoading(true);

    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        { data: members }
      );
      const excludedIds = members.users.map((user) => user.user_id);
      this.state.members = this.state.members.filter(
        (item) => !excludedIds.includes(item.user_id)
      );

      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const groupStore = new GroupStore();
export default groupStore;

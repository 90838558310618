import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Modal } from '@mui/material';
import ModalHead from '../../Modal/Header/modalHead';
import SearchableSelect from '../../SearchableSelect';
import { useSnackbar } from 'notistack';
import contentStore from '../../../store/content/content';

const CompareVersionForm = ({
  heading,
  submitButton,
  docHistory,
  open,
  handleClose,
  currentId,
  updateContentArgs
}) => {
  const { restorePreviousVersion } = contentStore;

  const { enqueueSnackbar } = useSnackbar();

  const [selectedVersion2, setSelectedVersion2] = useState({});

  const handleSelectVersion2 = (data) => {
    setSelectedVersion2(data);
  };

  const handleRestorePreviousVersion = async (articleId, historyId) => {
    restorePreviousVersion(articleId, historyId).then((res) => {
      if (res === 0) {
        enqueueSnackbar('Failed to restore the article', {
          variant: 'error'
        });
      } else {
        updateContentArgs(res);
        enqueueSnackbar('Article restored successfully', {
          variant: 'success'
        });
      }
      handleClose();
    });
  };

  return (
    <Modal open={open}>
      <div className="flex items-center h-full drop-shadow-lg">
        <form
          // onSubmit={onSubmit}
          className="bg-white w-[500px] mx-auto rounded-lg"
        >
          <ModalHead handleClose={handleClose} heading={heading} />
          <div className="px-5">
            <SearchableSelect
              heading="Previous Versions"
              data={docHistory.map(
                (x) =>
                  (x = {
                    ...x,
                    name:
                      x.title +
                      ' (v' +
                      x.version +
                      '.' +
                      x.revision +
                      ')' +
                      (x.updatedAt
                        ? x.updatedAt.length >= 2 &&
                          ' ' +
                            x.updatedAt[2] +
                            '/' +
                            x.updatedAt[1] +
                            '/' +
                            x.updatedAt[0]
                        : '')
                  })
              )}
              value={selectedVersion2}
              onSelect={handleSelectVersion2}
              // disabled={!selectedVersion1.id}
            />

            <hr className="my-5" />

            <div className="flex justify-end">
              <button
                className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              {submitButton === 'Compare'
                ? (
                <Link
                  to={`/content/version-comparison/${currentId}/${selectedVersion2.id}`}
                  className="mb-4 mrvn-sv rounded"
                >
                  {submitButton}
                </Link>
                  )
                : (
                <button
                  className="mb-4 text-white border-[1px] mrvn-sv rounded px-4 mr-2"
                  type="button"
                  onClick={() =>
                    handleRestorePreviousVersion(currentId, selectedVersion2.id)
                  }
                >
                  {submitButton}
                </button>
                  )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CompareVersionForm;

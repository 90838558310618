import loadingStore from '../loading';
import client from '../../utils/axios';
const DIRECTRY_ENDPOINT = '/directory/handler';

class DirectoryStore {
  createContact = async (contactDetail) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${DIRECTRY_ENDPOINT}`,
        contactDetail
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getContactDetailById = async (id) => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BASE_URL}${DIRECTRY_ENDPOINT}` + '/' + id
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  searchContactDirectory = async (searchFilters) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${DIRECTRY_ENDPOINT}` + '/search', {
        params: searchFilters
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  fetchUserId = async (userId) => {
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${DIRECTRY_ENDPOINT}` + '/userId/' + userId);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateContactDetail = async (contactDetail) => {
    try {
      loadingStore.setLoading(true);
      const response = await client.put(
        `${process.env.REACT_APP_BASE_URL}${DIRECTRY_ENDPOINT}` + '/' + contactDetail.id,
        contactDetail
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteContactDetail = async (id) => {
    try {
      loadingStore.setLoading(true);
      await client.delete(`${process.env.REACT_APP_BASE_URL}${DIRECTRY_ENDPOINT}` + '/' + id);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const directoryStore = new DirectoryStore();
export default directoryStore;

import { makeObservable, observable, action } from 'mobx';
import client from '../../utils/axios';

import loadingStore from '../loading';

const ENDPOINT = '/groups/projects';

class ProjectStore {
  state = {
    projects: [],
    memberships: []
  };

  constructor () {
    makeObservable(this, {
      state: observable,
      getProjects: action,
      getProject: action,
      addProject: action,
      updateProject: action,
      deleteProject: action
    });
  }

  getProjects = async () => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`);
      this.state.projects = response.data;
      return response.data;
    } catch (error) {
      return [];
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getProject = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addProject = async (data) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}`, {
        project: {
          name: data.name
        }
      });
      this.state.projects = [...this.state.projects, response.data];
      return 1;
    } catch (error) {
      console.error(error);

      if (error?.response?.data?.includes('already added')) {
        return 2;
      }
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateProject = async (data) => {
    loadingStore.setLoading(true);
    try {
      await client.put(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}`, {
        project: {
          name: data.name,
          membership_type: 'CUSTOM',
          parent_location_id: 3
        }
      });
      const index = this.state.projects.findIndex((c) => c.id === data.id);
      this.state.projects[index] = data;
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteProject = async (id) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}`);
      this.state.projects = this.state.projects.filter((c) => c.id !== id);
      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  getProjectMemberships = async (id) => {
    loadingStore.setLoading(true);
    try {
      const response = await client.get(`${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`);
      return response.data;
    } catch (error) {
      return {};
    } finally {
      loadingStore.setLoading(false);
    }
  };

  addProjectMembership = async (data, projectId) => {
    loadingStore.setLoading(true);

    try {
      const response = await client.post(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${projectId}/member`,
        data
      );
      // this.state.memberships = [...this.state.memberships, response];
      return response.data;
    } catch (error) {
      console.error(error);
      return error.response ? error.response.code : 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  updateProjectMembership = async (data) => {
    loadingStore.setLoading(true);
    try {
      await client.put(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${data.id}/member`,
        { userId: data.user_id, readOnly: data.readOnly }
      );

      // this.state.memberships = this.state.memberships.map((x) =>
      //   x.id === data.id ? data : x
      // );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };

  deleteProjectMembership = async (id, data) => {
    loadingStore.setLoading(true);
    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_URL}${ENDPOINT}/${id}/members`,
        { data }
      );

      this.state.memberships = this.state.memberships.filter(
        (x) => x.id !== id
      );

      return 1;
    } catch (error) {
      return 0;
    } finally {
      loadingStore.setLoading(false);
    }
  };
}

const projectStore = new ProjectStore();
export default projectStore;

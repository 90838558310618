import canI from '../../../utils/canI';
import PermissionChecker from '../../../utils/permissionChecker';

const Menu = ({ selected, setSelected }) => {
  const menu = [
    {
      label: 'General',
      to: '/setting/features/general'
    }, {
      label: 'Channels',
      to: '/setting/features/channels'
    }, {
      label: 'Organisation',
      to: '/setting/features/organisation'
    }, {
      label: 'Publishing',
      to: '/setting/features/publishing'
    }, {
      label: 'Tagging',
      to: '/setting/features/tagging'
    }
  ];

  const isPermissibleMenu = (label) => {
    if (label === 'Organisation') {
      return canI('read:organisations') || canI('read:locations') ||
        canI('read:departments') || canI('read:teams') || canI('read:programmes') ||
        canI('read:projects') || canI('read:folders');
    }
    return true;
  };

  return (
    <div className="flex gap-8 border-b-[1px] border-b-[#eee] bg-white pb-0 p-5 rounded">
      {menu.map((x, i) => {
        return PermissionChecker.hasSubMenuPermission(x.to, 'read') && isPermissibleMenu(x.label) && (
          <Item value={x.label} key={i} selected={selected} setSelected={setSelected} />
        );
      })}
    </div>
  );
};

export default Menu;

const Item = ({ value, selected, setSelected }) => {
  return (
    <button
      onClick={() => setSelected(value)}
      className={`${selected === value
        ? 'border-b-2 border-b-blue text-blue'
        : 'border-b-2 border-b-[rgba(0,0,0,0)]'
        } pb-5`}
    >
      {value}
    </button>
  );
};

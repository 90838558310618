import { useEffect, useState, useRef } from 'react';
import { FormControl } from '@mui/material';
import ModalHead from '../Header/modalHead';
import Quill from 'quill';
import Delta from 'quill-delta';
import 'quill/dist/quill.snow.css';
import { useSnackbar } from 'notistack';
import phraseStore from '../../../store/toolsandstyling/phrase';

const AddPhrase = ({ handleClose, isEdit, currentPhrase, type, fav }) => {
  const {
    createUserPhrase,
    createOrganisationPhrase,
    updateUserPhase,
    updateOrganisationPhrase
  } = phraseStore;
  const { enqueueSnackbar } = useSnackbar();

  const [content, setContent] = useState('');
  const [plainText, setPlainText] = useState('');
  const [phrase, setPhrase] = useState('');
  const [category, setCategory] = useState('TITLE');
  const editorRef = useRef();

  useEffect(() => {
    const quill = new Quill(editorRef.current, {
      theme: 'snow',
      placeholder: 'Write something...',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean']
        ],
        clipboard: {
          matchers: [
            [
              'SPAN',
              (node, delta) => {
                return delta.compose(new Delta().retain(delta.length()), {
                  user: 'user'
                });
              }
            ]
          ]
        }
      },
      height: 300
    });

    quill.on('text-change', () => {
      const delta = quill.getContents();
      setContent(JSON.stringify(delta));
      const plainText = quill.getText().trim();
      setPlainText(plainText);
    });

    if (currentPhrase) {
      try {
        const parsedContent = JSON.parse(currentPhrase.richText.delta);
        quill.setContents(parsedContent);
      } catch (err) { }
    }

    return () => {
      quill.off('text-change');
    };
  }, []);

  const adding = async () => {
    const data = {
      text: plainText,
      richText: {
        delta: content,
        deltaBase64: 'deltabase'
      },
      fav: !!fav,
      name: phrase,
      category
    };
    type === 'user' &&
      createUserPhrase(data).then((res) => {
        if (res === 1) {
          enqueueSnackbar('Phrase added successfully', { variant: 'success' });
          handleClose();
        }
        if (res === 0) {
          enqueueSnackbar('Failed to add a phrase', { variant: 'error' });
        }
      });

    type === 'org' &&
      createOrganisationPhrase(data).then((res) => {
        if (res === 1) {
          enqueueSnackbar('Phrase added successfully', { variant: 'success' });
          handleClose();
        }
        if (res === 0) {
          enqueueSnackbar('Failed to add a phrase', { variant: 'error' });
        }
      });
  };

  const editing = async () => {
    const data = {
      id: currentPhrase.id,
      scope: currentPhrase.scope,
      text: plainText,
      richText: {
        delta: content,
        deltaBase64: 'deltabase'
      },
      fav: !!fav,
      name: phrase,
      category
    };

    type === 'user' &&
      updateUserPhase(data).then((res) => {
        if (res === 1) {
          enqueueSnackbar('Phrase updated successfully', { variant: 'success' });
          handleClose();
        }
        if (res === 0) {
          enqueueSnackbar('Failed to updated a phrase', { variant: 'error' });
        }
      });

    type === 'org' &&
      updateOrganisationPhrase(data).then((res) => {
        if (res === 1) {
          enqueueSnackbar('Phrase updated successfully', { variant: 'success' });
          handleClose();
        }
        if (res === 0) {
          enqueueSnackbar('Failed to updated a phrase', { variant: 'error' });
        }
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    isEdit ? editing() : adding();
  };

  useEffect(() => {
    if (currentPhrase) {
      setPhrase(currentPhrase.name);
      setContent(currentPhrase.richText.delta);
      setPlainText(currentPhrase.text);
      setCategory(currentPhrase.category);
    }
  }, []);

  return (
    <div className="flex items-center h-full drop-shadow-lg">
      <form
        onSubmit={onSubmit}
        className="bg-white w-[500px] mx-auto rounded-lg"
      >
        <ModalHead
          handleClose={handleClose}
          heading={isEdit ? 'Edit Phrase' : 'Add Phrase'}
        />
        <div className="px-5">
          <FormControl className="mt-2" fullWidth>
            <label htmlFor="company">Phrase</label>
            <input
              className="border-[1px] border-gray outline-none p-4 rounded"
              value={phrase}
              onChange={(e) => setPhrase(e.target.value)}
            />
          </FormControl>

          <FormControl className="mt-2" fullWidth>
            <label htmlFor="company">Content</label>
            <div className="rounded bg-white phrase-editor border-[1px] border-gray rounded">
              <div ref={editorRef} className="quill-editor" />
            </div>
          </FormControl>

          <FormControl className="mt-2" fullWidth>
            <label htmlFor="company">Phrase Category</label>
            <select
              className="border-[1px] border-gray outline-none p-4 rounded"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {[
                {
                  display: 'Title',
                  value: 'TITLE'
                },
                {
                  display: 'Body - Intro',
                  value: 'BODYINTRO'
                },
                {
                  display: 'Body - Closing',
                  value: 'BODYCLOSING'
                },
                {
                  display: 'Body - Compliance',
                  value: 'BODYCOMPLIANCE'
                }
              ].map((x, i) => (
                <option key={i} value={x.value}>
                  {x.display}
                </option>
              ))}
            </select>
          </FormControl>

          <hr className="my-5" />

          <div className="flex justify-end">
            <button
              className="mb-4 text-blue border-[1px] border-blue rounded px-4 mr-2"
              onClick={handleClose}
              type="button"
            >
              Cancel
            </button>
            <button
              variant="contained"
              color="primary"
              type="submit"
              className="mb-4 mrvn-sv rounded"
            >
              {!isEdit ? 'Add Phrase' : 'Save Changes'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPhrase;
